import { useMutation } from "@apollo/client";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { togglePopupActions } from "../../../actions/togglePopupActions";
import { ExternalProps } from "../../../contexts/externalPropsContext";
import { CREATE_RSKU } from "../../../GraphQL";
import displayNotistack from "../../../lib/common/SnackBarUtils";
import { getProductData } from "../../../lib/common/util";
import {
  getConfigBom,
  getConfigObjectDataForSave,
  getLocalizationOption,
  setModelDataForDefaultConfig
} from "../../../services/utils";
import ExportRskuBomToExcel from "./ExportRskuBomToExcel";
import ProductList from "./ProductList";
import ProductSkeleton from "./ProductSkeleton";
import WithAddToQuote from "./withAddToQuote";

const useStyles = makeStyles(theme => ({
  title: {
    margin: 0,
    textAlign: "center"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  message: {
    fontWeight: "bold",
    fontSize: "1.12rem"
  },
  paper: {
    minWidth: "45%",
    minHeight: "35%"
  },
  infoContainer: {
    display: "flex",
    border: "1px solid #0096d6",
    padding: "10px 15px",
    margin: "10px 0px",
    borderRadius: 5
  },
  infoMessage: {
    fontSize: "small",
    paddingLeft: "10px"
  }
}));
const RequestRsku = props => {
  const { addCTOToQuote } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const baseModelCategory = localStorage.getItem("selectedCategory");

  // states
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isGraphQLErrors, setIsGraphQLErrors] = useState(false);
  const [requestrSKu, setRequestrSku] = useState({});
  const [newConfigId, setNewConfigId] = useState(null);
  const { modelData } = useSelector(state => state.configFilterData);
  const {
    requestRskuProduct,
    showRskuAddToQuote,
    allBomAVs,
    configID,
    proCategory,
    localization,
    rSKUBom,
    productCountryCode,
    productRegionCode,
    rskuImage,
    otherComponents,
    standardCarePacks,
    selectedWarrenty
  } = useSelector(state => state.rskuReducer);
  const {
    countryCode,
    country,
    userEmail,
    currencyCode,
    configurationName = "",
    userId = "",
    brID,
    companyId = "",
    spcType = "C",
    isStandaloneAccess = false
  } = React.useContext(ExternalProps);
  const [requestrSkuProduct, setRequestrSkuProduct] = useState([]);
  const {
    configNameRed,
    configuration,
    originSelectedItems,
    newSavedConfigId,
    accessType
  } = useSelector(state => state.configFilterData);
  let configNameRes = localStorage.getItem("configName");
  const docType = configuration?.configHeader?.docType;
  let selectedBaseUnit = undefined;
  let { formattedBOMItems: configBOM } = getConfigBom(modelData);
  if (configBOM && configBOM.length) {
    selectedBaseUnit = configBOM.find(config => config.choiceId === "BUNIT");
  }
  const refConfigId =
    docType === "SP" || docType === "CC"
      ? configuration?.configHeader?.configId?.toString() || ""
      : configuration?.configHeader?.refConfigId?.toString() || "";
  let bmiData = JSON.parse(localStorage.getItem("bmiData"));
  const mDCPOrgID = bmiData["mDCPOrgID"] || "";
  const systemLocalizationDefault = getLocalizationOption(
    modelData.systemLocOptions
  );
  const osLocalizationDefault = getLocalizationOption(modelData.osLocOptions);
  const keyBoardLocalizationDefault = getLocalizationOption(
    modelData.kybdLocOptions
  );
  let systemLocOptions, osLocOptions, kybdLocOptions;
  systemLocOptions =
    modelData.systemLocOptions &&
    modelData.systemLocOptions.filter(item => item.selected === true);
  const { requestSkuDialog } = useSelector(state => state.rskuReducer.popups);
  osLocOptions =
    modelData.osLocOptions &&
    modelData.osLocOptions.filter(item => item.selected === true);
  kybdLocOptions =
    modelData.kybdLocOptions &&
    modelData.kybdLocOptions.filter(item => item.selected === true);
  let countryLcLzRes = [
    {
      countryCode: productCountryCode,
      systemLocalization: systemLocOptions && systemLocOptions[0]?.locOption,
      osLocalization: osLocOptions && osLocOptions[0]?.locOption,
      keyBoardLocalization: kybdLocOptions && kybdLocOptions[0]?.locOption,
      delFl: "N"
    }
  ];
  const updatedChoicesDetails = setModelDataForDefaultConfig(modelData);
  const getConfigObjectDataRes = getConfigObjectDataForSave(
    updatedChoicesDetails
  );
  const { selectedSeries, selectedCategory } = useSelector(
    state => state.productSelector
  );

  //function calls
  const handleRequestSkuDialogClose = React.useCallback(
    (isGraphqlError, isComponentError) => {
      if (!(isGraphqlError || isComponentError)) {
        dispatch(
          togglePopupActions({
            name: "confirmationDialog",
            open: !isStandaloneAccess
          })
        );
        dispatch(
          togglePopupActions({
            name: "requestSkuDialog",
            open: !isStandaloneAccess
          })
        );
        dispatch({
          type: "SET_RSKU_DAILOG_ALERT",
          payload: !isStandaloneAccess
        });
      } else {
        dispatch(
          togglePopupActions({
            name: "requestSkuDialog",
            open: false
          })
        );
      }
    },
    []
  );

  const handleClickRsku = () => {
    setLoading(true);
    setIsGraphQLErrors(false);
    createRsku({
      variables: {
        input: {
          bomList: allBomAVs,
          localization,
          baseModelType: selectedCategory?.name || baseModelCategory || "",
          mloCd: selectedWarrenty,
          configID: `${configID}`,
          countryCode: productCountryCode,
          requestorEmail: userEmail,
          configName: configNameRed
            ? configNameRed
            : configNameRes || configurationName,
          configHeader: {
            configType: configuration?.configHeader?.configType || "custom",
            leadCountryCode: productCountryCode || "US",
            configHighLights:
              configuration?.configHeader?.configHighlights || "",
            configImage:
              encodeURIComponent(configuration?.configHeader?.configImage) ||
              "",
            kmatId: configuration?.configHeader?.kmatId || "",
            baseUnitAv:
              selectedBaseUnit?.materialNumber ||
              configuration?.configHeader?.baseUnitAv ||
              "",
            programName: configuration?.configHeader?.programName || "",
            accessType,
            refConfigId,
            userId: userId,
            userEmail: userEmail,
            customerCompanyName:
              brID ||
              (companyId
                ? companyId
                : configuration?.configHeader?.companyName) ||
              "",
            mdcpOrgId: mDCPOrgID
              ? mDCPOrgID
              : configuration?.configHeader?.mdcpOrgId || "",
            originatingAsset: "RSKU",
            bandingFlag: "N",
            bandingType: "OC-FIX",
            docType: "QU",
            regionCode:
              productRegionCode ||
              configuration?.configHeader?.rgnCd ||
              configuration?.configHeader?.regionCode,
            lead_locale: configuration?.configHeader?.leadLocale || "",
            autoLocalized: configuration?.configHeader?.autoLocalized || "",
            kbVersion: configuration?.configHeader?.kbVersion || "",
            marketProgramName:
              configuration?.configHeader?.mktProgramName || "",
            marketProgramType:
              configuration?.configHeader?.mktProgramType || "",
            shipmentDate: configuration?.configHeader?.shipmentDate
              ? moment(configuration?.configHeader?.shipmentDate).format(
                  "YYYY-MM-DD"
                )
              : null,
            exportStatusId: configuration?.configHeader?.exportStatusId || "",
            bandingEol: configuration?.configHeader?.bandingEol
              ? moment(configuration?.configHeader?.bandingEol).format(
                  "YYYY-MM-DD"
                )
              : null,
            configEol: configuration?.configHeader?.configEol
              ? moment(configuration?.configHeader?.configEol).format(
                  "YYYY-MM-DD"
                )
              : null,
            businessModel: configuration?.configHeader?.businessModel
              ? configuration?.configHeader?.businessModel.toLowerCase()
              : "indirect",
            copiedFrom: configuration?.configHeader?.copiedFrom || "",
            localizationType: configuration?.configHeader?.lclznType || "",
            priceGeo: configuration?.configHeader?.priceGeo || "",
            currencyCd: configuration?.configHeader?.currCd
              ? configuration?.configHeader?.currCd.toUpperCase()
              : configuration?.configHeader?.currencyCd
              ? configuration?.configHeader?.currencyCd.toUpperCase()
              : "USD",
            incoterm:
              configuration?.configHeader?.incoterm || modelData.incoTerm || "",
            priceListType: configuration?.configHeader?.priceListType || "",
            globalFl: configuration?.configHeader?.globalFl || "N",
            activeFl: configuration?.configHeader?.activeFl || "Y",
            delFl: configuration?.configHeader?.delFl || "N",
            favoriteFl: "N",
            productLine: modelData?.modelRequirement?.productGroup || "DT",
            storeFront: "IQ",
            startDate: configuration?.configHeader?.startDate
              ? moment(configuration?.configHeader?.startDate).format(
                  "YYYY-MM-DD"
                )
              : null,
            endDate: configuration?.configHeader?.endDate
              ? moment(configuration?.configHeader?.endDate).format(
                  "YYYY-MM-DD"
                )
              : null,
            geoMrkt: "",
            spcType
          },
          countryLcLz: countryLcLzRes,
          configBom: configBOM && configBOM.length ? configBOM : [],
          configObject: {
            configDefault: {
              configuration: {
                localization: [
                  {
                    choiceId: "systemLocalization",
                    values: [...systemLocalizationDefault]
                  },
                  {
                    choiceId: "osLocalization",
                    values: [...osLocalizationDefault]
                  },
                  {
                    choiceId: "keyBoardLocalization",
                    values: [...keyBoardLocalizationDefault]
                  }
                ],
                choices: [...getConfigObjectDataRes]
              }
            },
            configBanding: { banding: {} }
          },
          hierarchy: {
            categoryName:
              selectedCategory?.name ||
              baseModelCategory ||
              "Business Desktop PCs",
            seriesName: selectedSeries?.name || "HP Elite Slice series",
            productType: proCategory || "compute"
          }
        }
      }
    });
  };

  // hooks call
  useEffect(() => {
    handleClickRsku();
  }, []);

  useEffect(() => {
    if (requestrSkuProduct?.length) {
      dispatch({
        type: "SET_REQUEST_RSKU",
        payload: {
          requestRsku: requestrSkuProduct
        }
      });

      return () => {
        dispatch({
          type: "SET_REQUEST_RSKU",
          payload: {
            requestRsku: []
          }
        });
      };
    }
  }, [requestrSkuProduct]);

  useEffect(() => {
    if (!isEmpty(requestrSKu)) {
      const requestRskuProductData = getProductData(
        requestrSKu,
        rSKUBom,
        currencyCode,
        rskuImage
      );
      setRequestrSkuProduct(requestRskuProductData);
    }
  }, [requestrSKu]);

  // middleware calls
  const [createRsku] = useMutation(CREATE_RSKU, {
    fetchPolicy: "no-cache",

    onCompleted(response) {
      setLoading(false);
      setIsError(false);
      if (response) {
        const { rskuMaterialNumber, shortDescription, configID } =
          response.createRSKU;
        if (rskuMaterialNumber) {
          const rskuProduct = {
            rskuMaterialNumber,
            shortDescription
          };
          setRequestrSku(rskuProduct);
          setNewConfigId(configID);
          const successMsg = `Successfully saved ${configID}`;
          displayNotistack.success(successMsg, "success", 6000);
        } else {
          setIsError(true);
        }
      } else {
        setIsError(true);
      }
    },
    onError({ graphQLErrors, networkError }) {
      setLoading(false);
      setIsGraphQLErrors(true);
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (localStorage.getItem("isDebug") === "true") {
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
          }
        });
      }
      if (networkError) {
        if (localStorage.getItem("isDebug") === "true") {
          console.log(networkError);
        }
      }
    }
  });

  return (
    <Dialog
      open={true}
      onClose={false}
      fullWidth
      maxWidth="md"
      classes={{ paper: isGraphQLErrors && classes.paper }}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          {t("common:rsku.requestSKU.mainTitle")}
        </Typography>
        <IconButton
          onClick={() => handleRequestSkuDialogClose(isGraphQLErrors, isError)}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {!loading ? (
          !isGraphQLErrors ? (
            <>
              <Typography variant="h6" style={{ fontSize: "medium" }}>
                {t("common:rsku.requestSKU.subTitle")}
              </Typography>
              <Box>
                {isError ? (
                  <>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      {t("common:rsku.requestSKU.queryFailLine1")}
                    </Typography>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      {t("common:rsku.requestSKU.queryFailLine2")}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Box className={classes.infoContainer}>
                      <InfoOutlinedIcon color="primary" />
                      <Typography
                        color="primary"
                        className={classes.infoMessage}
                      >
                        {t("common:rsku.requestSKU.tatDisclaimer")}
                      </Typography>
                    </Box>
                    {!isEmpty(otherComponents) && (
                      <Box className={classes.infoContainer}>
                        <InfoOutlinedIcon color="primary" />
                        <Box
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            color="primary"
                            className={classes.infoMessage}
                            style={{
                              marginBottom: "10px"
                            }}
                          >
                            {t("common:rsku.dialog.configurationWarningOne")}
                            {!isStandaloneAccess && (
                              <span>
                                {t(
                                  "common:rsku.dialog.configurationWarningTwo"
                                )}
                              </span>
                            )}
                          </Typography>
                          <Typography
                            color="primary"
                            className={classes.infoMessage}
                          >
                            {t("common:rsku.dialog.configurationWarningThree")}
                          </Typography>

                          {otherComponents?.length && (
                            <Box component={"ul"} style={{ margin: 0 }}>
                              {otherComponents.map(each => (
                                <Box
                                  component={"li"}
                                  style={{ color: "#0096d6" }}
                                >
                                  <Typography
                                    color="primary"
                                    style={{ fontSize: "small" }}
                                  >
                                    {each.partNumber} - {each.description}
                                  </Typography>
                                </Box>
                              ))}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}
                    {!isStandaloneAccess && !isEmpty(standardCarePacks) && (
                      <Box className={classes.infoContainer}>
                        <InfoOutlinedIcon color="primary" />
                        <Typography
                          color="primary"
                          className={classes.infoMessage}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {t("common:rsku.dialog.standardCarepack")}
                        </Typography>
                      </Box>
                    )}
                    {requestRskuProduct.map(product => (
                      <ProductList
                        key={product.sku}
                        {...product}
                        image={rskuImage}
                      />
                    ))}
                  </>
                )}
              </Box>
            </>
          ) : (
            <>
              <Typography color="primary" variant="h6">
                {t("common:rsku.requestSKU.graphQLfail")}
              </Typography>
            </>
          )
        ) : (
          <ProductSkeleton />
        )}
      </DialogContent>
      <DialogActions>
        {isGraphQLErrors ? (
          <Button
            variant="contained"
            color="primary"
            loading={loading}
            onClick={handleClickRsku}
          >
            {t("common:rsku.button.tryAgain")}
          </Button>
        ) : (
          // Remove the code once hardening sprint tested succesfully
          /*    <Button
            variant="contained"
            color="primary"
            disabled={!showRskuAddToQuote}
            onClick={addBTOToQuote}
          >
            {t("common:rsku.button.addToQuote")}
          </Button> */
          <>
            {requestSkuDialog?.open && isStandaloneAccess && (
              <ExportRskuBomToExcel loading={loading} isError={isError} />
            )}
            {!isStandaloneAccess && (
              <Button
                variant="contained"
                color="primary"
                disabled={isStandaloneAccess || !showRskuAddToQuote}
                onClick={addCTOToQuote}
              >
                {t("common:rsku.button.addToQuote")}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default WithAddToQuote(RequestRsku);
