import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "10px 0px 8px 0px"
  },
  configid_container: {
    height: "74px",
    background: "#FFFFFF",
    boxShadow: "0px 5px 12px rgb(0 0 0 / 25%) !important",
    borderRadius: "4px",
    padding: "0 15px"
  },
  heading: {
    fontSize: "16px",
    color: "#000000"
  }
}));

const ConfigID = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const newSavedConfigId = useSelector(
    state => state.configFilterData.newSavedConfigId
  );
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );

  const [docType, setDocType] = useState(null);
  const [configID, setConfigID] = useState(null);

  //based on the response from API set local states here
  useEffect(() => {
    if (configuration?.configHeader) {
      const {
        configHeader: { docType: docTypeFromApi, configId }
      } = configuration;
      setDocType(docTypeFromApi);
      setConfigID(configId);
    }
    if (props?.isUploadFlow) {
      setDocType("QU");
    }
  }, [configuration]);

  const getConfigId = useCallback(() => {
    if (docType == "QU" || docType == "CC") {
      return configID || "-";
    } else if (docType == "SP") {
      return "-";
    }
  }, [docType]);

  useEffect(() => {
    dispatch({
      type: "SET_CONFIGID",
      payload:
        docType && !newSavedConfigId
          ? getConfigId()
          : docType && newSavedConfigId
          ? newSavedConfigId
          : "-"
    });
  }, [docType, newSavedConfigId]);

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems="center"
        className={classes.configid_container}
      >
        <Grid item xs={12} sm={6}>
          <Typography className={classes.heading}>Configuration ID</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.heading}>
            {docType && !newSavedConfigId
              ? getConfigId()
              : docType && newSavedConfigId
              ? newSavedConfigId
              : "-"}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default ConfigID;
