import Chip from "@material-ui/core/Chip";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import displayNotistack from "./SnackBarUtils";

export default function ClipboardField(props) {
  const { t } = useTranslation();
  const {
    text,
    partNumber,
    size,
    fontSize,
    color,
    variant,
    onClick,
    component,
    icon,
    style
  } = props;
  const iconProps = {
    size: size ? size : "small",
    fontSize: fontSize ? fontSize : "small",
    color: color ? color : "primary",
    variant: variant ? variant : "outLined",
    component: component,
    icon: icon ? icon : null,
    style: style ? style : {}
  };
  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        displayNotistack.info(t("common:snackbar.copied"), { autoHideDuration: 2000 });
      }}
    >
      {partNumber ? (
        <Chip {...iconProps} label={partNumber} onClick={onClick} />
      ) : (
        <FileCopyOutlinedIcon
          {...iconProps}
          style={{ paddingLeft: 4, paddingTop: 6, cursor: "pointer" }}
          onClick={e => {
            e.stopPropagation();
          }}
        />
      )}
    </CopyToClipboard>
  );
}
