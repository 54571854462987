import { ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { Container, CssBaseline, MuiThemeProvider } from "@material-ui/core";
import React from "react";
import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import Popups from "../src/components/Configurator/Rsku/Popups";
import FullscreenModal from "../src/components/FullscreenModal";
import { HPMuiTheme } from "../src/config/theme";
import ConfiguratorContainer from "./components/Configurator/ConfigContainer";
import ManageMyConfigs from "./components/ManageMyConfig/ManageMyConfigs";
import configureStore from "./configureStore";
import ProductSelectorContainer from "./containers/ProductSelectorContainer";
import { ExternalPropsProvider } from "./contexts/externalPropsContext";
import i18n from "./i18n/index";
import displayNotistack, {
  SnackbarUtilsConfigurator,
  StyledSnackbarProvider
} from "./lib/common/SnackBarUtils";
import { getRefreshedToken, getStorageValue } from "./lib/common/util";
import ViewDetails from "./lib/common/ViewDetails";
import UploadConfig from "./models/configuration/upload";
import * as serviceWorker from "./serviceWorker";

const { ApolloClient, InMemoryCache } = require("@apollo/client");
const { createUploadLink } = require("apollo-upload-client");

const store = configureStore();

let rootEle = document.getElementById("product-selector");

if (!rootEle) {
  console.log("rootEle", rootEle);
  rootEle = document.getElementById("upload-config");
}
if (!rootEle) {
  rootEle = document.getElementById("configurator")
    ? document.getElementById("configurator")
    : document.getElementById("oneconfig-manage-myconfig");
}
if (rootEle && document.querySelector("#loaderWrapper") !== null) {
  document.querySelector("#loaderWrapper").style.display = "none";
}
let parsedDataObject = undefined;
if (rootEle.dataset.configsettings && !parsedDataObject) {
  parsedDataObject = JSON.parse(rootEle.dataset.configsettings);
  if (typeof parsedDataObject === "string") {
    parsedDataObject = JSON.parse(parsedDataObject);
  }
} else {
  parsedDataObject = {
    countryCode: "US",
    pricingGeo: "US",
    configRegion: "NA",
    currencyCode: "USD",
    incoterms: "DDP",
    purchaseAgreement: "",
    productLine: "",
    userId: "shashikantk",
    userType: "INTERNAL",
    userEmail: "shashikant.kalaskar@hp.com",
    companyId: "itgpanhpi",
    productCategory: "",
    productCatalog: "Business Desktop PCs",
    resellerID: "",
    distributorId: "",
    routeToMarket: "Direct",
    mDCPOrgID: "99",
    client: "iqintegrated",
    clientLocale: "en",
    channelRegion: "",
    quoteNumber: "",
    transactionID: "9467234642",
    configJWT:
      "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyRW1haWwiOiJzaGFzaGFuay5zYXVyYXZAaHAuY29tIiwidXNlcklkIjoic2hhc2hhbmsuc2F1cmF2IiwidXNlck5hbWUiOiJzaGFzaGFuay5zYXVyYXYiLCJleHAiOjE2MzM5NDU1MzN9.QM3Sc7t_2J7wJ_uBI9oEesROVm4l70PdCinTz8USQniB-LwtcH2o54snEllDMWfpm2547779u50yO0OS2QJ0KtXzIcvQYHLxkfqPoz-D0XENBTHYhXvMuI2nSPqbGIWiJAU0ZDY4y-UCPxHMhlb3Owf_atwZt57nIujeAsyGJE1dbBftwPocdr1jviWXkpi6R_4YDZegGGIUX_zT8rq80bt8ninIl7nWQmqKLStEolu3LSL5C-vvTz8VRf634uuQ4MXyvZetfzfSYNFusgyD3pyxhcRAHmjLgKDQwU1m1Ki287rZAC4I1XNxLqqlwdy94zNsoE-i1sH9P8YjVjiYfA",
    authToken:
      "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJRCI6IjU0ZjY2MGZlLWQwMGYtMTFlYS04NTYxLTBhNThhOWZlYWMyYSIsImF1dGhvcml6ZWQiOnRydWUsImV4cCI6MTYzNjU1OTQ4NiwicGVybWlzc2lvbnMiOlsiTVVUQVRJT05fUEVSTUlTU0lPTiIsIlFVRVJZX1BFUk1JU1NJT04iLCJRVU9URV9TSEFSSU5HX1BFUk1JU1NJT04iLCJGSUxFVVBMT0FEX1BFUk1JU1NJT04iXSwicm9sZUlEIjoiZjY2MjlkNDgtMGIxNi0xMWVjLWJiMTMtMGE1OGE5ZmVhYzAyIiwidXNlcklEIjoiOTRmNDZjZWMtMmJlYi0xMWVjLWE4YzMtMGE1OGE5ZmVhYzAyIiwidXNlck5hbWUiOiJsYWtzaG1hbi5zYWkua3VtYXIucGFuZGlyaUBocC5jb20ifQ.F59Aaa7oA-Lnlay5NUtY5nifJhaPVEp0rh3c1gjUcRflZVBuDTzwIORVNlczUKALLbq74XlQ5HkyMUdZIB9JiOP3BRnq1TYEzYBkEI2QYILXyWrJKTzBJCoRW8VQEXtvNdSnBKMGtcQENpVio8If7w6Zg7qv5dGFlGj-ahAF5xfb3oJS4ErURmdLKBQdzYf1UBJkMKOXk9WxuuwnJA5FWaNmHPh2w_T0YLKdlgXT0Vd-jdcZSYJI2OAV8G-fzYrejT_MTPrDh0QnfRFJUpYeoc7dxJcJFTEX0wRsRbOzAEqoUNDdwiT_XfGetMBtbmRS_RSuoMX3u55YvLqroa3Mo8lW4NY3px8OQTE2HWyaqs-9yrln4ZD0t92y5bWVGkAZ6p4j8KSTiZFzWvnNNI9dGYrwHDsaV-yRbydwl711_woGjex0enz2pNBLfGsVODMgxJk1bIjUjTFvuU4r3Z5qQHl-mO5Pwo2LlUghtmTt46uGZeKxfX66AogWlM_xe-TtbY5Eeyo0lCgrG10x8Inzc9vC4goDcLcA5moZuLvVYyM0stQUuF21lca9A86YxMVJLQ-iEMHnlPrcIXBM-NPZpFObGQsCyX0j8jdJvBXUPWxCH_BLZGf-daBVXvRqeHOWCMuwKOBuBJJt2BEIYsw3CIXC4hgAoaVanp1DFy8Awtc",
    isManageConfig: false,
    isPartnerPortalFlag: false,
    isOpenOneConfigReconfigure: false,
    isDisplayProdCountry: false,
    isStandaloneAccess: false,
    isListOfPLForPANumber: false,
    saveConfigurationLabel: "SAVE CONFIGURATION",
    spcType: "C",
    configurationName: "",
    visibility: {
      showCustomBandedFlag: true,
      showPreApprovedConfigurationButton: true,
      showManageMyConfigButton: true,
      showConfigurationButton: true,
      showStartOverButton: true,
      showExportToPdf: true,
      showExportToExcel: true,
      showConfigurationID: true,
      showConfigurationName: true,
      showMonitorsAccessoriesTab: true,
      showAccessType: true,
      showStartingPointInformation: true,
      showGaDateConfigError: true
    }
  };
}

const localeTable = {
  de_DE: "de",
  fr_FR: "fr",
  it_IT: "it",
  es_ES: "es"
};

if (parsedDataObject?.clientLocale) {
  localStorage.setItem(
    "i18nextLng",
    localeTable[parsedDataObject.clientLocale] || parsedDataObject.clientLocale
  );
  i18n.changeLanguage();
}
if (!parsedDataObject.kmatId) {
  setTimeout(() => {
    localStorage.setItem("bmiData", JSON.stringify(parsedDataObject));
  });
}

let isProductSelector = document.getElementById("product-selector");

let isUploadConfig = document.getElementById("upload-config");
if (!isUploadConfig) {
  isUploadConfig = localStorage.getItem("isUploadConfig");
}

let isManageMyConfig = document.getElementById("oneconfig-manage-myconfig");
const clientOrigin = getStorageValue("clientOrigin");
// WalkMe Implementation
var walkmeScript = document.createElement("script");
walkmeScript.type = "text/javascript";
walkmeScript.async = true;
walkmeScript.src =
  "https://cdn.walkme.com/users/abf5be86fee448c08494155593f46d83/test/walkme_abf5be86fee448c08494155593f46d83_https.js";
document.body.appendChild(walkmeScript);
var s = document.getElementsByTagName("script")[0];
s.parentNode.insertBefore(walkmeScript, s);
window._walkmeConfig = { smartLoad: true };
window.walkme_get_language = function () {
  let languageCode = localStorage.getItem("i18nextLng") || "";
  if (languageCode) {
    if (languageCode.includes("-")) {
      languageCode = languageCode.split("-")[0];
    }
    if (languageCode.includes("_")) {
      languageCode = languageCode.split("_")[0];
    }
  }
  languageCode = languageCode.toUpperCase();
  return !languageCode || languageCode === "EN" ? "" : languageCode;
};
// Web Assembly Initialization
let webAssemblyScript = undefined;
webAssemblyScript = document.createElement("script");
webAssemblyScript.type = "text/javascript";
// IE fix
// webAssemblyScript.src = `${
//   process.env.REACT_APP_HPID_DOMAIN_URL + "/wasm/encoding-indexes.js"
// }`;
// webAssemblyScript.src = `${
//   process.env.REACT_APP_HPID_DOMAIN_URL + "/wasm/encoding.js"
// }`;
webAssemblyScript.src = `${
  process.env.REACT_APP_S3_BUCKET_URL + "/wasm_exec.js"
}`;
document.body.appendChild(webAssemblyScript);

//swiper stylesheet

let swiperStyleSheet = document.createElement("link");
swiperStyleSheet.type = "text/css";
swiperStyleSheet.rel = "stylesheet";
swiperStyleSheet.href =
  "https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css";

document.head.appendChild(swiperStyleSheet);

// swiper script

let swiperScript = document.createElement("script");
swiperScript.type = "text/javascript";
swiperScript.src = "https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.js";
document.head.appendChild(swiperScript);

//Initializing Graph QL here
//When first call is made, token would be fetched from parsedDataObject.
//For subsequent calls, Apollo will take it from local storage.
window.userServiceToken = parsedDataObject?.authToken;
window.authToken = parsedDataObject?.configJWT;
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${window.authToken}`
    }
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (localStorage.getItem("isDebug") === "true") {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        }
        let displayMessage = "";
        try {
          displayMessage = JSON.parse(message);
        } catch (err) {
          displayMessage = message;
        }
        message &&
          displayNotistack.error(
            false,
            typeof displayMessage !== "string"
              ? displayMessage.message
              : displayMessage ||
                  i18n.t("common:productSelector.errorMessage.graphQLErrors")
          );
      });
    }
    if (networkError) {
      if (networkError.statusCode === 401) {
        getRefreshedToken({ displayNotistack });
      } else {
        displayNotistack.error(false, i18n.t("common:table.networkErrorMsg"));
      }
    }
  }
);

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_GRAPHQL_BASE_URL}/model`,
  cache: new InMemoryCache({
    addTypename: false
  }),
  link: authLink.concat(
    errorLink.concat(
      createUploadLink({
        uri: `${process.env.REACT_APP_GRAPHQL_BASE_URL}/model`
      })
    )
  )
});

const configContainer = () => {
  return (
    <ConfiguratorContainer
      config={parsedDataObject}
      proCategory={parsedDataObject.proCategory}
    />
  );
};

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <MuiThemeProvider theme={HPMuiTheme}>
          <CssBaseline />
          <I18nextProvider i18n={i18n}>
            <ExternalPropsProvider value={parsedDataObject}>
              <StyledSnackbarProvider>
                <SnackbarUtilsConfigurator />
                {isManageMyConfig ? (
                  <ManageMyConfigs />
                ) : isUploadConfig ? (
                  <Container>
                    <UploadConfig />
                  </Container>
                ) : isProductSelector ? (
                  <ProductSelectorContainer />
                ) : clientOrigin === "BMI" ? (
                  <FullscreenModal isOpen={true}>
                    {configContainer()}
                  </FullscreenModal>
                ) : (
                  configContainer()
                )}
                <Popups />
                <ViewDetails />
              </StyledSnackbarProvider>
            </ExternalPropsProvider>
          </I18nextProvider>
        </MuiThemeProvider>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  rootEle
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
