import { Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import ComputerIcon from "@material-ui/icons/Computer";
// import { withRouter } from "react-router";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import ConfiguratorContainer from "../../../components/Configurator/ConfigContainer";
import FullscreenModal from "../../../components/FullscreenModal";
import ClipboardField from "../../../lib/common/ClipboardField";
import TableSkeleton from "../../../lib/common/TableSkeleton";
import CloningRsku from "./CloningRsku";
import { BTO_SKU } from "./Constants";

const headCells = [
  {
    width: "1"
  },
  {
    width: "1"
  },
  {
    width: "1"
  },
  {
    width: "2"
  },
  {
    width: "2"
  },
  {
    width: "1"
  },
  {
    width: "1"
  },
  {
    width: "1"
  },
  {
    width: "1"
  },
  {
    width: "1"
  }
];

const btoHeadCells = [
  {
    width: 1
  },
  {
    width: 2
  },
  {
    width: 6
  },
  {
    width: 2
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    alignItems: "left",
    justifyContent: "left",
    paddingTop: "10px"
  },
  container: {
    maxHeight: 440
  },
  topPadding: {
    paddingTop: "20px"
  },
  headStyle: {
    fontSize: 15,
    fontWeight: "bold"
  },
  cellEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  textTransform: {
    textTransform: "capitalize"
  },
  wordBreak: {
    overflowWrap: "break-word"
  },
  fillColor: {
    color: "#ffd700"
  },
  searchResult: {
    fontFamily: "hpsimplified",
    fontSize: "normal",
    fontWeight: "normal",
    marginRight: "256px"
  },
  chipStyle: {
    color: "#000000",
    fontSize: 16
  },
  iconStyle: {
    color: "#000000"
  }
}));

function ConfigResultsTable(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const columns = useSelector(state => state.productSelector.columns);
  const configResultList = useSelector(
    state => state.productSelector.configResultList
  );
  const searchType = useSelector(state => state.productSelector.searchType);
  const page = useSelector(state => state.productSelector.page);
  const category = useSelector(state => state.productSelector.category);
  const tableHeadCells = searchType === BTO_SKU ? btoHeadCells : headCells;
  const { btoSkuInfo, warningMessage } = useSelector(
    state => state.productSelector
  );
  const { isSkuCloning } = useSelector(state => state.rskuReducer);
  const dispatch = useDispatch();
  const handleChangePage = (event, newPage) => {
    dispatch({
      type: "SET_RESET_PAGE",
      payload: newPage
    });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    dispatch({
      type: "SET_RESET_PAGE",
      payload: 0
    });
  };

  const setConfigurePath = config => {
    localStorage.setItem("selectedCategory", config.productCategory || "");
    localStorage.setItem("configName", config.configName);
    setConfig(config);
    setOpen(true);
    //   props.history.push({
    //     pathname: '/configurator/' + config.configID,
    //     configName: config.configName,
    // });
  };

  const setRskuConfigurePath = skuIDResult => {
    batch(() => {
      dispatch({ type: "SEARCH_SKU_ID", payload: skuIDResult });
      dispatch({ type: "SET_CLONING_SKU", payload: true });
    });
  };

  const handleFullscreenModalClose = () => {
    setOpen(false);
  };

  const tableRows = _.orderBy(configResultList, ["favorite"], ["desc"]).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  if (localStorage.getItem("isDebug") === "true") {
    console.log("category", category);
  }
  return props.isLoading ? (
    <TableSkeleton
      lineNumber={3}
      cells={tableHeadCells}
      spacing={3}
      height={20}
    />
  ) : configResultList && configResultList.length > 0 ? (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow hover>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  classes={{ head: classes.headStyle }}
                >
                  {t(column.label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.map((row, index) => (
              <TableRow hover key={index}>
                {columns.map(column => {
                  // const configID = configResultList[index].configID;
                  // const configName = configResultList[index].configName;
                  const value = row[column.id];
                  return column.id !== "configPunchInURL" ? (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.wordBreak}
                      style={{
                        maxWidth: column.minWidth
                      }}
                    >
                      {column.displayAs === "image" ? (
                        value === "Y" ? (
                          <div style={{ textAlign: "center" }}>
                            <StarRoundedIcon className={classes.fillColor} />
                          </div>
                        ) : (
                          ""
                        )
                      ) : column.displayAs === "chip" ? (
                        !btoSkuInfo?.length ? (
                          <ClipboardField
                            text={value}
                            component="span"
                            variant="outlined"
                            size="small"
                            partNumber={value}
                          />
                        ) : (
                          <Chip
                            className={classes.chipStyle}
                            icon={
                              <ComputerIcon className={classes.iconStyle} />
                            }
                            label={value}
                          />
                        )
                      ) : column.format && typeof value === "number" ? (
                        column.format(value)
                      ) : column.id === "createdAt" ? (
                        moment(value).format("L")
                      ) : (
                        value
                      )}
                    </TableCell>
                  ) : (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ maxWidth: column.minWidth }}
                    >
                      <Button
                        classes={{ root: classes.textTransform }}
                        variant="text"
                        color="primary"
                        onClick={
                          !btoSkuInfo?.length
                            ? () => setConfigurePath(tableRows[index])
                            : () => setRskuConfigurePath(tableRows[index])
                        }
                        style={{ backgroundColor: "transparent" }}
                      >
                        {t("common:productSelector.button.Configure")}
                      </Button>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={(configResultList && configResultList.length) || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("common:table.rowsPerPage")}
      />

      {isSkuCloning && <CloningRsku />}
      <FullscreenModal
        isOpen={open}
        handleModalClose={() => handleFullscreenModalClose()}
      >
        <ConfiguratorContainer
          proCategory={category}
          config={config}
          setOpen={setOpen}
        ></ConfiguratorContainer>
      </FullscreenModal>
    </React.Fragment>
  ) : (
    <Typography variant="h6" align="left">
      <Box className={classes.searchResult}>
        {!warningMessage?.length
          ? t("common:productSelector.errorMessage.resultNotFound")
          : warningMessage}
      </Box>
    </Typography>
  );
}

export default ConfigResultsTable;
