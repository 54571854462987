import Box from "@material-ui/core/Box";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import Typography from "@material-ui/core/Typography";
import DibHardware from "@material-ui/icons/AcUnit";
import KitIcon from "@material-ui/icons/AllInbox";
import AppsSharpIcon from "@material-ui/icons/AppsSharp";
import BatteryStdIcon from "@material-ui/icons/BatteryStd";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import BusinessCenterSharpIcon from "@material-ui/icons/BusinessCenterSharp";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import PackagingIcon from "@material-ui/icons/CardGiftcardRounded";
import CardReaderIcon from "@material-ui/icons/ChromeReaderMode";
import HpStandAloneAnalyticsIcon from "@material-ui/icons/ConfirmationNumber";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import ContactMailOutlinedIcon from "@material-ui/icons/ContactMailOutlined";
import NetworkingIcon from "@material-ui/icons/Contacts";
import PriorityServiceIcon from "@material-ui/icons/ContactSupport";
import DesktopMacOutlinedIcon from "@material-ui/icons/DesktopMacOutlined";
import ChassisIcon from "@material-ui/icons/Devices";
import CarepackIcon from "@material-ui/icons/Favorite";
import GraphicCardIcon from "@material-ui/icons/GraphicEq";
import GroupWorkSharpIcon from "@material-ui/icons/GroupWorkSharp";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import EnergyLabelIcon from "@material-ui/icons/InsertChart";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import WarrantyIcon from "@material-ui/icons/LocalConvenienceStore";
import OSLocalizationIcon from "@material-ui/icons/LocalFlorist";
import MemoryIcon from "@material-ui/icons/Memory";
import MouseIcon from "@material-ui/icons/Mouse";
import PowerIcon from "@material-ui/icons/Power";
import SdStorageIcon from "@material-ui/icons/SdStorage";
import SdStorageSharpIcon from "@material-ui/icons/SdStorageSharp";
import SecuritySharpIcon from "@material-ui/icons/SecuritySharp";
import IdMechanicalIcon from "@material-ui/icons/Settings";
import SlimBayIcon from "@material-ui/icons/SettingsInputHdmi";
import SettingsInputHdmiSharpIcon from "@material-ui/icons/SettingsInputHdmiSharp";
import StorageIcon from "@material-ui/icons/Storage";
import StandsIcon from "@material-ui/icons/Straighten";
import ViewCompactIcon from "@material-ui/icons/ViewCompact";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import React from "react";
import ContentLoader from "react-content-loader";
import {
  CLONING_SKU,
  HP_LOGO
} from "../../components/Configurator/Rsku/Constants";
import i18n from "../../i18n";
import displayNotistack from "./SnackBarUtils";

// variety of material icon here https://material.io/tools/icons/?search=desk&style=baseline

export const getHPColor = (category = "hardware") => {
  const hpcolor = {
    hardware: "#03a2e9",
    plforservice: "#76c842",
    plforaccessories: "#d00e86",
    plfordisplay: "#e8f613",
    others: "#4eacb1"
  };
  return hpcolor[category.toLowerCase()];
};
export const getTaxQuery = (quote, lineItemRecords = []) => {
  const quoteInput = {
    deliveryTerms: quote.incoterms_quote ? quote.incoterms_quote.value : "",
    isSEZ: quote.isSEZ_quote,
    gstID: quote.gstID_quote ? quote.gstID_quote : "",
    transactionID: quote.transactionID_quote ? quote.transactionID_quote : "",
    customerName: quote.accountName_quote ? quote.accountName_quote : "",
    customerNumber: quote.cRSID_quote ? quote.cRSID_quote : "",
    countryCode: quote.country_quote ? quote.country_quote.value : "",
    currencyCode: quote.currency_quote ? quote.currency_quote.value : "",
    address: [
      {
        addressType: "BillTo",
        transactionID: quote.transactionID_quote
          ? quote.transactionID_quote
          : "",
        countryCode: quote._billTo_country ? quote._billTo_country.value : "",
        state: quote._billTo_state ? quote._billTo_state.value : "",
        city: quote._billTo_city ? quote._billTo_city : "",
        zip: quote._billTo_zip ? quote._billTo_zip : ""
      },
      {
        addressType: "SoldTo",
        transactionID: quote.transactionID_quote
          ? quote.transactionID_quote
          : "",
        countryCode: quote._soldTo_country ? quote._soldTo_country.value : "",
        state: quote._soldTo_state ? quote._soldTo_state.value : "",
        city: quote._soldTo_city ? quote._soldTo_city : "",
        zip: quote._soldTo_zip ? quote._soldTo_zip : ""
      },
      {
        addressType: "ShipTo",
        transactionID: quote.transactionID_quote
          ? quote.transactionID_quote
          : "",
        countryCode: quote._shipTo_country ? quote._shipTo_country.value : "",
        state: quote._shipTo_state ? quote._shipTo_state.value : "",
        city: quote._shipTo_city ? quote._shipTo_city : "",
        zip: quote._shipTo_zip ? quote._shipTo_zip : ""
      },
      {
        addressType: "ShipFrom",
        transactionID: quote.transactionID_quote
          ? quote.transactionID_quote
          : "",
        countryCode: quote.country_quote ? quote.country_quote.value : "",
        state: quote.billingState_quote
      }
    ]
  };

  const lineItemInput = lineItemRecords.map(each => {
    return {
      parentDocNumber: each._parent_doc_number ? each._parent_doc_number : "",
      partNumber: each.visualPartNumber_line ? each.visualPartNumber_line : "",
      docNumber: each._document_number ? String(each._document_number) : "",
      quantity: each._price_quantity ? each._price_quantity : "",
      authorizedNet: each.authorizedNet_line
        ? each.authorizedNet_line.value
        : "",
      configID: each.ucid_line ? each.ucid_line : "",
      description: each.visualPartDescription_line
        ? each.visualPartDescription_line
        : "",
      isBundleHeader: each._model_variable_name ? each._model_variable_name : ""
    };
  });
  const hpStatus = quote.hpStatus_quote;
  return { quoteInput, lineItemInput, hpStatus };
};

export const getJsonBody = lineItems => {
  const lineAttributesToDefault = lineItems.map(record => {
    return {
      ...lineAttributesToDefault,
      _document_number: record._document_number,
      taxPercentage_line: null,
      netDiscountWithTax_line: null,
      extNetPriceWithTax_line: null,
      hsnCode_line: null
    };
  });

  const jsonBody = {
    documents: {
      billingType_quote: null,
      totalTaxDirectIndia_quote: null,
      shallCalculateTax_quote: false,
      line_process: {
        items: lineAttributesToDefault
      }
    }
  };
  return jsonBody;
};

export const getRandomInt = max => Math.floor(Math.random() * Math.floor(max));

export const getPartIconColor = category => {
  let partIcon;
  let partColor = "primary";
  switch (category) {
    case "Hardware":
      partIcon = <ImportantDevicesIcon />;
      break;
    case "Services":
      partIcon = <ContactMailIcon />;
      break;
    case "Accessories":
      partIcon = <ListAltIcon />;
      break;
    case "Displays":
      partIcon = <DesktopMacOutlinedIcon />;
      break;
    default:
      partIcon = <CardGiftcardIcon />;
  }
  return { partIcon, partColor };
};

export const getVisibleColumnIds = props => {
  if (props.records.length > 0) {
    let statusQoute = props.quote ? props.quote.record.hpStatus_quote : "";
    let visibleUserColumns = props.userPreference.columns
      .filter(column => column.isVisible)
      .map(c => c.id);
    let appColumns = props.application.columns.filter(
      column =>
        !column.hideOnStatuses ||
        (column.hideOnStatuses && !column.hideOnStatuses.includes(statusQoute))
    );
    if (props.autoHideBlankColumns) {
      let nonEmptyKeys = [];
      for (let key in props.nonBlankColumns) {
        nonEmptyKeys.push(key);
      }
      appColumns = appColumns.filter(column => {
        return column.sortBy
          ? nonEmptyKeys.includes(column.sortBy)
          : nonEmptyKeys.includes(column.id);
      });
    }
    let visibleColumnIds = appColumns
      .map(c => c.id)
      .filter(columnId => {
        return (
          columnId !== "compatible_products" &&
          visibleUserColumns.includes(columnId)
        );
      });
    if (!props.application.showMyEmpowermentColumn) {
      visibleColumnIds = visibleColumnIds.filter(columnId => {
        return columnId !== "myEmpowerPrice";
      });
    }
    if (!props.application.shallDisplayHoldTheLine) {
      visibleColumnIds = visibleColumnIds.filter(columnId => {
        return columnId !== "holdTheLine_line";
      });
    }
    if (!props.application.shallDisplayCarepackContact) {
      visibleColumnIds = visibleColumnIds.filter(columnId => {
        return columnId !== "carepackContact";
      });
    }
    if (!props.application.shallDisplayDealSelection) {
      visibleColumnIds = visibleColumnIds.filter(columnId => {
        return columnId !== "dealSelection";
      });
    }
    if (props.application.shallHideIBPUplift) {
      visibleColumnIds = visibleColumnIds.filter(columnId => {
        return columnId !== "ibpUplift_channel_line";
      });
    }
    if (props.quote.record.noShowCountryFlag_channel_quote) {
      visibleColumnIds = visibleColumnIds.filter(columnId => {
        return !(
          columnId === "resellerNetDiscount_channel_line" ||
          columnId === "recommendedPrice_channel_line"
        );
      });
    }
    return visibleColumnIds;
  } else {
    return [];
  }
};

export const filterColumns = (props, columns) => {
  columns = columns.filter(column => {
    return column.id !== "compatible_products";
  });
  if (!props.application.showMyEmpowermentColumn) {
    columns = columns.filter(column => {
      return column.id !== "myEmpowerPrice";
    });
  }
  if (!props.application.shallDisplayHoldTheLine) {
    columns = columns.filter(column => {
      return column.id !== "holdTheLine_line";
    });
  }
  if (!props.application.shallDisplayCarepackContact) {
    columns = columns.filter(column => {
      return column.id !== "carepackContact";
    });
  }
  if (!props.application.shallDisplayDealSelection) {
    columns = columns.filter(column => {
      return column.id !== "dealSelection";
    });
  }
  if (props.application.shallHideIBPUplift) {
    columns = columns.filter(column => {
      return column.id !== "ibpUplift_channel_line";
    });
  }
  if (props.quote.record.noShowCountryFlag_channel_quote) {
    columns = columns.filter(column => {
      return !(
        column.id === "resellerNetDiscount_channel_line" ||
        column.id === "recommendedPrice_channel_line"
      );
    });
  }
  return columns;
};

export const decorate = (records, props) => {
  return records.map(record => {
    let slimRecord = {};
    Object.keys(record).forEach(function (key) {
      if (record[key] != null) {
        slimRecord[key] = record[key];
      }
    });
    if (window.localStorage.getItem("LIGCrash") === "true") {
      slimRecord["id"] = record["test"].value;
    }
    slimRecord["id"] = record["_id"];
    slimRecord["isDiscountUpdated"] = false;
    slimRecord["lineNo"] = record["_group_sequence_number"];
    slimRecord["ibpUplift_channel_line"] = parseFloat(
      (Math.round(record.ibpUplift_channel_line * 100 || 0) / 100).toFixed(2)
    );
    slimRecord["recommendedPrice_channel_line"] = record[
      "modelRecommendedPrice_channel_line"
    ]
      ? parseFloat(record["modelRecommendedPrice_channel_line"].value).toFixed(
          2
        )
      : 0;
    slimRecord["resellerNetDiscount_channel_line"] = parseFloat(
      Math.round(record.modelResellerNetDiscount_channel_line * 100 || 0) / 100
    ).toFixed(2);
    let [a, b] = record["_group_sequence_number"].split(".");
    slimRecord["lineNoFloat"] = parseInt(a) * 1000 + parseInt(b);
    slimRecord["dealSelectionLine"] = record["dealSelection_line"]
      ? record["dealSelection_line"].value
      : "Deal A";
    slimRecord["dealSelectionDisplayValue"] = record["dealSelection_line"]
      ? record["dealSelection_line"].displayValue
      : "Deal A";
    slimRecord["partNo"] =
      record["_part_display_number"] || record["visualPartNumber_line"];
    slimRecord["isCtoHeader"] = record["_part_display_number"] ? false : true;
    slimRecord["isBundle"] = record["_model_variable_name"]
      ? record["_model_variable_name"] === "hardBundleConfig"
        ? true
        : false
      : false;
    slimRecord["parentDocumentNumber"] = record["_parent_doc_number"];
    slimRecord["hasParent"] = record["_parent_doc_number"] ? true : false;
    slimRecord["listPrice"] = record["listPrice_line"]
      ? parseFloat(record["listPrice_line"].value.toFixed(2))
      : 0;
    slimRecord["listPrice_line"] = record["listPrice_line"] || {
      value: 0
    };

    slimRecord["stdNetPrice"] = record["contractNetPrice_line"]
      ? parseFloat(record["contractNetPrice_line"].value.toFixed(2))
      : 0;
    slimRecord["contractNetPrice_line"] = record["contractNetPrice_line"] || {
      value: 0
    };
    slimRecord["stdDiscountPercentage"] = record["contractPercent_line"]
      ? record["contractPercent_line"]
      : 0;

    slimRecord["eclipseDealNetPrice"] = record["eclipseDealNetPrice_line"]
      ? record["eclipseDealNetPrice_line"].value
      : 0;
    slimRecord["eclipseDealNetPrice_line"] = record[
      "eclipseDealNetPrice_line"
    ] || { value: 0 };
    slimRecord["eclipseDealPercent"] = record["eclipseDealPercent_line"];
    slimRecord["myEmpowerPrice"] = record["myEmpowerPrice_line"]
      ? record["myEmpowerPrice_line"].value
      : 0;
    slimRecord["myEmpowerPrice_line"] = record["myEmpowerPrice_line"] || {
      value: 0
    };
    slimRecord["myEmpowermentPercentage"] = record[
      "authorizedDiscountPROS_line"
    ]
      ? parseFloat(record["authorizedDiscountPROS_line"].toFixed(2))
      : 0;
    slimRecord["authorizedNetLine"] = record["authorizedNet_line"]
      ? parseFloat(record["authorizedNet_line"].value.toFixed(2))
      : 0;
    slimRecord["authorizedNet_line"] = record["authorizedNet_line"] || {
      value: 0
    };
    slimRecord["discountPercent"] = record["discountPercent_line"];

    slimRecord["extendedNetPriceLine"] = record["extendedNetPrice_line"]
      ? parseFloat(record["extendedNetPrice_line"].value.toFixed(2))
      : 0;
    if (slimRecord["hasParent"]) {
      const parent = records.filter(
        item =>
          !item._part_display_number &&
          parseInt(item._document_number) ===
            parseInt(record["_parent_doc_number"])
      )[0];
      typeof parent !== "undefined"
        ? (slimRecord["extendedNetPriceLineChart"] =
            parseFloat(slimRecord["extendedNetPriceLine"]) *
            parent._price_quantity)
        : (slimRecord["extendedNetPriceLineChart"] =
            slimRecord["extendedNetPriceLine"]);
    } else {
      slimRecord["extendedNetPriceLineChart"] =
        slimRecord["extendedNetPriceLine"];
    }
    slimRecord["netPriceWithBenefits_channel_line"] = record[
      "netPriceWithBenefits_channel_line"
    ]
      ? record["netPriceWithBenefits_channel_line"]
      : 0;
    slimRecord["resellerProgramBenefits_channel_line"] = record[
      "modelResellerProgramBenefits_channel_line"
    ]
      ? record["modelResellerProgramBenefits_channel_line"].value
      : 0;
    slimRecord["modelResellerProgramBenefits_channel_line"] = record[
      "modelResellerProgramBenefits_channel_line"
    ] || {
      value: 0
    };
    slimRecord["recommendedPriceWithBenefits_channel_line"] = record[
      "modelRecPriceWBenefits_channel_line"
    ]
      ? record["modelRecPriceWBenefits_channel_line"].value
      : 0;
    slimRecord["modelRecPriceWBenefits_channel_line"] = record[
      "modelRecPriceWBenefits_channel_line"
    ] || {
      value: 0
    };
    slimRecord["programType_channel_line"] = record["programType_channel_line"]
      ? record["programType_channel_line"]
      : null;
    slimRecord["programID_channel_line"] = record["programID_channel_line"]
      ? record["programID_channel_line"]
      : null;
    //slimRecord["discount_line"] = record["discount_line"] ? record["discount_line"].value : record["discount_line"].value
    slimRecord["discountType"] = record["dealType_line"]
      ? record["dealType_line"].value
      : null;
    slimRecord["modelListPrice_channel_line"] = record[
      "modelListPrice_channel_line"
    ] || { value: 0, currency: "USD" };
    slimRecord["quantity"] = record["_price_quantity"];
    slimRecord["minimumQuantity"] = record["minimumQuantity"] || 1;
    slimRecord["description"] = record["visualPartDescription_line"];
    slimRecord["holdTheLine"] = record["holdInitialFlagCheck_channel_line"];
    slimRecord["selected_line"] = record["selected_line"];
    slimRecord["carepackContact"] = record["firstName_cp"]
      ? `${record["firstName_cp"]} ${record["lastName_cp"]}`
      : "Add";
    slimRecord["allowAdjustment"] =
      record["allowAdjustment"] === true || record["allowAdjustment"] === null
        ? true
        : false;
    slimRecord.groupSequenceNumberOriginal = slimRecord._group_sequence_number;

    slimRecord["recommendationTypeInbound_line"] = record[
      "recommendationTypeInbound_line"
    ]
      ? record["recommendationTypeInbound_line"]
      : null;

    if (
      record["recommendationObjectInbound_line"] !== "null" &&
      record["recommendationObjectInbound_line"] != null &&
      typeof record["recommendationObjectInbound_line"] === "string"
    ) {
      let parentPartsData = [];
      let childPartsData = [];
      let partTypeData = "";
      record["recommendationObjectInbound_line"]
        .split("$")
        .forEach((item, index) => {
          item = JSON.parse(item.replace(/'/g, '"'));
          parentPartsData = item.parentParts.reduce(
            (parentPartsData, record) =>
              !parentPartsData.includes(record)
                ? [...parentPartsData, record]
                : [...parentPartsData],
            parentPartsData
          );
          childPartsData = item.childParts.reduce(
            (childPartsData, record) =>
              !childPartsData.includes(record)
                ? [...childPartsData, record]
                : [...childPartsData],
            childPartsData
          );
          partTypeData = item.partType !== "" ? item.partType : partTypeData;
        });
      record["recommendationObjectInbound_line"] = {
        parentParts: parentPartsData,
        childParts: childPartsData,
        partType: partTypeData
      };
    }

    slimRecord["recommendationObjectInbound_line"] =
      record["recommendationObjectInbound_line"] != null
        ? record["recommendationObjectInbound_line"]
        : null;

    slimRecord["recommendationType_line"] = record["recommendationType_line"]
      ? record["recommendationType_line"]
      : record["recommendationTypeInbound_line"]
      ? record["recommendationTypeInbound_line"]
      : null;

    slimRecord["recommendationObject_line"] =
      record["recommendationObject_line"] != null
        ? typeof record["recommendationObject_line"] === "string"
          ? JSON.parse(record["recommendationObject_line"].replace(/'/g, '"'))
          : record["recommendationObject_line"]
        : record["recommendationObjectInbound_line"] != null
        ? record["recommendationObjectInbound_line"]
        : null;

    if (
      slimRecord["recommendationType_line"] &&
      slimRecord["recommendationTypeInbound_line"]
    ) {
      if (
        (slimRecord["recommendationType_line"] === "A" &&
          slimRecord["recommendationTypeInbound_line"] === "G") ||
        (slimRecord["recommendationType_line"] === "G" &&
          slimRecord["recommendationTypeInbound_line"] === "A")
      ) {
        slimRecord["recommendationType_line"] = "M";
      }
    }
    if (
      slimRecord["recommendationObject_line"] &&
      slimRecord["recommendationObject_line"].childParts &&
      slimRecord["recommendationObject_line"].parentParts &&
      slimRecord["recommendationObjectInbound_line"] &&
      slimRecord["recommendationObjectInbound_line"].childParts &&
      slimRecord["recommendationObjectInbound_line"].parentParts
    ) {
      slimRecord["recommendationObjectInbound_line"].childParts.forEach(n => {
        if (
          slimRecord["recommendationObject_line"].childParts.indexOf(n) === -1
        ) {
          slimRecord["recommendationObject_line"].childParts.push(n);
        }
      });
      slimRecord["recommendationObjectInbound_line"].parentParts.forEach(n => {
        if (
          slimRecord["recommendationObject_line"].parentParts.indexOf(n) === -1
        ) {
          slimRecord["recommendationObject_line"].parentParts.push(n);
        }
      });
    }
    if (props.application && props.application.isReseller) {
      slimRecord["programBenefits_channel_line"] = record[
        "modelResellerProgramBenefits_channel_line"
      ]
        ? record["modelResellerProgramBenefits_channel_line"].value
        : 0;
    } else
      slimRecord["programBenefits_channel_line"] = record[
        "distributorProgramBenefits_channel_line"
      ]
        ? record["distributorProgramBenefits_channel_line"]
        : 0;

    slimRecord.discountTypeline = record[
      props.application.discountTypeIdentifier
    ] || {
      displayValue: "%",
      value: "%"
    };

    slimRecord.requestedDiscountNetPrice = parseFloat(
      record[props.application.requestNetPriceIdentifier]
        ? record[props.application.requestNetPriceIdentifier].value
        : slimRecord.listPrice
    );

    if (
      slimRecord.discountTypeline.value === "Net" ||
      slimRecord.discountTypeline.value === "Amt"
    ) {
      slimRecord.requestedDiscountAmount =
        slimRecord.listPrice - slimRecord.requestedDiscountNetPrice;
      slimRecord.requestedDiscountPerc =
        slimRecord["listPrice"] && slimRecord["listPrice"] > 0
          ? parseFloat(
              (slimRecord.requestedDiscountAmount * 100) /
                slimRecord["listPrice"]
            ).toFixed(2)
          : 0;
    } else {
      slimRecord.requestedDiscountAmount = record[
        props.application.discountLineIdentifier
      ]
        ? (record[props.application.discountLineIdentifier] *
            slimRecord["listPrice"]) /
          100
        : 0;
      slimRecord.requestedDiscountPerc = record[
        props.application.discountLineIdentifier
      ]
        ? parseFloat(
            (record[props.application.discountLineIdentifier] * 100) / 100
          ).toFixed(2)
        : 0;
    }
    slimRecord["taxPercentage_line"] = record["taxPercentage_line"]
      ? record["taxPercentage_line"] * 100
      : 0;
    slimRecord["netDiscountWithTax_line"] = record["netDiscountWithTax_line"]
      ? typeof record["netDiscountWithTax_line"] !== "object"
        ? parseFloat(record["netDiscountWithTax_line"]).toFixed(2)
        : parseFloat(record["netDiscountWithTax_line"].value.toFixed(2))
      : 0;
    slimRecord["extNetPriceWithTax_line"] = record["extNetPriceWithTax_line"]
      ? typeof record["extNetPriceWithTax_line"] !== "object"
        ? parseFloat(record["extNetPriceWithTax_line"]).toFixed(2)
        : parseFloat(record["extNetPriceWithTax_line"].value.toFixed(2))
      : 0;
    slimRecord["hsnCode_line"] = record["hsnCode_line"]
      ? record["hsnCode_line"]
      : "";
    slimRecord["requestedOrc_line"] = record["requestedOrc_line"]
      ? record["requestedOrc_line"]
      : "0.00";
    slimRecord["approvedOrc_line"] = record["approvedOrc_line"]
      ? record["approvedOrc_line"]
      : "0.00";

    slimRecord["category"] = getPLCategoryName(
      getPLCategoryKey(record["_part_custom_field2"], props)
    );
    Object.keys(slimRecord).forEach(function (key) {
      if (slimRecord[key] != null && props.nonBlankColumns !== undefined) {
        props.nonBlankColumns[key] = true;
      }
    });
    return slimRecord;
  });
};

export const obsoleteResponseValidator = (data, lineItemCreateData) => {
  let acceptedLineItemsArray = lineItemCreateData;
  let lineItemJsonToreturn = {
    acceptedLineItems: [],
    rejectedLineItems: []
  };

  data.forEach(item => {
    if (item._part_custom_field11.lookupCode !== "A") {
      lineItemJsonToreturn.rejectedLineItems.push(item.partNumber);
      acceptedLineItemsArray = lineItemCreateData.filter(
        lineitem => lineitem._part_number !== item.partNumber
      );
    }
    lineItemCreateData = [...acceptedLineItemsArray];
  });
  lineItemJsonToreturn.acceptedLineItems = acceptedLineItemsArray;
  return lineItemJsonToreturn;
};

export const getJsonforSaveLineItem = (
  callerName,
  value,
  props,
  ibpUpliftDistributorDiscount
) => {
  let saveLineItemJson = {
    documents: {}
  };
  switch (callerName) {
    case "discountField":
      let currency = props.quoteCurrency.value;
      let discountLine = props.application.discountLineIdentifier;
      let discountType = props.application.discountTypeIdentifier;
      let requestNetPrice = props.application.requestNetPriceIdentifier;
      const isReseller = props.application.isReseller;
      const isDirect = props.quote.isDirect;
      if (isReseller) {
        saveLineItemJson.documents = {
          [discountLine]: value,
          [discountType]: {
            displayValue: "Net",
            value: "Net"
          },
          requestedNet_line: {
            value: ibpUpliftDistributorDiscount,
            currency: currency
          },
          distributorDiscountType_channel_line: {
            displayValue: "Net",
            value: "Net"
          },
          [requestNetPrice]: {
            value: value,
            currency: currency
          },
          distributorReqtNet_channel_line: ibpUpliftDistributorDiscount
        };
      } else if (isDirect) {
        saveLineItemJson.documents = {
          [discountLine]: value,
          [discountType]: {
            displayValue: "Net",
            value: "Net"
          },
          [requestNetPrice]: {
            value: value,
            currency: currency
          }
        };
      } else
        saveLineItemJson.documents = {
          [discountLine]: value,
          [discountType]: {
            displayValue: "Net",
            value: "Net"
          },
          [requestNetPrice]: {
            value: value,
            currency: currency
          },
          distributorReqtNet_channel_line: value
        };
      break;

    case "quantityField":
      saveLineItemJson.documents._price_quantity = value;
      break;
    case "holdTheLineField":
      saveLineItemJson.documents.holdInitialFlagCheck_channel_line = value;
      break;
    case "dealSelectionField":
      saveLineItemJson.documents.dealSelection_line = value;
      break;
    case "carepackContactField":
      saveLineItemJson.documents = value;
      break;
    case "freightField":
      saveLineItemJson.documents.freightForQuotation_quote = { value: value };
      break;
    case "estimatedTaxField":
      saveLineItemJson.documents.estimatedTax_quote = value;
      break;

    default:
  }

  return saveLineItemJson;
};

export const getJsonForCreate = lineItems => {
  let json = {
    documents: {
      newPartsAdded_quote: true,
      line_process: {
        items: []
      }
    }
  };
  for (var i = 0; i < lineItems.length; i = i + 1) {
    json.documents.line_process.items.push(
      lineItems[i]._document_number
        ? {
            _document_number: lineItems[i]._document_number,
            _price_quantity: lineItems[i]._price_quantity || 1,
            holdInitialFlagCheck_channel_line:
              lineItems[i].holdInitialFlagCheck_channel_line
          }
        : {
            _part_number: lineItems[i]._part_number,
            _price_quantity: lineItems[i]._price_quantity || 1,
            holdInitialFlagCheck_channel_line:
              lineItems[i].holdInitialFlagCheck_channel_line
          }
    );
  }
  return json;
};
export const getJsonForRecommendation = lineItems => {
  let json = {
    documents: {
      line_process: {
        items: []
      }
    }
  };
  for (var i = 0; i < lineItems.length; i = i + 1) {
    json.documents.line_process.items.push({
      _document_number: lineItems[i]._document_number,
      recommendationType_line: lineItems[i].recommendationType_line,
      recommendationObject_line: JSON.stringify(
        lineItems[i].recommendationObject_line
      ).replace(/"/g, "'")
    });
  }
  return json;
};
export const getJsonForDiscountUpdate = (lineItems, props) => {
  let json = {
    documents: {
      line_process: {
        items: []
      }
    }
  };
  let discountLine = props.application.discountLineIdentifier;
  let discountType = props.application.discountTypeIdentifier;
  const isReseller = props.application.isReseller;
  let requestNetPrice = props.application.requestNetPriceIdentifier;
  const isDirect = props.quote.isDirect;
  const requestCurrency = props.quoteCurrency.value;
  if (isReseller) {
    lineItems.forEach(lineItem => {
      json.documents.line_process.items.push({
        _document_number: lineItem._document_number,
        [discountLine]: lineItem[discountLine],
        [discountType]: { displayValue: "Net", value: "Net" },
        requestedNet_line: {
          value: lineItem.distributorDiscount_channel_line,
          currency: requestCurrency
        },
        distributorDiscountType_channel_line: {
          displayValue: "Net",
          value: "Net"
        },
        [requestNetPrice]: {
          value: lineItem[discountLine],
          currency: requestCurrency
        },
        distributorReqtNet_channel_line:
          lineItem.distributorDiscount_channel_line
      });
    });
  } else if (isDirect) {
    lineItems.forEach(lineItem => {
      json.documents.line_process.items.push({
        _document_number: lineItem._document_number,
        [discountLine]: lineItem[discountLine],
        [discountType]: { displayValue: "Net", value: "Net" },
        [requestNetPrice]: {
          value: lineItem[discountLine],
          currency: requestCurrency
        }
      });
    });
  } else
    lineItems.forEach(lineItem => {
      json.documents.line_process.items.push({
        _document_number: lineItem._document_number,
        [discountLine]: lineItem[discountLine],
        [discountType]: { displayValue: "Net", value: "Net" },
        [requestNetPrice]: {
          value: lineItem[discountLine],
          currency: requestCurrency
        },
        distributorReqtNet_channel_line: lineItem[discountLine]
      });
    });

  return json;
};

export const getJsonForHoldTheLineUpdate = (lineItems, holdTheLine) => {
  let json = {
    documents: {
      holdTheLine_quote: holdTheLine,
      line_process: {
        items: []
      }
    }
  };
  for (let i = 0; i < lineItems.length; i = i + 1) {
    json.documents.line_process.items.push({
      _document_number: lineItems[i]._document_number,
      holdInitialFlagCheck_channel_line: holdTheLine
    });
  }
  return json;
};

export const getJsonforCarepackContact = (lineItem, fields) => {
  let json = {
    documents: {
      line_process: {
        items: []
      }
    }
  };
  json.documents.line_process.items.push({
    _document_number: lineItem[0]._document_number,
    firstName_cp: fields.firstName_cp,
    lastName_cp: fields.lastName_cp,
    emailAddress_cp: fields.emailAddress_cp,
    partnerEmailAddress_cp: fields.partnerEmailAddress_cp,
    address1_cp: fields.address1_cp,
    address2_cp: fields.address2_cp,
    city_cp: fields.city_cp,
    state_cp: fields.state_cp,
    zip_cp: fields.zip_cp,
    faxNumber_cp: fields.faxNumber_cp,
    contactExt_cp: fields.contactExt_cp,
    phoneNumber_cp: fields.phoneNumber_cp
  });
  return json;
};

export const getErrorMsg = error => {
  error = error || {};
  error.data = error.data || {};
  return refineErrorMsg(error.data) || error + "";
};

export const refineErrorMsg = error => {
  if (error.documents || error.title) {
    let error_array =
      error.documents &&
      error.documents._state &&
      error.documents._state.hasError
        ? error.documents._state.messages[1].split("\n")
        : error.title.split("\n");
    let newMsg = error_array
      .filter((err, i) =>
        error_array.length === 1 ? true : i > 0 ? true : false
      )
      .toString()
      .replace(",", "");

    let errorMsg =
      error.documents &&
      error.documents._state &&
      error.documents._state.hasError
        ? newMsg + ". " + error.documents._state.messages[0]
        : newMsg;

    return errorMsg;
  }
};

export const RowLoader = () => {
  const random = Math.random() * (1 - 0.7) + 0.7;
  return (
    <ContentLoader
      height={35}
      width={1060}
      speed={1}
      primaryColor="#d9d9d9"
      secondaryColor="#ecebeb"
    >
      <rect x="30" y="15" rx="0" ry="0" width="8" height="8" />
      <rect x="64" y="13" rx="6" ry="6" width={550 * random} height="8" />
      <rect x="643" y="13" rx="6" ry="6" width={23 * random} height="8" />
      <rect x="683" y="13" rx="6" ry="6" width={78 * random} height="8" />
      <rect x="785" y="13" rx="6" ry="6" width={117 * random} height="8" />
      <rect x="968" y="13" rx="6" ry="6" width={83 * random} height="8" />
    </ContentLoader>
  );
};

export const TableHeaderLoader = () => {
  const random = Math.random() * (1 - 0.7) + 0.7;
  return (
    <ContentLoader
      height={40}
      width={1060}
      speed={1}
      primaryColor="#d9d9d9"
      secondaryColor="#ecebeb"
    >
      <rect x="23" y="19" rx="4" ry="4" width="0" height="4" />
      <rect x="104" y="19" rx="8" ry="8" width={0 * random} height="6.5" />

      <rect x="1025" y="18" rx="6" ry="6" width={0 * random} height="6" />
    </ContentLoader>
  );
};

export const plcResponseValidator = (data, lineItemCreateData, isDirect) => {
  let currentDate = new Date();
  //Logic to calculate expiry date, if indirect expiry date is date-90
  if (!isDirect) currentDate.setDate(currentDate.getDate() - 90);
  let acceptedLineItemsArray = lineItemCreateData;
  let lineItemJsonToreturn = {
    acceptedLineItems: [],
    rejectedLineItems: []
  };
  let formattedDateToCompare =
    currentDate.getFullYear() * 10000 +
    (currentDate.getMonth() + 1) * 100 +
    currentDate.getDate();
  data.forEach(item => {
    if (item.es !== 0 && formattedDateToCompare > item.es) {
      lineItemJsonToreturn.rejectedLineItems.push(item.sku);
      acceptedLineItemsArray = lineItemCreateData.filter(
        lineitem => lineitem._part_number !== item.sku
      );
    }
    lineItemCreateData = [...acceptedLineItemsArray];
  });
  lineItemJsonToreturn.acceptedLineItems = acceptedLineItemsArray;
  return lineItemJsonToreturn;
};
export const saveInGA = (functionName, timeElapsed) => {
  if (window.ga) {
    window.ga("hpi.send", "timing", "API time", functionName, timeElapsed);
  }
};

export const validatePartDuplication = (props, summaryData) => {
  let newPartsNo = summaryData.map(item => item.partNo);
  let partDuplicationLineItems = props.lineItem.records.filter(
    item => !item.hasParent && newPartsNo.includes(item.partNo)
  );
  return partDuplicationLineItems;
};

export const filterAVPNs = summaryData => {
  let newPartAVs = summaryData.filter(item => {
    let partNo = item.partNo.toUpperCase();
    return partNo.endsWith("AV") || partNo.indexOf("AV#") > 0;
  });
  return newPartAVs.map(item => item.partNo);
};

export const formatMessage = (msg, ...args) => {
  let returnMsg = msg;
  for (var i = 0; i < args.length; i++) {
    returnMsg = returnMsg.replace(
      "{" + i + "}".replace(/,(\s+)?$/, ""),
      args[i]
    );
  }
  return returnMsg;
};

export const getCurrencySymbol = (locale, currencyCode) => {
  return Number(0)
    .toLocaleString(locale, {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    .replace(/\d/g, "");
};

export const isEmpty = val => {
  return val === undefined ||
    val === null ||
    val === "" ||
    val === 0 ||
    val === {} ||
    val === []
    ? true
    : false;
};
export const getObsoleteCheckQuery = lineItems => {
  let query = {
    criteria: {
      fields: ["partNumber", "description", "_part_custom_field11"]
    }
  };
  let q = "{ $or: [ ";
  lineItems.forEach(lineItem => {
    q = q + "{ partNumber: {$eq:'" + lineItem._part_number + "'}},";
  });
  q = q.slice(0, q.length - 1);
  q = q + "] }";
  query.criteria.q = q;
  return query;
};

export const displayStyledCurrencyLite = (
  data = {
    amount: 0.0,
    currencyArg: "",
    locale: "en-US"
  },
  style = {
    color: "#0096d6"
  }
) => {
  if (isNaN(data.amount) || data.amount === "") {
    return "";
  }

  let styledCurrency = Number(Math.abs(data.amount)).toLocaleString(
    data.locale,
    {
      style: "currency",
      currency: data.currencyArg,
      currencyDisplay: "code"
    }
  );
  let amountNonDecimal = styledCurrency.substring(
    data.currencyArg.length,
    styledCurrency.length - 2
  );
  let decimal = styledCurrency.substring(styledCurrency.length - 2);
  let color = style.color ? style.color : "#0096d6";
  return (
    <span style={{ display: style.displayStyle }}>
      <Typography variant="inherit" style={{ color: color }}>
        {data.amount < 0 ? "-" + amountNonDecimal : amountNonDecimal}
      </Typography>
      <Box
        position="relative"
        top={"-0.2em"}
        fontSize={"0.675em"}
        fontWeight={"normal"}
        component={"span"}
      >
        {decimal}
      </Box>
    </span>
  );
};

export const displayCurrencyLite = price => {
  let currencySymbol = Number(0)
    .toLocaleString(price.locale, {
      style: "currency",
      currency: price.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    .replace(/\d/g, "");
  let styledCurrency = displayCurrency(price);
  return styledCurrency.substring(currencySymbol.length, styledCurrency.length);
};

export const displayCurrency = price => {
  return price.value.toLocaleString("en-US", {
    style: "currency",
    currency: price.currency
  });
};

export const displayStyledCurrency = (
  data = {
    amount: 0.0,
    currencyArg: "",
    locale: "en-US"
  },
  style = {
    color: "#0096d6"
  }
) => {
  if (isNaN(data.amount)) {
    return "";
  }

  let styledCurrency = data.amount.toLocaleString(data.locale, {
    style: "currency",
    currency: data.currencyArg
  });

  let currencySymbol = getCurrencySymbol(data.locale, data.currencyArg);
  let amountNonDecimal = styledCurrency.substring(
    currencySymbol.length,
    styledCurrency.length - 2
  );
  let decimal = styledCurrency.substring(styledCurrency.length - 2);
  let color = style.color ? style.color : "#0096d6";
  let topArg = style.displayStyle === "flex" ? "0.1em" : "-0.2em";
  let paddingRightButton = style.displayStyle === "flex" ? "10px" : "0px";
  return (
    <span style={{ display: style.displayStyle }}>
      <Typography
        variant="inherit"
        style={{
          color: lighten(color, 0.5),
          paddingRight: "3px",
          fontSize: "0.875rem"
        }}
      >
        {currencySymbol}
      </Typography>
      <Typography
        variant="inherit"
        style={{ color: color, fontWeight: "bold", fontSize: "0.875rem" }}
      >
        {amountNonDecimal}
      </Typography>
      <Typography
        variant="inherit"
        style={{
          color: color,
          fontSize: "0.675rem",
          paddingRight: paddingRightButton,
          top: topArg,
          position: "relative"
        }}
      >
        {decimal}
      </Typography>
    </span>
  );
};

export const redirectTo = response => {
  window.location.href = response.data.destinationURL;
};

export const reportErrorToService = (error, id) => {
  error.response = error.response || {};
  error.response.data = error.response.data || {};
  let responseTitle = error.response.data.title
    ? error.response.data.title
    : error + "";
  let subStr = "Could not perform Action:";
  responseTitle = responseTitle.replace(subStr, "").substring(0, 100);
  if (window.ga) {
    window.ga("hpi.set", "dimension3", error.response.status + "" || "");
    window.ga("hpi.set", "dimension4", responseTitle);
    window.ga("hpi.set", "dimension5", id);
    window.ga("hpi.send", "pageview");
  }
};

//please do not delete the following two lines of code,it is for auto migrate lig
const token = localStorage.getItem("authToken");

export const axiosCall =
  window.navigator.userAgent.indexOf("Trident") > -1
    ? axios.create({
        headers: {
          Pragma: "no-cache"
        }
      })
    : axios.create({
        headers: {
          "content-type": "application/json"
        }
      }); // Trident is for checking IE browser

export const checkTransactionsInProgress = props => {
  const { t, transactionsInProgress } = props;
  if (transactionsInProgress && transactionsInProgress.length >= 1) {
    displayNotistack.info(t("common:snackbar.activeTransactionInProgress"));

    return false;
  }
};

export const getIbpUpliftDistributorDiscount = (
  currentLineItem,
  resellerReqtNet
) => {
  let ibpUpliftDistributorDiscount = "";
  const lineItem = currentLineItem[0];
  const ibpUplift = lineItem.ibpUplift_channel_line;
  ibpUpliftDistributorDiscount = parseFloat(
    (resellerReqtNet / (1 + ibpUplift / 100)).toFixed(2)
  );
  return ibpUpliftDistributorDiscount;
};

export const hidePriceForPriceVisibilityRules = (n, row, props) => {
  const quoteRecord =
    props.quote && props.quote.record ? props.quote.record : {};
  if (n.hasParent && !n.isCtoHeader) {
    if (
      window._BM_HPI_USER_TYPE === "reseller" &&
      (!quoteRecord.isExistingInPriceVisibilityRules_quote ||
        (quoteRecord.isExistingInPriceVisibilityRules_quote &&
          !quoteRecord.actionInPriceVisibilityRules_quote)) &&
      (row.id === "listPrice" ||
        row.id === "recommendedPrice_channel_line" ||
        row.id === "resellerNetDiscount_channel_line")
    ) {
      return false;
    } else if (
      window._BM_HPI_USER_TYPE === "distributor" &&
      quoteRecord.isExistingInPriceVisibilityRules_quote &&
      !quoteRecord.actionInPriceVisibilityRules_quote &&
      (row.id === "listPrice" || row.id === "authorizedNetLine")
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const isBlankCell = (n, row, props) => {
  const columns = [
    "taxPercentage_line",
    "netDiscountWithTax_line",
    "extNetPriceWithTax_line",
    "hsnCode_line"
  ];
  if (columns.includes(row.id)) {
    if (!n.hasParent && n.isCtoHeader && n.isBundle) {
      return false;
    } else if (!n.hasParent && n.isCtoHeader && !n.isBundle) {
      return true;
    } else if (n.hasParent) {
      const isBundle = props.records
        .filter(record => record.isCtoHeader)
        .filter(
          item => item._document_number === parseInt(n._parent_doc_number)
        )[0].isBundle;
      return isBundle;
    }
  }
  return true;
};

export const getCarePackChoiceIdsQuery = () => {
  let query =
    "?q={SettingID:'CarepackServicesConfigKeys' , ProdCatalogID : '*'}," +
    "{SettingValue: 1} ";
  return query;
};

export const getCompatibleProductsIcon = type => {
  switch (type) {
    case "Services":
      return <ContactMailIcon />;
    case "Carepack":
      return <ContactMailIcon />;
    case "Displays":
      return <DesktopMacOutlinedIcon />;
    case "Display":
      return <DesktopMacOutlinedIcon />;
    case "Accessories":
      return <ListAltIcon />;
    default:
      return null;
  }
};

export const getCompatibleProductsIconV2 = type => {
  switch (type) {
    case "Services":
      return <ContactMailOutlinedIcon />;
    case "Carepack":
      return <ContactMailOutlinedIcon />;
    case "Displays":
      return <DesktopMacOutlinedIcon />;
    case "Display":
      return <DesktopMacOutlinedIcon />;
    case "Accessories":
      return <ListAltOutlinedIcon />;
    default:
      return null;
  }
};

export const removeDuplicates = (array, key) => {
  return array.reduce((arr, item) => {
    const removed = arr.filter(i => i[key] !== item[key]);
    return [...removed, item];
  }, []);
};

export const getCompatibleProductsRequest = compatibleItems => {
  let requestedProducts = [];
  if (compatibleItems && compatibleItems.length > 0) {
    requestedProducts = compatibleItems.map(item => ({
      _part_number: item.sku,
      _price_quantity: 1
    }));
  }
  return requestedProducts;
};

export const getCompatibleProductType = (linkType, category) => {
  let type;
  switch (linkType) {
    case "Accessories":
      type = category === "Business Monitors" ? "Display" : "Accessories";
      break;
    case "Services":
      type = "Carepack";
      break;
    default:
      type = "";
      break;
  }
  return type;
};

export const getSKUListPrice = (priceItems, sku) => {
  let filteredPriceItems = priceItems.filter(priceItem => {
    // This condition needs to be modified for handling
    // multiple list prices for the same SKU
    return priceItem.sku === sku;
  });
  let listPrice =
    filteredPriceItems && filteredPriceItems.length > 0
      ? filteredPriceItems[0].listprice
      : null;
  return listPrice;
};

export const getGuideSellingProducts = (
  category,
  partNo,
  recommendationData,
  showMoreOrLess
) => {
  let filteredRecommData = recommendationData.filter(
    item => item.partNo === partNo
  )[0];
  const autoAttachProducts = filteredRecommData.compatibleProducts.reduce(
    (result, item) =>
      item.recommendationType_line === "A" && item.isChosen
        ? [...result, item.partNo]
        : [...result],
    []
  );
  const guidedSellingRecommedationProducts =
    filteredRecommData.compatibleProducts.map(item =>
      autoAttachProducts.includes(item.partNo)
        ? { ...item, isChosen: true }
        : item
    );
  const guideSellingProducts = guidedSellingRecommedationProducts
    .filter(
      item =>
        item.recommendationType_line === "G" &&
        item.type === category &&
        item.listPrice
    )
    .map(item => {
      if (item.priority === 0) item.priority = 99;
      return item;
    })
    .sort((a, b) => {
      return a.priority - b.priority;
    });

  const option = showMoreOrLess.filter(item => item.category === category)[0];
  const topRecommendedProducts = guideSellingProducts.reduce(
    (result, product, index) =>
      index < option.show &&
      product.priority > 0 &&
      product.priority <= option.maxPriority
        ? [...result, product.partNo]
        : result,
    []
  );

  const guideSellingProductsData = {
    filteredRecommData,
    topRecommendedProducts,
    guideSellingProducts
  };
  return guideSellingProductsData;
};

export const getDataTableQuery = (
  columnID,
  columnValue,
  selectColumns,
  orderby,
  orColumnID,
  orColumnValues
) => {
  let query;
  if (columnID) {
    query = "?q={";
    columnID.forEach((item, index) => {
      query = query + item + ":'" + columnValue[index] + "',";
    });
    if (orColumnID) {
      query = query + "$or:[";
      orColumnValues.forEach((item, index) => {
        query = query + "{" + orColumnID + ":'" + orColumnValues[index] + "'},";
      });
      query = query.substr(0, query.length - 1) + "]}";
    } else {
      query = query.substr(0, query.length - 1) + "}";
    }

    if (selectColumns) {
      query = query + "&fields=";
      selectColumns.forEach(item => {
        query = query + item + ",";
      });
      query = query.substr(0, query.length - 1);
    }
    if (orderby) {
      query = query + "&orderby=" + orderby;
    }
    query = query.replace(/#/g, "%23");
  } else {
    query = "";
  }
  return query;
};

export const getPartNumberQuery = partNumbers => {
  let query = {
    criteria: {
      fields: ["partNumber", "description"]
    }
  };
  let q = "{ $or: [ ";
  partNumbers.forEach(partNumber => {
    q = q + "{ partNumber: {$eq:'" + partNumber + "'}},";
  });
  q = q.slice(0, q.length - 1);
  q = q + "] }";
  query.criteria.q = q;
  return query;
};

function dateToDate(date) {
  var sDate = new Date();
  if (typeof date == "object" && typeof new Date().getMonth == "function") {
    sDate = date;
  } else if (typeof date == "string") {
    var arr = date.split("-");
    if (arr.length === 3) {
      sDate = new Date(arr[0] + "-" + arr[1] + "-" + arr[2]);
    }
  }
  return sDate;
}

function addMonth(date, num) {
  num = parseInt(num);
  var sDate = dateToDate(date);
  var sYear = sDate.getFullYear();
  var sMonth = sDate.getMonth() + 1;
  var sDay = sDate.getDate();
  var eYear = sYear;
  var eMonth = sMonth + num;
  var eDay = sDay;
  while (eMonth > 12) {
    eYear++;
    eMonth -= 12;
  }
  var eDate = new Date(eYear, eMonth - 1, eDay);
  while (eDate.getMonth() !== eMonth - 1) {
    eDay--;
    eDate = new Date(eYear, eMonth - 1, eDay);
  }
  return eDate;
}

export const calcDate = (date, num) => {
  var eDate = addMonth(date, num);
  var s = "-";
  var year = eDate.getFullYear();
  var month = eDate.getMonth() + 1;
  var strDate = eDate.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var calculatedDate = year + s + month + s + strDate;
  return calculatedDate;
};

export const getNowDateBySpecialFormat = () => {
  var nowDate = new Date();
  var s = "-";
  var year = nowDate.getFullYear();
  var month = nowDate.getMonth() + 1;
  var strDate = nowDate.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var backDate = year + s + month + s + strDate;
  return backDate;
};

export const uploadFileKeys = attachmentType => {
  let fileKeys;
  switch (attachmentType) {
    case "POAttachment":
      fileKeys = ["poAttachment_channel_quote"];
      break;
    case "quoteOutputAttachment":
      fileKeys = [
        "additionalDocumentationPDF_quote",
        "attachAdditionalDocumentsPDF2_quote",
        "attachAdditionalDocumentsPDF3_quote"
      ];
      break;
    case "justificationFormAttachment":
      fileKeys = [
        "supportingDocument_channel_quote",
        "supportingDocument_channel_quote2",
        "supportingDocument_channel_quote3",
        "supportingDocument_channel_quote4",
        "supportingDocument_channel_quote5"
      ];
      break;
    default:
  }
  return fileKeys;
};

export const getProperErrorMessage = messages => {
  let filterMessage = [
    "Could not perform Action",
    "无法执行操作",
    "报价时发生了错误。 现在无法继续。 请单击“请求协助”以获取其他帮助。"
  ];
  let errorMessage =
    messages.length > 1
      ? [...messages].filter(item => {
          let flag = true;
          filterMessage.some(element => {
            if (item.indexOf(element) !== -1) {
              flag = false;
              return true;
            }
            return false;
          });
          return flag;
        })
      : messages;
  let returnMsg = "";
  errorMessage.forEach(msg => {
    returnMsg = returnMsg + msg + "\n";
  });
  return returnMsg;
};

export const getPLCategoryKey = (customField2, props) => {
  if (props.PLCommodityMapping) {
    const PLCategoryKey = Object.entries(props.PLCommodityMapping).reduce(
      (result, [key, value]) =>
        value.includes(customField2) ? [...result, key] : [...result],
      []
    )[0];
    return PLCategoryKey;
  }
};

export const getPLCategoryName = key => {
  let name = "";
  switch (key) {
    case "PLforPCHardware":
      name = "Hardware";
      break;
    case "PLforPrinterHardware":
      name = "Hardware";
      break;
    case "PLforService":
      name = "Services";
      break;
    case "PLforAccessories":
      name = "Accessories";
      break;
    case "PLforDisplay":
      name = "Displays";
      break;
    default:
      name = "Others";
  }
  return name;
};

export const resetRecommendationObjectInbound = data => {
  let requestJson = {
    documents: {
      line_process: {
        items: []
      }
    }
  };
  data.forEach((item, index) => {
    if (item.recommendationObjectInbound_line) {
      requestJson.documents.line_process.items.push({
        _document_number: item._document_number,
        recommendationType_line: item.recommendationType_line,
        recommendationObject_line: JSON.stringify(
          item.recommendationObject_line
        ).replace(/"/g, "'"),
        recommendationTypeInbound_line: null,
        recommendationObjectInbound_line: null
      });
    }
  });
  let options = {
    actionName: "emptySave_quote",
    payloadJson: requestJson,
    field: "recommendationObject",
    showSnackbar: false
  };
  return options;
};

export const sortByTimeStamp = array => {
  let productWithTimestamp = array
    .filter(record => record.timeStamp)
    .map((el, index) => [el, index]);
  const productWithoutTimeStamp = array.filter(record => !record.timeStamp);
  productWithTimestamp.sort((a, b) => {
    return new Date(a[0].timeStamp) - new Date(b[0].timeStamp);
  });
  return [...productWithoutTimeStamp, ...productWithTimestamp.map(el => el[0])];
};
export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};
export const createReconfigTxnInput = (docNum, docId, quoteId) => {
  const childNames = [
    "_priceInfo",
    "_recmdParts",
    "_recmdModels",
    "_mandatoryParts",
    "_mandatoryModels",
    "_bom",
    "_sysNavigationInfo"
  ];
  const input = {
    documentNumber: docNum,
    documentId: parseInt(docId),
    bsId: parseInt(quoteId),
    mainDocAction: true,
    legacyMode: true,
    criteria: {
      state: true,
      childDefs: []
    }
  };
  childNames.forEach(cName => {
    input.criteria.childDefs = input.criteria.childDefs.concat({ name: cName });
  });

  return input;
};
export const createReconfigLoadDataInput = cacheInstanceId => {
  const childNamesObj = [
    { name: "_setarraySet", len: 25 },
    { name: "_setcarepackArraySet", len: 6 },
    { name: "_setadditionalCarepackArraySet", len: 5 }
  ];
  const input = {
    cacheInstanceId: cacheInstanceId,
    criteria: {
      state: true,
      childDefs: []
    }
  };
  childNamesObj.forEach(c => {
    input.criteria.childDefs = input.criteria.childDefs.concat(
      getChildDefArraySetStr(c.name, c.len)
    );
  });

  return input;
};

export const getChildDefArraySetStr = (cName, length) => {
  let str = [];
  for (var i = 1; i <= length; i++) {
    str = str.concat({
      name: cName + i,
      queryDef: {
        state: true,
        offset: 0,
        totalResults: true
      }
    });
  }
  return str;
};

export const getValidQuoteDetail = (type, record) => {
  switch (type) {
    case "Customer":
      const customerData = [
        record._soldTo_first_name,
        record._soldTo_last_name,
        record._soldTo_email,
        record._soldTo_phone
      ];
      return (
        customerData.filter(data => data === "" || data === null).length > 0
      );
    default:
  }
};

export const sortHardwareBySequence = records => {
  return records.sort((a, b) => {
    return a.sequenceNo - b.sequenceNo;
  });
};

export const sortByItemDisplaySeq = records => {
  return records.sort((a, b) => {
    return a.BOMSeqNo - b.BOMSeqNo;
  });
};

export const groupByCategory = (records, key) => {
  return records.filter(item => item.category === key).map(item => item);
};
export const getRefreshedToken = async options => {
  const { displayNotistack } = options;
  const token = localStorage.getItem("authToken");
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios
    .get(`${process.env.REACT_APP_GRAPHQL_BASE_URL}/renew/token`, {
      headers
    })
    .then(response => {
      localStorage.setItem("authToken", response.data.token);
      displayNotistack.error(false, "Please refresh the page");
    })
    .catch(error => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message ===
          "Idle timeout exceeded. Please refresh the page"
      ) {
        displayNotistack.error(true, error.response.data.message);
      } else {
        displayNotistack.error(false, i18n.t("common:table.networkErrorMsg"));
      }
    });
};

export const getChoiceIcon = choiceId => {
  let partIcon = <CardGiftcardIcon color="primary" />;
  let choiceIconData = [
    {
      name: ["Keyboard", "Keyboards/Mice and Input Devices"],
      variableName: ["KYBD"],
      icon: <KeyboardIcon color="primary" />
    },
    {
      name: ["Display", "Business Monitors"],
      variableName: ["LCD", "PANEL"],
      icon: <DesktopMacOutlinedIcon color="primary" />
    },
    {
      name: "Memory",
      variableName: ["MEM", "RAM"],
      icon: <MemoryIcon color="primary" />
    },
    {
      name: "Base Unit",
      variableName: ["BUNIT"],
      icon: <StorageIcon color="primary" />
    },
    {
      name: ["Battery", "Batteries/Chargers/Adapters"],
      variableName: ["BATTERY"],
      icon: <BatteryStdIcon color="primary" />
    },
    {
      name: "Mouse",
      variableName: ["MOUS", "MOUSE"],
      icon: <MouseIcon color="primary" />
    },
    {
      name: "HP Care Pack Services (only E)",
      variableName: ["CAREPACK"],
      icon: <ContactMailIcon color="primary" />
    },
    {
      name: "Processor",
      variableName: ["PROC"],
      icon: <BlurOnIcon color="primary" />
    },
    {
      name: ["PC Drives and Storage", "Internal Storage"],
      variableName: ["HDD1", "INSTOR", "INSTORM2"],
      icon: <SdStorageSharpIcon color="primary" />
    },
    {
      name: "Operating System",
      variableName: ["OS"],
      icon: <GroupWorkSharpIcon color="primary" />
    },
    {
      name: "Power Requirements - Power Supply",
      variableName: ["PSU"],
      icon: <PowerIcon color="primary" />
    },
    {
      name: "HP Absolute Care Pack Services",
      variableName: ["ABSCP"],
      icon: <CarepackIcon color="primary" />
    },
    {
      name: "HP Standalone Analytics Proactive Management services - CTO/BTCO only (E)",
      variableName: ["APME"],
      icon: <HpStandAloneAnalyticsIcon color="primary" />
    },
    {
      name: "China CCC Compliance",
      variableName: ["CCCPRC"],
      icon: <ViewCompactIcon color="primary" />
    },
    {
      name: "China Energy Label",
      variableName: ["CEL"],
      icon: <EnergyLabelIcon color="primary" />
    },
    {
      name: "Chassis",
      variableName: ["CHAS"],
      icon: <ChassisIcon color="primary" />
    },
    {
      name: "Country / Hardware Kit",
      variableName: ["CKIT"],
      icon: <KitIcon color="primary" />
    },
    {
      name: "DIB Hardware",
      variableName: ["DIB"],
      icon: <DibHardware color="primary" />
    },
    {
      name: ["Graphics Cards", "Graphics Card"],
      variableName: ["GRPH"],
      icon: <GraphicCardIcon color="primary" />
    },
    {
      name: "ID/Mechanical",
      variableName: ["IDMECH"],
      icon: <IdMechanicalIcon color="primary" />
    },
    {
      name: "Internal Storage",
      variableName: ["INSTOR"],
      icon: <SdStorageIcon color="primary" />
    },
    {
      name: "Internal M.2 Storage",
      variableName: ["INSTORM2"],
      icon: <StorageIcon color="primary" />
    },
    {
      name: "Media Card Reader",
      variableName: ["MCR"],
      icon: <CardReaderIcon color="primary" />
    },
    {
      name: "Networking",
      variableName: ["NET"],
      icon: <NetworkingIcon color="primary" />
    },
    {
      name: "OS Localization",
      variableName: ["OSLOC"],
      icon: <OSLocalizationIcon color="primary" />
    },
    {
      name: "Packaging",
      variableName: ["PACK"],
      icon: <PackagingIcon color="primary" />
    },
    {
      name: "Priority Services (E)",
      variableName: ["PSE"],
      icon: <PriorityServiceIcon color="primary" />
    },
    {
      name: "Slim Bay",
      variableName: ["SBAY"],
      icon: <SlimBayIcon color="primary" />
    },
    {
      name: "Stands",
      variableName: ["STANDS"],
      icon: <StandsIcon color="primary" />
    },
    {
      name: "Warranty",
      variableName: ["WRRTY"],
      icon: <WarrantyIcon color="primary" />
    },
    {
      name: "Application Software",
      icon: <AppsSharpIcon color="primary" />
    },
    {
      name: ["Cables/Cable Kits/Cable Adapters", "Network Adapters"],
      icon: <SettingsInputHdmiSharpIcon color="primary" />
    },
    {
      name: "Security and Protection",
      icon: <SecuritySharpIcon color="primary" />
    },

    {
      name: "Cases and Covers",
      icon: <BusinessCenterSharpIcon color="primary" />
    }
  ];
  choiceIconData.map(item => {
    if (item && item.variableName && item.variableName.includes(choiceId)) {
      partIcon = item.icon;
    } else if (item.name.includes(choiceId)) {
      partIcon = item.icon;
    }
    return item;
  });
  return partIcon;
};

export const resetAttributeValue = (data, fields) => {
  fields.forEach(field => {
    if (
      document.getElementsByName(field) &&
      document.getElementsByName(field)[0]
    ) {
      document.getElementsByName(field)[0].value =
        data.documents[field] && data.documents[field].value
          ? parseFloat(data.documents[field].value).toFixed(2)
          : 0;
    }
  });
};
export const hasSameOfferDifferentDeals = lineItems => {
  const offerSet = new Set();
  const dealSet = new Set();
  let dealCount = 0;
  let headerCount = 0;
  lineItems.forEach(item => {
    if (item.requestedDiscountPerc > 0) {
      return false;
    }
    if (item._part_numeber === null) {
      headerCount = headerCount + 1;
    }
    if (item.dealID_line !== undefined && item.dealID_line !== null) {
      offerSet.add(item.offerID_line);
      dealSet.add(item.dealID_line);
      dealCount = dealCount + 1;
    }
  });
  return lineItems.length - headerCount === dealCount && offerSet.size === 1;
};
export const validateSingleDealSource = lineItems => {
  const eligibleSrcId = lineItems.find(
    item => item.listPrice_line.value > 0 && item.dealID_line
  );
  const sourceId = eligibleSrcId && eligibleSrcId.dealID_line;
  let isDiscZero = true;
  let isCommonSrcId = true;
  lineItems.forEach(eachLineItem => {
    if (eachLineItem.requestedDiscountPerc > 0) {
      isDiscZero = false;
    }
    if (
      !sourceId ||
      (eachLineItem.partNo.search("#") === -1 &&
        eachLineItem.listPrice_line &&
        eachLineItem.listPrice_line.value > 0 &&
        eachLineItem.partNo !== undefined &&
        eachLineItem.partNo !== null &&
        !eachLineItem.isCtoHeader &&
        !eachLineItem.isBundle &&
        (eachLineItem.dealID_line !== sourceId ||
          eachLineItem.dealID_line === "" ||
          (eachLineItem.dealType_line &&
            eachLineItem.dealType_line.value !== "Eclipse")))
    ) {
      isCommonSrcId = false;
    }
  });
  return isDiscZero && isCommonSrcId;
};
export const getFieldIndexInExcel = (headerData, option) => {
  let fieldIndex;
  headerData &&
    headerData.forEach((item, index) => {
      if (option.includes(item.toString().toLowerCase().replace(/\s/g, ""))) {
        fieldIndex = index;
      }
    });
  return fieldIndex;
};
export const getDataCaption = (text, index) => {
  const regexp = /[^[]+|[^a-z]+|[^]]+/gi;
  const matchesArray = text.match(regexp);
  return (
    <React.Fragment>
      {matchesArray &&
        matchesArray.length > 0 &&
        matchesArray.map(string =>
          string.includes("[") ? (
            <Typography
              variant="caption"
              display="inline"
              key={index}
              color="textSecondary"
            >
              {string}
            </Typography>
          ) : (
            <Typography variant="subtitle1" display="inline" key={index}>
              {string}
            </Typography>
          )
        )}
    </React.Fragment>
  );
};
export const getDomElement = (getElementBy, attrArray) => {
  let attrJson = {};

  attrArray.forEach(item => {
    if (getElementBy === "id") {
      attrJson = {
        ...attrJson,
        [item]:
          document.getElementById(item) && document.getElementById(item).value
            ? document.getElementById(item).value
            : ""
      };
    } else {
      attrJson = {
        ...attrJson,
        [item]:
          document.getElementsByName(item) &&
          document.getElementsByName(item)[0] &&
          document.getElementsByName(item)[0].value
            ? document.getElementsByName(item)[0].value
            : ""
      };
    }
  });

  return attrJson;
};

export const getCommonRequestConfig = (quote, countryCode, prtnrPortalFlag) => {
  quote = quote || {};
  const quoteRecord = quote.record || {};
  const transactionID = quoteRecord.transactionID_quote
    ? quoteRecord.transactionID_quote
    : "manageConfig";
  const currencyCode = quoteRecord.hpQuoteNumber_quote
    ? quote.currency
      ? quote.currency.value
      : ""
    : getAttributeValue("attribute-currency");
  const routeToMarket = quoteRecord.goToMarketRoute_quote
    ? quoteRecord.goToMarketRoute_quote
    : prtnrPortalFlag
    ? "Indirect"
    : "Direct";
  const incoterms = quoteRecord.incoterms_quote
    ? quoteRecord.incoterms_quote.value
    : getAttributeValue("attribute-incoterms");
  const pricingGeo = quoteRecord.geo_quote
    ? quoteRecord.geo_quote.value
    : getAttributeValue("attribute-pricingGeo");
  const mDCPOrgID =
    Object.keys(quoteRecord).length > 0
      ? quoteRecord.mdcpOrgID_quote
        ? quoteRecord.mdcpOrgID_quote
        : ""
      : getAttributeValue("attribute-mDCPOrgID");
  const locationID =
    Object.keys(quoteRecord).length > 0
      ? quoteRecord.locationID_PrimaryReseller_channel_quote
        ? quoteRecord.locationID_PrimaryReseller_channel_quote
        : ""
      : getAttributeValue("attribute-location");
  const listOfPLForPANumber =
    Object.keys(quoteRecord).length > 0
      ? quoteRecord.productLineDelimitedString_channel_quote
        ? quoteRecord.productLineDelimitedString_channel_quote.replaceAll(
            "##",
            "~"
          )
        : ""
      : getAttributeValue("attribute-listOfPLForPANumber");
  return {
    transactionID,
    currencyCode,
    routeToMarket,
    countryCode,
    incoterms,
    pricingGeo,
    mDCPOrgID,
    prtnrPortalFlag,
    locationID,
    listOfPLForPANumber
  };
};
export const getConfigurationLink = (quote, link) => {
  const quoteRecord = quote && quote.record ? quote.record : {};
  const urlParams =
    quoteRecord && quoteRecord.linkToDocument_quote
      ? quoteRecord.linkToDocument_quote.substring(
          quoteRecord.linkToDocument_quote.indexOf("&")
        )
      : "&action_id=1307461495&document_id=4653823&id=8524672957&version_id=4748762";
  return quoteRecord && quoteRecord.hpQuoteNumber_quote
    ? `${link}&isFixedConfiguration=true${urlParams}`
    : link;
};

export const getAttributeValue = elementId => {
  const element = document.getElementById(elementId);
  return element ? element.value : "";
};

export const getStorageValue = elementId => {
  const element = JSON.parse(localStorage.getItem("bmiData"));
  return element ? element[elementId] : "";
};

export const getUploadedExcel = options => {
  const { excelData, previewProductsData, partNumberIndex, quantityIndex } =
    options;
  return excelData.slice(1).map((item, index) => {
    const filterItem = previewProductsData.filter(
      product => product.sku === item[partNumberIndex].toString().toUpperCase()
    );
    return {
      quantity: item[quantityIndex],
      partNo: item[partNumberIndex],
      description:
        filterItem && filterItem[0] && filterItem[0].description
          ? filterItem[0].description
          : "",
      listPrice:
        filterItem && filterItem[0] && filterItem[0].listPrice
          ? filterItem[0].listPrice
          : "",
      notes:
        filterItem && filterItem[0] && filterItem[0].errorMessage
          ? filterItem[0].errorMessage
          : "",
      override:
        filterItem && filterItem[0] && filterItem[0].isOveridden
          ? filterItem[0].isOveridden
          : false,
      hasError:
        filterItem && filterItem[0] && filterItem[0].hasError
          ? filterItem[0].hasError
          : false,
      id: index,
      isSelected: false,
      disable: false,
      showNotes: true,
      isOverridden: false
    };
  });
};

export const getBUByPL = (lineItem, productLine) => {
  const productLinePL = productLine.productLinePL;
  let leadBu_quote = "";
  if (productLinePL) {
    const PLValue = lineItem.records[0]._part_custom_field2;
    const { PLforPC, PLforPrinter } = productLinePL;
    PLforPC &&
      PLforPC.length > 0 &&
      PLforPC.includes(PLValue) &&
      (leadBu_quote = "PC");

    PLforPrinter &&
      PLforPrinter.length > 0 &&
      PLforPrinter.includes(PLValue) &&
      (leadBu_quote = "Print");
    if (!leadBu_quote) leadBu_quote = "Other";
  }

  return leadBu_quote;
};

export const getLineItems = lineItems => {
  const filteredLineItems = lineItems.map(lineItem => {
    return {
      docNumber: lineItem._document_number
        ? lineItem._document_number.toString()
        : "",
      quantity: lineItem._price_quantity ? lineItem._price_quantity : "",
      partNumber:
        lineItem._part_display_number || lineItem.visualPartNumber_line
          ? lineItem._part_display_number || lineItem.visualPartNumber_line
          : "",
      configID: lineItem.ucid_line ? lineItem.ucid_line : "",
      clonedConfigID: lineItem.bMIConfigID_line
        ? lineItem.bMIConfigID_line
        : "",
      replacementConfig: lineItem.replacementConfig_line === true,
      isBundleHeader: lineItem._model_variable_name
        ? lineItem._model_variable_name
        : "",
      parentDocNumber: lineItem._parent_doc_number
        ? lineItem._parent_doc_number
        : "",
      productLine: lineItem._part_custom_field2
        ? lineItem._part_custom_field2
        : "",
      authorizedNet:
        lineItem.authorizedNet_line && lineItem.authorizedNet_line.value
          ? lineItem.authorizedNet_line.value
          : 0.0,
      groupSequenceNumber: lineItem._group_sequence_number
        ? lineItem._group_sequence_number
        : "",
      weightUnit: lineItem._part_custom_field9
        ? lineItem._part_custom_field9
        : "",
      weightGross: lineItem._part_custom_field5
        ? lineItem._part_custom_field5.toString()
        : "",
      listPrice:
        lineItem.listPrice_line && lineItem.listPrice_line.value
          ? lineItem.listPrice_line.value
          : 0.0
    };
  });
  return filteredLineItems;
};

export const getItemLeaseRate = (
  _part_custom_field2,
  isCtoHeader,
  leaseRate,
  productLinePL
) => {
  if (isCtoHeader && leaseRate) {
    return leaseRate && leaseRate.hardwarePCRate;
  } else if (_part_custom_field2 && leaseRate && productLinePL) {
    if (
      productLinePL.pcHardwarePL &&
      productLinePL.pcHardwarePL.includes(_part_custom_field2)
    ) {
      return leaseRate && leaseRate.hardwarePCRate;
    } else if (
      productLinePL.pcAccessoriesPL &&
      productLinePL.pcAccessoriesPL.includes(_part_custom_field2)
    ) {
      return leaseRate && leaseRate.hardwarePCRate;
    } else if (
      productLinePL.pcDisplayPL &&
      productLinePL.pcDisplayPL.includes(_part_custom_field2)
    ) {
      return leaseRate && leaseRate.hardwarePCRate;
    } else if (
      productLinePL.printerHardwarePL &&
      productLinePL.printerHardwarePL.includes(_part_custom_field2)
    ) {
      return leaseRate && leaseRate.hardwarePrintRate;
    } else if (
      productLinePL.printerAccessoriesPL &&
      productLinePL.printerAccessoriesPL.includes(_part_custom_field2)
    ) {
      return leaseRate && leaseRate.hardwarePrintRate;
    } else if (
      productLinePL.printerDisplayPL &&
      productLinePL.printerDisplayPL.includes(_part_custom_field2)
    ) {
      return leaseRate && leaseRate.hardwarePrintRate;
    } else if (
      productLinePL.pcServicePL &&
      productLinePL.pcServicePL.includes(_part_custom_field2)
    ) {
      return leaseRate && leaseRate.servicePCRate;
    } else if (
      productLinePL.printerServicePL &&
      productLinePL.printerServicePL.includes(_part_custom_field2)
    ) {
      return leaseRate && leaseRate.servicePrintRate;
    }
  }
  return null;
};

export const getQuoteLeaseInputs = (lineItems, productLinePL) => {
  let hwAmt = 0,
    printAmt = 0,
    serviceAmt = 0;
  if (productLinePL && Object.keys(productLinePL).length > 0) {
    for (const lineItem of lineItems) {
      const {
        _part_custom_field2,
        recommendedPrice_channel_line,
        _price_quantity,
        hasParent,
        isCtoHeader
      } = lineItem;
      if (hasParent) {
        //do nothing
      } else if (isCtoHeader) {
        hwAmt +=
          parseFloat(_price_quantity) *
          parseFloat(recommendedPrice_channel_line);
      } else if (
        productLinePL.pcHardwarePL &&
        productLinePL.pcHardwarePL.includes(_part_custom_field2)
      ) {
        hwAmt +=
          parseFloat(_price_quantity) *
          parseFloat(recommendedPrice_channel_line);
      } else if (
        productLinePL.pcAccessoriesPL &&
        productLinePL.pcAccessoriesPL.includes(_part_custom_field2)
      ) {
        hwAmt +=
          parseFloat(_price_quantity) *
          parseFloat(recommendedPrice_channel_line);
      } else if (
        productLinePL.pcDisplayPL &&
        productLinePL.pcDisplayPL.includes(_part_custom_field2)
      ) {
        hwAmt +=
          parseFloat(_price_quantity) *
          parseFloat(recommendedPrice_channel_line);
      } else if (
        productLinePL.printerHardwarePL &&
        productLinePL.printerHardwarePL.includes(_part_custom_field2)
      ) {
        printAmt +=
          parseFloat(_price_quantity) *
          parseFloat(recommendedPrice_channel_line);
      } else if (
        productLinePL.printerAccessoriesPL &&
        productLinePL.printerAccessoriesPL.includes(_part_custom_field2)
      ) {
        printAmt +=
          parseFloat(_price_quantity) *
          parseFloat(recommendedPrice_channel_line);
      } else if (
        productLinePL.printerDisplayPL &&
        productLinePL.printerDisplayPL.includes(_part_custom_field2)
      ) {
        printAmt +=
          parseFloat(_price_quantity) *
          parseFloat(recommendedPrice_channel_line);
      } else if (
        productLinePL.pcServicePL &&
        productLinePL.pcServicePL.includes(_part_custom_field2)
      ) {
        serviceAmt +=
          parseFloat(_price_quantity) *
          parseFloat(recommendedPrice_channel_line);
      } else if (
        productLinePL.printerServicePL &&
        productLinePL.printerServicePL.includes(_part_custom_field2)
      ) {
        serviceAmt +=
          parseFloat(_price_quantity) *
          parseFloat(recommendedPrice_channel_line);
      }
    }
  }
  return {
    hwAmt,
    printAmt,
    serviceAmt,
    totalAmt: hwAmt + printAmt + serviceAmt
  };
};

export const getLightGetPriceInput = (record, lineItems) => {
  const aMID2 =
    record && record.goToMarketRoute_quote === "Direct"
      ? record.aMID2Direct_quote
      : record.aMID2Direct_quote;
  const shippingMethod =
    record.hpStatus_quote === "Complete" ||
    record.hpStatus_quote === "Orderable"
      ? record.deliverySpeedEncoreOrder_quote.value
      : record.deliverySpeedEncore_quote.value;
  const state =
    record &&
    record.shipToCountry_quote &&
    record.shipToCountry_quote.value !== "US"
      ? record.shipToState_quote.value
      : record.shipToStateUS_quote && record.shipToStateUS_quote.value;
  return {
    countryCode:
      record && record.country_quote && record.country_quote.value
        ? record.country_quote.value
        : "",
    aMID2: aMID2 ? aMID2 : "",
    businessModel:
      record && record.businessModel_quote && record.businessModel_quote.value
        ? record.businessModel_quote.value
        : "",
    businessRelationshipID:
      record && record.businessRelationshipID_channel_quote
        ? record.businessRelationshipID_channel_quote
        : "",
    createdDate:
      record && record.createdDate_quote ? record.createdDate_quote : "",
    crsID: record && record.cRSID_quote ? record.cRSID_quote : "",
    currencyCode:
      record && record.currency_quote && record.currency_quote.value
        ? record.currency_quote.value
        : "",
    customerEngagement:
      record && record.customerEngagement_quote
        ? record.customerEngagement_quote
        : "",
    customerType:
      record && record.customerType_quote && record.customerType_quote.value
        ? record.customerType_quote.value
        : "",
    dealType: record && record.dealType_quote ? record.dealType_quote : "",
    distributorID:
      record && record.iD_distributor_channel_quote
        ? record.iD_distributor_channel_quote
        : "",
    geo:
      record && record.geo_quote && record.geo_quote.value
        ? record.geo_quote.value
        : "",
    hpStatus: record && record.hpStatus_quote ? record.hpStatus_quote : "",
    incoterms:
      record && record.incoterms_quote && record.incoterms_quote.value
        ? record.incoterms_quote.value
        : "",
    languageCode: record && record.language_quote ? record.language_quote : "",
    lineItems: getLineItems(lineItems),
    mDCPOrgID: record && record.mdcpOrgID_quote ? record.mdcpOrgID_quote : "",
    mDMBusinessAreaGroupData:
      record && record.mDMBusinessAreaGroupData_quote
        ? record.mDMBusinessAreaGroupData_quote
        : "",
    mdcpCustomerSegment:
      record && record.mdcpCustomerSegment_quote
        ? record.mdcpCustomerSegment_quote
        : "",
    mdcpOtherPartySiteID:
      record && record.mdcpOtherPartySiteID_quote
        ? record.mdcpOtherPartySiteID_quote
        : "",
    mdcpSiteID: "",
    opportunityID:
      record && record.hpOpportunityIDImported_quote
        ? record.hpOpportunityIDImported_quote
        : "",
    opsiID: record && record.oPSIID_quote ? record.oPSIID_quote : "",
    partnerTypeDistributor:
      record && record.partnerType_disti_channel_quote
        ? record.partnerType_disti_channel_quote
        : "",
    partnerTypePrimaryReseller:
      record && record.partnerType_PrimaryReseller_Channel_Quote
        ? record.partnerType_PrimaryReseller_Channel_Quote
        : "",
    presalesID:
      record && record.presalesID_quote ? record.presalesID_quote : "",
    primaryResellerID:
      record && record.iD_PrimaryReseller_Channel_quote
        ? record.iD_PrimaryReseller_Channel_quote
        : "",
    quoteNo:
      record && record.hpQuoteNumber_quote ? record.hpQuoteNumber_quote : "",
    routeToMarket:
      record && record.goToMarketRoute_quote
        ? record.goToMarketRoute_quote
        : "",
    shipToAddress: {
      transactionID:
        record && record.transactionID_quote ? record.transactionID_quote : "",
      street:
        record && record.shipToStreet_quote ? record.shipToStreet_quote : "",
      city: record && record.shipToCity_quote ? record.shipToCity_quote : "",
      state: state ? state : "",
      zip: record && record.shipToZip_quote ? record.shipToZip_quote : "",
      countryCode: "US"
    },
    soldToAddress: {
      transactionID:
        record && record.transactionID_quote ? record.transactionID_quote : "",
      state:
        record && record._soldTo_state && record._soldTo_state.value
          ? record._soldTo_state.value
          : "",
      zip: record && record._soldTo_zip ? record._soldTo_zip : ""
    },
    shippingmethod: shippingMethod ? shippingMethod : "",
    stAccountID:
      record && record.STAccountID_quote ? record.STAccountID_quote : "",
    transactionID:
      record && record.transactionID_quote ? record.transactionID_quote : ""
  };
};

export const customerFormActions = name => {
  switch (name) {
    case "company_name":
      return {
        type: `SET_COMPANY_NAME`
      };
    case "address":
      return {
        type: `SET_COMPANY_ADDRESS`
      };
    case "city":
      return {
        type: `SET_COMPANY_CITY`
      };
    case "state_or_province":
      return {
        type: `SET_COMPANY_STATE`
      };
    case "zipcode":
      return {
        type: `SET_COMPANY_ZIPCODE`
      };
    case "country":
      return {
        type: `SET_COMPANY_COUNTRY`
      };
    case "id":
      return {
        type: `SET_COMPANY_ID`
      };
    default:
      return;
  }
};

export const opportunityFormActions = type => {
  switch (type) {
    case "opportunityName":
      return {
        type: `SET_NAME`
      };
    case "closeDate":
      return {
        type: `SET_DATE`
      };
    case "currency":
      return {
        type: `SET_CURRENCY`
      };
    case "description":
      return {
        type: `SET_DESCRIPTION`
      };
    case "owner":
      return {
        type: `SET_OWNER`
      };
    default:
      return;
  }
};

export const getPlaceholder = (t, selectedValue) => {
  switch (selectedValue) {
    case "None":
      return t("common:productSelector.textMessage.pleaseEnterNone");
    case "configID":
      return t("common:productSelector.textMessage.pleaseEnterConfigID");
    case "configName":
      return t("common:productSelector.textMessage.pleaseEnterConfigName");
    case "email":
      return t("common:productSelector.textMessage.pleaseEnterEmail");
    case "Customer ID (MDCP ID)":
      return t("common:productSelector.textMessage.pleaseEnterCustomerID");
    case "btoSku":
      return t("common:productSelector.textMessage.pleaseEnterBtoSku");
    default:
      return;
  }
};

export const sortAlphabetically = (x, y) => {
  let a = x.displayValue.toUpperCase(),
    b = y.displayValue.toUpperCase();
  return a === b ? 0 : a > b ? 1 : -1;
};
//FIXME: needs code cleanup - quick fix
export const sortAlphabeticallyByName = (x, y) => {
  let a = x.name.toUpperCase(),
    b = y.name.toUpperCase();
  return a === b ? 0 : a > b ? 1 : -1;
};

export const getStringLength = string => {
  const REGEX_CHINESE =
    /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;
  return string
    .split("")
    .reduce(
      (result, char) =>
        (result = REGEX_CHINESE.test(char) ? result + 2 : result + 1),
      0
    );
};

export const timeDiff = dateStr => {
  let date = "";
  const todayDate1 = new Date().toISOString().slice(0, 10);
  if (dateStr && dateStr.length !== 10) {
    const dateArray = dateStr.split(" ");
    dateArray[0] = moment(dateArray[0]).format("YYYY/MM/DD");
    const splitDate = dateArray[0].split("/");
    const splitTime = dateArray[1].split(":");
    date = Math.round(
      (Date.parse(
        new Date(
          splitDate[0],
          splitDate[1] - 1,
          splitDate[2],
          splitTime[0],
          splitTime[1],
          splitTime[2]
        )
      ) -
        Date.parse(todayDate1)) /
        (24 * 3600 * 1000)
    );
  } else {
    const dateFormat = moment(dateStr).format("YYYY/MM/DD");
    date = Math.round(
      (Date.parse(dateFormat) - Date.parse(todayDate1)) / (24 * 3600 * 1000)
    );
  }
  return date;
};

export const formatDate = date => {
  return date.slice(6) + "/" + date.slice(4, 6) + "/" + date.slice(0, 4);
};

export const obsoleteDateFormat = date => {
  return date.slice(4, 6) + "/" + date.slice(6) + "/" + date.slice(0, 4);
};

export const dateFormat = date => {
  return date.slice(0, 4) + "-" + date.slice(4, 6) + "-" + date.slice(6);
};

export const gadateFormat = date => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  return (
    date?.slice(8) +
    " " +
    monthNames[date?.slice(5, 7) - 1] +
    " " +
    date?.slice(0, 4)
  );
};

export const calculateDays = formatEod => {
  let eod = new Date(formatEod);
  let toDay = new Date();
  let timeDifference = eod.getTime() - toDay.getTime();
  let days = Math.abs(timeDifference / (1000 * 3600 * 24));

  return days;
};

export const showEsAndPriceSource = (
  channelRegion,
  countryCode,
  type,
  configID,
  configOrigin
) => {
  let showEsAndPriceSource;
  if (type === "EM" && !configID && ["AP", "APJ"].includes(channelRegion)) {
    showEsAndPriceSource = true;
  } else if (configOrigin === "OCA") {
    showEsAndPriceSource = true;
  } else {
    showEsAndPriceSource =
      !["EMEA", "EU", "NA"].includes(channelRegion) && countryCode !== "US";
  }
  return showEsAndPriceSource;
};

export const loadingLabel = (text, loading) =>
  loading ? (
    <div style={{ position: "relative" }}>
      <div>{text}</div>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(50%,-50%)"
        }}
      >
        <HourglassFullIcon style={{ color: "rgb(41, 168, 221)" }} />
      </div>
    </div>
  ) : (
    text
  );

export const delay = timeout => {
  return new Promise(resolve => {
    setTimeout(resolve, timeout);
  });
};
export const dateCompare = formatGaD => {
  if (!["", "NA"].includes(formatGaD)) {
    let gaD = moment(formatGaD).format("YYYY/MM/DD");
    let toDay = new Date();
    let date = moment(toDay).format("YYYY/MM/DD");
    return gaD > date;
  } else {
    return false;
  }
};

export const isFutureDate = date => {
  if (!["", "NA"].includes(date)) {
    const inputDate = moment(date).format("YYYY-MM-DD");
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    return todayDate < inputDate;
  } else {
    return false;
  }
};
export const updateIframeHeight = async (isLoading = false) => {
  await delay(2000);
  if (document.querySelector("#product-selector")) {
    const height = isLoading
      ? window.document.querySelector("body")?.offsetHeight
      : window.document.querySelector("#configContainer")?.offsetHeight;
    document.querySelector("#product-selector").dispatchEvent(
      new CustomEvent("iframeloaded", {
        detail: { height: `${height + 100}` }
      })
    );
  } else if (document.querySelector("#configurator")) {
    const height = isLoading
      ? window.document.querySelector("body")?.offsetHeight
      : window.document.querySelector("#configContainer")?.offsetHeight;
    document.querySelector("#configurator").dispatchEvent(
      new CustomEvent("iframeloaded", {
        detail: { height: `${height + 100}` }
      })
    );
  }
};

export const getFilteredAccessories = products => {
  const currencyCode = getStorageValue("currencyCode");
  const sectionInfo = [
    "carePacks",
    "monitoringAccesories",
    "deploymentServices",
    "manageabilityService"
  ];
  const filteredRskuAccesories = products.filter(each =>
    sectionInfo.includes(each.sectionId)
  );
  return filteredRskuAccesories.map(each => {
    return {
      sku: each.partNumber,
      name: each.description,
      listPrice: `${each.listPrice}`,
      quantity: each.qty,
      currency: currencyCode,
      currencySign: currencyCode,
      image: [HP_LOGO]
    };
  });
};

export const updateCheckoutRSKUQuantity = (
  checkoutRskus,
  partNumber,
  skuArray
) => {
  let updatedSkuList = [...checkoutRskus];
  const index = checkoutRskus.findIndex(each => each.sku === partNumber);
  if (index === -1) {
    const newSku = skuArray.find(each => each.sku === partNumber);
    updatedSkuList.push(newSku);
  }
  return updatedSkuList.filter(each => each.quantity > 0);
};

export const getSkuNumber = products => {
  return products.reduce((acc, el) => {
    acc.push(el.sku);
    return acc;
  }, []);
};

export const getCarepackData = (accessories, nonHardwareProducts) => {
  const { products } = nonHardwareProducts;
  const filteredAccesoryImages =
    !isEmpty(products) &&
    Object.keys(products).reduce((acc, ele) => {
      const productDetails = products[ele]?.images?.[0]?.details?.[0];
      const productImage = productDetails?.imageUrlHttps
        ? [productDetails.imageUrlHttps]
        : [HP_LOGO];
      acc[ele] = productImage;
      return acc;
    }, {});
  return accessories.map(each => {
    return {
      ...each,
      image: filteredAccesoryImages ? filteredAccesoryImages[each.sku] : [HP_LOGO]
    };
  });
};

export const getSkuImages = async (
  filteredAccessories,
  countryCode,
  clientLocale
) => {
  let requestURL = `${process.env.REACT_APP_CPQ_BASE_URL}/product`;
  let data = {
    sku: getSkuNumber(filteredAccessories),
    countryCode: countryCode,
    languageCode: clientLocale,
    layoutName: "IMAGE",
    cache: true,
    fallback: true,
    requestor: "IQ-ITG",
    reqContent: ["images"],
    log: true
  };
  const cHeader = {
    headers: { Authorization: `Bearer ${window.userServiceToken}` }
  };
  let response;
  // Fix me
  await axiosCall
    .post(requestURL, data, cHeader)
    .then(res => {
      response = res;
    })
    .catch(err => {
      response = false;
    });

  return response;
};

export const getHardwareAvs = bomData => {
  const avs = bomData.reduce((acc, el) => {
    if (el.sectionId === "hardware") {
      acc.push(el.partNumber);
    }
    return acc;
  }, []);
  return avs;
};

/*  ----Exact Match Products-----  */

export const getBaseProductData = bomData => {
  const listPrice = bomData.reduce((acc, el) => {
    if (el.sectionId === "hardware") {
      acc += el.listPrice;
    }
    return acc;
  }, 0);

  const attributes = bomData.reduce((acc, el, index) => {
    if (
      el.sectionId === "hardware" &&
      index <= 6 &&
      !el.partNumber.includes("#")
    ) {
      acc.push({ value: el.description });
    }
    return acc;
  }, []);

  return { listPrice, attributes };
};

export const getProductData = (product, bomData, currency, rskuImage) => {
  const { listPrice, attributes } = getBaseProductData(bomData);
  let requestRskuProduct = {};
  requestRskuProduct.sku = product.skuNumber
    ? product.skuNumber
    : product.rskuMaterialNumber;
  requestRskuProduct.name = product.ShortDescription
    ? product.ShortDescription
    : product.shortDescription;
  requestRskuProduct.listPrice = parseFloat(listPrice).toFixed(2);
  requestRskuProduct.attributes = attributes;
  requestRskuProduct.currency = currency;
  requestRskuProduct.currencySign = currency;
  requestRskuProduct.quantity = 0;
  requestRskuProduct.image = rskuImage[0];
  requestRskuProduct.isRSKUFinalized = Boolean(product.isRSKUFinalized);
  return [requestRskuProduct];
};

/*  ----Product Skeleton-----  */

export const generatArrayOfValues = count => {
  return [...Array(count).keys()].map(() => Math.floor(Math.random() * 100));
};

/*  ----Close Match Products-----  */

export const mergeAttributesByValue = (
  difference,
  other,
  differValue,
  otherValue
) => {
  const filteredDifferences = [];
  const filteredOtherAttributes = other?.length
    ? other.filter(each => {
        if (difference?.length) {
          let findDuplicate = difference.find(
            item => item.partNumber === each.partNumber
          );
          if (!findDuplicate) {
            return each;
          } else {
            filteredDifferences.push({
              ...findDuplicate,
              listPrice: each.listPrice
            });
          }
        } else {
          return each;
        }
      })
    : [];
  const filterOtherValue = otherValue
    ? otherValue
    : filteredOtherAttributes.length;
  const filterDifferValue = differValue ? differValue : difference.length;
  const attributes = other?.length
    ? [
        ...filteredOtherAttributes.slice(0, filterOtherValue),
        ...filteredDifferences.slice(0, filterDifferValue)
      ]
    : [...filteredDifferences.slice(0, filterDifferValue)];
  return attributes;
};

export const getAttributes = (bomDetail, typeOfAv, typeOfAttribute) => {
  return bomDetail.reduce((acc, ele) => {
    let checkType =
      typeOfAv === "localizationAvs"
        ? ele.productNumber.includes("#")
        : !ele.productNumber.includes("#");
    if (checkType) {
      acc.push({
        value: ele.shortDescription,
        partNumber: ele.productNumber,
        highlight: typeOfAttribute === "difference" ? true : false,
        listPrice: !isEmpty(ele.listPrice) ? ele.listPrice : 0,
        quantity: 1,
        sectionId: !isEmpty(ele.sectionId) ? ele.sectionId : ""
      });
    }
    return acc;
  }, []);
};

export const getSimilarProductData = (
  currency,
  closeMatchSkus,
  rskuImage,
  difference,
  other
) => {
  const closeMatchProducts = closeMatchSkus.slice(0, 3).map(each => {
    const { closeMatchDifference, closeMatchComponents } = each;
    let allDifferences = [];
    let allOtherComponent = [];

    if (closeMatchDifference && closeMatchDifference.bomDetail?.length) {
      const localizationDifferences = getAttributes(
        closeMatchDifference.bomDetail,
        "localizationAvs",
        "difference"
      );

      const nonLocalizationDifferences = getAttributes(
        closeMatchDifference.bomDetail,
        "nonLocalizationAvs",
        "difference"
      );

      allDifferences = mergeAllBomAttributes(
        localizationDifferences,
        nonLocalizationDifferences,
        "difference"
      );
    }

    if (closeMatchComponents && closeMatchComponents.bomDetail?.length) {
      const localizationComponent = getAttributes(
        closeMatchComponents.bomDetail,
        "localizationAvs",
        "other"
      );

      const nonLocalizationComponent = getAttributes(
        closeMatchComponents.bomDetail,
        "nonLocalizationAvs",
        "other"
      );

      allOtherComponent = mergeAllBomAttributes(
        localizationComponent,
        nonLocalizationComponent,
        "other"
      );
    }

    const attributes = mergeAttributesByValue(
      allDifferences,
      allOtherComponent,
      difference,
      other
    );
    return {
      sku: each.skuNumber,
      name: each.shortDescription,
      attributes: attributes,
      image: rskuImage[0],
      listPrice: each.closeMatchComponents?.totalListPrice
        ? each.closeMatchComponents?.totalListPrice.toFixed(2)
        : "0.00",
      currency: currency,
      currencySign: currency,
      quantity: 0,
      isRSKUFinalized: each.isRSKUFinalized
    };
  });
  return closeMatchProducts;
};

/*  ----BOM Details With All The Hardware Products-----  */

export const getUniqueAttributes = attributes => {
  return [...new Map(attributes.map(item => [item.partNumber, item])).values()];
};

export const mergeAllBomAttributes = (
  localizationAttriutes,
  allAttributes,
  typeOfAttribute
) => {
  const highlightValue = typeOfAttribute === "difference" ? true : false;
  const uniqueAttributes = getUniqueAttributes(allAttributes);
  const uniqueLocalizationAttriutes = getUniqueAttributes(
    localizationAttriutes
  );
  const filteredAttributes = uniqueAttributes.map(each => {
    const findDuplicate = uniqueLocalizationAttriutes.find(item =>
      item.partNumber.includes(each.partNumber)
    );
    if (findDuplicate) {
      return {
        value: findDuplicate.value,
        highlight: highlightValue,
        partNumber: findDuplicate.partNumber,
        listPrice: each.listPrice,
        quantity: 1,
        sectionId: findDuplicate.sectionId
      };
    } else {
      return {
        value: each.value,
        highlight: highlightValue,
        partNumber: each.partNumber
      };
    }
  });
  const attributes = uniqueLocalizationAttriutes?.length
    ? getUniqueAttributes(
        filteredAttributes.concat(uniqueLocalizationAttriutes)
      )
    : filteredAttributes;
  return attributes;
};

export const getAllBomDetails = (bomData, rskuImage) => {
  const filteredBomData = bomData.find(each => each.choiceId === "BUNIT");

  const filteredLocalizationAttributes = bomData.reduce((acc, ele) => {
    if (ele.partNumber.includes("#")) {
      acc.push({
        value: ele.description,
        partNumber: ele.partNumber
      });
    }
    return acc;
  }, []);

  const allAttributes = bomData.reduce((acc, el) => {
    if (el.sectionId === "hardware" && !el.partNumber.includes("#")) {
      acc.push({
        value: el.description,
        partNumber: el.partNumber
      });
    }
    return acc;
  }, []);

  const attributes = mergeAllBomAttributes(
    filteredLocalizationAttributes,
    allAttributes,
    "other"
  );

  const sku = filteredBomData.partNumber;
  const name = filteredBomData.description;
  const quantity = filteredBomData.qty;
  const image = rskuImage[0];

  return { attributes, sku, name, quantity, image };
};

export const getMandatorySkus = (mandatory, bomData) => {
  const filteredChoiceId = bomData.filter(item =>
    mandatory.includes(item.choiceId)
  );
  const filteredAvs = filteredChoiceId.reduce((acc, el) => {
    acc.push(el.partNumber);
    return acc;
  }, []);

  return filteredAvs;
};

export const shouldShowOnUI = (filterParams, gaDate, selected, partno) => {
  let flag = true;
  if (!selected) {
    flag = !(
      filterParams &&
      filterParams.currentConfigID &&
      !filterParams.showGADate &&
      gaDate &&
      dateCompare(gaDate)
    );
  }
  return flag;
};
export const gaDateErrorMessage = (showGADate, gaDate, isConfigOverDate) => {
  if (
    (dateCompare(gaDate) && !showGADate) ||
    (showGADate && !isConfigOverDate && dateCompare(gaDate))
  ) {
    return true;
  }
  return false;
};

export const getFilteredBomData = bomData => {
  const filteredBom = bomData.map(each => {
    return {
      productNumber: each.partNumber,
      shortDescription: each.description,
      listPrice: each.listPrice,
      sectionId: each.sectionId
    };
  });
  const localizatiedBomData = getAttributes(
    filteredBom,
    "localizationAvs",
    "other"
  );

  const nonLocalizatedBomData = getAttributes(
    filteredBom,
    "nonLocalizationAvs",
    "other"
  );

  const filteredBomAttributes = mergeAllBomAttributes(
    localizatiedBomData,
    nonLocalizatedBomData,
    "other"
  );
  return filteredBomAttributes;
};

export const getProductAttribute = bom => {
  return {
    partNumber: bom.partNumber,
    description: bom.description,
    quantity: bom.quantity ? bom.quantity : bom.qty,
    listPrice: bom.listPrice.toFixed(2).toString(),
    plcOverride: false,
    requestedBDNetPrice: "150.00"
  };
};

export const getCloseMatchBomData = closeMatchSkus => {
  const closeMatchBom = closeMatchSkus.slice(0, 3).map(each => {
    const { closeMatchComponents } = each;
    let attributes = [];
    if (closeMatchComponents && closeMatchComponents.bomDetail?.length) {
      attributes = closeMatchComponents.bomDetail.map(ele => {
        return {
          partNumber: ele.productNumber,
          description: ele.shortDescription,
          listPrice: !isEmpty(ele.listPrice) ? ele.listPrice : 0,
          quantity: 1
        };
      });
    }

    return {
      sku: each.skuNumber,
      attributes: attributes
    };
  });

  return closeMatchBom;
};

export const baseAndLocalizedProduct = (components, partNumber) => {
  let isBaseProduct;
  if (partNumber.includes("#")) {
    const baseProduct = partNumber.split("#")[0];
    isBaseProduct = components.find(each => each.partNumber === baseProduct);
  }
  return isBaseProduct;
};

export const validateRsku = (btoProducts, ctoProducts) => {
  let bto = [];
  let cto = {};
  if (!isEmpty(btoProducts)) {
    bto = btoProducts.reduce((acc, ele) => {
      ele.isRSKU = true;
      if (ele.sku.includes("#") && ele.isRSKUFinalized) {
        const baseProduct = {
          ...ele,
          sku: ele.sku.split("#")[0]
        };
        const localizedProduct = {
          ...ele,
          listPrice: "0.00"
        };
        acc.push(baseProduct, localizedProduct);
      } else {
        acc.push(ele);
      }
      return acc;
    }, []);
  }

  if (!isEmpty(ctoProducts) && !isEmpty(ctoProducts.components)) {
    const { components } = ctoProducts;
    const filteredComponents = components?.length
      ? components.reduce((acc, ele) => {
          const attributes = ele.reduce((ac, el) => {
            if (
              el.partNumber.includes("#") &&
              isEmpty(baseAndLocalizedProduct(ele, el.partNumber))
            ) {
              const baseProduct = {
                ...el,
                partNumber: el.partNumber.split("#")[0]
              };
              const localizedProduct = {
                ...el,
                listPrice: "0.00"
              };
              ac.push(baseProduct, localizedProduct);
            } else {
              ac.push(el);
            }
            return ac;
          }, []);
          acc.push(getUniqueAttributes(attributes));
          return acc;
        }, [])
      : [];
    cto = {
      ...ctoProducts,
      components: filteredComponents
    };
  }
  return { bto, cto };
};

export const errorCodeFilter = (icConflicts, code) => {
  return icConflicts?.find(item => item.code === code);
};

export const icConflictsFilter = (icConflicts, chaid) => {
  const icFind = icConflicts?.find(item =>
    chaid?.items?.find(chaidItem => chaidItem.partno === item.partNo)
  );
  return icFind;
};

export const transformDateFormat = (chosenDate, format) => {
  return chosenDate ? dayjs(chosenDate).format(format) : "";
};

export const showAddToQuoteBtn = products => {
  const isInValid = products.some(
    item =>
      ["", "99990101"].includes(item.gaDate) ||
      item.gaDate > moment().format("YYYYMMDD") ||
      ["", "99990101"].includes(item.emDate) ||
      item.emDate < moment().format("YYYYMMDD") ||
      ["", "N/A"].includes(item.listPrice) ||
      item.notes.includes("Not offered for CTO")
  );
  return !isInValid;
};

export const filteredRskuBomList = (bomInfo, status) => {
  return bomInfo.reduce((acc, ele) => {
    acc.push(status === "rSku" ? ele.materialNum : ele.productNumber);
    return acc;
  }, []);
};

export const validateSkuIDSearch = (searchValue, userRegion) => {
  const skuVersion = searchValue?.split("#")[0].slice(-2);
  const isValidSkuId = CLONING_SKU[userRegion] === skuVersion;
  const validSkuVersion = CLONING_SKU[userRegion];
  return { isValidSkuId, validSkuVersion };
};
export const checkSectionID = (sectionIds, bomData) => {
  const filteredSectionId = bomData?.length
    ? bomData?.filter(product => sectionIds?.includes(product?.sectionId))
    : [];
  const standardCarepacks = bomData?.length
    ? bomData?.filter(product => product?.sectionId === "carePacks")
    : [];
  // PlaceHolder for autoattachcarepacks logic
  const autoAttachCarepacks = [];

  const rSKUBom = bomData?.length
    ? bomData?.filter(product => {
        const slicedPartNumber = product.partNumber.slice(0, 2);
        if (
          !sectionIds.includes(product?.sectionId) &&
          (product.choiceId !== "" || slicedPartNumber !== "YT")
        ) {
          return product;
        }
      })
    : [];
  const grandPrice = rSKUBom.reduce((sum, f) => sum + f.listPrice, 0);
  const hasOtherComponent = Boolean(filteredSectionId?.length);
  let warrentyDescription = rSKUBom.find(
    bom => bom.choiceId === "WRRTY"
  )?.description;
  warrentyDescription = !isEmpty(warrentyDescription)
    ? warrentyDescription
    : "";

  return {
    filteredSectionId,
    hasOtherComponent,
    rSKUBom,
    grandPrice,
    standardCarepacks,
    autoAttachCarepacks,
    warrentyDescription
  };
};

export const getRskuImages = baseProductImages => {
  const sortingOrder = ["center", "left", "right"];
  baseProductImages = baseProductImages
    .slice()
    .sort(
      (prev, curr) =>
        sortingOrder.indexOf(prev.orientation.toLowerCase()) -
        sortingOrder.indexOf(curr.orientation.toLowerCase())
    );
  const baseImages = baseProductImages.reduce((images, product) => {
    images.push(product.httpsUrl);
    return images;
  }, []);
  return baseImages;
};
