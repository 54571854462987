// const { binarySearch } = require("./util.js");
// const { processOutput } = require("./processOutput");
import {
  ChangeOut_CH1,
  ChangeOut_CH2,
  ChangeOut_CH3,
  EdgeType_DECODE,
  EdgeType_ENCODE,
  EdgeType_EQU_0,
  EdgeType_GTE_0,
  EdgeType_INVERT,
  EdgeType_LTE_0,
  EdgeType_MUL_a,
  EdgeType_MUL_b,
  EdgeType_MUX_1,
  EdgeType_SEL_1,
  EdgeType_SEL_a,
  EdgeType_SUM_a,
  NodeListEncoding_ABB,
  NodeListEncoding_ABC,
  NodeListEncoding_CML,
  NodeListEncoding_CMP,
  NodeListEncoding_LUT,
  OutputGate_NOT_0,
  OutputGate_OPEN
} from "./Constants.js";
import { processOutput } from "./processOutput.js";
import { binarySearch } from "./util.js";
// ProcessInput is the function to call for updating the Load Model based upon the changes come from front end.
// Three changes will come from UI/UX. These are Edge type, iNode, changeIn corresponding to Chaid/Item.
//
const isDebug = window.localStorage.getItem("isDebug") === "true";
let currentInode;
//src/model/neural-net.go
const propagateChange = (payload, modelData) => {
  isDebug && console.log("propagateChange START");
  const { iEdge, iNode, iChange, nodeName = "" } = payload;

  if (!(iEdge & 0x40)) {
    const processInputPayload = {
      inputEdge: iEdge,
      inputNode: iNode,
      changeIn: iChange
    };
    modelData = processInput(processInputPayload, modelData);
  } else {
    const processOutputPayload = {
      iNode: iNode,
      change: iChange,
      edgeType: iEdge,
      currentInode : currentInode,
      nodeName : nodeName 
    };
    modelData = processOutput(processOutputPayload, modelData);
  }
  isDebug && console.log("propagateChange END");
  return modelData;
};

//src/model/neural-net.go
export const processInput = (itemChange, modelData) => {
  isDebug && console.log("processInput START");
  let { inputEdge: iEdge, inputNode: iNode, changeIn } = itemChange;
  let changeOut1 = 0;
  let changeOut2 = 0;
  let changeOut3 = 0;
  currentInode = iNode;
  isDebug &&
    console.log(
      "processInput inputEdge, inputNode, changeIn",
      iEdge,
      iNode,
      changeIn
    );
  // detect the invert flag on the input and invert the signal carryIn
  if ((iEdge & EdgeType_INVERT) == EdgeType_INVERT) {
    changeIn = -changeIn;
  }

  // strip the INVERT flag from the Edge before processing the transit
  iEdge = iEdge & 0x7f;

  //Search the node in KB with the node which is came in input  -- we can apply binary search here to optimize it
  const nodeIndex = binarySearch(modelData.Nodes, iNode, "id");
  let nodeName = ""
  let node = modelData.Nodes[nodeIndex];
  isDebug && console.log("processInput node", node);
  if (!node) {
    return modelData;
  }

  let pGate;
  let pVala;
  let pValb;

  switch (iEdge) {
    case EdgeType_ENCODE:
      // Transform Change-in into Change-out. Input type Quantity
      changeOut1 = changeIn - node.a;
      node.a = node.a + changeOut1;
      break;
    case EdgeType_SUM_a:
      // Internal Type Value
      changeOut1 = changeIn;
      node.a = node.a + changeOut1;
      break;
    case EdgeType_GTE_0:
      // Internal Type Sign Gate
      pGate = node.g;
      node.g = node.g + changeIn;
      changeOut1 = pGate >= 0 ? (node.g >= 0 ? 0 : -1) : node.g >= 0 ? 1 : 0;
      break;
    case EdgeType_EQU_0:
      // Internal Type Zero Gate
      pGate = node.g;
      node.g = node.g + changeIn;
      changeOut1 = pGate == 0 ? (node.g == 0 ? 0 : -1) : node.g == 0 ? 1 : 0;
      break;
    case EdgeType_LTE_0:
      // Internal Type Sign Gate
      pGate = node.g;
      node.g = node.g + changeIn;
      changeOut1 = pGate <= 0 ? (node.g <= 0 ? 0 : -1) : node.g <= 0 ? 1 : 0;
      break;
    case EdgeType_MUL_a:
      // Multiply A
      changeOut1 = node.b * changeIn;
      node.a = node.a + changeIn;
      break;
    case EdgeType_MUL_b:
      // Multiply B
      changeOut1 = node.a * changeIn;
      node.b = node.b + changeIn;
      break;
    case EdgeType_SEL_1:
      // Internal Type Value Gate
      pGate = node.g;
      node.g = node.g + changeIn;
      changeOut1 =
        pGate >= 0 ? (node.g >= 0 ? 0 : -node.a) : node.g >= 0 ? node.a : 0;
      break;
    case EdgeType_SEL_a:
      // Internal Type Gated Value
      changeOut1 = node.g >= 0 ? changeIn : 0;
      node.a = node.a + changeIn;
      break;
    case EdgeType_MUX_1:
      // Multiplexer Select
      pGate = node.g;
      node.g = node.g + changeIn;
      changeOut1 =
        pGate >= 0
          ? node.g >= 0
            ? 0
            : node.b - node.a
          : node.g >= 0
          ? node.a - node.b
          : 0;
      break;
    case EdgeType_DECODE:
      // UI Output a value
      node.a = node.a + changeIn;
      changeOut1 = node.a;
      break;
    default:
      isDebug && console.log("unrecognized Edge Type: %v", iEdge);
  }
  for (let idx = 0; idx <= node.edges.length - 1; idx++) {
    let nodeEdge = node.edges[idx];
    // For each Hyper Edge in the list of Hyper Edges
    let change = 0;
    let outputRegister = nodeEdge.type & 0xc000; // Decode output register

    switch (
      outputRegister // Select Output Register
    ) {
      case ChangeOut_CH1:
        change = changeOut1; // Select Change Out 1
        break;
      case ChangeOut_CH2:
        change = changeOut2; // Select Change Out 2
        break;
      case ChangeOut_CH3:
        change = changeOut3; // Select Change Out 3
        break;
      default:
        isDebug &&
          console.log("Unrecognized outputRegister: %v", outputRegister);
    }

    let enableOutput = false;
    let outputGate = nodeEdge.type & 0x3000; // Decode output Gate
    switch (
      outputGate // Select Output Gate
    ) {
      case OutputGate_NOT_0:
        enableOutput = change != 0; // Select Output Gate Change Not = 0
        break;
      case OutputGate_OPEN:
        enableOutput = true; // Select Output Gate Any Change
        break;
      default:
        isDebug && console.log("Unrecognized outputGate: %v", outputGate);
    }
    nodeName = node?.name
    if (!enableOutput) {
      isDebug && console.log("!ENABLE OUTPUT", enableOutput, !enableOutput);
      continue;
    }

    let path = nodeEdge.type & 0xf00; // Decode the Path Encoding
    let edgeType = nodeEdge.type & 0xff; // Decode the Edge
    let iNodes = nodeEdge.node; // Set List of iNodes
    let subPayLoad = {
      inEdgeType: edgeType,
      inNodes: iNodes,
      inChange: change,
      nodeName: nodeName
    };
    switch (
      path // hyper Edge Node map encoding
    ) {
      case NodeListEncoding_LUT:
        modelData = pathLUT(subPayLoad, modelData); // Signal propagation Lookup Table
        break;
      case NodeListEncoding_CML:
        isDebug && console.log("CML");
        pathCML(subPayLoad, modelData); // Signal propagation Consecutive Location
        break;
      case NodeListEncoding_CMP:
        isDebug && console.log("CMP");
        modelData = pathCMP(subPayLoad, modelData); // Signal propagation Pattern Location
        break;
      case NodeListEncoding_ABC:
        isDebug && console.log("ABC");
        pathABC(subPayLoad, modelData); // Hypergraph dynamic connection
        break;
      case NodeListEncoding_ABB:
        isDebug && console.log("ABB");
        pathABB(subPayLoad, modelData); // Hypergraph dynamic connection (Bus)
        break;
      default:
        isDebug && console.log("unrecognized AB_Map Type: %v", path);
    }
  }
  isDebug && console.log("processInput END");
  return modelData;
};

//src/model/neural-net.go
const pathLUT = (subPayLoad, modelData) => {
  isDebug && console.log("pathLUT START");
  const edge = subPayLoad.inEdgeType;
  const iNodeList = subPayLoad.inNodes;
  const change = subPayLoad.inChange;
  const nodeName = subPayLoad?.nodeName;

  for (let i = 0; i < iNodeList.length; i++) {
    const iNode = iNodeList[i];
    const lutPayLoad = {
      iEdge: edge,
      iNode: iNode,
      iChange: change,
      nodeName: nodeName
    };
    modelData = propagateChange(lutPayLoad, modelData);
  }
  isDebug && console.log("pathLUT END");
  return modelData;
};
//src/model/neural-net.go
const pathCML = (subPayLoad, modelData) => {
  const edge = subPayLoad.inEdgeType;
  const iNodeList = subPayLoad.inNodes;
  const change = subPayLoad.inChange;
  const nodeName = subPayLoad?.nodeName;

  const iNode = iNodeList[0];
  const iCount = iNodeList[1];
  const cmlPayLoad = {
    iEdge: edge,
    iNode: iNode,
    iChange: change,
    nodeName: nodeName
  };

  const iStep = 1;
  for (let i = 1; i <= iCount; i++) {
    propagateChange(cmlPayLoad, modelData);
    cmlPayLoad.iNode += iStep;
  }
};
//src/model/neural-net.go
const pathCMP = (subPayLoad, modelData) => {
  const edge = subPayLoad.inEdgeType;
  const iNodeList = subPayLoad.inNodes;
  const change = subPayLoad.inChange;
  const nodeName = subPayLoad?.nodeName;

  const iNode = iNodeList[0];
  const iCount = iNodeList[1];
  const iStep = iNodeList[2];
  const cmpPayLoad = {
    iEdge: edge,
    iNode: iNode,
    iChange: change,
    nodeName: nodeName
  };

  for (let i = 1; i <= iCount; i++) {
    modelData = propagateChange(cmpPayLoad, modelData);
    cmpPayLoad.iNode += iStep;
  }
  return modelData;
};
//src/model/neural-net.go
const pathABC = (subPayLoad, modelData) => {
  const iNodeList = subPayLoad.inNodes;

  const lenInodes = iNodeList && iNodeList.length;
  let iNodeFrom;
  let iFunctionTo;
  for (let i = 0; i <= lenInodes - 2; i += 2) {
    iNodeFrom = iNodeList[i];
    iFunctionTo = iNodeList[i + 1];
  }
  isDebug && console.log(iNodeFrom, iFunctionTo);
};
//src/model/neural-net.go
const pathABB = (subPayLoad, modelData) => {
  const iNodeList = subPayLoad.inNodes;
  const lenInodes = iNodeList.length;
  let iNodeFrom;
  let iNodeTo;
  let iNodeCount;
  for (let i = 0; i <= lenInodes - 3; i += 3) {
    iNodeFrom = iNodeList[i];
    iNodeTo = iNodeList[i + 1];
    iNodeCount = iNodeList[i + 2];
    for (let j = 1; i <= iNodeCount; i++) {
      iNodeFrom += 1;
      iNodeTo += 1;
      isDebug && console.log(j); // Not suppose to be done
    }
  }
};
