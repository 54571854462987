import { useLazyQuery, useQuery } from "@apollo/client";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import RequestAssistanceButton from "../../../components/RequestAssistance/RequestAssistanceButton";
import { HPMuiTheme } from "../../../config/theme";
import { ExternalProps } from "../../../contexts/externalPropsContext";
import {
  FETCH_USER_DETAILS,
  GET_RSKU_USER_ACCESS,
  PRODUCT_ACCESS_TYPE
} from "../../../GraphQL";
import CategorySeries from "./CategorySeries";
import { RECEIVE_USER_DETAILS, SET_PROCATEGORY } from "./Constants";
import DefaultConfigSearchFilters from "./DefaultConfigSearchFilters";
import SearchConfigs from "./SearchConfigs";
const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: "bold"
  },
  midPositioner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)"
  },
  spinner: {
    color: "rgb(41, 168, 221)"
  },
  outer: {
    marginBottom: "10%",
    marginLeft: "8%",
    paddingTop: "5%",
    width: "auto !important"
  },
  modelConfig: {
    position: "relative",
    width: "auto",
    height: "68px",
    marginLeft: "0px",
    top: "22px",
    fontWeight: "bold"
  },
  radioLabel: {
    fontSize: "12px"
  },
  box: {
    margin: "20px 0px 0px 0px"
  }
}));

// Inspired by blueprintjs
function StyledRadio(props) {
  return <Radio color="primary" size="small" {...props} />;
}
const ProductSelector = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [prodChangeFlag, setProdChangeFlag] = useState(false);
  const {
    userEmail,
    resellerID,
    userType,
    quoteNumber,
    channelRegion,
    mDCPOrgID,
    companyId,
    isPartnerPortalFlag = false,
    isDisplayProdCountry = false,
    visibility = {},
    setProductCategory,
    isStandaloneAccess,
    configRegion,
    configOrigin,
    routeToMarket
  } = React.useContext(ExternalProps);
  let { countryCode, country } = React.useContext(ExternalProps);
  countryCode = countryCode ? countryCode : country;
  let { productCategory } = React.useContext(ExternalProps);
  const { showCustomBandedFlag = false } = visibility;
  const prdCountry = window.sessionStorage.getItem("prdCountry" + userEmail);
  const fetchCountryOCA = window.sessionStorage.getItem(
    "countryFetchFromUserOCA" + userEmail
  );
  const { productRegionCode, productCountryCode } = useSelector(
    state => state.rskuReducer
  );
  const countryCodeFromFetchUserDetails = useSelector(
    state => state.productSelector?.userInfo?.userDetails?.countryCode
  );
  const initialCountryCode = isDisplayProdCountry
    ? window.sessionStorage.getItem("prdCountry" + userEmail)
    : countryCode;
  const [noShowModel, setNoShowModel] = useState(false);
  //define a local state which will store the product Access type
  let [productAccessType, setProductAccessType] = useState(null);

  //Defining states for loading and error
  const [error, setError] = useState({ isError: false, message: "" });
  const [isLoading, setIsLoading] = useState(true);

  //Defining state to set the productCategory based on the access type.
  const [proCategory, setProCategory] = useState(null);

  const [rSkuUserInfo, setRskuUserInfo] = useState({});
  useEffect(() => {
    if (!isEmpty(rSkuUserInfo)) {
      dispatch({
        type: "SET_RSKU_USER_ACCESS",
        payload: { ...rSkuUserInfo, productRegionCode, productCountryCode }
      });
    }
  }, [rSkuUserInfo, productRegionCode, productCountryCode]);

  //useEffect to fetch the data from API

  useQuery(PRODUCT_ACCESS_TYPE, {
    fetchPolicy: "no-cache",
    variables: {
      filter: {
        userType,
        companyId
      }
    },
    onCompleted(response) {
      setIsLoading(false);
      if (response) {
        setProductAccessType(response);
      }
    },
    onError({ queryError, networkError }) {
      setIsLoading(false);
      if (queryError && queryError instanceof Array) {
        setError({ isError: true, message: queryError.join("/n") });
      }
    }
  });
  const [getUserDetails] = useLazyQuery(FETCH_USER_DETAILS, {
    fetchPolicy: "no-cache",
    onCompleted({ fetchUserDetails }) {
      const loggedIdUserDetails = Array.isArray(fetchUserDetails)
        ? fetchUserDetails[0]
        : fetchUserDetails;
      // TODO: default region to NA ?
      if (!loggedIdUserDetails["region"]) loggedIdUserDetails["region"] = "NA";
      dispatch({ type: RECEIVE_USER_DETAILS, payload: loggedIdUserDetails });
      if (isDisplayProdCountry && isStandaloneAccess) {
        window.sessionStorage.setItem(
          "countryFetchFromUserOCA" + userEmail,
          fetchUserDetails[0].countryCode
        );
      }
    }
  });
  useQuery(GET_RSKU_USER_ACCESS, {
    fetchPolicy: "no-cache",
    variables: {
      userEmail
    },
    onCompleted({ getUserCountryMaps }) {
      if (getUserCountryMaps) {
        const payload = {
          getUserCountryMaps,
          routeToMarket
        };
        setRskuUserInfo(payload);
      }
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (localStorage.getItem("isDebug") === "true") {
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
          }
        });
      }
      if (networkError) {
        if (localStorage.getItem("isDebug") === "true") {
          console.log(networkError);
        }
      }
    }
  });

  useEffect(() => {
    if (!prdCountry && fetchCountryOCA === null && isDisplayProdCountry) {
      getUserDetails();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, []);
  //update the props productCategory as soon as data gets loaded
  useEffect(() => {
    //if prodChangeFlag is true, ignore initializations.
    if (prodChangeFlag === true) {
      setProdChangeFlag(false);
      return;
    }
    //case 1, if access is all, compute or null we will set pro category to compute
    if (
      (productAccessType && productAccessType.getProductAccessType === "all") ||
      (productAccessType &&
        productAccessType.getProductAccessType === "compute") ||
      !productAccessType
    ) {
      setProCategory("compute");
      setProductCategory("compute");
    }
    //case 2 if access is only print then we set proCategory as print
    if (
      productAccessType &&
      productAccessType.getProductAccessType === "print"
    ) {
      setProCategory("print");
      setProductCategory("print");
    }
  }, [productAccessType]);

  useEffect(() => {
    if (proCategory) {
      dispatch({
        type: "SET_INITIAL_VALUES",
        payload: {
          showCustomBandedFlag,
          isPartnerPortalFlag,
          initialCountryCode,
          userEmail,
          countryCode,
          resellerID,
          quoteNumber,
          channelRegion,
          mDCPOrgID,
          productCategory: proCategory,
          category: proCategory,
          countryCodeFromFetchUserDetails: fetchCountryOCA
            ? isDisplayProdCountry && isStandaloneAccess && fetchCountryOCA
            : isDisplayProdCountry &&
              isStandaloneAccess &&
              countryCodeFromFetchUserDetails
        }
      });
      setIsLoading(false);
    }
  }, [
    initialCountryCode,
    showCustomBandedFlag,
    isPartnerPortalFlag,
    userEmail,
    productCategory,
    countryCode,
    resellerID,
    quoteNumber,
    channelRegion,
    mDCPOrgID,
    dispatch,
    proCategory,
    countryCodeFromFetchUserDetails
  ]);

  const [showDefaultConfig, setShowDefaultConfig] = useState(false);
  const [baseUnitValue, setBaseUnitValue] = useState("");

  //Function which handles the radio button changes to fetch the new productCategory
  const handleProductCategoryChange = e => {
    setProCategory(e.target.value);
    setProdChangeFlag(true);
    setProductCategory(e.target.value);
    setBaseUnitValue("");
    dispatch({
      type: SET_PROCATEGORY,
      payload: e.target.value
    });
  };
  return (
    <>
      {isLoading ? (
        <div className={classes.midPositioner}>
          <CircularProgress className={classes.spinner} />
        </div>
      ) : error.isError ? (
        <MuiThemeProvider theme={HPMuiTheme}>
          <div className={classes.midPositioner}>{error.message}</div>
        </MuiThemeProvider>
      ) : (
        <React.Fragment>
          <Container className={classes.outer}>
            {configOrigin !== "OCA" && (
              <Box className={classes.modelConfig}>
                <Typography variant="h5" align="left" className={classes.bold}>
                  {t("common:productSelector.textMessage.modelConfiguration")}
                </Typography>
              </Box>
            )}
            {showCustomBandedFlag && configOrigin !== "OCA" && (
              <SearchConfigs
                setShowDefaultConfig={setShowDefaultConfig}
                proCategory={proCategory}
              />
            )}
            {configOrigin !== "OCA" && (
              <Box className={classes.box}>
                {productAccessType?.getProductAccessType !== "print" &&
                (isStandaloneAccess ||
                  channelRegion === "NA" ||
                  configRegion === "NA") ? (
                  <RadioGroup
                    onChange={handleProductCategoryChange}
                    row={true}
                    value={proCategory ? proCategory : ""}
                  >
                    <FormControlLabel
                      classes={{ label: classes.radioLabel }}
                      value="compute"
                      control={<StyledRadio />}
                      label="PC"
                    />
                    <FormControlLabel
                      classes={{ label: classes.radioLabel }}
                      value="print"
                      control={<StyledRadio />}
                      label="Printer"
                    />
                  </RadioGroup>
                ) : (
                  ""
                )}
              </Box>
            )}
            <DefaultConfigSearchFilters
              setBaseUnitValue={setBaseUnitValue}
              baseUnitValue={baseUnitValue}
              setShowDefaultConfig={setShowDefaultConfig}
              setNoShowModel={setNoShowModel}
              proCategory={proCategory}
            />

            <CategorySeries
              setBaseUnitValue={setBaseUnitValue}
              setShowDefaultConfig={setShowDefaultConfig}
              showDefaultConfig={showDefaultConfig}
              proCategory={proCategory}
              noShowModel={noShowModel}
            />
            {configOrigin !== "OCA" && !isPartnerPortalFlag && (
              <RequestAssistanceButton />
            )}
          </Container>
        </React.Fragment>
      )}
    </>
  );
};

export default ProductSelector;
