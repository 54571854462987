import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
  withStyles
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UPDATE_TAB_EXPAND,
  WEB_ASSEMBLY_UPDATE_MODEL
} from "../../actions/Types";
import { updateLocalization } from "../../oneConfigNative/updateLocalization";
import { getAllDataExpandedOrCollapsedManul } from "../../services/utils";

const BlueRadio = withStyles({
  root: {
    color: "#00000054;",
    paddingBottom: "1px",
    paddingTop: "1px",

    "&$checked": {
      color: "#29A8DD"
    },
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  checked: {}
})(props => <Radio color="default" {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginBottom: "8px",
    padding: "0px 0px",
    background: "#FFFFFF",
    boxShadow: "0px 5px 12px rgb(0 0 0 / 25%) !important",
    borderRadius: "4px !important",
    fontSize: "12px !important"
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "50%",
    flexShrink: 0,
    color: "#000000",
    fontWeight: "400",
    padding: "8px 17px 18px 0px"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(16),
    color: "#000000",
    fontWeight: "400",
    padding: "8px 0px 0px 18px"
  },
  expanded: {
    "&$expanded": {
      margin: "1px 0"
    }
  },
  fontSize: {
    fontSize: "12px !important"
  },
  radioLabel: {
    fontSize: 12
  },
  selectedItemStyle: {
    fontSize: 12,
    fontWeight: "bold"
  }
}));

function Localization({ name, options, chaid }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedRadioRef = useRef();
  const expandAll = useSelector(state => state.configFilterData.expandAll);
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const autoExpandChoice = useSelector(
    state => state.configFilterData.autoExpandChoice
  );
  const modelData = useSelector(state => state.configFilterData.modelData);
  const [expanded, setExpanded] = useState(true);
  const [value, setValue] = useState("");
  const [locName, setLocName] = useState(name);
  const [buttonClicked, setButtonClicked] = useState(false);
  const isGoEngine = localStorage.getItem("KB_ENGINE") === "GO";
  const handleChange = e => {
    e.preventDefault();
    setExpanded(!expanded);
    setButtonClicked(true);
  };

  useEffect(() => {
    if (autoExpandChoice?.length > 0) {
      autoExpandChoice.map(choiceId => {
        if (choiceId === chaid) {
          setExpanded(true);
        }
      });
    } else {
      retainChoiceState();
    }
  }, [autoExpandChoice]);

  const retainChoiceState = () => {
    if (expandAll.length && defaultConfigStepSlected) {
      const relevantTabData = expandAll.find(
        expandObject => expandObject.tab === defaultConfigStepSlected
      );
      if (relevantTabData && relevantTabData.choicesExpandState) {
        setExpanded(relevantTabData.choicesExpandState[chaid]);
      }
    }
  };

  useEffect(() => {
    options &&
      options.forEach(option => {
        if (option.selected && option.visible) {
          setValue(option.locOption);
          return;
        }
      });
  }, [options]);

  useEffect(() => {
    retainChoiceState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandAll, defaultConfigStepSlected]);

  useEffect(() => {
    if (buttonClicked) {
      const relevantExpandData = expandAll.find(
        expandedData => expandedData.tab === defaultConfigStepSlected
      );
      if (relevantExpandData) {
        relevantExpandData.choicesExpandState[chaid] = expanded;
      }
      const expand = getAllDataExpandedOrCollapsedManul(
        relevantExpandData.choicesExpandState
      )[0]
        ? true
        : getAllDataExpandedOrCollapsedManul(
            relevantExpandData.choicesExpandState
          )[1]
        ? false
        : relevantExpandData.expand;
      const payload = {
        tab: defaultConfigStepSlected,
        expand,
        choicesExpandState: relevantExpandData.choicesExpandState,
        flag: true
      };
      dispatch({
        type: UPDATE_TAB_EXPAND,
        payload
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, buttonClicked]);

  const handleRadioChange = event => {
    console.time("radioButtonWaitingTime");
    setValue("");
    selectedRadioRef.current = event.target.closest("label");
    selectedRadioRef.current.style.opacity = 0.4;
    setLocName(locName);
    setValue(event.target.value);
    setTimeout(
      () => {
        if (locName === "System Localization Options") {
          const { value } = event.target;
          const payload = { SYSLOC: value };
          parseModelObj(
            isGoEngine && !window._DISABLE_WASM
              ? window.updateLocalization &&
                  window.updateLocalization(JSON.stringify(payload))
              : updateLocalization(payload, modelData)
          );
        } else if (locName === "OS Localization Options") {
          const { value } = event.target;
          const payload = { OSLOC: value };
          parseModelObj(
            isGoEngine && !window._DISABLE_WASM
              ? window.updateLocalization &&
                  window.updateLocalization(JSON.stringify(payload))
              : updateLocalization(payload, modelData)
          );
        } else if (locName === "Keyboard Localization Options") {
          const { value } = event.target;
          const payload = { KYBD: value };
          parseModelObj(
            isGoEngine && !window._DISABLE_WASM
              ? window.updateLocalization &&
                  window.updateLocalization(JSON.stringify(payload))
              : updateLocalization(payload, modelData)
          );
        } else if (locName === "Monitor Localization Options") {
          const { value } = event.target;
          const payload = { MONLOC: value };
          parseModelObj(
            isGoEngine && !window._DISABLE_WASM
              ? window.updateLocalization &&
                  window.updateLocalization(JSON.stringify(payload))
              : updateLocalization(payload, modelData)
          );
        }
      },
      isGoEngine ? 200 : 5
    );
  };

  function parseModelObj(isSuccess) {
    (localStorage.getItem("isDebug") === "true" ||
      localStorage.getItem("showTime") === "true") &&
      console.log("isSuccess localization update", isSuccess);
    if (isSuccess || window._DISABLE_WASM) {
      let modelJson = isGoEngine ? window._modelJson : isSuccess;
      if (window._DEBUG) {
        printResult("window.modelJsonlocas js object has updated!");
        printResult(modelJson);
      }
      dispatch({
        type: WEB_ASSEMBLY_UPDATE_MODEL,
        modelData: modelJson
      });
      handleEvent(modelJson);
      if (
        selectedRadioRef &&
        selectedRadioRef.current &&
        selectedRadioRef.current.style
      ) {
        selectedRadioRef.current.style.opacity = 1;
      }
      console.timeEnd("radioButtonWaitingTime");
    }
  }

  const handleEvent = modelJson => {
    if (document.querySelector("#product-selector")) {
      document
        .querySelector("#product-selector")
        .dispatchEvent(new CustomEvent("changeItem", { detail: modelJson }));
    } else if (document.querySelector("#configurator")) {
      document
        .querySelector("#configurator")
        .dispatchEvent(new CustomEvent("changeItem", { detail: modelJson }));
    }
  };

  async function printResult(result) {
    if (localStorage.getItem("isDebug") === "true") {
      console.log("webassembly response", result);
    }
  }
  return (
    <>
      <div className={classes.root} id={name}>
        <Accordion expanded={expanded} onChange={handleChange}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="itemPanelbh-content"
            id="itemPanelbh-header"
          >
            <Box display="flex" flexGrow="1">
              <Typography
                className={classes.heading}
                component={"span"}
                variant={"body2"}
              >
                {name}
              </Typography>
              <Typography
                className={classes.secondaryHeading}
                component={"span"}
                variant={"body2"}
              >
                {value}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ marginTop: "-20px" }}>
            <FormControl id={name} component="fieldset">
              <RadioGroup value={value} onChange={handleRadioChange}>
                {options &&
                  options
                    .filter(option => option.visible)
                    .map(option => (
                      <FormControlLabel
                        key={option.locOption}
                        value={option.locOption}
                        control={<BlueRadio disableRipple size="small" />}
                        classes={{
                          label: option.selected
                            ? classes.selectedItemStyle
                            : classes.radioLabel
                        }}
                        label={`${option.locOption}-${option.locDescrition}`}
                        style={{
                          border: "0px red solid",
                          fontSize: "12px !important"
                        }}
                      />
                    ))}
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}
export default Localization;
