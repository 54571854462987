import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  }
}));

export default function TableSkeleton(props) {
  const classes = useStyles();
  const { lineNumber, cells, spacing, height } = props;
  return (
    <List dense className={classes.root}>
      {Array(lineNumber)
        .fill("")
        .map((item ,index) => (
          <ListItem key={index}>
            <Grid container alignItems="center" wrap="wrap" spacing={spacing}>
              {cells.map((cell, index) => (
                <Grid item xs={parseInt(cell.width)} key={index}>
                  <Skeleton variant="rect" width="100%" height={height} />
                </Grid>
              ))}
            </Grid>
          </ListItem>
        ))}
    </List>
  );
}
