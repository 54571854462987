import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { useSelector } from "react-redux";
import ClipboardField from "../../../lib/common/ClipboardField";
import ImageSwiper from "../../ImageSwiper";
import { HP_LOGO } from "./Constants";

const useStyles = makeStyles(theme => {
  return createStyles({
    content: {
      padding: theme.spacing(0),
      overflowX: "hidden"
    },
    title: {
      padding: theme.spacing(1, 2)
    },
    details: {
      padding: theme.spacing(3)
    }
  });
});

const ProductDescription = props => {
  const { handleClose, sku, name, image } = props;
  const classes = useStyles(); 

  return (
    <Dialog open={true} onClose={handleClose} fullScreen>
      <DialogTitle className={classes.title} disableTypography>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={5}></Grid>
          <Grid item xs={4} zeroMinWidth>
            <Typography variant="h6" noWrap component="div" align="start">
              Detail Description
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Box textAlign="right">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <div className={classes.details}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5} zeroMinWidth>
              <ImageSwiper images={image} />
            </Grid>
            <Grid item xs={12} md={7} zeroMinWidth>
              <Typography variant="h6">{name}</Typography>
              <Typography
                variant={"body2"}
                component={"span"}
                color="textSecondary"
                noWrap
              >
                {sku}
                <ClipboardField text={sku} />
              </Typography>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ProductDescription;
