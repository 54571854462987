import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import { useSnackbar } from "notistack";
import Dropzone from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { ExternalProps } from "../../contexts/externalPropsContext";
import {
  CREATE_CASE_ATTACHES,
  CREATE_CASE_REQUEST_ASSISTANCE
} from "../../GraphQL";
import displayNotistack from "../../lib/common/SnackBarUtils";
import AddNotesAttachmentsList from "../../models/opportunity/AddNotesAttachmentsList";
// import CpqButton from "migrated/lib/common/CpqButton";
// import {
//   CREATE_CASE_ATTACHES,
//   CREATE_CASE_REQUEST_ASSISTANCE
// } from "model/Dashboard/GraphQL";
import moment from "moment";
import React, { useCallback, useState } from "react";
// import {
const MAX_NUM_OF_ATTACHMENTS = 5;
const MAX_ATTACHMENT_SIZE = 15700000;
const useStyles = makeStyles(theme => ({
  rootIcon: {
    height: 150,
    width: 150
  },
  dragNdropStyle: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 10,
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: theme.palette.grey[400]
  },
  textColor: {
    color: "#0096d6"
  },
  asterisk: {
    color: "#FF7272"
  },
  disableText: {
    color: theme.palette.text.disabled
  },
  checkCircleOutlineRoundedIcon: {
    width: "auto",
    height: "150px"
  },
  dialogContnet: {
    textAlign: "center"
  },
  typography: {
    fontWeight: "700"
  }
}));

export default function RequestAssistanceDialog(props) {
  const { setOpen } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  // // const requestAssistance = useSelector(
  // //   (state) => state.opportunity.requestAssistanceIssue
  // // );
  // const records = useSelector(state => state.quoteFields.fields);
  const [description, setDescription] = useState("");
  const [toggle, setToggle] = useState(false);
  const [value, setValue] = useState("Request Assistance - Configurations");
  const [caseNumber, setCaseNumber] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const snackbar = { enqueueSnackbar, closeSnackbar };
  // const partnerUserRecord = useSelector(
  //   state => state.userReducer.partnerUserRecord
  // );
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userEmail } = React.useContext(ExternalProps);
  // const customerName =
  //   props.quotePage && records?.customerName
  //     ? `${records?.customerName}-`
  //     : props.customerName
  //     ? `${props?.customerName}-`
  //     : "";
  // const hpQuoteNumber =
  //   props.quotePage && records?.hpQuoteNumber
  //     ? `-${records?.hpQuoteNumber}`
  //     : "";
  // const hpOpportunityID =
  //   props.quotePage && records?.opportunityData?.hpOpportunityIDImported
  //     ? `-${records?.opportunityData?.hpOpportunityIDImported}`
  //     : props.hpOpportunityID
  //     ? `-${props?.hpOpportunityID}`
  //     : "";

  // const subject = `${customerName}New-${value}${hpQuoteNumber}${hpOpportunityID}`;

  const showWarning = () => {
    displayNotistack(
      snackbar,
      "You have hit the max attached limit​.",
      "warning",
      6000
    );
  };

  const [createCaseAttaches] = useMutation(CREATE_CASE_ATTACHES, {
    onCompleted({ createCaseAttaches }) {
      setLoading(false);
      let errorAttaches = [];
      createCaseAttaches.forEach(element => {
        if (element.errorMessage) {
          errorAttaches.push(element.fileName);
        }
      });
      if (errorAttaches?.length > 0) {
        enqueueSnackbar(
          t("common:opportunity.errorAddingAttachments") +
            errorAttaches.join(", "),
          {
            variant: "error",
            persist: false
          }
        );
      }
      setToggle(true);
    },
    onError() {
      setLoading(false);
    }
  });

  const [createCaseRequestAssistance] = useMutation(
    CREATE_CASE_REQUEST_ASSISTANCE,
    {
      onCompleted({ createCaseRequestAssistance }) {
        const { caseNumber, errorMessage, caseID } =
          createCaseRequestAssistance;
        if (!errorMessage) {
          setCaseNumber(caseNumber);
          if (attachments?.length > 0) {
            createCaseAttaches({
              variables: {
                input: {
                  caseID: caseID,
                  createAttaches: attachments.map(each => {
                    return {
                      documentBody: each.documentBody,
                      mimeType: each.mimeType,
                      subject: "subject",
                      fileName: each.fileName
                    };
                  })
                }
              }
            });
          } else {
            setLoading(false);
            setToggle(true);
          }
        } else {
          const errorMsg = {
            snackBarMsg: t("common:productSelector.errorMessage.graphQLErrors"),
            graphQLMsg: errorMessage
          };
          displayNotistack.error(false, errorMsg, "error", 6000);
          // returnErrorMessage(snackbar, errorMsg, true);
          setLoading(false);
        }
      },
      onError() {
        setLoading(false);
      }
    }
  );
  const handleSave = () => {
    setLoading(true);
    createCaseRequestAssistance({
      variables: {
        input: {
          caseReason: value,
          description
          // subject: subject
        }
      }
    });
  };
  const handleDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      let existingAttachments = attachments;
      let fileExceedsSize = [];
      let invalidFiles = [];
      let isLimitExceeds = false;
      if (rejectedFiles) {
        rejectedFiles.forEach(each => {
          if (each?.errors[0].code === "file-invalid-type") {
            invalidFiles.push(each?.file.name);
          } else if (each?.file.size > MAX_ATTACHMENT_SIZE) {
            fileExceedsSize.push(each?.file.name);
          }
        });
      }
      if (fileExceedsSize.length > 0) {
        enqueueSnackbar(
          fileExceedsSize.join(", ") +
            " " +
            "exceed file limit. Please re-upload your file with size lower than 15 MB.",
          {
            variant: "error",
            persist: false
          }
        );
      }
      if (invalidFiles.length > 0) {
        displayNotistack(
          snackbar,
          "Add attachments failed. Please re-upload using the correct format.",
          "error",
          6000
        );
      }
      acceptedFiles.forEach((file, index) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          if (!isLimitExceeds) {
            let encoded = reader?.result
              ?.toString()
              .replace(/^data:(.*,)?/, "");
            if (encoded?.length % 4 > 0) {
              encoded += "=".repeat(4 - (encoded?.length % 4));
            }
            const newAttachment = {
              fileName: file.name,
              createdAt: moment(Date.now()).format("DD/MM/YYYY[ ]HH:mm:ss"),
              userName: userEmail,
              // userName: `${partnerUserRecord?.user?.firstName} ${partnerUserRecord?.user?.lastName}`,
              newRecord: true,
              uploadedTime: new Date(),
              mimeType: file.name?.substr(file.name.lastIndexOf(".") + 1) || "",
              documentBody: encoded || "",
              file: reader?.result
            };

            existingAttachments = [...existingAttachments, newAttachment];
            let newlength = existingAttachments.filter(
              each => each.newRecord
            ).length;
            if (newlength === MAX_NUM_OF_ATTACHMENTS) {
              setAttachments(existingAttachments);
              showWarning();
            } else if (newlength > MAX_NUM_OF_ATTACHMENTS) {
              displayNotistack(
                snackbar,
                "Add attachments succeeded only for 5 files which is the system limit.​",
                "info",
                6000
              );
              isLimitExceeds = true;
            } else {
              setAttachments(existingAttachments);
            }
          }
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attachments]
  );

  const handleChange = e => {
    let list = e.target.value;
    setValue(list);
  };
  const issueReasons = [
    {
      value: "Request Assistance - Opportunity",
      displayValue: "Opportunity Process"
    },
    {
      value: "Request Assistance - Quote",
      displayValue: "Quote Process"
    },
    {
      value: "Request Assistance - Configurations",
      displayValue: "Configuration"
    }
  ];

  return (
    <>
      <Dialog
        maxWidth="md"
        open={!toggle}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>
          <Typography variant="h6">
            <b>REQUEST ASSISTANCE</b>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography variant="subtitle1">
                <b>Where have this issue? </b>
                <span className={classes.asterisk}>
                  * Please note:No special characters are allowed in the
                  description.
                </span>
              </Typography>
              <Paper>
                <Select
                  margin="dense"
                  variant="outlined"
                  size="small"
                  value={value}
                  onChange={handleChange}
                  fullWidth
                >
                  {issueReasons.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.displayValue}
                    </MenuItem>
                  ))}
                </Select>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <b>Description</b>
                <span className={classes.asterisk}>*</span>
              </Typography>
              <Paper>
                <TextField
                  placeholder="Please provide the detail information(e.g: number, filds, errors information, quote ID, opportunity ID, etc)"
                  id="outlined-multiline-static"
                  multiline
                  variant="outlined"
                  fullWidth={true}
                  minRows={5}
                  onChange={e => setDescription(e.target.value)}
                  InputProps={{
                    inputProps: {
                      maxLength: 500
                    }
                  }}
                  required
                />
              </Paper>
            </Grid>
            {attachments?.filter(each => each.newRecord).length <
              MAX_NUM_OF_ATTACHMENTS && (
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  <b>Screenshot/Attachments</b>
                </Typography>
                <Typography className={classes.disableText}>
                  Please input the screenshot of your issue in below fileds.
                </Typography>

                <Grid item>
                  <Box
                    p={1}
                    textAlign="center"
                    className={classes.dragNdropStyle}
                  >
                    <Dropzone
                      onDrop={handleDrop}
                      multiple
                      accept={
                        ".jpg, .png, .jpeg, .doc, .docx, .xls, .xlsx, .csv"
                      }
                      maxSize={MAX_ATTACHMENT_SIZE}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Grid container direction="column" {...getRootProps()}>
                          <Typography
                            variant="body1"
                            style={{ marginTop: "20px" }}
                          >
                            Drop your files gere
                          </Typography>
                          <Typography variant="body1">
                            or
                            <Typography className={classes.textColor}>
                              Browse Folder
                            </Typography>
                            <Typography>
                              Maximum 5 files per upload and 15MB/file
                              <br />
                              Supported file types are .jpg, .png, .jpeg, .doc,
                              .docx, .xls, .csv
                            </Typography>
                          </Typography>
                          <input
                            {...getInputProps()}
                            style={{ visibility: "hidden", width: 0 }}
                          />
                        </Grid>
                      )}
                    </Dropzone>
                  </Box>
                </Grid>
              </Grid>
            )}
            {attachments?.length > 0 && (
              <Grid item xs={12}>
                <AddNotesAttachmentsList
                  attachments={attachments}
                  setAttachments={setAttachments}
                  requestAssist={true}
                />
              </Grid>
            )}
            <Grid item container justifyContent="flex-end">
              <Button
                id="createCase"
                variant="contained"
                color="primary"
                onClick={handleSave}
                // disabled={!description?.length || loading}
                loading={loading}
              >
                save
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {toggle && (
        <Dialog open={toggle}>
          <DialogTitle>
            <Typography variant="h6">REQUEST ASSISTANCE</Typography>
          </DialogTitle>
          <CheckCircleOutlineRoundedIcon
            color="primary"
            className={classes.checkCircleOutlineRoundedIcon}
          />
          <DialogContent className={classes.dialogContnet}>
            <Typography className={classes.typography}>
              <Trans
                i18nKey="Assistance Ticket"
                defaults={""}
                values={{ caseNumber: caseNumber }}
              />
            </Typography>
            <Typography>
              System will trigger notification to your email for fuither
              updates.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setToggle(false);
                setOpen(false);
              }}
              color="primary"
            >
              CLOSE
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
