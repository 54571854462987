export const HARDWARE = "HARDWARE";
export const OTHER_COMPONENTS = "OTHER COMPONENTS";
export const AMS = "AMS";
export const NA = "NA";
export const BOM = "BOM";
export const HP_LOGO =
  "https://d1wtk2ls73gxia.cloudfront.net/assets/images/Hplogo.png";
export const CLONING_SKU = {
  NA: "UA",
  AMS: "UA",
  EU: "EA",
  EMEA: "EA",
  AP: "PA",
  APJ: "PA"
};

export const RSKU_REGIONS = {
  EMEA: "EU",
  APJ: "AP",
  AMS: "NA",
  EU: "EU",
  AP: "AP",
  NA: "NA"
};

export const SKIP_USER_VALIDATION_REGIONS = ["EMEA", "EU"];

export const CONFIGURATION_SERVICES = ["configurationService"];
export const ALL_OTHER_COMPONENTS = [
  "configurationService",
  "monitoringAccesories",
  "carePacks",
  "deploymentServices",
  "manageabilityService"
];
export const EXPORT_RSKU = "EXPORT RSKU";
