import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { togglePopupActions } from "../../../actions/togglePopupActions";

const useStyles = makeStyles(theme => ({
  title: {
    margin: 0,
    textAlign: "center"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  text: {
    color: "#000000",
    fontSize: 20
  },
  dailogContent: {
    marginBottom: 60
  },
  alertText: {
    fontSize: 18
  }
}));
const ConfirmationDialog = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { rskuDailogAlert } = useSelector(state => state.rskuReducer);

  const handleConfirmationDialogClose = React.useCallback(() => {
    dispatch(togglePopupActions({ name: "confirmationDialog", open: false }));
    dispatch({ type: "SET_RSKU_DAILOG_ALERT", payload: false });
  }, []);

  const handleRequestSkuDialogOpen = React.useCallback((params = {}) => {
    dispatch(
      togglePopupActions({
        name: "requestSkuDialog",
        open: !rskuDailogAlert
      })
    );
    handleConfirmationDialogClose();
  }, []);
  return (
    <>
      <Dialog open={true} onClose={handleConfirmationDialogClose} fullWidth>
        <DialogTitle disableTypography className={classes.title}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            {t("common:rsku.dialog.confirmationTitle")}
          </Typography>
          <IconButton
            onClick={handleConfirmationDialogClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.dailogContent}>
          {rskuDailogAlert ? (
            <Typography className={classes.alertText}>
              {t("common:rsku.dialog.alertConfirmationText")}
            </Typography>
          ) : (
            <Fragment>
              <DialogContentText className={classes.text}>
                {t("common:rsku.dialog.confirmationSubTitle")}
              </DialogContentText>
              <Typography>
                {t("common:rsku.dialog.confirmationText")}
              </Typography>
            </Fragment>
          )}

          <DialogActions>
            <Button onClick={handleConfirmationDialogClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleRequestSkuDialogOpen()}
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
