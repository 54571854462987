import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withSnackbar } from "notistack";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DisplayError } from "./DisplayError";
import StepList from "./StepList";
import UploadConfigBody from "./UploadConfigBody";

const QontoConnector = withStyles({
  horizontal: {
    display: "none"
  }
})(StepConnector);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginRight: theme.spacing(1),
    marginLeft: "6px",
    textTransform: "none"
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  horizontal: {
    width: "33%"
  },
  step: {
    paddingLeft: 4,
    "& .MuiStepConnector-root": {
      flex: "0 0 auto !important"
    }
  }
}));

function GetSteps() {
  let highlight = {
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87)"
  };
  const { t } = useTranslation();

  return [
    <Typography style={highlight}>
      {t("common:uploadConfig:uploadTip")}
    </Typography>,
    <Typography style={highlight}>
      {t("common:uploadConfig:submitTip")}
    </Typography>,
    <Typography style={highlight}>
      {t("common:uploadConfig:noIssuesTip")}
    </Typography>
  ];
}

const StepOne = t => {
  return (
    <div>
      <Typography>{t("common:uploadConfig.inputTip")}</Typography>
      <Typography>
      {t("common:uploadConfig.startTip")}
      </Typography>
      <Typography>
      {t("common:uploadConfig.removeTip")}
      </Typography>
      <StepList />
    </div>
  );
};

function getStepContent(step, t) {
  switch (step) {
    case 0:
      return StepOne(t);
    case 1:
      return "";
    case 2:
      return "This is the bit I really care about!";
    default:
      return "Unknown step";
  }
}

const HorizontalLinearStepper = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentStep = useSelector(state => state.uploadConfig.activeStep);
  const [skipped, setSkipped] = React.useState(new Set());
  const { hasUpload, setHasUpload } = props;
  const { loading, setLoading } = props;
  const errorMessage = useSelector(state => state.uploadConfig.errorMessage);
  const warningMessage = useSelector(
    state => state.uploadConfig.warningMessage
  );
  const uploadStatus = useSelector(state => state.uploadConfig.uploadStatus);
  const modelId = useSelector(state => state.uploadConfig.modelId);
  const [error, setError] = useState();
  const [warning, setWarning] = useState();
  const [status, setStatus] = useState();
  const steps = GetSteps();

  useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    setWarning(warningMessage);
  }, [warningMessage]);

  useEffect(() => {
    setStatus(uploadStatus);
  }, [uploadStatus]);

  const isStepOptional = step => {
    return step === 1;
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleReset = () => {
    dispatch({
      type: "SET_ACTIVE_STEP",
      payload: 0
    });
  };

  return (
    <div className={classes.root}>
      {!hasUpload && !loading && (
        <>
          {currentStep === 0 || currentStep === 1 ? (
            <Fragment>
              <Stepper
                activeStep={currentStep}
                nonLinear
                connector={<QontoConnector />}
                className={classes.step}
              >
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepOptional(index)) {
                    labelProps.optional = (
                      <Typography variant="caption"></Typography>
                    );
                  }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step
                      key={label}
                      {...stepProps}
                      disabled={true}
                      className={classes.horizontal}
                      active={true}
                    >
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <>
                <div style={{ marginBottom: 20 }}>
                  {currentStep === steps.length ? (
                    <div>
                      <Typography className={classes.instructions}>
                        All steps completed - you&apos;re finished
                      </Typography>
                      <Grid container justifyContent="flex-end">
                        <Button
                          onClick={handleReset}
                          className={classes.button}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </div>
                  ) : (
                    <div>
                      {(error || warning) && (
                        <DisplayError
                          message={error}
                          warningMsg={warning}
                          uploadStatus={status}
                          modelId={modelId}
                        />
                      )}
                      <Typography className={classes.instructions}>
                        {getStepContent(currentStep, t)}
                      </Typography>
                    </div>
                  )}
                </div>
              </>
            </Fragment>
          ) : (
            <p></p>
          )}
        </>
      )}

      <UploadConfigBody
        hasUpload={hasUpload}
        setHasUpload={setHasUpload}
        loading={loading}
        setLoading={setLoading}
      ></UploadConfigBody>
    </div>
  );
};

export default withSnackbar(HorizontalLinearStepper);
