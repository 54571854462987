import {
  Box,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
  withStyles
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ExternalProps from "../contexts/externalPropsContext";
import {
  dateCompare,
  gaDateErrorMessage,
  isFutureDate,
  loadingLabel,
  shouldShowOnUI,
  showEsAndPriceSource,
  transformDateFormat,
  updateIframeHeight
} from "../lib/common/util";

function getPrice(item, selected, currencyCode) {
  let sign = "";
  // below condition is for issue no:3127
  let price = item.netPrice;
  let color = "#1f96d8";
  if (item.costPriceSource) {
    price = item.productCost;
    color = "#08a06d";
  }

  if (
    selected.inode &&
    selected.inode !== item.inode &&
    item.costPriceSource !== "CP"
  ) {
    const delta = price - (selected.netPrice || 0);
    sign = delta < 0 ? "-" : "+";
    price = delta;
  }
  if (selected.inode && selected.inode === item.inode) {
    sign = "";
  }
  return (
    <>
      {sign ? (
        <Box component="span" marginRight="5px">
          {sign}
        </Box>
      ) : null}
      <Typography
        style={{ color: color }}
        variant={item.selected ? "inherit" : "caption"}
        fontWeight={item.selected ? "bold" : "normal"}
      >
        {currencyCode} {Math.abs(isNaN(price) ? 0 : price).toFixed(2)}
      </Typography>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  radioLabel: {
    fontSize: "12px"
  },
  negativePrecon: {
    fontSize: "12px",
    color: "#9e9e9e"
  },
  selectedItemStyle: {
    fontSize: 12,
    fontWeight: "bold"
  },
  box: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "10px",
    whiteSpace: "nowrap"
  },
  itemName: {
    flex: 1
  },
  caption: {
    paddingTop: "7px"
  },
  notCompatible: {
    color: "#FF8540",
    borderBottom: "1px solid #FF8540"
  },
  viewMore: {
    cursor: "pointer"
  },
  required: {
    color: "#ff0000"
  }
}));

function ItemSingleRadio({
  chaid,
  handleItemChange,
  model,
  pricesList,
  selectedItemPrice,
  chaidReq
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState({ inode: "None" });
  const [open, setOpen] = React.useState(false);
  const {
    configRegion,
    countryCode,
    currencyCode,
    channelRegion,
    isStandaloneAccess = false,
    configID,
    configOrigin
  } = React.useContext(ExternalProps);

  const country = useSelector(state => state.productSelector.country);
  const countrySelectedDropdown = useSelector(
    state => state.productSelector.countrySelectedDropdown
  );
  const errorData = useSelector(state => state.configFilterData.errorData);
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const loadingSelection = useSelector(
    state => state.configFilterData.loadingSelection
  );
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  const modelData = useSelector(state => state.configFilterData.modelData);
  const showGADate = useSelector(state => state.productSelector.showGADate);
  const isConfigOverDate = useSelector(
    state => state.configFilterData.isConfigOverDate
  );
  const region = isStandaloneAccess
    ? configuration?.configHeader?.rgnCd || "NA"
    : channelRegion
    ? channelRegion
    : configRegion;
  const currentConfigID = useSelector(
    state => state.productSelector.currentConfigID
  );
  const { t } = useTranslation();
  const [toggleName, setToggleName] = React.useState(t("common:conflicts.viewMore"));
  const [check, setCheck] = React.useState(true);


  const [conflictItems2, setConflictItems2] = React.useState([]);

  const handleOpen = () => {
    setCheck(!check);
    setConflictItems2(check ? itemShowOnPopup : []);
    setToggleName(check ? t("common:conflicts.viewLess") : t("common:conflicts.viewMore"));
    updateIframeHeight(false)
  };

  useEffect(() => {
    const items =
      model &&
      model.Items?.filter(
        part =>
          part.visible &&
          // part.precon &&
          part.inode >= chaid.firstItem &&
          part.inode <= chaid.lastItem
      );
    items?.some(item => {
      if (item.selected) {
        setValue({ inode: item.inode, netPrice: item.netPrice });
        return true;
      } else {
        setValue({ inode: "None" });
        return false;
      }
    });
  }, [chaid.firstItem, chaid.lastItem, model]);

  const compare = (a, b) => {
    const valueA = a.partdes.toUpperCase();
    const valueB = b.partdes.toUpperCase();
    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
    return comparison;
  };

  const compareN = (a, b) => {
    const valueA = a.partno.toUpperCase();
    const valueB = b.partno.toUpperCase();
    let comparisonN = 0;
    if (valueA > valueB) {
      comparisonN = 1;
    } else if (valueA < valueB) {
      comparisonN = -1;
    }
    return comparisonN;
  };

  const handleChange = event => {
    const items =
      model &&
      model.Items?.filter(
        part =>
          part.visible &&
          part.precon >= 0 &&
          part.inode >= chaid.firstItem &&
          part.inode <= chaid.lastItem
      );
    if (event.target.value === "None") {
      setValue({ inode: "None" });
    } else {
      items?.forEach(item => {
        if (item.inode.toString() === event.target.value) {
          setValue({ inode: item.inode, netPrice: item.netPrice });
        }
      });
    }
  };

  const BlueRadio = withStyles({
    root: {
      color: "#00000054;",
      paddingBottom: "1px",
      paddingTop: "1px",
      alignSelf: "baseline",
      "&$checked": {
        color: "#29A8DD"
      },
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    checked: {}
  })(props => <Radio color="default" {...props} />);

  const conflictItems =
    model?.Items?.filter(
      part =>
        part.visible === true &&
        part.precon < 0 &&
        part.inode >= chaid.firstItem &&
        part.inode <= chaid.lastItem &&
        shouldShowOnUI(
          { currentConfigID, showGADate, isConfigOverDate },
          part.gaDate,
          part.selected
        )
    ) || [];

  // Check itemConflicts
  conflictItems.forEach(part => {
    if (
      model &&
      model.conflicts &&
      model.conflicts.length &&
      model.conflicts[0].itemConflictsList
    ) {
      let index = model.conflicts[0].itemConflictsList.filter(
        (x, i) => x.effect.partno === part.partno
      );
      if (index.length >= 0) {
        const causeItem = index[0]?.cause;
        if (causeItem) {
          part.cause = causeItem;
        }
      }
    }
  });

  useEffect(() => {
    if (modelData) {
      let chaidIndex =
        modelData.Chaids?.findIndex(el => el.chaId === chaid.chaId) ?? -1;
      if (chaidIndex !== -1) {
        modelData.Chaids[chaidIndex].conflictItems = conflictItems;
      }
    }
  }, [conflictItems, modelData, chaid.chaId]);
  const startDateHide = isFutureDate();

  let itemShowOnPopup = conflictItems?.splice(5);
  const finalConflictItems = [...conflictItems, ...itemShowOnPopup];
  finalConflictItems?.sort(a => (a?.selected !== true ? 1 : -1));
  const finalConflictItemsSort = finalConflictItems.slice(0, 5);

  const recommndedProducts =
    (model &&
      model.Items?.filter(
        item => Number(item.priority) >= 1 && Number(item.priority) <= 5
      )) ||
    [];
  const nonRecommendedProducts =
    (model &&
      model.Items?.filter(
        item => Number(item.priority) > 5 || item.priority === ""
      )?.sort(compareN)) ||
    [];
  const sortByPriority = [...recommndedProducts, ...nonRecommendedProducts];

  return (
    <>
      <Box id={"chaid-dropdown-" + chaid.chaId} width="100%">
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            defaultValue="None"
            value={value.inode}
            onChange={handleChange}
          >
            <FormControlLabel
              name={chaid.chaId}
              disabled={chaidReq}
              value="None"
              control={<BlueRadio disableRipple size="small" />}
              label={t("common:opportunity.dialog.legalEntity.None")}
              classes={{ label: classes.radioLabel }}
              onChange={e => handleItemChange(e)}
            />
            {sortByPriority
              .filter(
                part =>
                  part.visible &&
                  part.precon >= 0 &&
                  part.inode >= chaid.firstItem &&
                  part.inode <= chaid.lastItem &&
                  shouldShowOnUI(
                    { currentConfigID, showGADate, isConfigOverDate },
                    part.gaDate,
                    part.selected
                  )
              )
              .sort(compare)
              .sort(compareN)
              .concat(
                conflictItems2.length === 0
                  ? finalConflictItemsSort
                  : finalConflictItems
              )
              .map(item => (
                <div key={item.inode}>
                  {item.selected && isFutureDate(item.saDate)}
                  {!startDateHide ? (
                    <Box key={item.inode} display="flex" alignItems="center">
                      <Box flex="1" style={{ wordBreak: "break-word" }}>
                        <FormControlLabel
                          name={chaid.chaId}
                          id={item.inode}
                          value={item.inode}
                          classes={{
                            label: item.selected
                              ? classes.selectedItemStyle
                              : item.precon < 0
                              ? classes.negativePrecon
                              : classes.radioLabel
                          }}
                          control={<BlueRadio disableRipple size="small" />}
                          label={loadingLabel(
                            item.partno !== ""
                              ? `${item.partno} - ${item.partdes}`
                              : `${item.partdes}`,
                            loadingSelection === item.inode
                          )}
                          onChange={e => handleItemChange(e)}
                          //checked={item.selected}
                          className={
                            model &&
                            model.conflicts &&
                            model.conflicts[0]?.itemConflictsList[0]?.cause
                              ?.length === 0 &&
                            Number(errorData.value) === item.inode
                              ? classes.required
                              : null
                          }
                        />
                      </Box>
                      {item.precon >= 0 ||
                      (item.precon < 0 && item.selected) ? (
                        <>
                          <Box display="flex">
                            {defaultConfigStepSlected ===
                              "monitoringAccesories" &&
                              Number(item.priority) >= 1 &&
                              Number(item.priority) <= 5 && (
                                <Box width="100px">Recommended Item</Box>
                              )}
                          </Box>
                          {defaultConfigStepSlected ===
                            "configurationService" && chaid.chaId === "CS_C" ? (
                            ""
                          ) : (
                            <Box
                              display="flex"
                              fontWeight={item.selected ? "bold" : "normal"}
                              style={{ fontSize: "0.74rem" }}
                            >
                              <Box
                                width="120px"
                                className={classes.box}
                                fontWeight={item.selected ? "bold" : "normal"}
                              >
                                {getPrice(item, value, currencyCode)}
                              </Box>
                              {showEsAndPriceSource(
                                region,
                                isStandaloneAccess
                                  ? countrySelectedDropdown
                                    ? countrySelectedDropdown
                                    : country
                                  : countryCode,
                                "EM",
                                currentConfigID,
                                configOrigin
                              ) && (
                                <Box
                                  width="100px"
                                  fontWeight={item.selected ? "bold" : "normal"}
                                >
                                  {t("common:configurator.EM") + (item.esDate
                                    ? transformDateFormat(
                                        item.esDate,
                                        "MM/DD/YYYY"
                                      )
                                    : "")}
                                </Box>
                              )}

                              {/* Commented below code for getting pricesource for allthe regions
                            issue no: 3126 */}
                              {showEsAndPriceSource(
                                region,
                                isStandaloneAccess
                                  ? countrySelectedDropdown
                                    ? countrySelectedDropdown
                                    : country
                                  : countryCode,
                                "PriceSource",
                                currentConfigID,
                                configOrigin
                              ) && (
                                <Box
                                  width="100px"
                                  fontWeight={item.selected ? "bold" : "normal"}
                                >
                                  {/*Below condition is for issue no:3126 */}
                                  {t("common:configurator.priceSource") + (item.costPriceSource
                                    ? item.costPriceSource
                                    : item.priceSource)}
                                </Box>
                              )}
                              {currentConfigID &&
                                showGADate &&
                                isConfigOverDate && (
                                  <Box
                                    width="100px"
                                    className={classes.box}
                                    fontWeight={
                                      item.selected ? "bold" : "normal"
                                    }
                                  >
                                    {item?.gaDate && dateCompare(item?.gaDate) && (
                                      <>
                                        GA:
                                        {item.gaDate
                                          ? transformDateFormat(
                                              item.gaDate,
                                              "MM/DD/YYYY"
                                            )
                                          : ""}
                                      </>
                                    )}
                                  </Box>
                                )}
                            </Box>
                          )}
                        </>
                      ) : (
                        <>
                          <Typography
                            variant="caption"
                            className={classes.negativePrecon}
                          >
                            {item.cause
                              ? `This component has a conflict with current ${item.cause.chaDes} ${item.cause.partno}`
                              : t("common:conflicts.text4")}
                          </Typography>
                        </>
                      )}
                    </Box>
                  ) : (
                    ""
                  )}
                  {item.selected && item.plcStatus === "I" && (
                    <div style={{ color: "red" }}>{item.plcMessage}</div>
                  )}
                  {currentConfigID &&
                    !showGADate &&
                    item.selected &&
                    item.plcStatus !== "I" &&
                    gaDateErrorMessage(
                      showGADate,
                      item.gaDate,
                      isConfigOverDate
                    ) && (
                      <Box style={{ color: "red" }}>
                        This component is not available for this country
                      </Box>
                    )}
                </div>
              ))}
          </RadioGroup>
          {itemShowOnPopup?.length > 0 && (
            <Typography variant="caption" className={classes.caption}>
              {t("common:conflicts.text5").replace("{0}", finalConflictItems.length)}
              <Typography
                component="span"
                variant="caption"
                color="primary"
                onClick={handleOpen}
              >
                <span className={classes.viewMore}>{toggleName}.</span>
              </Typography>
            </Typography>
          )}
        </FormControl>
      </Box>
    </>
  );
}

export default ItemSingleRadio;
