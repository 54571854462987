import { useQuery } from "@apollo/client";
import { Fab, Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { KeyboardArrowUp } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  CONFIG_STEP_SELECTION,
  SET_CONFIG_LOAD_MODEL,
  SET_IS_CONFIG_OVERDATE,
  SET_KMAT_ID,
  SET_ORIGIN_SELECTED_ITEMS,
  SET_SPC_ID
} from "../../actions/Types";
import RequestAssistanceButton from "../../components/RequestAssistance/RequestAssistanceButton";
import {
  BOM_CONTAINER_POSTION,
  PANEL_CONTAINER_POSITION
} from "../../config/env";
import { HPMuiTheme } from "../../config/theme";
import { ExternalProps } from "../../contexts/externalPropsContext";
import { PRODUCT_SELECTION } from "../../GraphQL";
import displayNotistack from "../../lib/common/SnackBarUtils";
import {
  axiosCall,
  calculateDays,
  delay,
  getStorageValue,
  obsoleteDateFormat
} from "../../lib/common/util";
import { SET_COUNTRY_REGION } from "../../models/configuration/productSelector/Constants";
import { loadOneModel } from "../../oneConfigNative/main";
import { segregatePayload } from "../../oneConfigNative/util";
import {
  getConfigData,
  getUiDataFileName,
  getUISpecData,
  setIsSpecOrigin
} from "../../services/utils";
import MyAnimation from "../MyAnimation";
import ConfigBOM from "./ConfigBOM/ConfigBOM";
import ConfigHome from "./ConfigExpansionDetails/ConfigHome";
import ConfigFilter from "./ConfigFilter/ConfigFilter";
import ConfigStepper from "./ConfigStepper/ConfigStepper";
const pako = require("pako");
const useStyles = makeStyles(theme => ({
  container: {
    margin: "0 5% 5% 5%"
  },
  midPositioner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)"
  },
  spinner: {
    color: "rgb(41, 168, 221)"
  },
  warningColor: {
    color: "#ffbf00"
  },
  typo: {
    color: "#29A8DD"
  },
  yopacity: {
    backgroundColor: "transparent",
    boxShadow: "none",
    height: "46px",
    width: "48px",
    background: "#0096D6 !important",
    position: "fixed",
    fontSize: "3rem",
    zIndex: "9999",
    cursor: "pointer",
    right: "24px",
    "&:hover": {
      color: "green"
    }
  },
  bottomReconfigure: {
    bottom: "20rem"
  },
  bottom: {
    bottom: "16rem"
  }
}));

const ConfigContainer = props => {
  //get proCategory from props
  const { proCategory, setOpen } = props;

  const classes = useStyles();
  const country = useSelector(state => state.productSelector.country);
  const countrySelectedDropdown = useSelector(
    state => state.productSelector.countrySelectedDropdown
  );
  const countryRegion = useSelector(
    state => state.productSelector.countryRegion
  );
  const businessModel = useSelector(
    state => state.productSelector.businessModel
  );
  const { isSkuCloning, skuConfiguration } = useSelector(
    state => state.rskuReducer
  );
  const dispatch = useDispatch();
  const {
    countryCode = "US",
    resellerID,
    channelRegion = "",
    configRegion = "",
    mDCPOrgID = "99",
    pricingGeo = "US",
    currencyCode = "USD",
    incoterms = "DDP",
    routeToMarket,
    purchaseAgreement,
    isStandaloneAccess = false,
    setCurrency,
    userType,
    productCatalog = "Business Desktop PCs",
    transactionID,
    visibility = {},
    isOneConfigIQ,
    clientLocale,
    configOrigin,
    isOpenOneConfigReconfigure,
    isPartnerPortalFlag = false
  } = React.useContext(ExternalProps);
  const { showGaDateConfigError = true } = visibility;
  const showGADate = useSelector(state => state.productSelector.showGADate);
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const showBOMSectionFlag = useSelector(
    state => state.configFilterData.showBOMSectionFlag
  );
  const modelData = useSelector(state => state.configFilterData.modelData);
  const bomData = useSelector(state => state.configFilterData.bomData);
  const chaiItems = useSelector(state => state.configFilterData.chaiItems);
  const chaiItemsRadioList = useSelector(
    state => state.configFilterData.chaiItemsRadioList
  );
  const expandAll = useSelector(state => state.configFilterData.expandAll);
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  const steppers = useSelector(state => state.configFilterData.steppers);
  const displayBusinessModel = useSelector(
    state => state.productSelector.displayBusinessModel
  );
  const currentCountry = isStandaloneAccess
    ? countrySelectedDropdown
      ? countrySelectedDropdown
      : country
    : countryCode;
  const locale = clientLocale
    ? clientLocale.indexOf("_") !== -1
      ? clientLocale
      : clientLocale + "_" + countryCode
    : "en_US";
  let modelQuery = `query modelConfiguration($filter: ConfigurationFilter) {
        modelConfiguration(filter: $filter)
      }`;

  const [error, setError] = useState({ isError: false, message: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [isUISpecDataLoaded, setIsUISpecDataLoaded] = useState(false);
  const [stepDetails, getStepDetails] = useState("");
  const [steppersList, setStepperList] = useState([]);
  const { config } = props ? props : getStorageValue("config");
  const [configStartDate, setConfigStartDate] = useState("");
  //define a state to store localization Data as received from api response
  const [localisationData, setLocalisationData] = useState({});
  const { configID, kmatId, spcStartDate } = config;
  const [isWebAInit, setIsWebAInit] = useState(false);
  const { t } = useTranslation();
  const isGoEngine = localStorage.getItem("KB_ENGINE") === "GO";
  const [containerScrollValue, setContainerScrollValue] = useState(0);
  let myInternal;
  const wasmPath =
    window._WASM_TEST_URL ||
    `${process.env.REACT_APP_S3_BUCKET_URL}/oneconfig-engine.wasm.gz`;
  useEffect(() => {
    let webAInterval;
    async function fetchMyAPI() {
      if (!WebAssembly.instantiateStreaming) {
        // polyfill
        WebAssembly.instantiateStreaming = async (resp, importObject) => {
          const source = await (await resp).arrayBuffer();
          return await WebAssembly.instantiate(source, importObject);
        };
      }
      const go = window.Go && new window.Go();
      const url = wasmPath; // the gzip-compressed wasm file
      //const pako = pako.deflate(url);
      let wasm = pako.ungzip(await (await fetch(url)).arrayBuffer());
      let mod;
      let inst;
      const result = await WebAssembly.instantiate(wasm, go && go.importObject)
        .then(async result => {
          inst = result.instance;
          setIsWebAInit(true);
          await go.run(inst);
        })
        .catch(err => {
          console.error(err);
        });
    }

    webAInterval = setInterval(() => {
      if (window.Go) {
        fetchMyAPI();
        clearInterval(webAInterval);
      }
    }, 100); //Fix issue 43300 - Reducing the timeout to 100 to avoid hang issue
    //since wasm engine is loading very late.
  }, [wasmPath]);

  useEffect(() => {
    if (configID) {
      let getTransactionURL = `${process.env.REACT_APP_GRAPHQL_BASE_URL}/model`;
      const cHeader = {
        headers: { Authorization: `Bearer ${window.authToken}` }
      };
      const data = {
        query: modelQuery,
        variables: {
          filter: {
            configId: configID,
            country: currentCountry,
            pricingGeo: isStandaloneAccess
              ? countrySelectedDropdown
                ? countrySelectedDropdown
                : country
              : pricingGeo,
            originatingAsset: "OCIQ", // two possible values are OCIQ and OCA. This code is not rendered during OCA, hence hardcoded to OCIQ.
            currency: isStandaloneAccess ? "" : currencyCode,
            incoterms: isStandaloneAccess ? "" : incoterms,
            routeToMarket: displayBusinessModel
              ? businessModel
              : routeToMarket || "Direct",
            resellerId: resellerID || "",
            mDCPOrgID: mDCPOrgID,
            banded: false,
            isStandAloneAccess: isStandaloneAccess,
            purchaseAgreementNumber: purchaseAgreement,
            productType:
              proCategory && proCategory === "print" ? "print" : "compute",
            userType:
              userType?.toUpperCase() === "PARTNER" ? "Partner" : "Internal",
            language: locale
          }
        }
      };
      axiosCall
        .post(getTransactionURL, data, cHeader)
        .then(result => {
          if (!result.data.errors) {
            window.scrollTo(0, 0);
            setError({ isError: false, message: "" });
            batch(() => {
              dispatch({
                type: CONFIG_STEP_SELECTION,
                selectedStep: "hardware"
              });
              dispatch({
                type: SET_CONFIG_LOAD_MODEL,
                configuration:
                  result.data?.data?.modelConfiguration?.configuration
              });
              dispatch({
                type: SET_KMAT_ID,
                payload:
                  result.data?.data?.modelConfiguration?.modelData?.kmatId
              });
              dispatch({
                type: SET_SPC_ID,
                payload:
                  result.data?.data?.modelConfiguration?.configuration
                    ?.configHeader?.configId
              });
            });
            setCurrency(
              result.data?.data?.modelConfiguration?.modelData?.currency
            );
            const startDate =
              result.data?.data?.modelConfiguration?.configuration?.configHeader
                ?.startDate;
            const docType =
              result.data?.data?.modelConfiguration?.configuration?.configHeader
                ?.docType;
            const date =
              docType === "SP"
                ? startDate
                : (docType === "QU" || docType === "CC") && !spcStartDate
                ? startDate
                : spcStartDate
                ? spcStartDate
                : "";
            if (date && date !== "NA" && date !== "") {
              dispatch({
                type: SET_IS_CONFIG_OVERDATE,
                payload:
                  moment(date).format("YYYY-MM-DD") >
                  moment().format("YYYY-MM-DD")
              });
              setConfigStartDate(date);
            }
            triggerWebAssembly(
              result.data?.data?.modelConfiguration?.modelData,
              result.data?.data?.modelConfiguration?.configuration
            );
          } else {
            setIsUISpecDataLoaded(false);
            setIsLoading(false);
            const errors = result.data.errors.map(error => {
              const fullMessage = JSON.parse(error.message);
              return `${fullMessage.code}: ${fullMessage.message}`;
            });
            setError({ isError: true, message: errors.join("/n") });
            displayNotistack.error(true, {
              snackbarMessage: t(
                "common:productSelector.errorMessage.graphQLErrors"
              ),
              errorMessage: result.data.errors
            });
            if (typeof setOpen !== "undefined") {
              setOpen(false);
            }
          }
        })
        .catch(error => {
          setIsUISpecDataLoaded(false);
          setIsLoading(false);
          setError({ isError: true, message: error });
          displayNotistack.error(true, {
            snackbarMessage: t(
              "common:productSelector.errorMessage.graphQLErrors"
            ),
            errorMessage: error
          });
          if (typeof setOpen !== "undefined") {
            setOpen(false);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    configID,
    countryCode,
    channelRegion,
    pricingGeo,
    incoterms,
    routeToMarket,
    resellerID,
    mDCPOrgID,
    modelQuery,
    dispatch,
    purchaseAgreement,
    proCategory
  ]);

  useEffect(() => {
    updateIframeHeight(isLoading);
  }, [
    isLoading,
    stepDetails,
    steppersList,
    error,
    isUISpecDataLoaded,
    configID,
    kmatId,
    expandAll
  ]);

  const updateIframeHeight = async (isLoading = false) => {
    await delay(3000);
    if (document.querySelector("#product-selector")) {
      const height = isLoading
        ? window.document.querySelector("body")?.offsetHeight
        : window.document.querySelector("#configContainer")?.offsetHeight;
      document.querySelector("#product-selector").dispatchEvent(
        new CustomEvent("iframeloaded", {
          detail: {
            height: `${height + 100}`,
            showBOMSectionFlag,
            bomList: bomData
          }
        })
      );
    } else if (document.querySelector("#configurator")) {
      const height = isLoading
        ? window.document.querySelector("body")?.offsetHeight
        : window.document.querySelector("#configContainer")?.offsetHeight;
      document.querySelector("#configurator").dispatchEvent(
        new CustomEvent("iframeloaded", {
          detail: {
            height: `${height + 100}`,
            showBOMSectionFlag,
            bomList: bomData
          }
        })
      );
    }
  };
  const containerItem = document?.getElementById("configContainer");
  if (containerItem && !isOneConfigIQ) {
    const scrollParent = containerItem?.closest("[role=dialog]");
    scrollParent?.addEventListener("scroll", () => {
      let scrollValue = scrollParent?.scrollTop;
      setContainerScrollValue(scrollValue > 0);
    });
  }
  const triggerWebAssembly = (data, configuration) => {
    let payload = {
      itemChanges: [{}]
    };

    data.startDate = configuration?.configHeader?.startDate;
    data.showGADate = showGADate;
    data.showGaDateConfigError = showGaDateConfigError;
    window._modelJson = data;
    const oneModel = new loadOneModel(data);
    const { cpPayload, nonCpPayload, chaId } = segregatePayload(payload, data);
    if (window._WASM_DELAY) {
      setTimeout(
        () => {
          parseModelObj(
            isGoEngine && !window._DISABLE_WASM
              ? window.updateModelWithMultipleChanges &&
                  window.updateModelWithMultipleChanges(JSON.stringify(payload))
              : oneModel.updateModelWithMultipleChanges(nonCpPayload),
            configuration
          );
        },
        isGoEngine ? window._WASM_DELAY : 5
      );
    } else {
      myInternal = setInterval(
        () => {
          if (isGoEngine) {
            window.updateModelWithMultipleChanges &&
              parseModelObj(
                !window._DISABLE_WASM &&
                  window.updateModelWithMultipleChanges(
                    JSON.stringify(payload)
                  ),
                configuration
              );
          } else {
            parseModelObj(
              oneModel.updateModelWithMultipleChanges(nonCpPayload),
              configuration
            );
          }
        },
        isGoEngine ? 1000 : 5
      ); //Fix issue 43300 - Increasing the delay to make sure wasm engine is
      // ready before calling it
    }
  };

  function parseModelObj(isSuccess, configuration) {
    if (isSuccess || window._DISABLE_WASM) {
      if (
        localStorage.getItem("isDebug") === "true" ||
        localStorage.getItem("showTime") === "true"
      ) {
        console.log("isSuccess on first load", isSuccess);
      }
      let modelJson;
      let selectedItems = [];
      if (typeof window._modelJson !== "undefined") {
        !window._WASM_DELAY && clearInterval(myInternal);
        modelJson = isGoEngine ? window._modelJson : isSuccess;
        setIsSpecOrigin(modelJson);
        selectedItems =
          (modelJson.Items &&
            modelJson.Items.filter(
              item =>
                item.selected === true &&
                item.partno !== "" &&
                item.visible === true
            )) ||
          [];
      }
      dispatch({
        type: SET_CONFIG_LOAD_MODEL,
        modelData: modelJson,
        configuration: !isSkuCloning ? configuration : skuConfiguration
      });
      dispatch({
        type: SET_ORIGIN_SELECTED_ITEMS,
        selectedItems: JSON.stringify(selectedItems),
        haveSaved: false
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const stepperList = steppers?.map(stepper => {
        if (stepper.isWarning) return stepper.id;
      });
      setStepperList(stepperList);
    }, 500);
  }, [defaultConfigStepSlected, steppers, chaiItemsRadioList, chaiItems]);

  useEffect(() => {
    let categoryVal =
      localStorage.getItem("selectedCategory") || productCatalog;
    let selectedCategoryName;
    if (categoryVal) {
      selectedCategoryName = categoryVal;
    } else {
      selectedCategoryName = "Business Desktop PCs";
    }
    async function fetchDesktopUiSpec(category) {
      let uiSpecFile = getUiDataFileName(category);
      await getUISpecData.getData(uiSpecFile);
      setIsUISpecDataLoaded(true);
    }
    fetchDesktopUiSpec(selectedCategoryName);
  }, []);

  useEffect(() => {
    if (kmatId && isWebAInit) {
      // dispatch({
      //   type: SET_CONFIG_LOAD_MODEL,
      //   configuration: {},
      //   modelData: config
      // });
      setTimeout(() => {
        triggerWebAssembly(config, config.configuration);
      }, 300);
      // setIsLoading(false);
      setError({ isError: false, message: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kmatId, config, isWebAInit]);

  useEffect(() => {
    dispatch({
      type: "SET_CURRENT_CONFIGID",
      payload: configID
    });
  }, [configID]);
  const setCountryRegionOnMount = countryRegions => {
    let countryRegion = "";
    countryRegions &&
      currentCountry &&
      Object.keys(countryRegions).forEach(region => {
        const country = countryRegions[region].countries.find(
          country => country.code === currentCountry
        );
        if (country) {
          countryRegion = region;
        }
      });
    dispatch({
      type: SET_COUNTRY_REGION,
      payload: countryRegion
    });
  };

  const selectCarePackText = () => {
    if (defaultConfigStepSlected === "hardware") {
      const data = getConfigData(modelData, "carePacks");
      let isSelected = false;
      data.forEach(chaid => {
        if (!isSelected) {
          for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
            isSelected =
              isSelected ||
              (modelData.Items[i].selected === true ? true : false);
            if (isSelected) break;
          }
        }
      });
      return !isSelected ? (
        <Typography
          className={classes.typo}
          component={"span"}
          variant={"body2"}
        >
          {t("common:table.selectCarePack")}
        </Typography>
      ) : (
        <></>
      );
    }
    return <></>;
  };

  useQuery(PRODUCT_SELECTION, {
    fetchPolicy: "no-cache",
    variables: {
      filter: {
        region: countryRegion || channelRegion || configRegion || "",
        country: currentCountry || "",
        routeToMarket: displayBusinessModel
          ? businessModel
          : routeToMarket || "Direct"
      },
      transactionId: transactionID || ""
    },
    onCompleted({ productSelection }) {
      const countryDataMap =
        productSelection.ProductSelectionData.regionLookupDataMap;
      setCountryRegionOnMount(countryDataMap);
      setLocalisationData(productSelection.ProductSelectionData.localizations);
      dispatch({
        type: "SET_SHOW_GADATE",
        payload: productSelection.ShowGADate
      });
      const region = countryRegion || channelRegion || configRegion;
      const filterLevel = [
        configOrigin || "OCIQ",
        proCategory,
        region,
        currentCountry,
        userType && userType?.toLowerCase()
      ];
      let ocfgParams = [productSelection.ocfg_params];

      // Select both the data of target parameters and "ALL"/"all" data at the same level
      filterLevel.map(val => {
        let filteredArr = [];
        ocfgParams.map(item => {
          filteredArr.push(item?.[val], item?.["ALL"] || item?.["all"]);
        });
        ocfgParams = filteredArr.filter(value => value !== undefined);
      });
      const ocfgParamsData = ocfgParams.reduce((pre, cur) => {
        return {
          monitorQty: { ...pre?.Monitor_Qty, ...cur?.Monitor_Qty },
          hideTab: { ...pre?.hideTab, ...cur?.hideTab },
          hideChoice: { ...pre?.hideChoice, ...cur?.hideChoice }
        };
      }, {});
      dispatch({
        type: "SET_OCFG_PARAMS",
        payload: ocfgParamsData
      });
    },

    onError({ graphQLErrors, networkError }) {
      if (localStorage.getItem("isDebug") === "true") {
        if (graphQLErrors) {
          console.log(graphQLErrors);
        }
        if (networkError) {
          console.log(networkError);
        }
      }
    }
  });

  let panelContainer = {
    html: (
      <Grid item xs={12} sm={8} key="panel">
        <ConfigHome
          proCategory={proCategory}
          localisationData={localisationData}
        />
      </Grid>
    ),
    position: PANEL_CONTAINER_POSITION
  };

  let bomContainer = {
    html: (
      <Grid item xs={12} sm={4} key="bom">
        <ConfigBOM />
      </Grid>
    ),
    position: BOM_CONTAINER_POSTION
  };

  function navigateToTop() {
    let topRefLink = document.querySelector("#configContainer");
    topRefLink?.scrollIntoView({ behavior: "smooth" });
  }

  let containers = [panelContainer, bomContainer];
  containers.sort((c1, c2) => (c1.position > c2.position ? 1 : -1));
  const lottieLoadingFile =
    "https://dev-ociq.hpcloud.hp.com/assets/lotties/product_selector_animation.json";

  return (
    <>
      {isLoading || !isUISpecDataLoaded ? (
        <div className={classes.midPositioner}>
          <Typography variant="h4" align="center">
            Hold on tight
          </Typography>
          <Typography align="center">
            We are getting your config ready.
          </Typography>
          <MyAnimation
            lottieFile={lottieLoadingFile}
            style={{ height: "300px", width: "400px" }}
          />
        </div>
      ) : error.isError ? (
        <MuiThemeProvider theme={HPMuiTheme}>
          <div className={classes.midPositioner}>{error.message}</div>
        </MuiThemeProvider>
      ) : (
        <div className={classes.container} id="configContainer">
          <ConfigStepper
            getStepDetails={getStepDetails}
            stepDetails={stepDetails}
            proCategory={proCategory}
            setStepperList={setStepperList}
            configStartDate={configStartDate}
          />
          {selectCarePackText()}
          <br />
          {((stepDetails && defaultConfigStepSlected === stepDetails) ||
            (steppersList?.length > 0 &&
              steppersList.includes(defaultConfigStepSlected))) &&
            showBOMSectionFlag &&
            (chaiItems?.length > 0 ||
              (modelData &&
                modelData?.Items?.some(item => {
                  let formatEod = obsoleteDateFormat(item.esDate);
                  const days = calculateDays(formatEod);
                  const isDateGreater = new Date(formatEod) > new Date();
                  return (
                    item.selected && item.visible && isDateGreater && days <= 90
                  );
                }))) && (
              <Box display="flex" justifyContent="center">
                <Typography>There are items that will be</Typography>&nbsp;
                <Typography className={classes.warningColor}>
                  obsolete
                </Typography>
                &nbsp;
                <Typography>within current configuration in 90 days</Typography>
              </Box>
            )}
          <br />
          <ConfigFilter
            proCategory={proCategory}
            name={configuration?.configHeader?.configName}
            currentCountry={currentCountry}
          />
          <br />
          <br />
          <Grid container spacing={3} id="container">
            {containers.map(container => container.html)}
          </Grid>
          {!isOneConfigIQ && containerScrollValue ? (
            <Fab
              color="primary"
              className={
                isOpenOneConfigReconfigure
                  ? [classes.bottomReconfigure, classes.yopacity]
                  : [classes.bottom, classes.yopacity]
              }
              onClick={navigateToTop}
            >
              <KeyboardArrowUp />
            </Fab>
          ) : null}
        </div>
      )}
      {configOrigin !== "OCA" && !isPartnerPortalFlag && (
        <RequestAssistanceButton />
      )}
    </>
  );
};

export default ConfigContainer;
