import Box from "@material-ui/core/Box";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  CONFIG_STEP_SELECTION,
  LOAD_CONFIG_STEPPER,
  SET_SEARCHED_TEXT,
  UPDATE_TAB_EXPAND
} from "../../../actions/Types";
import { ExternalProps } from "../../../contexts/externalPropsContext";
// import { getStorageValue } from "../../../lib/common/util";
import {
  compare,
  getAllDataExpandedOrCollapsedManul,
  getConfigBom,
  getConfigData,
  getLocalizedMandaCPItem,
  shouldHideChoices,
  validateStepper,
  validateWarning
} from "../../../services/utils";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "2%",
    textAlign: "center",
    "& > div.MuiPaper-root": {
      backgroundColor: "transparent"
    }
  },
  iconSize: {
    width: "32px",
    height: "32px",
    color: "#0096d7"
  },
  labelBox: {
    width: "auto",
    position: "absolute",
    top: 10,
    margin: "0px -7px 0px -7px !important",
    "& > span.MuiStepLabel-labelContainer .MuiStepLabel-alternativeLabel": {
      marginTop: "1px"
    },
    "& $span": {
      marginTop: "0px !important"
    }
  },
  iconSizeError: {
    width: "32px",
    height: "32px"
  },
  warningIcon: {
    width: "32px",
    height: "32px",
    color: "#ffbf00"
  },
  labelStepper: {
    cursor: "pointer",
    color: "#000000",
    fontSize: "16px",
    marginLeft: "13px",
    marginRight: "13px"
  },
  activeLabelStepper: {
    color: "#0096d7",
    cursor: "default",
    fontSize: "16px",
    marginLeft: "13px",
    marginRight: "13px"
  },
  labelStepperError: {
    color: "rgb(214,0,42)",
    cursor: "default",
    fontWeight: "bold",
    fontSize: "16px",
    marginLeft: "13px",
    marginRight: "13px"
  },
  labelStepperWarning: {
    color: "#ffbf00",
    cursor: "default",
    fontSize: "16px"
  },
  buttonStepper: {
    cursor: "pointer"
  },
  activeButtonStepper: {
    cursor: "default"
  },
  internalAndexternal: {
    fontSize: "15px !important",
    position: "absolute",
    left: "858px",
    top: "48px"
  },
  internalCls: {
    color: "#0096d7",
    paddingRight: "30px",
    cursor: "default",
    fontSize: "14px !important"
  },
  externalCls: {
    color: "#0096d7",
    paddingLeft: "30px",
    cursor: "default",
    fontSize: "14px !important"
  },
  internalActiveCls: {
    color: "#000000",
    fontSize: "14px !important"
  },
  externalActiveCls: {
    color: "#000000",
    cursor: "pointer",
    fontSize: "14px !important"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  completed: {
    display: "inline-block"
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  errorPlaceholder: {
    marginTop: "60px",
    textAlign: "center",
    "& $a": {
      color: "red",
      textDecoration: "none"
    }
  },
  errorColor: {
    color: "rgb(214,0,42)",
    fontSize: "18px"
  },
  errorMessage: {
    display: "inline-block",
    cursor: "pointer",
    justifyContent: "center",
    "& $p": {
      fontSize: "18px !important"
    }
  }
}));

const ColorlibConnector = withStyles({
  alternativeLabel: {
    position: "absolute",
    margin: "0px -7px 0px -7px !important",
    width: "auto",
    color: "#0096d7",
    active: {
      color: "#0096d7"
    }
  },
  active: {
    "& $line": {
      color: "#0096d7"
    }
  },
  completed: {
    "& $line": {
      color: "#0096d7"
    }
  },
  line: {
    height: "4px",
    border: 0,
    backgroundColor: "#0096d7",
    borderRadius: 0
  }
})(StepConnector);

//------below method not used-----
// function getConfig() {
//   let rootEle = document.getElementById("product-selector");
//   if (!rootEle) {
//     rootEle = document.getElementById("configurator");
//   }
//   let config = getStorageValue("config");
//   if (rootEle.dataset.configsettings) {
//     config = JSON.parse(rootEle.dataset.configsettings);
//   }
//   return config || {};
// }

export function scrollToChoice(chaId, carePacksErr) {
  const chaIdFilter = chaId => {
    if (carePacksErr === "topRecommend") {
      return (chaId = "99999");
    } else if (carePacksErr === "additional") {
      return (chaId = "99998");
    } else if (chaId === "ABSCP" || chaId === "PSE") {
      return (chaId = "SSS99");
    }
    return chaId;
  };
  const targetSection = document.getElementById("chaid-" + chaIdFilter(chaId));
  if (targetSection) {
    let targetPosition = targetSection.offsetTop;
    // const headerPresent = getConfig().configOrigin === 'OCA';
    // if (headerPresent) targetPosition -= 64;
    const scrollParent = targetSection.closest("[role=dialog]");
    if (scrollParent) scrollParent.scrollTop = targetPosition;
    else {
      if (window !== window.parent) {
        const parentWindow = window.parent;
        const parentDocument = parentWindow.document;
        const iframeOffset = parentDocument.querySelector("iframe").offsetTop;
        parentWindow.scrollTo({
          top: iframeOffset + targetPosition - 64,
          behavior: "smooth"
        });
      }
    }
  }
}

function focusNextError(filteredChoices, modelData) {
  const chaids = filteredChoices.map(choice => choice.chaId);
  const conflicts = modelData?.conflicts[0]?.icConflicts.filter(
    x => x.code === "ERR04" || x.code === "ERR03"
  );

  if (
    modelData?.conflicts[0]?.itemConflictsList &&
    modelData?.conflicts[0]?.itemConflictsList[0]?.cause &&
    conflicts.indexOf(modelData?.conflicts[0]?.itemConflictsList[0]?.cause) ===
      -1
  ) {
    conflicts.push(modelData?.conflicts[0]?.itemConflictsList[0]?.cause);
  }
  conflicts.sort((a, b) => chaids.indexOf(a.chaId) - chaids.indexOf(b.chaId));
  const first = conflicts[0];
  if (first) scrollToChoice(first.chaId);
}

export default function ConfigStepper(props) {
  const { proCategory } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [currentErr, setCurrentErr] = React.useState([]);
  const [restOfErr, setRestOfErr] = React.useState([]);
  const [carePacksErr, setCarePacksErr] = React.useState();
  const modelData = useSelector(state => state.configFilterData.modelData);
  const bomSelected = useSelector(state => state.configFilterData.bomSelected);
  const filteredChoices = useSelector(
    state => state.configFilterData.filteredChoices
  );
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const checkedFilterParts = useSelector(
    state => state.configFilterData.checkedFilterParts
  );
  const expandAll = useSelector(state => state.configFilterData.expandAll);
  const chaiItems = useSelector(state => state.configFilterData.chaiItems);
  const showBOMSectionFlag = useSelector(
    state => state.configFilterData.showBOMSectionFlag
  );
  const chaiItemsRadioList = useSelector(
    state => state.configFilterData.chaiItemsRadioList
  );
  const steppers = useSelector(state => state.configFilterData.steppers);
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  const showGADate = useSelector(state => state.productSelector.showGADate);
  const isConfigOverDate = useSelector(
    state => state.configFilterData.isConfigOverDate
  );
  const currentConfigID = useSelector(
    state => state.productSelector.currentConfigID
  );
  const ocfgParams = useSelector(state => state.productSelector.ocfgParams);
  let { configRegion } = React.useContext(ExternalProps);
  const data = getConfigData(modelData, defaultConfigStepSlected);
  const { userType, visibility = {} } = React.useContext(ExternalProps);
  let {
    showConfigurationServicesTab = true,
    showManageabilityServicesTab = true,
    showMonitorsAccessoriesTab = true
  } = visibility;
  const [visibilityTabs, setVisibilityTabs] = React.useState({
    hideConfigurationServicesTab: false,
    hideManageabilityServicesTab: false,
    hideMonitorsAccessoriesTab: false,
    hideHardwareTab: false,
    hideCarePacksTab: false,
    hideDeploymentServicesTab: false
  });
  const { haveFutureItem, maxGaDate } = getConfigBom(modelData, {
    currentConfigID,
    showGADate,
    isConfigOverDate
  });
  const hideChaidListConfigurationTab = [
    "CS_C",
    "V4_SI",
    "V4_HI",
    "V4_SS",
    "V4_HS",
    "V4_HD",
    "V4_GM",
    "V4_CI",
    "V4_CI_QTY",
    "V4_SP",
    "V4_ESA",
    "V4_PPS",
    "V4_DPS",
    "V4_VACS",
    "V4_SCD",
    "V4_SI_PIL",
    "V4_SI_PIML",
    "V4_SI_PIBL",
    "V4_SI_PCIS",
    "V4_SI_PALS",
    "V4_SI_UPS",
    "V4_HI_SPBS",
    "V4_HI_DMM",
    "V4_HI_THWI",
    "V4_HI_CBSS",
    "V4_HI_CPBSS",
    "V4_SS_IVSC",
    "V4_SS_BMDR",
    "V4_SS_PBRC",
    "V4_SS_PBAT",
    "V4_HS_SAT",
    "V4_HS_SDAT",
    "V4_HS_CSAT",
    "V4_HS_SST",
    "V4_HS_CST",
    "V4_HS_SATS",
    "V4_HD_DIP",
    "V4_GM_TS",
    "V4_GM_TPS",
    "V4_SP_OPS",
    "V4_PPS_HPPS",
    "V4_GM_THS",
    "V4_DPS_PPS",
    "V4_DPS_OVS",
    "V4_DPS_RRS",
    "V4_VACS_PCS",
    "V4_VACS_RSCS",
    "V4_VACS_RSR",
    "V4_VACS_GS",
    "V4_VACS_DUMMY",
    "V4_VACS_CCTPE",
    "V4_VACS_CCTPW",
    "V4_VACS_CCICE",
    "V4_VACS_CCICW",
    "AMS",
    "V4_SE_RTG",
    "V4_SE",
    "V4_DC"
  ];
  const chaids = filteredChoices.map(choice => choice.chaId);
  const searchText = useSelector(state => state.configFilterData.searchText);

  const monitorBomSequence = () => {
    if (modelData) {
      let tabChaidsList = window.uiSpecData;
      let nonSortedArray = [];
      let indexOfMonitor =
        tabChaidsList.UISpec[0].sections.findIndex(
          section => section.id === "monitoringAccesories"
        ) || -1;

      if (indexOfMonitor > -1) {
        nonSortedArray = tabChaidsList.UISpec[0].sections[
          indexOfMonitor
        ].choices.filter(
          item => item.id === "ASCM_3242660" || item.id === "ASCM_64283"
        );

        let choicesWithRecommendedItems = [];
        let choicesWithNonRecommendedItems = [];

        tabChaidsList.UISpec[0].sections[indexOfMonitor].choices
          .filter(choice => !["ASCM_3242660", "ASCM_64283"].includes(choice.id))
          .map(choice =>
            modelData.Chaids.filter(chaid => {
              let isRecommendedItems = false;
              if (chaid.chaId === choice.id) {
                isRecommendedItems = modelData.Items.some(
                  (item, index) =>
                    index >= chaid.firstItem &&
                    index <= chaid.lastItem &&
                    Number(item.priority) >= 1 &&
                    Number(item.priority) <= 5
                );
                if (isRecommendedItems) {
                  choicesWithRecommendedItems.push(choice);
                } else {
                  choicesWithNonRecommendedItems.push(choice);
                }
              }
            })
          );
        choicesWithRecommendedItems = choicesWithRecommendedItems.sort((a, b) =>
          a.title > b.title ? 1 : -1
        );
        choicesWithNonRecommendedItems = choicesWithNonRecommendedItems.sort(
          (a, b) => (a.title > b.title ? 1 : -1)
        );

        let finalSortedArray = [
          ...nonSortedArray,
          ...choicesWithRecommendedItems,
          ...choicesWithNonRecommendedItems
        ];
        finalSortedArray.map(
          (value, index) => (value.sequence = (index + 1) * 5)
        );
        window.uiSpecData.UISpec[0].sections[indexOfMonitor].choices =
          finalSortedArray;
      }
    }
  };
  useEffect(() => {
    let additionItems = [];
    let topRecommendedItems = [];
    let additionItemsPartNo = [];
    let topRecommendedItemsPartNo = [];

    filteredChoices.forEach((chaid, index) => {
      if (chaid) {
        if (
          (chaid.chaId.includes("EUCPQ") || chaid.chaId.includes("ELECTCPQ")) &&
          !shouldHideChoices(ocfgParams?.hideChoice, chaid.chaId) &&
          chaid.visible
        ) {
          topRecommendedItems = [
            ...chaid.items.filter(item => item.priority <= 5 && item.visible)
          ];
          additionItems = [
            ...chaid.items.filter(item => item.priority > 5 && item.visible)
          ];

          topRecommendedItemsPartNo =
            topRecommendedItems && topRecommendedItems.map(item => item.partno);
          additionItemsPartNo =
            additionItems && additionItems.map(item => item.partno);
        }
      }
      const conflictFilterPartNo = conflictFilter.map(item => item.partNo);
      if (
        conflictFilterPartNo.some(item =>
          topRecommendedItemsPartNo.includes(item)
        )
      ) {
        setCarePacksErr("topRecommend");
      }
      if (conflictFilterPartNo.some(item => additionItemsPartNo.includes(item)))
        setCarePacksErr("additional");
    });
  }, [filteredChoices]);

  useEffect(() => {
    if (ocfgParams?.hideTab) {
      const hideTabs = ocfgParams.hideTab;
      for (const hideTab in hideTabs) {
        setVisibilityTabs(visibilityTabs => {
          return {
            ...visibilityTabs,
            [hideTab]: hideTabs?.[hideTab] === "Y"
          };
        });
      }
    }
    if (ocfgParams?.hideChoice) {
      const hideChoices = ocfgParams.hideChoice;
      for (const hideChoice in hideChoices) {
        let ind = modelData.Chaids.findIndex(
          chaid => hideChoices[hideChoice] === "Y" && chaid.chaId === hideChoice
        );
        if (ind > -1) modelData.Chaids[ind].visible = false;
      }
    }
    monitorBomSequence();
  }, [ocfgParams]);

  useEffect(() => {
    let tabChaidsList;
    const nonCompliantTabsForEMEA = [
      "Manageability Services",
      "Configuration Service"
    ];
    if (modelData) {
      tabChaidsList = window.uiSpecData;
      let stepperData = tabChaidsList.UISpec[0].sections;
      const filterChaids = accordions => {
        let filteredChaids =
          modelData &&
          modelData.Chaids &&
          modelData.Chaids.filter(
            chaid =>
              (chaid.precon >= 0 ||
                chaid.isOverridePrecon ||
                chaid.required ||
                chaid.selcon) &&
              chaid.visible &&
              accordions &&
              accordions.some(
                acc => acc.id === chaid.chaId && acc.id !== "CS_C"
              )
          ).filter(chaid =>
            chaid.required ||
            chaid.selcon ||
            modelData.Items.some(
              part =>
                part.partno !== "" &&
                part.visible === true &&
                part.inode >= chaid.firstItem &&
                part.inode <= chaid.lastItem
            )
              ? (chaid.isUsed = true)
              : (chaid.isUsed = false)
          );
        return filteredChaids || [];
      };
      stepperData.forEach(stepper => {
        // TODO: for now 'isComplete is set to true directly,
        // once the validation is integrated then this needs to be updated
        stepper["isComplete"] = validateStepper(modelData, stepper);
        stepper["isWarning"] = validateWarning(
          stepper,
          [...chaiItemsRadioList, ...chaiItems],
          modelData,
          defaultConfigStepSlected
        );
        const tabsMap = {
          hardware: "hideHardwareTab",
          monitoringAccesories: "hideMonitorsAccessoriesTab",
          carePacks: "hideCarePacksTab",
          configurationService: "hideConfigurationServicesTab",
          deploymentServices: "hideDeploymentServicesTab",
          manageabilityService: "hideManageabilityServicesTab"
        };
        const hideCurrentTab = visibilityTabs[tabsMap[stepper.id]];
        if (hideCurrentTab) {
          stepper.doNotDisplay = hideCurrentTab;
        } else {
          let accordions = [...stepper.choices];
          let result = filterChaids(accordions);
          stepper.doNotDisplay = result.length === 0;
        }
      });
      dispatch({
        type: LOAD_CONFIG_STEPPER,
        steppers: stepperData
      });
    }

    if (steppers && steppers[activeStep].doNotDisplay) {
      setActiveStep(0);
      dispatch({
        type: CONFIG_STEP_SELECTION,
        selectedStep: steppers[0].id
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelData, configRegion, userType, dispatch, visibilityTabs]);

  const handleStep = stepIndex => () => {
    if (stepIndex !== activeStep) {
      dispatch({ type: SET_SEARCHED_TEXT, payload: "" });
    }
    if (activeStep === stepIndex) {
      focusNextError(filteredChoices, modelData);
    }
    setActiveStep(stepIndex);
    if (stepIndex !== activeStep) {
      dispatch({
        type: CONFIG_STEP_SELECTION,
        selectedStep: steppers[stepIndex].id
      });
    }
    const stepperList = steppers?.map(stepper => {
      if (stepper.isWarning) return stepper.id;
    });
    props.setStepperList(stepperList);
  };

  const renderWarningIcon = (title, index) => {
    props.getStepDetails(title);
    return <ErrorOutlineIcon className={classes.warningIcon} label={index} />;
  };

  useEffect(() => {
    if (bomSelected) {
      scrollToChoice(bomSelected);
    }
  }, [bomSelected]);

  const chaidMap = [];
  for (let choice of filteredChoices) {
    chaidMap[choice.chaId] = choice.chaDes;
  }
  let conflicts = [];
  let plcConflicts = [];
  let gaDateConflicts = [];
  if (
    modelData?.conflicts &&
    modelData?.conflicts.length &&
    modelData?.conflicts[0].icConflicts
  ) {
    conflicts = modelData.conflicts[0].icConflicts.filter(
      icConflict => icConflict.code === "ERR04" || icConflict.code === "ERR03"
    );

    plcConflicts = modelData.conflicts[0]?.plcConflicts?.filter(
      plcConflict => plcConflict.code === "ERR06"
    );

    gaDateConflicts = modelData.conflicts[0]?.gaDateConflicts?.filter(
      plcConflict => plcConflict.code === "ERR07"
    );
    conflicts = [...conflicts, ...plcConflicts, ...gaDateConflicts];
  }
  if (
    modelData &&
    modelData?.conflicts &&
    modelData?.conflicts[0]?.itemConflicts?.effect &&
    modelData?.conflicts[0].itemConflicts.effect[0] &&
    conflicts.indexOf(modelData.conflicts[0].itemConflicts.effect[0]) === -1
  ) {
    conflicts.push(modelData.conflicts[0].itemConflicts.effect[0]);
  }
  if (
    modelData &&
    modelData?.conflicts &&
    modelData?.conflicts[0] &&
    modelData?.conflicts[0].counterConflicts[0] &&
    conflicts.indexOf(modelData.conflicts[0].counterConflicts[0]) === -1
  ) {
    conflicts.push(modelData.conflicts[0].counterConflicts[0]);
  }
  if (
    modelData &&
    modelData?.conflicts[0]?.hiddenChoiceConflicts &&
    modelData?.conflicts[0]?.itemConflictsList?.length === 0 &&
    modelData?.conflicts[0]?.plcConflicts?.length === 0 &&
    modelData?.conflicts[0]?.counterConflicts?.length === 0 &&
    modelData?.conflicts[0]?.hiddenChoiceConflicts[0]?.cause &&
    conflicts.indexOf(modelData.conflicts[0].hiddenChoiceConflicts[0].cause) ===
      -1
  ) {
    conflicts.push(modelData?.conflicts[0]?.hiddenChoiceConflicts[0]?.cause);
  }
  if (
    modelData?.conflicts[0]?.choiceConflicts &&
    modelData?.conflicts[0]?.choiceConflicts[0]?.effectChoice &&
    conflicts.indexOf(
      modelData.conflicts[0].choiceConflicts[0].effectChoice
    ) === -1
  ) {
    conflicts.push(modelData?.conflicts[0]?.choiceConflicts[0]?.effectChoice);
  }
  if (defaultConfigStepSlected === "carePacks") {
    const mandCarepacks = [];
    const otherCarepacks = [];
    let chaid = [];
    filteredChoices?.forEach(chaid => {
      if (chaid && chaid.chaId.startsWith("CAPQ_")) {
        const item = getLocalizedMandaCPItem(modelData, chaid);
        chaid.priority = Number(item?.priority);
        mandCarepacks.push(chaid);
      } else {
        otherCarepacks.push(chaid);
      }
    });
    mandCarepacks.sort(compare);

    chaid = [...otherCarepacks, ...mandCarepacks];
    chaid = chaid.map(choice => choice.chaId);
    if (conflicts.length > 0 && chaid.length > 0) {
      conflicts.sort((a, b) => chaid.indexOf(a.chaId) - chaid.indexOf(b.chaId));
    }
  } else {
    if (conflicts.length > 0 && chaids.length > 0) {
      conflicts.sort(
        (a, b) => chaids.indexOf(a.chaId) - chaids.indexOf(b.chaId)
      );
    }
  }
  const conflictFilter =
    steppers?.reduce((acc, step) => {
      if (step.id === defaultConfigStepSlected) {
        const visibleConflicts = conflicts?.reduce((res, item) => {
          if (defaultConfigStepSlected === "hardware") {
            if (data.some(_ => _.chaId === item.chaId)) {
              res = [...res, item];
            }
          } else {
            if (step.choices.some(_ => _.id === item.chaId)) {
              res = [...res, item];
            }
          }
          return res;
        }, []);
        acc = [...acc, ...visibleConflicts];
      }
      return acc;
    }, []) || [];

  useEffect(() => {
    if (conflictFilter.length > 0 && !checkedFilterParts) {
      if (expandAll.length > 0 && defaultConfigStepSlected) {
        const relevantExpandData = expandAll.find(
          expandedData => expandedData.tab === defaultConfigStepSlected
        );
        if (
          relevantExpandData &&
          relevantExpandData.choicesExpandState &&
          !searchText
        ) {
          const allChoiceCollapsed = Object.values(
            relevantExpandData.choicesExpandState
          ).every(choice => !choice);
          relevantExpandData.choicesExpandState[conflictFilter[0].chaId] = true;
          const expand = getAllDataExpandedOrCollapsedManul(
            relevantExpandData.choicesExpandState
          )[0]
            ? true
            : getAllDataExpandedOrCollapsedManul(
                relevantExpandData.choicesExpandState
              )[1]
            ? false
            : relevantExpandData.expand;

          const payload = {
            tab: defaultConfigStepSlected,
            expand,
            choicesExpandState: relevantExpandData.choicesExpandState,
            flag: true
          };
          if (allChoiceCollapsed) {
            dispatch({
              type: UPDATE_TAB_EXPAND,
              payload
            });
          }
        }
      }
    }
  }, [conflictFilter]);
  const currentStepError = (title, index) => {
    props.getStepDetails(title);
    return conflictFilter.map(item => {
      return (
        <div
          className={classes.errorMessage}
          key={item.chaId}
          onClick={() => scrollToChoice(item.chaId, carePacksErr)}>
          <Box display="flex" justifyContent="center">
            <Typography style={{ fontSize: "18px" }}>
              {t("common:configurator:pleaseCheck")}
            </Typography>
            &nbsp;
            <Typography className={classes.errorColor} key={item.chaId}>
              {carePacksErr === "topRecommend" &&
              defaultConfigStepSlected === "carePacks"
                ? "Top Recommended Care Packs"
                : carePacksErr === "additional" &&
                  defaultConfigStepSlected === "carePacks"
                ? "Additional Care Packs"
                : item.chaId.includes("CAPQ")
                ? t("common:configurator:plcConflictManda") + " " + item.chaDes
                : item.chaDes}
            </Typography>
            &nbsp;
            <Typography style={{ fontSize: "18px" }}>
              {t("common:configurator:selectionToResolveIssue")}
            </Typography>
          </Box>
        </div>
      );
    })[0];
  };

  useEffect(() => {
    if (steppers) {
      let currentErrorSet = [];
      let restOfErrorSet = [];
      steppers.forEach((data, index) => {
        if (data.id === defaultConfigStepSlected) {
          const stepProps = {};
          const buttonProps = {};
          if (!data.isComplete) {
            stepProps.error = "";
            buttonProps.error = "";
            buttonProps.erroricon = "";
            if (!data.doNotDisplay) {
              currentErrorSet.push(currentStepError(data.id, index));
            }
          }
        } else {
          const stepProps = {};
          const buttonProps = {};
          if (!data.isComplete) {
            stepProps.error = "";
            buttonProps.error = "";
            buttonProps.erroricon = "";
          }
          if (!data.doNotDisplay && !data.isComplete) {
            restOfErrorSet.push(data);
          }
        }
      });
      setCurrentErr(currentErrorSet);
      setRestOfErr(restOfErrorSet);
    }
  }, [
    steppers,
    defaultConfigStepSlected,
    modelData,
    filteredChoices,
    carePacksErr
  ]);

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        className={classes.stepper}>
        {steppers &&
          steppers.map((data, index) => {
            const stepProps = {};
            const stepLabelProps = {};
            const buttonProps = {};
            if (!data.isComplete) {
              stepProps.error = "";
              stepLabelProps.error = true;
              buttonProps.error = "";
              buttonProps.erroricon = "";
            }
            const isDisplay = data.doNotDisplay;
            return !isDisplay ? (
              <Step key={data.id} {...stepProps}>
                <StepButton
                  className={
                    activeStep === index && !data.isComplete
                      ? classes.buttonStepperError
                      : activeStep === index
                      ? classes.activeButtonStepper
                      : classes.buttonStepper
                  }
                  onClick={handleStep(index)}
                  icon={
                    !data.isComplete ? (
                      <CancelOutlinedIcon
                        color="error"
                        className={classes.iconSizeError}
                        label={index}
                      />
                    ) : data.isWarning && showBOMSectionFlag ? (
                      renderWarningIcon(data.id, index)
                    ) : (
                      <CheckCircleOutlineIcon
                        className={classes.iconSize}
                        label={index}
                      />
                    )
                  }
                  disableRipple
                  {...buttonProps}>
                  <StepLabel {...stepLabelProps} className={classes.labelBox}>
                    {!data.isComplete && activeStep === index ? (
                      <Typography
                        title={data.title}
                        className={classes.labelStepperError}>
                        {" "}
                        {data.title}
                      </Typography>
                    ) : data.isWarning && showBOMSectionFlag ? (
                      <Typography
                        title={data.title}
                        className={classes.labelStepperWarning}>
                        {" "}
                        {data.title}
                      </Typography>
                    ) : activeStep === index ? (
                      <Typography
                        title={data.title}
                        className={classes.activeLabelStepper}>
                        {" "}
                        {data.title}
                      </Typography>
                    ) : (
                      <Typography
                        title={data.title}
                        className={classes.labelStepper}>
                        {" "}
                        {data.title}
                      </Typography>
                    )}
                  </StepLabel>
                </StepButton>
              </Step>
            ) : null;
          })}
      </Stepper>
      <br />
      <div className={classes.root}>
        {gaDateConflicts?.length === 0 &&
          currentConfigID &&
          props.configStartDate &&
          props.configStartDate !== "" &&
          defaultConfigStepSlected === "hardware" &&
          ((showGADate && isConfigOverDate) || haveFutureItem) && (
            <>
              <Box display="flex" justifyContent="center">
                <Typography style={{ fontSize: "18px" }}>
                  {t("common:GADate.text1").replace("{0}", moment(maxGaDate || props.configStartDate).format(
                      "DD MMMM YYYY"
                    ))}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography style={{ fontSize: "18px" }}>
                {t("common:GADate.text2")}
                </Typography>
              </Box>
            </>
          )}
        {currentErr.length > 0 || restOfErr.length > 0 ? (
          <Box display="flex" justifyContent="center">
            <Typography style={{ fontSize: "18px" }}>
              {t("common:configurator:issueWithCurrent")}
            </Typography>
          </Box>
        ) : null}
        {currentErr.map(errP => {
          return errP;
        })}
        {restOfErr.length > 0 ? (
          <Box display="flex" justifyContent="center">
            <Typography style={{ fontSize: "18px" }}>
              {t("common:configurator:pleaseCheck")}{" "}
            </Typography>
            &nbsp;
            {restOfErr.map((data, index) => {
              return (
                <Typography className={classes.errorColor} key={index}>
                  {data && data?.title
                    ? data.title +
                      (index === restOfErr.length - 1
                        ? ""
                        : index === restOfErr.length - 2
                        ? " and"
                        : ", ")
                    : ""}
                  &nbsp;
                </Typography>
              );
            })}
            &nbsp;
            <Typography style={{ fontSize: "18px" }}>
              {" "}
              {t("common:configurator:tabForOtherIssue")}
            </Typography>
          </Box>
        ) : null}
      </div>
    </div>
  );
}
