// import { EdgeType_GTE_0, EdgeType_I_SEL } from "./Constants.js";
import { updateCarepacksMANDA } from "./applyLocalizationManda.js";
import { conflictDetection } from "./conflictDetection";
import { MANDACarepackChaidPreffix, MonLocChaids } from "./Constants";
import { processInput } from "./processInput";
import {
  getDeselItemsList,
  getIChaid,
  getTechAvItems,
  setChoiceConflicts,
  setDeselItemsList,
  setHiddenChoiceConflicts,
  setItemConflictsNn,
  setTechAvItems
} from "./processOutput";
import {
  getEffects,
  printConflicts,
  printModelUpdate,
  segregatePayload,
  selectSingleAvailableItemUnderRequiredChoice,
  setSameConflictItems,
  updateCarePacks,
  updateVisibilityForGrayoutItems
} from "./util.js";
// const this._rawModel = require("./test/test/before.json");
const fs = require("fs");

// console.log(
//   `Model attribute(${Object.keys(this._rawModel).length}): ${Object.keys(this._rawModel)
//     .sort()
//     .join(", ")}`
// );
const DO_DESELECT = -1;
const DO_SELECT = 1;
function OneModel(rawModel) {
  this._rawModel = rawModel;
  this.Nodes = rawModel.Nodes;
  this.Items = rawModel.Items;
}

const doesElementExistInListTechav = (elm, techAvChoiceItems) => {
  let flag = false;
  techAvChoiceItems?.forEach(each => {
    if (each?.cause?.chaId === elm) {
      flag = true;
    }
  });
  return flag;
};
const doesElementExistInTechavChoiceItems = (elm, modelTechAv) => {
  return modelTechAv?.some(each => each.partno === elm) || false;
};
const deselectionTechAvChoice = model => {
  let payloads = [];
  if (model.techAvChoiceItems?.length > 0) {
    model.techAvChoiceItems?.forEach(techAvItem => {
      model?.Items.forEach(item => {
        if (
          techAvItem.partNo === item.partno &&
          item.selected &&
          item.valueId?.length > 0
        ) {
          const node = model.Nodes[item.inode];
          const payload = {
            inputEdge: node.type,
            inputNode: node.id,
            changeIn: DO_DESELECT
          };
          payloads = [...payloads, payload];
        }
      });
    });
    model.techAvChoiceItems = [];
  }
  if (payloads.length !== 0) {
    if (localStorage.getItem("isDebug") === "true") {
      console.log("PAYLOADS Deselection", payloads);
    }
    payloads.forEach(iPayLoad => {
      processInput(iPayLoad, model);
    });
  }
  return;
};
const selectTechAVunderChoice = (model, techAvlist) => {
  let payloads = [];
  techAvlist?.forEach(techAv => {
    for (let i = 0; i < model.Items.length; i++) {
      if (
        techAv.partNo === model.Items[i].partno &&
        model.Items[i].valueId?.length > 0 &&
        !model.Items[i].selected &&
        model.Items[i].precon >= 0
      ) {
        const node = model.Nodes[model.Items[i].inode];
        const payload = {
          inputEdge: node.type,
          inputNode: node.id,
          changeIn: DO_SELECT
        };
        payloads = [...payloads, payload];
        break;
      }
    }
  });
  //techavadding
  techAvlist.forEach(techItem => {
    if (
      techItem?.partNo !== techItem?.cause?.partno &&
      !doesElementExistInTechavChoiceItems(
        techItem.partno,
        model.techAvChoiceItems
      )
    ) {
      const techAvChoiceItems = {
        partNo: techItem.partNo,
        chaId: techItem.chaId,
        itemInode: techItem.itemInode,
        selected: true,
        cause: techItem.cause
      };
      if (model?.techAvChoiceItems) {
        model.techAvChoiceItems = [
          ...model?.techAvChoiceItems,
          techAvChoiceItems
        ];
      } else {
        model.techAvChoiceItems = [techAvChoiceItems];
      }
    }
  });
  if (payloads.length !== 0) {
    if (localStorage.getItem("isDebug") === "true") {
      console.log("PAYLOADS", payloads);
    }
    payloads.forEach(payload => {
      processInput(payload, model);
    });
  }
  return;
};
OneModel.prototype.updateSelections = function (changes) {
  /*
  ProcessInput(change)
    - find Node (from change.nodeID)
    - switch edgeType -- find changeOut1, changeOut2, changeOut3, update a & g attributes as needed
    - process edge in loop, for every edge
        * find change & enableOutput value
        * set subPayload
        * switch (path), based on path values call PathXXX (subPayload)
  
  PathXXX ()
    - go through the nodes in loop, and call PropagateChange

  PropagateChange
    - if edgeType = 64 -- ProcessInput (recursive call)
    - else call ProcessOutput

  ProcessOutput
    - logic based on edgeType
    
  */

  // console.log(`\nupdateSelectionss ${changes}`);
  // const updateNode = change => {
  //   //https://github.azc.ext.hp.com/OneConfig-212852/kb-engine-service/blob/41c2a1afd9c56531f5e27a4fd34f82a1260717f4/src/model/neural-net.go
  //   //This function is replacement of ProcessInput defined in the link -- by simplifying only for const EdgeType_GTE_0
  //   console.log(`=> updateNode ......................................`);
  //   console.log(change);

  //   //find the node based on nodeID
  //   const node = this.Nodes[change.nodeID];

  //   switch (change.type) {
  //     case EdgeType_GTE_0: // type = 2
  //       const pGate = node.g;
  //       node.g = node.g + change.value;
  //       const changeOut =
  //         pGate >= 0 ? (node.g >= 0 ? 0 : -1) : node.g >= 0 ? 1 : 0;
  //       break;
  //   }
  //   //process the node itself (update g value)

  //   const processEdge = edge => {
  //     const edgeTypeH = parseInt(edge.type, 16);
  //     switch (edgeTypeH) {
  //       case EdgeType_I_SEL:
  //     }
  //   };
  //   //process the node neighbours
  //   node.edges.forEach(e => {
  //     console.log("edge", e);
  //   });
  // };

  // changes.forEach(c => {
  //   updateNode(c);
  // });

  changes.sort(function (a, b) {
    return a.value - b.value;
  });
  for (let i = 0; i < changes.length; i++) {
    const item = changes[i];
    const payload = {
      iNode: item.nodeID,
      changeIn: item.value,
      iEdge: item.edgeType
    };
    processInput(payload, this._rawModel);
  }
  printConflicts(this._rawModel);
};

OneModel.prototype.printStats = function () {
  if (localStorage.getItem("isDebug") === "true") {
    console.log(
      `\n> Chaids (${this.Chaids.length}), attributes: ${Object.keys(
        this.Chaids[0]
      )
        .sort()
        .join(", ")}`
    );
    console.log(
      `> Nodes (${this.Nodes.length}), attributes: ${Object.keys(this.Nodes[0])
        .sort()
        .join(", ")}`
    );
    console.log(
      `> Items (${this.Items.length}), attributes: ${Object.keys(this.Items[0])
        .sort()
        .join(", ")}`
    );
  }
};

//src/main-webasm.go    use
//src/webasm/validate.go   define
OneModel.prototype.updateModelWithMultipleChanges = function (payload) {
  localStorage.getItem("showTime") === "true" &&
    console.time("TIME: updateModelWithMultipleChanges");
  if (localStorage.getItem("isDebug") === "true") {
    console.log("updateModelWithMultipleChanges", payload);
  }
  payload.itemChanges.sort(function (a, b) {
    return a.changeIn - b.changeIn;
  });

  let updatedModel = Object.assign({}, this._rawModel);
  if (updatedModel?.conflicts?.length > 0) {
    if (updatedModel.conflicts[0].itemConflictsList?.length > 0) {
      setItemConflictsNn(
        updatedModel?.conflicts[0] &&
          updatedModel?.conflicts[0]?.itemConflictsList
      );
    }
    if (updatedModel.conflicts[0].hiddenChoiceConflicts?.length > 0) {
      setHiddenChoiceConflicts(
        updatedModel?.conflicts[0] &&
          updatedModel?.conflicts[0]?.hiddenChoiceConflicts
      );
    }
    if (updatedModel.conflicts[0].choiceConflicts?.length > 0) {
      setChoiceConflicts(
        updatedModel?.conflicts[0] &&
          updatedModel?.conflicts[0]?.choiceConflicts
      );
    }
  }
  for (let i = 0; i < payload.itemChanges.length; i++) {
    const iPayLoad = payload.itemChanges[i];
    const processInputResult = processInput(iPayLoad, updatedModel);
    if (localStorage.getItem("isDebug") === "true") {
      console.log("Process input result", processInputResult);
    }
    updatedModel = processInputResult;
    const { Ichaid, ChaId, ChaDes } = getIChaid(
      iPayLoad.inputNode,
      updatedModel
    );
    if (
      iPayLoad?.changeIn == DO_DESELECT &&
      MonLocChaids.includes(MonLocChaids, ChaId)
    ) {
      if (updatedModel.Items[iPayLoad.inputNode].partno.includes("#")) {
        const itemBasePart =
          updatedModel.Items[iPayLoad.inputNode].partno?.split("#")[0];
        updatedModel = updateCarepacksMANDA(
          updatedModel,
          MANDACarepackChaidPreffix + itemBasePart
        );
      }
    }
  }
  printModelUpdate(updatedModel);
  const getDeselItems = getDeselItemsList();
  //Select Techav part numbers under choice if added in BOM
  if (getDeselItems && getDeselItems > 0) {
    const { Ichaid, ChaId, ChaDes } = getIChaid(
      getDeselItemsList(),
      updatedModel
    );
    if (doesElementExistInListTechav(ChaId, updatedModel?.techAvChoiceItems)) {
      deselectionTechAvChoice(updatedModel);
    }
    setDeselItemsList();
  }

  const techav = getTechAvItems();
  setTechAvItems();
  if (techav?.length > 0) {
    selectTechAVunderChoice(updatedModel, techav);
  }
  //SetSameConflictItems will run except first load, will capture iNodes with same cause & effect
  if (payload.itemChanges?.length > 0 && payload.itemChanges[0].inputNode) {
    setSameConflictItems(updatedModel);
    updatedModel = updateVisibilityForGrayoutItems(updatedModel);
  }
  selectSingleAvailableItemUnderRequiredChoice(updatedModel);
  updatedModel = conflictDetection(updatedModel);
  if (!(payload.itemChanges?.length > 0 && payload.itemChanges[0].inputNode)) {
    getEffects(updatedModel, updatedModel?.conflicts);
  }
  printConflicts(updatedModel);
  localStorage.getItem("showTime") === "true" &&
    console.timeEnd("TIME: updateModelWithMultipleChanges");
  return updatedModel;
};

OneModel.prototype.updateQuantity = function (payload) {
  let payloads = [];
  if (localStorage.getItem("isDebug") === "true") {
    console.log("updateQuantity function start", payload);
  }
  let qtyPayload = {};
  for (let i = 0; i < payload?.partQty?.length; i++) {
    const iPayLoad = payload.partQty[i];
    qtyPayload[iPayLoad.partInode] = iPayLoad.qty;
  }

  let updatedModel = Object.assign({}, this._rawModel);
  for (let i = 0; i < updatedModel?.Items?.length; i++) {
    const item = updatedModel?.Items[i];
    const itemInode = String(item.inode);
    let node = updatedModel.Nodes[item.inode];
    let qty = qtyPayload[itemInode];
    if (Object.keys(qtyPayload).includes(itemInode)) {
      updatedModel.Items[i].quantity = qty;
      if (qty && !item.selected) {
        let iPayLoad = {
          inputEdge: node.type,
          inputNode: node.id,
          changeIn: 1
        };
        payloads = [...payloads, iPayLoad];
      }

      if (qty == 0 && item.selected) {
        let iPayLoad = {
          inputEdge: node.type,
          inputNode: node.id,
          changeIn: -1
        };
        payloads = [...payloads, iPayLoad];
      }
    }
  }
  if (payloads?.length > 0) {
    let iPayload = {
      itemChanges: payloads
    };
    const oneModel = new loadOneModel(updatedModel);
    const { cpPayload, nonCpPayload, chaId } = segregatePayload(
      iPayload,
      updatedModel
    );
    localStorage.getItem("isDebug") === "true" &&
      console.log("cpPayload segregatePayload", cpPayload, chaId, nonCpPayload);
    if (cpPayload?.itemChanges != 0) {
      updatedModel = updateCarePacks(cpPayload, updatedModel, chaId);
    }
    updatedModel = oneModel.updateModelWithMultipleChanges(
      nonCpPayload,
      updatedModel
    );
  }
  if (localStorage.getItem("isDebug") === "true") {
    console.log("updateQuantity function end");
  }
  return updatedModel;
};

export const loadOneModel = OneModel;
// let payload = {
//   itemChanges: [
//     {
//       changeIn: -1,
//       inputEdge: 2,
//       inputNode: 811
//     },
//     {
//       changeIn: 1,
//       inputEdge: 2,
//       inputNode: 809
//     }
//   ]
// };
// const { cpPayload, nonCpPayload, chaId } = segregatePayload(payload, this._rawModel);
// if (cpPayload.length > 0) {
//   updateCarePacks(cpPayload, this._rawModel, chaId);
// }
// console.log("noncpPayload,", nonCpPayload);
// let outputModel = x.updateModelWithMultipleChanges(nonCpPayload);
// let content = null;
// try {
//   content = JSON.stringify(outputModel);
// } catch {
//   console.log("stringify outputModel failed!");
// }
// if (content) {
//   fs.writeFile("./output.json", content, err => {
//     if (err) return console.log(err);
//     console.log("outputModel was saved to file!");
//   });
// }
