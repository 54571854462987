import { useLazyQuery } from "@apollo/client";
import Box from "@material-ui/core/Box";
import green from "@material-ui/core/colors/green";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles, styled } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import LaptopMac from "@material-ui/icons/LaptopMac";
import RemoveIcon from "@material-ui/icons/Remove";
import { withSnackbar } from "notistack";
import React, { Fragment, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import ExternalProps from "../../../contexts/externalPropsContext";
import { OVERRIDE_CONFIG, SAVE_CONFIG } from "../../../GraphQL";
import ClipboardField from "../../../lib/common/ClipboardField";
import CpqButton from "../../../lib/common/CpqButton";
import TableSkeleton from "../../../lib/common/TableSkeleton";
import {
  displayStyledCurrencyLite,
  getCommonRequestConfig
} from "../../../lib/common/util";
import CpqConfirmationDialog from "../../opportunity/CpqConfirmationDialog";
import UploadLooseParts from "./UploadLooseParts";

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1)
  },
  rightButton: {
    padding: "5px"
  },
  backDrop: {
    background: "rgba(0,0,0,0)"
  },
  headStyle: {
    fontSize: 15,
    fontWeight: "bold"
  },
  listHeader: {
    height: "fit-content"
  },
  listItem: {
    height: "fit-content"
  },
  notes: {
    color: "red"
  }
}));

const StyledGrid = styled(props => <Grid container spacing={1} {...props} />)({
  alignItems: "center"
});

const UploadConfiguration = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [alert, setAlert] = useState(false);

  const [isShowTable, SetIsShowTable] = useState(false);
  const quote = useSelector(state => state.quote);
  const { bomList, currentStep } = useSelector(state => state.uploadConfig);
  const { isPartnerPortalFlag, countryCode } = useSelector(
    state => state.productSelector
  );
  const { transactionID, routeToMarket, pricingGeo, incoterms, mDCPOrgID } =
    getCommonRequestConfig(quote, countryCode, isPartnerPortalFlag);
  const [stepType, setStepType] = useState("validate");
  const [loading, setLoading] = useState(false);
  const { currencyCode = "USD" } = React.useContext(ExternalProps);
  const headCells = [
    {
      width: "1"
    },
    {
      width: "2"
    },
    {
      width: "4"
    },
    {
      width: "1"
    },
    {
      width: "1"
    },
    {
      width: "2"
    },
    {
      width: "1"
    }
  ];
  const columns = [
    {
      id: "quantity",
      label: "Quantity",
      displayAs: "quantity",
      visible: true
    },
    {
      id: "name",
      label: "Part Number",
      displayAs: "chip",
      visible: true
    },
    {
      id: "description",
      label: "Description",
      displayAs: "description",
      visible: true
    },
    {
      id: "confirm",
      label: "",
      displayAs: "confirm",
      visible: stepType === "override" || stepType === "save"
    },
    {
      id: "listPrice",
      label: "List Price",
      displayAs: "currency",
      visible: true
    },
    {
      id: "notes",
      label: "Notes",
      displayAs: "notes",
      visible: true
    },
    {
      id: "override",
      label: "Override",
      displayAs: "override",
      visible: stepType === "override" || stepType === "save"
    }
  ];
  const rows =
    bomList && bomList[0]?.partno && bomList.length
      ? bomList.map(item => ({
          quantity: item.quantity ? item.quantity : "1",
          partNumber: item.partno,
          description: item.partdes,
          notes: item.notes,
          listPrice: Number(item.listPrice)
        }))
      : [];
  const [overRideConfigurations] = useLazyQuery(OVERRIDE_CONFIG, {
    fetchPolicy: "no-cache",
    onCompleted({ overrideConfig }) {
      const { bomItems, looseBomItems } =
        overrideConfig && overrideConfig.length ? overrideConfig[0] : {};
      batch(() => {
        dispatch({
          type: "SET_VALIDATE_UPLOAD_CONFIG",
          payload: looseBomItems
        });
        dispatch({
          type: "SET_BOM_ITEMS",
          payload: bomItems
        });
      });
      setAlert(false);
      setStepType("save");
    },
    onError() {
      const {
        data: { overrideConfig }
      } = require("../../../data/overRideConfig.json");
      const { bomItems, looseBomItems } =
        overrideConfig && overrideConfig.length ? overrideConfig[0] : {};
      batch(() => {
        dispatch({
          type: "SET_VALIDATE_UPLOAD_CONFIG",
          payload: looseBomItems
        });
        dispatch({
          type: "SET_BOM_ITEMS",
          payload: bomItems
        });
      });
      setAlert(false);
      setStepType("save");
    }
  });
  const [saveConfigurations] = useLazyQuery(SAVE_CONFIG, {
    fetchPolicy: "no-cache",
    onCompleted({ saveConfig }) {}
  });
  //remove handleUploadConfiguration-validateUploadConfigurations related code, due to no use anymore

  const handleDelete = () => {};
  const handleSaveConfiguration = () => {
    setStepType("addToQuote");
    saveConfigurations({
      variables: {
        filter: {
          priceDescriptor: `${pricingGeo};${currencyCode};${incoterms}`,
          routeToMarket,
          customerID: mDCPOrgID,
          partnerID:
            quote && quote.record
              ? quote.record.locationID_PrimaryReseller_channel_quote
              : "",
          sessionID: "SAMPLE_OCSInternalSessionID",
          transactionID: "test",
          lineItems: {
            bom: [
              {
                isOveridden: false,
                name: "4HF92AV"
              },
              {
                isOveridden: false,
                name: "4HF98AV"
              },
              {
                isOveridden: false,
                name: "2ZZ55AV"
              }
            ],
            looseBom: [
              {
                isOveridden: false,
                name: "4HF92AV"
              },
              {
                isOveridden: false,
                name: "4HF98AV"
              },
              {
                isOveridden: false,
                name: "2ZZ55AV"
              }
            ]
          }
        }
      }
    });
  };
  const handleAddtoQuote = () => {
    dispatch({
      type: "SET_ACTIVE_STEP",
      payload: currentStep + 1
    });
  };
  const handleOverride = () => {
    setChecked(true);
    setAlert(true);
  };
  const handleCancel = () => {
    setChecked(false);
    setAlert(false);
  };
  const handleConfirm = () => {
    overRideConfigurations({
      variables: {
        filter: {
          priceDescriptor: `${pricingGeo};${currencyCode};${incoterms}`,
          routeToMarket,
          customerID: mDCPOrgID,
          partnerID:
            quote && quote.record
              ? quote.record.locationID_PrimaryReseller_channel_quote
              : "",
          sessionID: "SAMPLE_OCSInternalSessionID",
          transactionID: "test",
          lineItems: [
            {
              configID: "10172038",
              components: [
                {
                  isOveridden: false,
                  name: "1JP11AV"
                }
              ]
            },
            {
              configID: "10172038",
              components: [
                {
                  isOveridden: false,
                  name: "4HF92AV"
                },
                {
                  isOveridden: false,
                  name: "4HF98AV"
                },
                {
                  isOveridden: false,
                  name: "2ZZ55AV"
                }
              ]
            }
          ]
        }
      }
    });
  };
  return !loading ? (
    <Fragment>
      <Box className="MuiPaper-outlined MuiPaper-rounded" component={Paper}>
        <List disablePadding>
          <ListItem
            className={classes.listHeader}
            alignItems="center"
            disableGutters={false}
          >
            <Grid container spacing={1}>
              {columns.map(
                (column, index) =>
                  column.visible && (
                    <Grid
                      key={column.label + index}
                      item
                      xs={column.displayAs === "description" ? 4 : 2}
                      md={
                        column.displayAs === "description"
                          ? 4
                          : column.displayAs === "chip"
                          ? 2
                          : column.displayAs === "notes"
                          ? 2
                          : 1
                      }
                    >
                      <Typography
                        variant="subtitle2"
                        align={
                          column.name || column.message || column.listPrice
                            ? "right"
                            : "left"
                        }
                      >
                        <Box fontWeight="fontWeightBold" m={1}>
                          {column.label}
                        </Box>
                      </Typography>
                    </Grid>
                  )
              )}
            </Grid>
          </ListItem>
          <Divider />
        </List>

        {rows.map(
          (item, rowIndex) =>
            (item.partNumber !== "" || item.name) && (
              <Fragment>
                <ListItem
                  key={rowIndex}
                  alignItems="center"
                  className={classes.listItem}
                >
                  <StyledGrid>
                    {columns.map(
                      (column, index) =>
                        column.visible && (
                          <Grid
                            item
                            key={index}
                            xs={column.displayAs === "description" ? 4 : 2}
                            md={
                              column.displayAs === "description"
                                ? 4
                                : column.displayAs === "chip"
                                ? 2
                                : column.displayAs === "notes"
                                ? 3
                                : 1
                            }
                          >
                            {column.displayAs === "chip" ? (
                              <Typography variant="subtitle2">
                                <ClipboardField
                                  style={{
                                    backgroundColor: "#e0e0e0",
                                    padding: 5,
                                    borderColor: "#e0e0e0",
                                    textTransform: "uppercase"
                                  }}
                                  text={item.partNumber || item.name}
                                  icon={<LaptopMac />}
                                  size="medium"
                                  color="default"
                                  variant="contained"
                                  partNumber={item.partNumber || item.name}
                                />
                              </Typography>
                            ) : column.displayAs === "override" ? (
                              rowIndex === 0 && (
                                <fragment>
                                  <Switch
                                    name="Override"
                                    checked={checked}
                                    onChange={handleOverride}
                                    color="primary"
                                  />
                                  <CpqConfirmationDialog
                                    isOpen={alert}
                                    handleCancel={handleCancel}
                                    handleConfirm={handleConfirm}
                                  />
                                </fragment>
                              )
                            ) : column.displayAs === "confirm" ? (
                              <IconButton
                                color="primary"
                                aria-label="delete"
                                onClick={handleDelete}
                              >
                                {item.status === "added" ? (
                                  <AddIcon color="primary" />
                                ) : item.status === "check" ? (
                                  <CheckIcon style={{ color: green[500] }} />
                                ) : item.status === "delete" ? (
                                  <DeleteIcon color="action" />
                                ) : item.status === "removed" ? (
                                  <RemoveIcon />
                                ) : (
                                  <CloseIcon />
                                )}
                              </IconButton>
                            ) : column.displayAs === "currency" ? (
                              <Typography
                                color={"primary"}
                                variant="subtitle2"
                                style={{ paddingLeft: 10 }}
                              >
                                {!isNaN(item.listPrice) && currencyCode}
                                {!isNaN(item.listPrice)
                                  ? displayStyledCurrencyLite({
                                      amount: `${item.listPrice}`,
                                      currencyArg: currencyCode,
                                      locale: "en-US"
                                    })
                                  : "N/A"}
                              </Typography>
                            ) : (
                              <Typography
                                color={"textPrimary"}
                                variant="subtitle2"
                                style={{ paddingLeft: 10 }}
                              >
                                {item[column.id]}
                              </Typography>
                            )}
                          </Grid>
                        )
                    )}
                  </StyledGrid>
                </ListItem>
                <Divider />
              </Fragment>
            )
        )}
      </Box>
      <Grid container justifyContent="flex-end">
        {stepType === "save" ? (
          <CpqButton
            variant="contained"
            color="primary"
            onClick={handleSaveConfiguration}
            className={classes.button}
            style={{ marginTop: 10 }}
            loading={loading}
          >
            {"Save"}
          </CpqButton>
        ) : stepType === "addToQuote" ? (
          <CpqButton
            variant="contained"
            color="primary"
            onClick={handleAddtoQuote}
            className={classes.button}
            style={{ marginTop: 10 }}
            loading={loading}
          >
            {"Add To Quote"}
          </CpqButton>
        ) : null}
      </Grid>
      {isShowTable && (
        <Grid>
          <UploadLooseParts />
        </Grid>
      )}
    </Fragment>
  ) : (
    <TableSkeleton lineNumber={10} cells={headCells} spacing={2} height={20} />
  );
};

export default withSnackbar(UploadConfiguration);
