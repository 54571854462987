import { useSelector } from "react-redux";
import CompareDialog from "./CompareDialog";
import ConfirmationDialog from "./ConfirmationDialog";
import RequestRsku from "./RequestRsku";
import RskuDialog from "./RskuDialog";
const Popups = () => {
  const { rskuDialog, compareDialog, requestSkuDialog, confirmationDialog } =
    useSelector(state => state.rskuReducer.popups);

  return (
    <>
      {rskuDialog?.open && <RskuDialog />}
      {compareDialog?.open && <CompareDialog />}
      {requestSkuDialog?.open && <RequestRsku />}
      {confirmationDialog?.open && <ConfirmationDialog />}
    </>
  );
};

export default Popups;
