import { useMutation } from "@apollo/client";
import { Checkbox, Grid } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { saveAs } from "file-saver";
import _ from "lodash";
import moment from "moment";
import { useSnackbar, withSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { togglePopupActions } from "../../../actions/togglePopupActions";
import {
  CHECKED_FILTER_PARTS,
  SET_CAREPACKS_STATE,
  SET_CONFIG_NAME_SAVE,
  SET_FILTERED_CHOICES,
  SET_FILTERED_LOC,
  SET_ORIGINAL_ACCESS_TYPE,
  SET_ORIGIN_SELECTED_ITEMS,
  SET_SAVE_CONFIG_RESPONSE,
  SET_SEARCHED_TEXT,
  SET_SHOW_ADDITIONAL,
  SET_SHOW_TOP_RECOMMEND
} from "../../../actions/Types";
import { ExternalProps } from "../../../contexts/externalPropsContext";
import { SAVE_CONFIGURATION } from "../../../GraphQL";
import CpqButton from "../../../lib/common/CpqButton";
import { checkSectionID, getStorageValue } from "../../../lib/common/util";
import exportToPdf from "../../../services/exportToPdf";
import {
  getBOMData,
  getChoices,
  getConfigBom,
  getConfigData,
  getConfigObjectDataForSave,
  getLocalizationOption,
  setModelDataForDefaultConfig
} from "../../../services/utils";
import {
  ALL_OTHER_COMPONENTS,
  CONFIGURATION_SERVICES
} from "../Rsku/Constants";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    alignItems: "left",
    justifyContent: "left",
    paddingTop: "10px"
  },
  container: {
    maxHeight: 440
  },
  topPadding: {
    paddingTop: "20px"
  },
  searchInput: {
    marginTop: "7px",
    backgroundColor: "#fafafa"
  },
  input: {
    padding: "9px !important ",
    border: "none !important",
    width: "25ch"
  },
  listItem: {
    marginTop: "8px",
    "& button span": {}
  },
  searchButtonStyle: {
    marginLeft: "10px"
  },
  button: {
    whiteSpace: "nowrap",
    marginLeft: "auto",
    label: {
      fontWeight: "normal"
    },
    [theme.breakpoints.down("sm")]: {
      width: 100,
      "& .MuiButton-label": {
        overflow: "hidden",
        "text-overflow": "ellipsis"
      }
    }
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  shortWrapper: {
    position: "absolute",
    right: "0",
    transform: "translateY(10px)",
    paddingLeft: "12px",
    width: "33.333333%"
  },
  popperContainer: {
    margin: 0,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "100%"
  },
  popperHeader: {
    order: 1,
    flexShrink: 0,
    flexBasis: "30px",
    fontSize: "0.7em",
    alignSelf: "stretch"
  },
  popperList: {
    order: 2,
    overflowY: "scroll",
    flex: 1,
    scrollbarWidth: "thin" /*Firefox*/,
    width: "calc(100% - 2px)",
    "&::-webkit-scrollbar": {
      width: "4px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgb(179, 178, 178)",
      borderRadius: "20px",
      border: "1px solid rgb(179, 178, 178)"
    }
  },
  popperFooter: {
    order: 3,
    display: "flex",
    flexDirection: "row",
    flexShrink: 0,
    alignItems: "flex-end",
    flexBasis: "30px",
    padding: "0px 0px 6px"
  },
  closeIcon: {
    cursor: "pointer",
    fontSize: "1.2rem",
    flex: "0.1",
    "&:hover": {
      color: "white",
      background: "#f50"
    }
  },
  checkedDiv: {
    display: "flex",
    border: "1px solid #29a8dd",
    marginRight: "10px",
    marginLeft: "3px",
    backgroundColor: "#ccf5ff",
    padding: "1px 3px",
    width: "105px",
    height: "33px",
    justifyContent: "center",
    alignItems: "center"
  },
  checkedItem: {
    textDecoration: "none",
    textOverflow: "ellipsis",
    fontSize: "0.75rem",
    fontWeight: "400",
    color: "black",
    position: "relative",
    verticalAlign: "middle",
    alignItems: "center",
    overflow: "hidden",
    overflowWrap: "break-word",
    display: "-webkit-box",
    "&::-webkit-line-clamp": 2,
    "&::-webkit-box-orient": "vertical",
    flex: "0.9",
    justifyContent: "center"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  wordBreak: {
    wordWrap: "break-word"
  },
  dropDownArrowIcon: {
    color: "#000000",
    opacity: "0.54"
  },
  ocaBottomSnackbar: {
    marginBottom: 0
  },
  bottomSnackbar: {
    marginBottom: 115
  },
  oracleExitSnackbar: {
    marginBottom: 115,
    backgroundColor: "#00d072 !important"
  }
}));

const mystyleButton = {
  marginLeft: "6px",
  textTransform: "none",
  color: "#29A8DD",
  fontWeight: 200,
  fontSize: "12px"
};
const myStyleFilter = {
  marginLeft: "6px",
  width: "auto",
  height: "36px",
  textTransform: "none",
  zIndex: "1",
  color: "#29A8DD",
  border: "1px solid #29A8DD",
  borderRadius: "0px",
  fontSize: "14px"
};
const myStyleExport = { marginLeft: "10px" };
const myStylePopper = {
  zIndex: "100",
  position: "fixed"
};
const myStylePaper = {
  height: "360px",
  maxHeight: "360px",
  overflow: "hidden",
  width: "430px",
  marginTop: "7px",
  marginLeft: "95px",
  zIndex: "1000",
  border: "2px solid #29A8DD",
  scrollbarWidth: "thin"
};

const ConfigFilter = props => {
  const { proCategory, currentCountry } = props;
  //const location = useLocation();
  //const [configName, setConfigName] = useState(location.configName);

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isButtonShort, setIsButtonShort] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  //const history = useHistory();
  const dispatch = useDispatch();
  const bomData = useSelector(state => state.configFilterData.bomData);
  const newSavedConfigId = useSelector(
    state => state.configFilterData.newSavedConfigId
  );
  const originalAccess = useSelector(
    state => state.configFilterData.originalAccessType
  );
  const accessType = useSelector(state => state.configFilterData.accessType);
  const configNameRed = useSelector(
    state => state.configFilterData.configNameRed
  );
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const configNameRedSaved = useSelector(
    state => state.configFilterData.configNameRedSaved
  );
  const modelData = useSelector(state => state.configFilterData.modelData);
  const haveSaved = useSelector(state => state.configFilterData.haveSaved);
  const originSelectedItems = useSelector(
    state => state.configFilterData.originSelectedItems
  );
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  const showBOMSectionFlag = useSelector(
    state => state.configFilterData.showBOMSectionFlag
  );
  const configFilterData = useSelector(item => item.configFilterData);
  const showGADate = useSelector(state => state.productSelector.showGADate);
  const { showSearchSku, showRskuBackDrop, category } = useSelector(
    state => state.productSelector
  );
  const programType = useSelector(state => state.productSelector.programType);
  const { isSkuCloning } = useSelector(state => state.rskuReducer);
  const { skuIdData } = useSelector(state => state.productSelector);
  const ocfgParams = useSelector(state => state.productSelector.ocfgParams);
  const originalData = useSelector(
    state => state.configFilterData.originalData
  );

  const filteredLoc = useSelector(state => state.configFilterData.filteredLoc);

  const { skuId } = skuIdData;

  const configName = props.name || configNameRed;
  const {
    transactionID = "",
    snackbarPosition = "top-left",
    saveConfigurationLabel = "SAVE CONFIGURATION",
    countryCode = "US",
    userId = "",
    userEmail = "",
    companyId = "",
    // productLine = "DT",
    spcType = "C",
    configurationName = "",
    visibility = {},
    brID,
    configOrigin,
    isStandaloneAccess = false,
    isOracleExit = false
  } = React.useContext(ExternalProps);
  const {
    showConfigurationButton = false,
    showStartOverButton = false,
    showExportToPdf = false,
    showExportToExcel = false
  } = visibility;
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [searchFilters, setSearchFilters] = useState([]);
  const [newSelectedItems, setNewSelectedItems] = useState([]);
  const [oldSelectedItems, setOldSelectedItems] = useState([]);
  const [isItemOverDate, setIsItemOverDate] = useState(false);
  const isConfigOverDate = useSelector(
    state => state.configFilterData.isConfigOverDate
  );
  const currentConfigID = useSelector(
    state => state.productSelector.currentConfigID
  );
  const { haveFutureItem } = getConfigBom(modelData, {
    currentConfigID,
    showGADate,
    isConfigOverDate
  });
  const [isOverDate, setIsOverDate] = useState(
    (showGADate && isConfigOverDate) || haveFutureItem
  );
  const anchorRef = useRef(null);

  let textInput = useRef(null);
  let buttonsRef = useRef(null);
  const FILTER_PARTS = "Filter Parts";
  const showFilterParts = defaultConfigStepSlected === "hardware";
  const originRetainConfigID = getStorageValue("retainConfigID");
  const [retainConfigID, setRetainConfigID] = useState(originRetainConfigID);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const snackbar = { enqueueSnackbar, closeSnackbar };
  const sectionIds = isStandaloneAccess
    ? ALL_OTHER_COMPONENTS
    : CONFIGURATION_SERVICES;
  const {
    filteredSectionId,
    hasOtherComponent,
    rSKUBom,
    standardCarepacks,
    autoAttachCarepacks,
    warrentyDescription
  } = checkSectionID(sectionIds, bomData);
  const docType = configuration?.configHeader?.docType;
  const refConfigId =
    docType === "SP" || docType === "CC"
      ? configuration?.configHeader?.configId?.toString() || ""
      : configuration?.configHeader?.refConfigId?.toString() || "";
  const otherHardCodeChaDes = [
    "Top Recommended Care Packs",
    "Additional Care Packs",
    "Software Solution Services",
    "Care Pack Services for:"
  ];
  const CloseButton = ({ caption }) => (
    <div className={classes.checkedDiv}>
      <div
        className={classes.checkedItem}
        style={{
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical"
        }}
      >
        {caption}
      </div>
      <span className={classes.closeIcon} onClick={handleCheckbox(caption, 2)}>
        &times;
      </span>
    </div>
  );

  const CheckedItemsDiv = () => (
    <div style={{ display: "flex", flexDirection: "row", marginTop: "6px" }}>
      {checked
        .filter(item => item !== "CECP Compliance")
        .map((item, index) => (
          <CloseButton caption={item} key={index} />
        ))}
    </div>
  );

  const getGroupData = val => {
    const priorityList = _.groupBy(val, item => Number(item.priority) <= 5);
    return priorityList;
  };

  const setChoicesAndParts = useCallback(() => {
    const choices = getChoices(
      modelData,
      defaultConfigStepSlected,
      proCategory
    );
    const carePackAddOns =
      defaultConfigStepSlected === "carePacks" &&
      choices &&
      choices.length &&
      choices[0].items
        ? getGroupData(choices[0].items)
        : false;
    // getGroupData(choices[0].items);
    const carePacks = defaultConfigStepSlected === "carePacks" && [
      { chaDes: "Top Recommended Care Packs", items: carePackAddOns["true"] },
      { chaDes: "Additional Care Packs", items: carePackAddOns["false"] },
      ...choices.filter(chaid => chaid.chaId !== "ELECTCPQ")
    ];
    const arrChaDes =
      defaultConfigStepSlected === "carePacks"
        ? _.map(carePacks, "chaDes")
        : _.map(choices, "chaDes");
    setOptions(arrChaDes);
    // const data = getConfigData(modelData, defaultConfigStepSlected);
    // dispatch({ type: SET_FILTERED_CHOICES, payload: data });
    // dispatch({ type: SET_FILTERED_LOC, payload: [] });
  }, [dispatch, modelData, defaultConfigStepSlected, proCategory]);
  useEffect(() => {
    let billMaterials = document.querySelector("#container").children[1];
    let billWidth = getComputedStyle(billMaterials).width;
    billWidth = Number(billWidth.substring(billWidth.length - 2, 0));
    let buttonsWidth = [...buttonsRef.current.children].reduce((pre, cur) => {
      let width = getComputedStyle(cur).width;
      return pre + Number(width.substring(width.length - 2, 0)) + 10;
    }, 0);
    if (buttonsWidth <= billWidth) {
      setIsButtonShort(true);
    } else {
      setIsButtonShort(false);
    }
  }, [
    showStartOverButton,
    showSearchSku,
    isConfigOverDate,
    showBOMSectionFlag,
    showConfigurationButton,
    showExportToPdf,
    showExportToExcel,
    modelData?.isUploadFlow
  ]);
  useEffect(() => {
    let billMaterials = document.querySelector("#container").children[1];
    let billWidth = getComputedStyle(billMaterials).width;
    billWidth = Number(billWidth.substring(billWidth.length - 2, 0));
    let buttonsWidth = [...buttonsRef.current.children].reduce((pre, cur) => {
      let width = getComputedStyle(cur).width;
      return pre + Number(width.substring(width.length - 2, 0)) + 10;
    }, 0);
    if (buttonsWidth <= billWidth) {
      setIsButtonShort(true);
    } else {
      setIsButtonShort(false);
    }
  }, [
    showStartOverButton,
    showSearchSku,
    isConfigOverDate,
    showBOMSectionFlag,
    showConfigurationButton,
    showExportToPdf,
    showExportToExcel,
    modelData?.isUploadFlow
  ]);

  useEffect(() => {
    // TODO: Remember per tab?
    setSearchText("");
    setChoicesAndParts();
  }, [setChoicesAndParts]);

  useEffect(() => {
    if (proCategory) {
      const data = getConfigData(modelData, defaultConfigStepSlected);
      const choices = getChoices(
        modelData,
        defaultConfigStepSlected,
        proCategory
      );
      const userType = getStorageValue("userType");
      dispatch({
        type: SET_CAREPACKS_STATE,
        payload: false
      });
      if (searchText) {
        // Choices
        const none = "none";
        let showCarePark = false;
        let shouldFilteredSoftware = false;
        otherHardCodeChaDes.forEach((item, index) => {
          if (item.toLowerCase().includes(searchText.toLowerCase())) {
            if (index === 0) {
              dispatch({
                type: SET_SHOW_TOP_RECOMMEND,
                payload: true
              });
            } else if (index === 1) {
              dispatch({
                type: SET_SHOW_ADDITIONAL,
                payload: true
              });
            } else if (index === 2) {
              shouldFilteredSoftware = true;
            }
            showCarePark = true;
          }
        });
        const result = data.filter(choice => {
          const filteredItems = choice.items.filter(
            chItems =>
              chItems.partno.toLowerCase().includes(searchText.toLowerCase()) ||
              chItems.partdes.toLowerCase().includes(searchText.toLowerCase())
          );
          const filteredconflictItems =
            choice.conflictItems &&
            choice.conflictItems.filter(
              chItems =>
                chItems.partno
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                chItems.partdes.toLowerCase().includes(searchText.toLowerCase())
            );
          if (
            choice.chaDes.toLowerCase().includes(searchText.toLowerCase()) ||
            (none.includes(searchText.toLowerCase()) &&
              choice.visible &&
              !choice.multiple)
          ) {
            choice.items = filteredItems;
            choice.conflictItems = filteredconflictItems;
            return choice.chaDes;
          } else {
            if (
              (choice.chaId.includes("EUCPQ") ||
                choice.chaId.includes("ELECTCPQ")) &&
              showCarePark
            ) {
              //For top Recommended and additional
              return (
                (choice.items && choice.items.length) ||
                (choice.conflictItems && choice.conflictItems.length)
              );
            } else if (
              !choice.chaId.includes("EUCPQ") &&
              !choice.chaId.includes("ELECTCPQ") &&
              !choice.chaId.startsWith("CAPQ_")
            ) {
              //For software solution
              if (!shouldFilteredSoftware) {
                choice.items = filteredItems;
                choice.conflictItems = filteredconflictItems;
              }
              return (
                (choice.items && choice.items.length) ||
                (choice.conflictItems && choice.conflictItems.length)
              );
            } else if (choice.chaId.startsWith("CAPQ_")) {
              //For "Care pack Services for:"
              return (
                (choice.items && choice.items.length) ||
                (choice.conflictItems && choice.conflictItems.length)
              );
            } else {
              choice.items = filteredItems;
              choice.conflictItems = filteredconflictItems;
              return (
                (choice.items && choice.items.length) ||
                (choice.conflictItems && choice.conflictItems.length)
              );
            }
          }
        });
        let filterData = data.filter(cha => filters.includes(cha.chaDes));
        let newFilteredChoices = [...new Set([...result, ...filterData])];
        dispatch({
          type: SET_FILTERED_CHOICES,
          payload: filterData.length ? newFilteredChoices : result
        });
        // Localizations
        const choicesList = choices
          .map(choice =>
            choice.items?.some(
              chItems =>
                chItems.partno
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                chItems.partdes
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                (choice.type === "loc" &&
                  chItems.visible &&
                  chItems.locDescrition
                    .toLowerCase()
                    .includes(searchText.toLowerCase()))
            ) ||
            (choice.conflictItems &&
              choice.conflictItems.length > 0 &&
              choice.conflictItems.filter(
                chItems =>
                  chItems.partno
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                  chItems.partdes
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
              )) ||
            (none.includes(searchText.toLowerCase()) &&
              choice.visible &&
              !choice.multiple &&
              !choice.type) ||
            choice.chaDes.toLowerCase().includes(searchText.toLowerCase())
              ? choice.chaDes
              : undefined
          )
          .filter(d => d !== undefined);

        //judge Configuration Services Customer
        const hasPartnerCustomer =
          "customer".includes(searchText.toLowerCase()) &&
          defaultConfigStepSlected === "configurationService" &&
          userType?.toUpperCase() !== "PARTNER";
        const inclusiveList = !hasPartnerCustomer
          ? [...filters, ...choicesList]
          : [...filters, ...choicesList, "Customer"];

        setSearchFilters(choicesList);
        dispatch({
          type: SET_FILTERED_LOC,
          payload: inclusiveList.length ? inclusiveList : ["dummy"]
        });
      } else {
        const choicesList = choices.map(choice => choice.chaDes);
        setSearchFilters([]);
        if (!filters.length) {
          if (
            defaultConfigStepSlected === "configurationService" &&
            userType?.toUpperCase() !== "PARTNER" &&
            filteredLoc.indexOf("Customer") !== -1
          ) {
            choicesList.push("Customer");
          }
          dispatch({ type: SET_FILTERED_CHOICES, payload: data });
          dispatch({
            type: SET_FILTERED_LOC,
            payload: filters.length
              ? filters
              : choicesList.length
              ? choicesList
              : ["dummy"]
          });
        }
      }
    }
  }, [
    dispatch,
    searchText,
    modelData,
    defaultConfigStepSlected,
    proCategory,
    filters
  ]);

  useEffect(() => {
    const filterSelectedItems =
      modelData?.Items &&
      modelData.Items.filter(
        item =>
          item.selected === true && item.partno !== "" && item.visible === true
      );

    filterSelectedItems &&
      setNewSelectedItems(_.orderBy(filterSelectedItems, ["partno"], ["asc"]));
    try {
      setOldSelectedItems(
        _.orderBy(JSON.parse(originSelectedItems), ["partno"], ["asc"])
      );
    } catch (e) {
      if (localStorage.getItem("isDebug") === "true") {
        console.log("No originSelectedItems");
      }
    }
    const baseUnitItem = modelData?.Chaids?.filter(
      item => item.chaId === "BUNIT"
    );
    const baseUnitSelectItems =
      baseUnitItem &&
      modelData?.Items &&
      modelData.Items.filter(
        item =>
          item.selected === true &&
          item.partno !== "" &&
          item.visible === true &&
          item.inode >= baseUnitItem[0].firstItem &&
          item.inode <= baseUnitItem[0].lastItem
      );
    setIsItemOverDate(
      baseUnitSelectItems?.some(
        item =>
          item.gaDate !== "NA" &&
          item.gaDate !== "" &&
          moment(item.gaDate).format("YYYY-MM-DD") >
            moment().format("YYYY-MM-DD")
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelData, originSelectedItems]);

  useEffect(() => {
    const { haveFutureItem } = getConfigBom(modelData, {
      currentConfigID,
      showGADate,
      isConfigOverDate
    });
    setIsOverDate((showGADate && isConfigOverDate) || haveFutureItem);
  }, [showGADate, isConfigOverDate, currentConfigID, modelData]);

  const debounce = (func, timeout = 500) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };
  const setSearchData = event => {
    const value = event.target.value.toString().trim().slice(0, 100);
    dispatch({ type: SET_SEARCHED_TEXT, payload: value });
    setSearchText(value);
  };
  const handleSearchChange = debounce(event => setSearchData(event));
  // const handleSearchChange = event => {
  //   let value = event.target.value;
  //   // let items = [];
  //   // data.map(cha => {
  //   //     cha.items.map(item => {
  //   //         items.push({ chaId: cha.chaId, chaDes: cha.chaDes, ...item });
  //   //     })
  //   // });
  //   dispatch({ type: SET_SEARCHED_TEXT, payload: value });
  //   setSearchText(value);
  // };
  const [saveConfiguration] = useMutation(SAVE_CONFIGURATION, {
    fetchPolicy: "no-cache",
    onCompleted({ createConfiguration }) {
      if (createConfiguration) {
        // TODO: find another way to keep button loading
        // below line makes button keep spinning in standalone after save
        // setLoading(true);
        const position = snackbarPosition.split("-");
        const snackbarKey = enqueueSnackbar(
          t("common:snackbar.saveConfiguration"),
          {
            variant: "success",
            anchorOrigin: {
              vertical: position[0],
              horizontal: position[1]
            },
            className:
              configOrigin === "OCA" && snackbarPosition.includes("bottom")
                ? classes.ocaBottomSnackbar
                : snackbarPosition.includes("bottom") && isOracleExit
                ? classes.oracleExitSnackbar
                : snackbarPosition.includes("bottom")
                ? classes.bottomSnackbar
                : {},
            action: (
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={() => closeSnackbar(snackbarKey)}
              >
                <CloseIcon />
              </IconButton>
            )
          }
        );
      }
      batch(() => {
        dispatch({
          type: SET_SAVE_CONFIG_RESPONSE,
          newSavedConfigId: createConfiguration.configId,
          eventTriggered:
            saveConfigurationLabel === "SAVE CONFIGURATION" || isOverDate
              ? "SAVE_CONFIGURATION"
              : "ADD_TO_QUOTE"
        });
        dispatch({
          type: SET_CONFIG_NAME_SAVE,
          payload: configNameRed ? configNameRed : configName
        });
        dispatch({
          type: SET_ORIGIN_SELECTED_ITEMS,
          selectedItems: JSON.stringify(newSelectedItems),
          haveSaved: true
        });
        dispatch({
          type: SET_ORIGINAL_ACCESS_TYPE,
          payload: accessType
        });
      });

      if (isOverDate) {
        setLoading(false);
      } else {
        handleData(createConfiguration.configId);
      }
    },
    onError({ graphQLErrors, networkError }) {
      setLoading(false);
      if (localStorage.getItem("isDebug") === "true") {
        if (graphQLErrors) {
          console.log(graphQLErrors);
        }
        if (networkError) {
          console.log(networkError);
        }
      }
    }
  });

  const handleData = newConfigId => {
    const data = {
      transactionID,
      eventTriggered:
        saveConfigurationLabel === "SAVE CONFIGURATION"
          ? "SAVE_CONFIGURATION"
          : "ADD_TO_QUOTE",
      configID: newConfigId,
      configName: configNameRed
        ? configNameRed
        : configuration?.configHeader?.configName,
      refConfigID: refConfigId,
      disableReconfigure: false,
      retainConfigID: modelData?.isUploadFlow ? true : retainConfigID,
      productCategory: proCategory,
      components: bomData.map(bom => {
        return {
          partNumber: bom.partNumber,
          description: bom.description,
          quantity: bom.qty,
          listPrice: bom.listPrice.toFixed(2).toString(),
          requestedBDNetPrice: "150.00",
          plcOverride: false
        };
      })
    };

    handleEvent(data);
  };

  const handleClick = action => {
    if (action === "Select_All") {
      setChecked(options);
    }

    if (action === "Clear_All") {
      setChecked([]);
    }

    if (action === "START_OVER") {
      //window.alert("start over");
      // browserHistory.push('/productselection/');
      // history.push('/productselection/');
      window.location.reload();
      localStorage.setItem("setProgramType", programType);
    }

    if (action === "EXPORT_TO_EXCEL") {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExt = "xlsx";
      const fileName = isSkuCloning
        ? `${skuId}.${fileExt}`
        : `${
            newSavedConfigId
              ? newSavedConfigId
              : configuration?.configHeader?.docType === "QU"
              ? configuration.configHeader.configId
              : "NA"
          }.${fileExt}`;
      const { formattedBOMItems, grandPrice } = getBOMData(
        modelData,
        "exportToExcel",
        configuration
      );
      var ws = XLSX.utils.aoa_to_sheet([
        ["Config ID", "Config Name", "KMAT", "Grand Price"]
      ]);
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            newSavedConfigId
              ? newSavedConfigId
              : configuration?.configHeader?.docType === "QU"
              ? configuration.configHeader.configId
              : "NA",
            configuration?.configHeader?.configName,
            configuration.configHeader.kmatId,
            grandPrice.toFixed(2)
          ]
        ],
        { origin: -1 }
      );
      XLSX.utils.sheet_add_aoa(ws, [new Array(4)], { origin: -1 });
      XLSX.utils.sheet_add_aoa(
        ws,
        [["Description", "Part Number", "Qty", "Price"]],
        { origin: -1 }
      );
      formattedBOMItems.forEach(item => {
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              item.description,
              item.partNumber,
              {
                v: item.qty,
                s: {
                  alignment: { horizontal: "right" }
                }
              },
              item.listPrice.toFixed(2) || "NA"
            ]
          ],
          { origin: -1 }
        );
      });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuff = XLSX.write(wb, { bookType: fileExt, type: "array" });
      const dataBuff = new Blob([excelBuff], { type: fileType });
      saveAs(dataBuff, fileName);
    }

    if (action === "EXPORT_TO_PDF") {
      // const data = getConfigData(modelData);
      exportToPdf(newSavedConfigId, configuration, modelData, skuId);
    }

    if (action === "SAVE_CONFIGURATION" || action === "ADD_TO_QUOTE") {
      setLoading(true);
      let bmiData = JSON.parse(localStorage.getItem("bmiData"));
      const mDCPOrgID = bmiData["mDCPOrgID"] || "";
      let configNameRes = localStorage.getItem("configName");
      let configDescRes = configuration?.configHeader?.configDesc;
      let selectedCategory = localStorage.getItem("selectedCategory");
      let selectedSeries = localStorage.getItem("selectedSeries");
      const updatedChoicesDetails = setModelDataForDefaultConfig(modelData);
      let { formattedBOMItems: configBOM } = getConfigBom(modelData);
      const getConfigObjectDataRes = getConfigObjectDataForSave(
        updatedChoicesDetails
      );
      const systemLocalizationDefault = getLocalizationOption(
        modelData.systemLocOptions
      );
      const osLocalizationDefault = getLocalizationOption(
        modelData.osLocOptions
      );
      const keyBoardLocalizationDefault = getLocalizationOption(
        modelData.kybdLocOptions
      );
      const monitorLocalizationDefault = getLocalizationOption(
        modelData.monitorLocOptions
      );
      let systemLocOptions, osLocOptions, kybdLocOptions, monitorLocOptions;
      systemLocOptions =
        modelData.systemLocOptions &&
        modelData.systemLocOptions.filter(item => item.selected === true);
      osLocOptions =
        modelData.osLocOptions &&
        modelData.osLocOptions.filter(item => item.selected === true);
      kybdLocOptions =
        modelData.kybdLocOptions &&
        modelData.kybdLocOptions.filter(item => item.selected === true);
      monitorLocOptions =
        modelData.monitorLocOptions &&
        modelData.monitorLocOptions.filter(item => item.selected === true);

      let countryLcLzRes = [
        {
          countryCode: currentCountry || countryCode,
          systemLocalization:
            systemLocOptions && systemLocOptions[0]?.locOption,
          osLocalization: osLocOptions && osLocOptions[0]?.locOption,
          keyBoardLocalization: kybdLocOptions && kybdLocOptions[0]?.locOption,
          monLocalization: monitorLocOptions && monitorLocOptions[0]?.locOption,
          delFl: "N"
        }
      ];
      const changeSelectedItem =
        JSON.stringify(newSelectedItems) !== JSON.stringify(oldSelectedItems);
      const getConfigId = () => {
        const originConfigId = configuration?.configHeader?.configId;
        let configId = null;
        if (action === "ADD_TO_QUOTE") {
          if (docType === "QU") {
            if (changeSelectedItem) {
              setRetainConfigID(true);
            } else {
              setRetainConfigID(false);
              configId = originConfigId || null;
            }
          } else if (docType === "SP" || docType === "CC") {
            setRetainConfigID(true);
          }
        } else if (action === "SAVE_CONFIGURATION") {
          if (originRetainConfigID) {
            configId = originConfigId || null;
          } else {
            if (docType === "QU") {
              if (changeSelectedItem) {
                setRetainConfigID(true);
              } else {
                configId = newSavedConfigId || originConfigId || null;
              }
            }
          }
        }
        return configId;
      };

      // Extract Base Unit to pass inside configHeader if changed
      let selectedBaseUnit = undefined;
      if (configBOM && configBOM.length) {
        selectedBaseUnit = configBOM.find(
          config => config.choiceId === "BUNIT"
        );
      }

      let inputData = {
        configName: configNameRed
          ? configNameRed
          : configNameRes || configurationName,
        configId: getConfigId(),
        configDesc: configDescRes,
        configHeader: {
          addtnlRegs: "",
          configType: configuration?.configHeader?.configType || "custom",
          leadCountryCode: currentCountry || countryCode || "US",
          configHighLights: configuration?.configHeader?.configHighlights || "",
          configImage:
            encodeURIComponent(configuration?.configHeader?.configImage) || "",
          kmatId: configuration?.configHeader?.kmatId || modelData.kmatId || "",
          baseUnitAv:
            selectedBaseUnit?.materialNumber ||
            configuration?.configHeader?.baseUnitAv ||
            "",
          programName: configuration?.configHeader?.programName || "",
          accessType,
          refConfigId,
          userId: userId,
          userEmail: userEmail,
          customerCompanyName:
            brID ||
            (companyId
              ? companyId
              : configuration?.configHeader?.companyName) ||
            "",
          mdcpOrgId: mDCPOrgID
            ? mDCPOrgID
            : configuration?.configHeader?.mdcpOrgId || "",
          originatingAsset: configOrigin ? configOrigin : "OCIQ",
          bandingFlag: "N",
          bandingType: "OC-FIX",
          docType: "QU",
          regionCode:
            configuration?.configHeader?.rgnCd || modelData.region || "NA",
          lead_locale: configuration?.configHeader?.leadLocale || "",
          autoLocalized: configuration?.configHeader?.autoLocalized || "",
          kbVersion: configuration?.configHeader?.kbVersion || "",
          marketProgramName: configuration?.configHeader?.mktProgramName || "",
          marketProgramType: configuration?.configHeader?.mktProgramType || "",
          shipmentDate: configuration?.configHeader?.shipmentDate
            ? moment(configuration?.configHeader?.shipmentDate).format(
                "YYYY-MM-DD"
              )
            : "",
          exportStatusId:
            configuration?.configHeader?.exportStatusId ||
            (modelData.isUploadFlow ? "new" : ""),
          bandingEol: configuration?.configHeader?.bandingEol
            ? moment(configuration?.configHeader?.bandingEol).format(
                "YYYY-MM-DD"
              )
            : null,
          configEol: configuration?.configHeader?.configEol
            ? moment(configuration?.configHeader?.configEol).format(
                "YYYY-MM-DD"
              )
            : null,
          businessModel: configuration?.configHeader?.businessModel
            ? configuration?.configHeader?.businessModel.toLowerCase()
            : "indirect",
          copiedFrom: configuration?.configHeader?.copiedFrom || "",
          localizationType: configuration?.configHeader?.lclznType || "",
          priceGeo:
            configuration?.configHeader?.priceGeo || modelData.priceGeo || "",
          currencyCd: configuration?.configHeader?.currCd
            ? configuration?.configHeader?.currCd.toUpperCase()
            : modelData.currency,
          incoterm:
            configuration?.configHeader?.incoterm || modelData.incoterm || "",
          priceListType:
            configuration?.configHeader?.priceListType ||
            modelData.priceListType ||
            "",
          globalFl: configuration?.configHeader?.globalFl || "N",
          activeFl: configuration?.configHeader?.activeFl || "Y",
          delFl: configuration?.configHeader?.delFl || "N",
          favoriteFl: "N",
          productLine: modelData?.modelRequirement?.productGroup || "NA", // when UI does not have valid value , UI should send NA so that API would handle the valid value
          storeFront: "IQ",
          startDate: configuration?.configHeader?.startDate
            ? moment(configuration?.configHeader?.startDate).format(
                "YYYY-MM-DD"
              )
            : moment().format("YYYY-MM-DD") || null,
          endDate: configuration?.configHeader?.endDate
            ? moment(configuration?.configHeader?.endDate).format("YYYY-MM-DD")
            : moment().add(1, "year").format("YYYY-MM-DD") || null,
          geoMrkt: "",
          spcType
        },
        countryLcLz: countryLcLzRes,
        configBom: configBOM && configBOM.length ? configBOM : [],
        configObject: {
          configDefault: {
            configuration: {
              localization: [
                {
                  choiceId: "systemLocalization",
                  values: [...systemLocalizationDefault]
                },
                {
                  choiceId: "osLocalization",
                  values: [...osLocalizationDefault]
                },
                {
                  choiceId: "keyBoardLocalization",
                  values: [...keyBoardLocalizationDefault]
                },
                {
                  choiceId: "monitorLocalization",
                  values: [...monitorLocalizationDefault]
                }
              ],
              choices: [...getConfigObjectDataRes]
            }
          },
          configBanding: { banding: {} }
        },
        hierarchy: {
          categoryName: selectedCategory || "N/A", // when UI does not have valid value , UI should send N/A so that API would handle the valid value
          seriesName: selectedSeries || "N/A",
          productType: proCategory || "compute"
        }
      };
      saveConfiguration({
        variables: {
          input: inputData
        }
      });
    }

    if (action === "SAVE_FILTER") {
      //TODO: Save if need
      let result = [];
      result = options.filter(cha => {
        return checked.includes(cha);
      });
      const inclusiveList = [...searchFilters, ...result];
      const data = getConfigData(modelData, defaultConfigStepSlected);
      if (inclusiveList.length) {
        dispatch({
          type: SET_FILTERED_CHOICES,
          payload: data.filter(cha => inclusiveList.includes(cha.chaDes))
        });
      } else {
        dispatch({ type: SET_FILTERED_CHOICES, payload: data });
      }
      const defaultData = getConfigData(modelData, defaultConfigStepSlected);
      if (result.length) {
        dispatch({
          type: SET_FILTERED_CHOICES,
          payload: defaultData.filter(cha => result.includes(cha.chaDes))
        });
      } else {
        dispatch({ type: SET_FILTERED_CHOICES, payload: defaultData });
      }
      setFilters(result);
      dispatch({ type: SET_FILTERED_LOC, payload: inclusiveList });
      setOpen(false);
      setChecked(result);
      setSaved(true);
    }
  };

  const handleEvent = modelJson => {
    const clientOrigin = getStorageValue("clientOrigin");
    if (!clientOrigin || clientOrigin !== "BMI") setLoading(false);
    if (document.querySelector("#product-selector")) {
      document
        .querySelector("#product-selector")
        .dispatchEvent(new CustomEvent("saveConfig", { detail: modelJson }));
    } else if (document.querySelector("#configurator")) {
      document
        .querySelector("#configurator")
        .dispatchEvent(new CustomEvent("saveConfig", { detail: modelJson }));
    } else if (document.querySelector("#upload-config")) {
      document
        .querySelector("#upload-config")
        .dispatchEvent(new CustomEvent("saveConfig", { detail: modelJson }));
    }
  };

  const handleToggle = () => {
    if (open) {
      setChecked([...prevChecked]);
    } else {
      setPrevChecked([...checked]);
    }
    setOpen(prevOpen => !prevOpen);
  };

  const handleClickAway = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setChecked(prevChecked);
    setOpen(false);
  };

  const [checked, setChecked] = useState([]);
  const [prevChecked, setPrevChecked] = useState([]);
  const [saved, setSaved] = useState(false);
  const handleCheckbox = (value, sel) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    if (sel === 2) {
      // set new data
      let result = [];
      result = options.filter(cha => {
        return newChecked.includes(cha);
      });
      const data = getConfigData(modelData, defaultConfigStepSlected);
      if (result.length) {
        dispatch({
          type: SET_FILTERED_CHOICES,
          payload: data.filter(cha => result.includes(cha.chaDes))
        });
      } else {
        dispatch({ type: SET_FILTERED_CHOICES, payload: data });
      }
      setFilters(result);
      const inclusiveList = [...searchFilters, ...result];
      dispatch({ type: SET_FILTERED_LOC, payload: inclusiveList });
    }
  };
  const handleRskuDialogOpen = React.useCallback(
    (params = {}) => {
      batch(() => {
        dispatch({ type: "SET_RSKU_BOM", payload: rSKUBom });
        dispatch(
          togglePopupActions({
            name: "rskuDialog",
            open: true,
            ...params
          })
        );
        dispatch({
          type: "SET_OTHER_COMPONENTS",
          payload: filteredSectionId
        });
        dispatch({
          type: "SET_PROCATEGORY",
          payload: proCategory
        });
        dispatch({
          type: "SET_WARRANTY_DESCRIPTION",
          payload: warrentyDescription
        });
        dispatch({
          type: "SET_CAREPACKS",
          payload: {
            standardCarePacks: standardCarepacks,
            autoAttachCarePacks: autoAttachCarepacks
          }
        });
      });
    },
    [
      hasOtherComponent,
      filteredSectionId,
      standardCarepacks,
      autoAttachCarepacks,
      warrentyDescription
    ]
  );
  useEffect(() => {
    dispatch({
      type: CHECKED_FILTER_PARTS,
      payload: filters.length > 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  useEffect(() => {
    if (category === "print" && configuration?.configHeader?.docType === "SP") {
      dispatch({
        type: SET_CONFIG_NAME_SAVE,
        payload: configuration?.configHeader?.configDesc
      });
    }
  }, []);

  useEffect(() => {
    let isDisable;
    if (category === "print" && configuration?.configHeader?.docType === "SP") {
      isDisable =
        accessType?.toLowerCase() === originalAccess?.toLowerCase() &&
        JSON.stringify(newSelectedItems) === JSON.stringify(oldSelectedItems) &&
        (configNameRed === "" || configNameRed === configNameRedSaved);
    } else {
      isDisable =
        accessType?.toLowerCase() === originalAccess?.toLowerCase() &&
        haveSaved &&
        JSON.stringify(newSelectedItems) === JSON.stringify(oldSelectedItems) &&
        (configNameRed === "" || configNameRed === configNameRedSaved);
    }
    setIsSaveButtonDisabled(isDisable);
  }, [configFilterData]);

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={
          showRskuBackDrop
            ? showRskuBackDrop
            : saveConfigurationLabel === "SAVE CONFIGURATION" || isOverDate
            ? loading
            : loading ||
              (haveSaved &&
                JSON.stringify(newSelectedItems) ===
                  JSON.stringify(oldSelectedItems) &&
                (configNameRed === "" ||
                  configNameRed === configuration?.configHeader?.configName))
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <Typography
          variant="h5"
          align="left"
          className={`${classes.bold} ${classes.wordBreak}`}
        >
          {configNameRedSaved ? t(configNameRedSaved) : t(configNameRed)}
        </Typography>
      </div>
      <Grid container className={classes.root}>
        <Grid item xs={3}>
          <TextField
            placeholder={t(
              "common:productSelector.textMessage.pleaseEnterNone"
            )}
            variant="outlined"
            fullWidth
            size="small"
            className={classes.searchInput}
            // value={searchText}
            // onChange={handleSearchChange}
            onKeyUp={handleSearchChange}
            onKeyPress={e => {
              if (e.key === "Enter") handleClick("ALL_SEARCH");
            }}
            InputProps={{
              classes: { input: classes.input },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon ref={anchorRef} />
                  {/* style={{marginTop:'8px'}}  */}
                </InputAdornment>
              )
            }}
            inputRef={textInput}
            // onInput={e => {
            //   e.target.value = e.target.value.toString().slice(0, 100);
            // }}
          />
          <Popper
            style={myStylePopper}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            placement="bottom"
            disablePortal
            modifiers={{
              flip: {
                enabled: false
              },
              offset: {
                enabled: true,
                offset: "142, 0"
              },
              preventOverflow: {
                enabled: false,
                boundariesElement: "scrollParent"
              },
              hide: { enabled: false },
              computeStyle: { gpuAcceleration: false }
            }}
          >
            {
              <Paper style={myStylePaper}>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div className={classes.popperContainer}>
                    <div className={classes.popperHeader}>
                      <Button
                        className={classes.button}
                        color="primary"
                        size="small"
                        style={mystyleButton}
                        onClick={event => handleClick("Select_All")}
                      >
                        {t("Select All")}
                      </Button>

                      <Button
                        className={classes.button}
                        color="primary"
                        size="small"
                        style={mystyleButton}
                        onClick={event => handleClick("Clear_All")}
                      >
                        {t("Clear All")}
                      </Button>
                    </div>

                    <MenuList
                      id="split-button-menu"
                      className={classes.popperList}
                    >
                      {options
                        .filter(option => option !== "CECP Compliance")
                        .map((option, index) => (
                          <MenuItem
                            key={index}
                            disableRipple
                            onClick={handleCheckbox(option, 1)}
                            style={{ fontSize: "smaller", height: "30px" }}
                          >
                            <Checkbox
                              disableRipple
                              edge="start"
                              checked={checked.indexOf(option) !== -1}
                              onClick={handleCheckbox(option, 1)}
                              color="primary"
                              style={{ padding: "0px 3px" }}
                            />
                            <span className={classes.partsOptions}>
                              {option}
                            </span>
                          </MenuItem>
                        ))}
                    </MenuList>
                    <div className={classes.popperFooter}>
                      <Button
                        style={{
                          textTransform: "none",
                          color: "#29A8DD",
                          width: "100px",
                          border: "2px solid #29A8DD",
                          marginRight: "15px"
                        }}
                        className={classes.button}
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={event => handleClick("SAVE_FILTER")}
                      >
                        {t("button.save")}
                      </Button>
                    </div>
                  </div>
                </ClickAwayListener>
              </Paper>
            }
          </Popper>
        </Grid>

        <Grid item xs={2} className={classes.listItem}>
          {showFilterParts && (
            <CpqButton
              style={myStyleFilter}
              color="primary"
              // size="small"
              variant="outlined"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              {t("common:configurator.filterParts") + " "}
              <ArrowDropDownIcon className={classes.dropDownArrowIcon} />
            </CpqButton>
          )}
        </Grid>
        <Grid
          container
          item
          xs
          className={!isButtonShort ? classes.wrapper : classes.shortWrapper}
          ref={buttonsRef}
        >
          <Grid>
            {showStartOverButton && (
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                size="medium"
                onClick={event => handleClick("START_OVER")}
              >
                {t("common:productSelector.button.startOver")}
              </Button>
            )}
          </Grid>
          {showSearchSku && !isConfigOverDate && configOrigin !== "OCA" && (
            <Grid>
              <CpqButton
                color="primary"
                variant="outlined"
                size="medium"
                style={{ marginLeft: "10px" }}
                disabled={!showBOMSectionFlag}
                onClick={handleRskuDialogOpen}
              >
                {t("common:productSelector.button.searchSKU")}
              </CpqButton>
            </Grid>
          )}
          {showBOMSectionFlag && showConfigurationButton && !isSkuCloning && (
            <Grid>
              <CpqButton
                color="primary"
                variant="outlined"
                disabled={isSaveButtonDisabled}
                onClick={event =>
                  handleClick(
                    isOverDate
                      ? "SAVE_CONFIGURATION"
                      : saveConfigurationLabel.toUpperCase() ===
                        "SAVE CONFIGURATION"
                      ? "SAVE_CONFIGURATION"
                      : "ADD_TO_QUOTE"
                  )
                }
                style={myStyleExport}
                isToolTipText={
                  category === "print" &&
                  isSaveButtonDisabled &&
                  t("common:configurator:saveConfigDisable")
                }
              >
                {isOverDate
                  ? t("common:configurator:saveConfig")
                  : saveConfigurationLabel.toUpperCase() ===
                    "SAVE CONFIGURATION"
                  ? t("common:configurator:saveConfig")
                  : t("common:searchProduct.addToQuote")}
              </CpqButton>
            </Grid>
          )}
          {showBOMSectionFlag && showExportToPdf && !modelData?.isUploadFlow && (
            <Grid>
              <CpqButton
                color="primary"
                variant="outlined"
                style={myStyleExport}
                onClick={event => handleClick("EXPORT_TO_PDF")}
                //   href={locationUrl}
              >
                {t("common:configurator:exportPdf")}
              </CpqButton>
            </Grid>
          )}
          {showBOMSectionFlag && showExportToExcel && !modelData?.isUploadFlow && (
            <Grid>
              <CpqButton
                color="primary"
                variant="outlined"
                style={myStyleExport}
                onClick={event => handleClick("EXPORT_TO_EXCEL")}
              >
                {t("common:configurator:exportExcel")}
              </CpqButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      {showFilterParts && checked.length && saved ? <CheckedItemsDiv /> : null}
    </>
  );
};

export default withSnackbar(ConfigFilter);
