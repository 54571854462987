import { Button, Tooltip } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    flex: 1,
    display: "inline-block"
  },
  wrapperUploadSku: {
    position: "relative",
    display: "grid"
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

function CpqButton(props) {
  const classes = useStyles();
  const buttonProps = {
    id: props.id ? props.id : props.children,
    type: props.type ? props.type : "",
    size: props.size ? props.size : "medium",
    title: props.title ? props.title : "",
    color: props.color ? props.color : "primary",
    variant: props.variant ? props.variant : "contained",
    disabled: props.disabled,
    onClick: props.onClick,
    className: props.className,
    style: props.style ? props.style : {}
  };
  const isToolTipText = props?.isToolTipText ? props.isToolTipText : "";
  if (props.href) {
    buttonProps["href"] = props.href;
  }
  return (
    <div
      className={
        props.type === "uploadSku" ? classes.wrapperUploadSku : classes.wrapper
      }
    >
      {props.icon ? (
        <IconButton {...buttonProps} component="span">
          {props.icon}
        </IconButton>
      ) : (
        <>
          {isToolTipText.length != 0 ? (
            <Tooltip title={isToolTipText}>
              <span>
                <Button {...buttonProps}>{props.children}</Button>
              </span>
            </Tooltip>
          ) : (
            <Button {...buttonProps}>{props.children}</Button>
          )}
        </>
      )}
      {props.loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
}

export default CpqButton;
