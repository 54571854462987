import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ProductList from "./ProductList";

const useStyles = makeStyles(theme => {
  return createStyles({
    paper: {
      padding: theme.spacing(2)
    },
    contentTextWrapper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      textAlign: "center"
    },
    textColor: {
      color: "#0096d6"
    },
    text: {
      color: "#000000"
    },
    subTitle: {
      paddingBottom: 20
    }
  });
});
const SimilarProducts = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { rskuImage } = useSelector(state => state.rskuReducer);

  return (
    <Grid item xs={12}>
      <Paper variant="outlined" className={classes.paper}>
        <Box>
          <Typography className={classes.text}>
            {t("common:rsku.similar.mainTitle")}
          </Typography>
        </Box>
        <Box className={classes.subTitle}>
          <Typography variant="body2">
            {" "}
            {t("common:rsku.similar.subTitle")} &nbsp;
            <span className={classes.textColor}>
              {t("common:rsku.similar.highlighted")}
            </span>{" "}
          </Typography>
        </Box>

        {props.data.map(product => (
          <ProductList
            key={product.sku}
            {...product}
            image={rskuImage}
            isSimilar={props.isSimilar}
          />
        ))}
      </Paper>
    </Grid>
  );
};

export default SimilarProducts;
