import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_TAB_EXPAND } from "../actions/Types";
import {
  compare,
  getAllDataExpandedOrCollapsedManul,
  getLocalizedMandaCPItem
} from "../services/utils";
import ChaidMultiple, { ErrorPart } from "./ChaidMultiple";
import ChaidSingle from "./ChaidSingle";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "50%",
    flexShrink: 0,
    color: "#000000",
    fontWeight: "400"
  },
  choiceLevelMarginTop: {
    marginTop: "16px"
  },
  accordian: {
    width: "100%",
    fontSize: "0.75rem",
    boxShadow: "0px 5px 12px rgb(0 0 0 / 25%) !important",
    borderRadius: "4px !important"
  },
  accordianDetails: {
    display: "flex",
    flexDirection: "column"
  }
}));

export const MandaCarePacks = props => {
  const classes = useStyles();
  const expandAll = useSelector(state => state.configFilterData.expandAll);
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const searchText = useSelector(state => state.configFilterData.searchText);
  const dispatch = useDispatch();
  const mandaCarePacks = [];
  const _expanded = [];
  const _choiceMap = {};

  let choiceIndex = 0;

  props.filteredChoices.forEach(chaid => {
    if (
      chaid &&
      chaid.chaId.startsWith("CAPQ_") &&
      !props.isChaidHideBasedOnRegion(chaid.chaId)
    ) {
      const item = getLocalizedMandaCPItem(props.modelData, chaid);
      chaid.priority = Number(item?.priority);
      mandaCarePacks.push(chaid);
      _expanded.push(false);
    }
  });
  mandaCarePacks.sort(compare);

  mandaCarePacks.forEach((mandaCarePacks, index) => {
    _choiceMap[mandaCarePacks.chaId] = index;
  });

  const [expanded, setExpanded] = useState(_expanded);

  useEffect(() => {
    if (expandAll.length && defaultConfigStepSlected) {
      const relevantTabData = expandAll.find(
        expandObject => expandObject.tab === defaultConfigStepSlected
      );
      if (relevantTabData && relevantTabData?.isClickExpandOrCollapse) {
        const _ex = Object.keys(relevantTabData.choicesExpandState).filter(
          ch => {
            return ch.startsWith("CAPQ_") && Array(_ex).indexOf(ch) === -1;
          }
        );
        // setExpanded(
        //   relevantTabData.expand ||
        //     Object.keys(relevantTabData.choicesExpandState).some(
        //       ch =>
        //         relevantTabData.choicesExpandState[ch] === true && ch.startsWith("CAPQ_")
        //     )
        // );
        _ex.forEach(ch => {
          _expanded[_choiceMap[ch]] =
            relevantTabData.expand || relevantTabData.choicesExpandState[ch];
        });
        if (mandaCarePacks.length === 0) {
          _ex.forEach((dch, index) => {
            delete relevantTabData.choicesExpandState[dch];
          });
        }
        setExpanded([..._expanded]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandAll, defaultConfigStepSlected]);

  useEffect(() => {
    if (searchText) {
      const _expanded =Array.isArray(expanded) ? [...expanded] : [expanded];
      // eslint-disable-next-line array-callback-return
      props.filteredChoices.map((choice, index) => {
        _expanded[index] = true;
        setExpanded(_expanded);
      });
    } else {
      setExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleChange = (index, e) => {
    e.preventDefault();
    const relevantExpandData = expandAll.find(
      expandObject => expandObject.tab === defaultConfigStepSlected
    );
    const _expanded = Array.isArray(expanded) ? [...expanded] : [expanded];
    if (relevantExpandData) {
      if (
        Object.keys(relevantExpandData.choicesExpandState).indexOf(
          mandaCarePacks[index].chaId
        ) !== -1
      ) {
        relevantExpandData.choicesExpandState[mandaCarePacks[index].chaId] =
          !_expanded[index];
      }

      const isShowAdditionalMandaCarePacks = mandaCarePacks[
        index
      ]?.items.filter(_ch => {
        return _ch.priority === "" || parseInt(_ch.priority) > 5;
      });
      if (!isShowAdditionalMandaCarePacks.length) {
        const recommendedCarePacksState = Object.keys(
          relevantExpandData.choicesExpandState
        ).filter(chaid => {
          return chaid === mandaCarePacks[index].chaId + "_additional";
        });
        delete relevantExpandData?.choicesExpandState[
          recommendedCarePacksState
        ];
      }

      const isShowRecommendedMandaCarePacks = mandaCarePacks[
        index
      ]?.items.filter(_ch => {
        return (
          _ch.priority !== "" &&
          parseInt(_ch.priority) >= 0 &&
          parseInt(_ch.priority) <= 5
        );
      });
      if (!isShowRecommendedMandaCarePacks.length) {
        const recommendedCarePacksState = Object.keys(
          relevantExpandData.choicesExpandState
        ).filter(chaid => {
          return chaid === mandaCarePacks[index].chaId + "_recommended";
        });
        delete relevantExpandData?.choicesExpandState[
          recommendedCarePacksState
        ];
      }
      // eslint-disable-next-line array-callback-return
      Object.keys(relevantExpandData.choicesExpandState).map((chaid, key) => {
        if (chaid.startsWith(mandaCarePacks[index].chaId)) {
          if (chaid.endsWith("_additional") || chaid.endsWith("_recommended")) {
            relevantExpandData.choicesExpandState[chaid] = false;
          }
        }
      });
      const expand = getAllDataExpandedOrCollapsedManul(
        relevantExpandData.choicesExpandState
      )[0]
        ? true
        : getAllDataExpandedOrCollapsedManul(
            relevantExpandData.choicesExpandState
          )[1]
        ? false
        : relevantExpandData.expand;
      const payload = {
        tab: defaultConfigStepSlected,
        expand,
        choicesExpandState: relevantExpandData.choicesExpandState,
        flag: false,
        isClickExpandOrCollapse: false
      };
      dispatch({
        type: UPDATE_TAB_EXPAND,
        payload
      });
    }
    _expanded[index] = !_expanded[index];
    setExpanded(_expanded);
  };

  const otherCarePackComponent = chaid => {
    return chaid.required ||
      chaid.selcon ||
      props.modelData.Items.some(
        part =>
          part.partno !== "" &&
          part.visible === true &&
          part.precon >= 0 &&
          part.inode >= chaid.firstItem &&
          part.inode <= chaid.lastItem
      ) ? (
      chaid.multiple ? (
        <ChaidMultiple
          key={chaid.chaId}
          chaid={chaid}
          model={props.modelData}
          handleItemChange={props.handleItemChange}
          handleQuantityChange={props.handleQuantityChange}
        />
      ) : (
        <ChaidSingle
          key={chaid.chaId}
          chaid={chaid}
          model={props.modelData}
          handleItemChange={props.handleItemChange}
        />
      )
    ) : null;
  };

  const getRecommendedMandaCarePacks = chaid => {
    const _items = chaid.items.filter(_ch => {
      return (
        _ch.priority !== "" &&
        parseInt(_ch.priority) >= 0 &&
        parseInt(_ch.priority) <= 5
      );
    });
    if (_items.length > 0) {
      const recommChaid = {
        ...chaid,
        chaId: chaid.chaId + "_recommended",
        chaDes: "Top Recommended Care Packs",
        items: _items
      };
      return otherCarePackComponent(recommChaid);
    } else {
      return [];
    }
  };

  const getAdditionalMandaCarePacks = chaid => {
    const _items = chaid.items.filter(_ch => {
      return _ch.priority === "" || parseInt(_ch.priority) > 5;
    });
    if (_items.length > 0) {
      const addchaid = {
        ...chaid,
        chaId: chaid.chaId + "_additional",
        chaDes: "Additional Care Packs",
        items: _items
      };
      return otherCarePackComponent(addchaid);
    } else {
      return [];
    }
  };

  return mandaCarePacks?.map((_manda, index) => {
    const partDesc = getLocalizedMandaCPItem(props?.modelData, _manda);
    return (
      <div
        className={classes.accordian}
        key={index}
        id={"chaid-" + _manda.chaId}
      >
        <ErrorPart chaid={_manda} model={props.modelData} setMargin={true} />
        <Accordion
          expanded={expanded[index] || false}
          onChange={e => handleChange(index, e)}
        >
          <AccordionSummary
            className={
              props.showWarning && props.showWarning.warning
                ? ""
                : classes.choiceLevelMarginTop
            }
            expandIcon={<ExpandMoreIcon />}
            aria-controls="itemPanelbh-content"
            id="itemPanelbh-header"
          >
            <div>
              <Typography
                className={classes.heading}
                component={"span"}
                variant={"body2"}
              >
                {`Care Pack Services for: ${partDesc.partno} - ${partDesc.partdes}`}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordianDetails}>
            <React.Fragment>
              {getRecommendedMandaCarePacks(_manda)}
              {getAdditionalMandaCarePacks(_manda)}
            </React.Fragment>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  });
};
