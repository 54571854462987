import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import ChaidMultiple, { ErrorPart } from "../ChaidMultiple";
import ChaidSingle from "../ChaidSingle";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "50%",
    flexShrink: 0,
    color: "#000000",
    fontWeight: "400"
  },
  choiceLevelMarginTop: {
    marginTop: "16px"
  },
  accordian: {
    width: "100%",
    fontSize: "0.75rem",
    boxShadow: "0px 5px 12px rgb(0 0 0 / 25%) !important",
    borderRadius: "4px !important"
  },
  accordianDetails: {
    display: "flex",
    flexDirection: "column"
  }
}));

export const OtherCarePacks = props => {
  const classes = useStyles();
  const { proCategory } = props;
  const otherCarePackComponent = chaid => {
    return chaid.required ||
      chaid.selcon ||
      props.modelData.Items.some(
        part =>
          part.partno !== "" &&
          part.visible === true &&
          part.precon >= 0 &&
          part.inode >= chaid.firstItem &&
          part.inode <= chaid.lastItem
      ) ? (
      chaid.multiple ? (
        <ChaidMultiple
          key={chaid.chaId}
          chaid={chaid}
          model={props.modelData}
          handleItemChange={props.handleItemChange}
          handleQuantityChange={props.handleQuantityChange}
        />
      ) : (
        <ChaidSingle
          key={chaid.chaId}
          chaid={chaid}
          model={props.modelData}
          handleItemChange={props.handleItemChange}
        />
      )
    ) : null;
  };
  const otherCarePacks = [];
  props.filteredChoices.forEach(chaid => {
    if (
      chaid &&
      !chaid.chaId.includes("EUCPQ") &&
      !chaid.chaId.includes("ELECTCPQ") &&
      !chaid.chaId.startsWith("CAPQ_") &&
      !props.isChaidHideBasedOnRegion(chaid.chaId)
    ) {
      otherCarePackComponent(chaid) &&
        otherCarePacks.push(otherCarePackComponent(chaid));
      window[chaid.chaId] = true;
    }
  });
  return (
    <div className={classes.accordian} id={"chaid-" + "SSS99"}>
      {props.filteredChoices.map(chaid => {
        if (
          chaid &&
          !chaid.chaId.includes("EUCPQ") &&
          !chaid.chaId.includes("ELECTCPQ") &&
          !chaid.chaId.startsWith("CAPQ_") &&
          !props.isChaidHideBasedOnRegion(chaid.chaId)
        ) {
          return (
            <ErrorPart chaid={chaid} model={props.modelData} setMargin={true} />
          );
        }
      })}
      {proCategory === "compute" ? (
        otherCarePacks.length ? (
          <Accordion
            expanded={props.expanded}
            onChange={props.handleChange.bind(this, otherCarePacks)}
          >
            <AccordionSummary
              className={
                props.showWarning && props.showWarning.warning
                  ? ""
                  : classes.choiceLevelMarginTop
              }
              expandIcon={<ExpandMoreIcon />}
              aria-controls="itemPanelbh-content"
              id="itemPanelbh-header"
            >
              <div>
                <Typography
                  className={classes.heading}
                  component={"span"}
                  variant={"body2"}
                >
                  Software Solution Services
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordianDetails}>
              {otherCarePacks}
            </AccordionDetails>
          </Accordion>
        ) : (
          <></>
        )
      ) : (
        <>{otherCarePacks}</>
      )}
    </div>
  );
};
