import { useLazyQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfiguratorContainer from "../../../components/Configurator/ConfigContainer";
import { RSKU_REGIONS } from "../../../components/Configurator/Rsku/Constants";
import FullscreenModal from "../../../components/FullscreenModal";
import MyAnimation from "../../../components/MyAnimation";
import ExternalProps from "../../../contexts/externalPropsContext";
import {
  GET_BOM_LIST,
  GET_CONFIG_BOM,
  UPLOAD_CONFIG_API,
  UPLOAD_CONFIG_API_RESTORATION
} from "../../../GraphQL";
import { filteredRskuBomList } from "../../../lib/common/util";
import {
  getUiDataFileName,
  getUISpecData,
  handleAddToQuoteClick
} from "../../../services/utils";

const useStyles = makeStyles(theme => ({
  midPositioner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)"
  }
}));

const CloningRsku = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(true);
  const [config, setConfig] = useState({});
  const { skuIdData, category } = useSelector(state => state.productSelector);
  const { productCountryCode, productRegionCode } = useSelector(
    state => state.rskuReducer
  );
  const countryRegion = useSelector(
    state => state.productSelector.countryRegion
  );
  const { configId, skuId } = skuIdData;
  const {
    userType = "INTERNAL",
    routeToMarket,
    userId,
    userEmail,
    companyId,
    channelRegion,
    setCurrency
  } = React.useContext(ExternalProps);
  const shipmentDate = moment(new Date()).format("YYYY-MM-DD");
  const defaultConfigName = "N/A";
  const region = productRegionCode;
  const configName = localStorage.getItem("configName");

  useEffect(() => {
    if (configId) {
      getConfigBom({
        variables: {
          filter: {
            configID: configId,
            country: "",
            bomformat: "volume",
            originatingAsset: "RSKU"
          }
        }
      });
    } else {
      getBomList({
        variables: {
          skuNumbers: [skuId]
        }
      });
    }
  }, []);

  const handleModalClose = () => {
    setModalOpen(false);
    dispatch({ type: "SET_CLONING_SKU", payload: false });
  };

  const [getConfigName] = useLazyQuery(UPLOAD_CONFIG_API, {
    fetchPolicy: "no-cache",
    onCompleted(response) {
      localStorage.setItem("configName", response.uploadConfigApi?.configName);
    },
    onError(error) {}
  });

  const [getBomList] = useLazyQuery(GET_BOM_LIST, {
    fetchPolicy: "no-cache",
    onCompleted({ getRSKUComponentList }) {
      const { errorMessage, bomList } = getRSKUComponentList;
      if (!errorMessage?.length) {
        if (bomList[0]?.bomDetail?.length) {
          const bomInfo = filteredRskuBomList(bomList[0].bomDetail, "ms4");
          const kmatId = bomList[0].kmatId;
          getConfigName({
            variables: {
              filter: {
                bomList: bomInfo,
                shipmentDate: shipmentDate,
                country: productCountryCode,
                region: "",
                mktProgramName: "",
                mktProgramType: ""
              }
            }
          });

          cloningRskuBto({
            variables: {
              filter: {
                modelId: kmatId,
                country: productCountryCode,
                region: RSKU_REGIONS[region],
                language: "",
                mDCPOrgID: "99",
                resellerId: "",
                userType: userType,
                productType: "compute",
                shipmentDate: shipmentDate,
                originatingAsset: "RSKU",
                routeToMarket: routeToMarket,
                marketProgramType: "",
                marketProgramName: "",
                bom: bomInfo
              }
            }
          });
        } else {
          handleModalClose();
        }
      } else {
        handleModalClose();
      }
    },
    onError(error) {
      handleModalClose();
    }
  });

  const [cloningRskuBto] = useLazyQuery(UPLOAD_CONFIG_API_RESTORATION, {
    fetchPolicy: "no-cache",
    onCompleted(response) {
      let modelData = response.uploadConfigRestoration?.modelData;
      setConfig(modelData);
      setCurrency(modelData.currency);
      async function fetchDesktopUiSpec(category) {
        let uiSpecFile = getUiDataFileName(category);
        await getUISpecData.getData(uiSpecFile);
        let configuration = handleAddToQuoteClick(
          modelData,
          defaultConfigName,
          userId,
          userEmail,
          companyId,
          RSKU_REGIONS[region]
        );
        configuration.configId = configId ? parseInt(configId) : "";
        configuration.configName = configName;
        configuration.configHeader.configId = configId
          ? parseInt(configId)
          : "";
        configuration.configHeader.configName = configName;
        dispatch({
          type: "SET_CONFIGURATION",
          payload: configuration
        });
      }
      fetchDesktopUiSpec("Business Desktop PCs");
    },
    onError(error) {
      handleModalClose();
    }
  });

  const [getConfigBom] = useLazyQuery(GET_CONFIG_BOM, {
    fetchPolicy: "no-cache",
    onCompleted({ getConfigBom }) {
      const { ConfigHeaderData, configBomApiProducts } = getConfigBom;
      const { kmatId } = ConfigHeaderData;
      const bomInfo = filteredRskuBomList(configBomApiProducts, "rSku");
      getConfigName({
        variables: {
          filter: {
            bomList: bomInfo,
            shipmentDate: shipmentDate,
            country: productCountryCode,
            region: "",
            mktProgramName: "",
            mktProgramType: ""
          }
        }
      });
      cloningRskuBto({
        variables: {
          filter: {
            modelId: kmatId,
            country: productCountryCode,
            region: RSKU_REGIONS[region],
            language: "",
            mDCPOrgID: "99",
            resellerId: "",
            userType: userType,
            productType: "compute",
            shipmentDate: shipmentDate,
            originatingAsset: "RSKU",
            routeToMarket: routeToMarket,
            marketProgramType: "",
            marketProgramName: "",
            bom: bomInfo
          }
        }
      });
    },
    onError(error) {
      handleModalClose();
    }
  });

  const lottieLoadingFile =
    "https://dev-ociq.hpcloud.hp.com/assets/lotties/product_selector_animation.json";

  return (
    <FullscreenModal
      isOpen={modalOpen}
      handleModalClose={() => handleModalClose()}
    >
      {isEmpty(config) ? (
        <div className={classes.midPositioner}>
          <Typography variant="h4" align="center">
            Hold on tight
          </Typography>
          <Typography align="center">
            We are getting your config ready.
          </Typography>
          <MyAnimation
            lottieFile={lottieLoadingFile}
            style={{ height: "300px", width: "400px" }}
          />
        </div>
      ) : (
        <ConfiguratorContainer
          proCategory={category}
          config={config}
          setOpen={setModalOpen}
        />
      )}
    </FullscreenModal>
  );
};
export default CloningRsku;
