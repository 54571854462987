import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(theme => ({
  rightButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    justifyContent: "flex-end"
  },
  backDrop: {
    backgroundColor: "rgba(0,0,0,0.2)"
  },
  DialogContent: {
    display: "flex",
    paddingLeft: "50px",
    paddingRight: "50px"
  }
}));

function DealExtentionConfirmation(props) {
  const classes = useStyles();
  const { isOpen, handleCancel, handleConfirm } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h6" align="center" style={{ fontWeight: "bold" }}>
          {t("common:button.configurationApp.configurationUpload.alert.title")}
        </Typography>
        <Grid container className={classes.rightButton}>
          <Grid item>
            <IconButton aria-label="close" onClick={handleCancel}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={1} className={classes.DialogContent}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            {t(
              "common:button.configurationApp.configurationUpload.alert.subTitle"
            )}
          </Typography>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel} color="primary">
          {t("common:button.cancel")}
        </Button>
        <Button variant="contained" onClick={handleConfirm} color="primary">
          {t("common:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DealExtentionConfirmation;
