import { makeStyles, withStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React,{ useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  SET_ACCESS_TYPE,
  SET_ORIGINAL_ACCESS_TYPE,
  UPDATE_TAB_EXPAND
} from "../../../actions/Types";
import { getAllDataExpandedOrCollapsedManul } from "../../../services/utils";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "0px 0px 8px 0px"
  },
  configid_container: {
    background: "#FFFFFF",
    boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px"
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "50%",
    flexShrink: 0,
    color: "#000000",
    fontWeight: 400
  },
  captionStyle: {
    fontSize: "12px",
    fontWeight: "normal",
    paddingTop: "3px"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(16),
    color: "#000000",
    fontWeight: 300,
    padding: "8px 0px 0px 18px"
  },
  radioLabel: {
    fontSize: "12px"
  },
  accordionDetails: {
    width: "100%",
    boxSizing: "border-box"
  }
}));
const BlueRadio = withStyles({
  root: {
    color: "#00000054;",
    paddingBottom: "1px",
    paddingTop: "1px",

    "&$checked": {
      color: "#29A8DD"
    },
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  checked: {}
})(props => <Radio color="default" {...props} />);
const AccessOrganization = ({ chaid }) => {
  const classes = useStyles();

  //defining local states to manage radio button toggle functionality
  //const [access, setAccess] = useState(null);
  //to handle the expand functionality
  const [expanded, setExpanded] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(false);
  const dispatch = useDispatch();

  //Fetching the configuration data from store to get hold of accessType object
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const expandAll = useSelector(state => state.configFilterData.expandAll);
  const accessType = useSelector(state => state.configFilterData.accessType);
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );

  const [access, setAccess] = useState(accessType ? accessType : null);
  if (localStorage.getItem("isDebug") === "true") {
    console.log(
      "checkAccess",
      access,
      accessType,
      configuration?.configHeader?.accessType
    );
  }
  useEffect(() => {
    if (configuration && Object.keys(configuration).length > 0) {
      const accessFromState = accessType
        ? accessType
        : configuration?.configHeader?.accessType;
      setAccess(
        accessFromState ? accessFromState.toLowerCase() : "organization"
      );
      batch(() => {
        dispatch({
          type: SET_ACCESS_TYPE,
          payload: accessFromState
            ? accessFromState.toLowerCase()
            : "organization"
        });
        dispatch({
          type: SET_ORIGINAL_ACCESS_TYPE,
          payload: accessFromState
            ? accessFromState.toLowerCase()
            : "organization"
        });
      });
    }
  }, [configuration, dispatch]);

  //to fetch the expanded state of the accordian
  useEffect(() => {
    if (expandAll.length && defaultConfigStepSlected) {
      const relevantTabData = expandAll.find(
        expandObject => expandObject.tab === defaultConfigStepSlected
      );
      if (relevantTabData) {
        setExpanded(relevantTabData.choicesExpandState[chaid]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandAll, defaultConfigStepSlected]);

  //to update expand state of the accordian in redux store
  useEffect(() => {
    if (buttonClicked) {
      const relevantExpandData = expandAll.find(
        expandedData => expandedData.tab === defaultConfigStepSlected
      );
      if (relevantExpandData) {
        relevantExpandData.choicesExpandState[chaid] = expanded;
      }
      const expand = getAllDataExpandedOrCollapsedManul(
        relevantExpandData.choicesExpandState
      )[0]
        ? true
        : getAllDataExpandedOrCollapsedManul(
            relevantExpandData.choicesExpandState
          )[1]
        ? false
        : relevantExpandData.expand;
      const payload = {
        tab: defaultConfigStepSlected,
        expand: expand,
        choicesExpandState: relevantExpandData.choicesExpandState,
        flag: true
      };
      dispatch({
        type: UPDATE_TAB_EXPAND,
        payload
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, buttonClicked]);

  //Handle expand change
  const handleChange = e => {
    e.preventDefault();
    setExpanded(!expanded);
    setButtonClicked(true);
  };

  //handle radio button change
  const handleAccessChange = e => {
    //updating local state
    const { value } = e.target;
    setAccess(value);
    //updating store so that later on dave configuration we can use this value
    dispatch({
      type: SET_ACCESS_TYPE,
      payload: value
    });
    if (document.querySelector("#configurator")) {
      document
        .querySelector("#configurator")
        .dispatchEvent(
          new CustomEvent("changeAccess", { detail: { access: value } })
        );
    }
    setExpanded(false);
  };

  //component renders here
  return (
    access && (
      <div className={classes.root}>
        <Accordion
          className={classes.configid_container}
          expanded={expanded}
          onChange={handleChange}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid item xs={12} sm={6}>
              <Typography className={classes.heading}>Access:</Typography>
              {expanded === false && (
                <Typography className={classes.captionStyle}>
                  Click in to pick your Access.
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.secondaryHeading}>
                {access.charAt(0).toUpperCase() + access.slice(1).toLowerCase()}
              </Typography>
            </Grid>

            {/* <Typography className={classes.heading}>
            Access:{" "}
            {access.charAt(0).toUpperCase() + access.slice(1).toLowerCase()}{" "}
          </Typography> */}
          </AccordionSummary>

          <AccordionDetails className={classes.accordionDetails}>
            <RadioGroup
              value={access ? access : ""}
              onChange={handleAccessChange}
            >
              <FormControlLabel
                classes={{ label: classes.radioLabel }}
                value="private"
                control={<BlueRadio />}
                label="Private"
              />
              <FormControlLabel
                classes={{ label: classes.radioLabel }}
                value="organization"
                control={<BlueRadio />}
                label="Organization"
              />
            </RadioGroup>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  );
};

export default AccessOrganization;
