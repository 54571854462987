import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
// import { withRouter } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { SET_ACCESS_TYPE, SET_CONFIG_NAME } from "../../../actions/Types";
import ConfiguratorContainer from "../../../components/Configurator/ConfigContainer";
import FullscreenModal from "../../../components/FullscreenModal";
import ExternalProps from "../../../contexts/externalPropsContext";
import CpqButton from "../../../lib/common/CpqButton";
import { dateCompare, gadateFormat } from "../../../lib/common/util";

const TextOnlyTooltip = withStyles({
  tooltip: {
    fontSize: "12px"
  }
})(Tooltip);

const useStyles = makeStyles({
  media: {
    padding: 10,
    backgroundSize: "100%",
    width: "75%",
    height: 60,
    marginLeft: "5px"
  },
  cardHeader: {
    backgroundColor: "#009D69",
    "& .MuiTypography-h5": {
      fontSize: "0.8rem",
      margin: "0rem"
    },
    width: "100%",
    "& .MuiCardHeader-title": {
      color: "#ffffff"
    },
    height: "1.5rem",
    padding: "0.8rem 0 0.8rem 0",
    textAlign: "center"
  },
  tooltipPopper: {
    backgroundColor: "#56585A",
    width: "13rem"
  },
  button: {
    marginLeft: "150px"
  },
  gridHeight: {
    maxHeight: "150px"
  },
  baseUnit: {
    height: 15
  },
  ellipsis: {
    padding: 1,
    marginLeft: 10
  },
  padding: {
    paddingLeft: 20
  },
  ellipsisButton: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
    fontSize: "12px",
    textTransform: "capitalize",
    fontWeight: "bold"
  },
  listName: {
    fontWeight: "bold"
  },
  root: {
    flexGrow: 1,
    cursor: "default"
  },
  cardEllipsis: {
    minHeight: 85,
    fontWeight: "bold",
    overflowWrap: "break-word"
  },
  cardActions: {
    flex: 1
  },
  cardButton: {
    width: "100%"
  }
});
function DefaultConfigCard(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { list, proCategory } = props;
  const dispatch = useDispatch();
  const [loading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const country = useSelector(state => state.productSelector.country);
  const countrySelectedDropdown = useSelector(
    state => state.productSelector.countrySelectedDropdown
  );
  const displayBusinessModel = useSelector(
    state => state.productSelector.displayBusinessModel
  );
  const businessModel = useSelector(
    state => state.productSelector.businessModel
  );
  const {
    countryCode = "US",
    resellerID,
    mDCPOrgID = "99",
    routeToMarket,
    isStandaloneAccess = false,
    userType,
    clientLocale
  } = React.useContext(ExternalProps);
  const locale = clientLocale
    ? clientLocale.indexOf("_") !== -1
      ? clientLocale
      : clientLocale + "_" + countryCode
    : "en_US";
  const currentCountry = isStandaloneAccess
    ? countrySelectedDropdown
      ? countrySelectedDropdown
      : country
    : countryCode;
  const showGADate = useSelector(state => state.productSelector.showGADate);
  const isSnippetLaunch = localStorage.getItem("isSnippetLaunch") === "true";
  const setConfigurePath = configtext => {
    if (isSnippetLaunch) {
      document.getElementById("product-selector").style.display = "none";
      const snippetContainer = document.createElement('div');
      snippetContainer.id = "oneConfigEmbed";
      document.body.appendChild(snippetContainer);
      // let getTransactionURL = `${process.env.REACT_APP_GRAPHQL_BASE_URL}/model`;
      const config = {
        token: window.authToken,
        gateway: "https://dev-gpl-ocsnpt.hpcloud.hp.com/model",
        configId: configtext.configID,
        country: currentCountry,
        originatingAsset: "OCIQ",
        routeToMarket: displayBusinessModel
          ? businessModel
          : routeToMarket || "Direct",
        resellerID: resellerID || "",
        mDCPOrgID: mDCPOrgID,
        banded: false,
        priceId: "ECI0088693",
        priceDesc: "ININRDP",
        contractId: "W52P1J-19-D-0054-TEST",
        openMrktId: "ECI0025807",
        productType:
          proCategory && proCategory === "print" ? "print" : "compute",
        userType:
          userType?.toUpperCase() === "PARTNER" ? "Partner" : "Internal",
        language: locale
      };
      let requireCleanup;
      if (requireCleanup) {
        window.OneConfigEmbed.cleanUp();
      }
      requireCleanup = true;
      window.OneConfigEmbed.init(config);
    } else {
      localStorage.setItem("configName", configtext.name);
      setSelectedConfig(configtext);
      setOpen(true);
    }
  };
  const handleFullscreenModalClose = () => {
    batch(() => {
      dispatch({
        type: SET_CONFIG_NAME,
        payload: ""
      });
      dispatch({
        type: SET_ACCESS_TYPE,
        payload: ""
      });
    });
    setOpen(false);
  };
  const listName = list && list.name ? list.name.substring(0, 100) : "";
  const baseUnit = list && list.baseUnits ? list.baseUnits.join(", ") : "";
  const gadateFmt = gadateFormat(list?.gaDate);
  const gadateTooltip = showGADate && dateCompare(list?.gaDate);
  return (
    <>
      <Card>
        {showGADate && dateCompare(list?.gaDate) && (
          <Grid container item xs={12} sm={12} lg={12}>
            <CardHeader
              title={t("common:GADate.startDate").replace("{0}", gadateFmt)}
              classes={{ root: classes.cardHeader }}
            />
          </Grid>
        )}
        <CardActionArea disableRipple={true}>
          <Grid container className={classes.root} direction="row">
            <Grid item xs={3} classes={{ root: classes.gridHeight }}>
              <CardMedia
                className={classes.media}
                image={list.imageURL || list.imageUrl}
                title={list.name}
                classes={{ root: classes.padding }}
              />
            </Grid>
            <Grid item xs={9} sm={9} className={classes.root}>
              <CardContent style={{ padding: 8 }}>
                <Typography
                  className={classes.cardEllipsis}
                  variant="subtitle2"
                  component="p"
                >
                  {listName}
                </Typography>
              </CardContent>
            </Grid>
            <Grid item xs={11} classes={{ root: classes.baseUnit }}>
              {baseUnit !== "" && (
                <CardContent classes={{ root: classes.ellipsis }}>
                  <TextOnlyTooltip title={list.baseUnits.join(", ")}>
                    <Typography variant="subtitle2">
                      <b>Base Unit:</b> {list.baseUnits.join(", ")}
                    </Typography>
                  </TextOnlyTooltip>
                </CardContent>
              )}
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center">
            <CardActions className={classes.cardActions}>
              <CpqButton
                onClick={() => setConfigurePath(list)}
                loading={loading}
                disabled={loading}
                color="primary"
                size="small"
                className={classes.cardButton}
                // href={`${getConfigurationLink(quote, list.configURL)}`}
              >
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    gadateTooltip
                      ? t("common:GADate.text3").replace("{0}", gadateFmt)
                      : t("common:productSelector.button.Configure")
                  }
                  classes={
                    gadateTooltip && {
                      tooltip: classes.tooltipPopper
                    }
                  }
                >
                  <Typography
                    variant="button"
                    classes={{ root: classes.ellipsisButton }}
                  >
                    {t("common:productSelector.button.Configure")}
                  </Typography>
                </Tooltip>
              </CpqButton>
              <FullscreenModal
                isOpen={open}
                handleModalClose={() => handleFullscreenModalClose()}
              >
                <ConfiguratorContainer
                  config={selectedConfig}
                  proCategory={proCategory}
                  setOpen={setOpen}
                ></ConfiguratorContainer>
              </FullscreenModal>
            </CardActions>
          </Grid>
        </CardActionArea>
      </Card>
    </>
  );
}

export default DefaultConfigCard;
