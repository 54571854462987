export const SET_EXPAND_COLLAPSE = "SET_EXPAND_COLLAPSE";
export const SET_EXPAND_COLLAPSE_PANEL = "SET_EXPAND_COLLAPSE_PANEL";
export const UPDATE_TAB_EXPAND = "UPDATE_TAB_EXPAND";
export const CONFIG_STEP_SELECTION = "CONFIG_STEP_SELECTION";
export const SET_CONFIG_LOAD_MODEL = "SET_CONFIG_LOAD_MODEL";
export const LOAD_CONFIG_STEPPER = "LOAD_CONFIG_STEPPER";
export const WEB_ASSEMBLY_UPDATE_MODEL = "WEB_ASSEMBLY_UPDATE_MODEL";
export const SET_ACCESS_TYPE = "SET_ACCESS_TYPE";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";

export const TOKEN_VERIFY_REQUEST = "TOKEN_VERIFY_REQUEST";
export const TOKEN_VERIFY_SUCCESS = "TOKEN_VERIFY_SUCCESS";
export const TOKEN_VERIFY_FAIL = "TOKEN_VERIFY_FAIL";

export const TOKEN_RENEW_REQUEST = "TOKEN_RENEW_REQUEST";
export const TOKEN_RENEW_SUCCESS = "TOKEN_RENEW_SUCCESS";
export const TOKEN_RENEW_FAIL = "TOKEN_RENEW_FAIL";
export const SET_CONFIG_NAME = "SET_CONFIG_NAME";
export const SET_CONFIG_NAME_SAVE = "SET_CONFIG_NAME_SAVE";
export const RECEIVE_ACCESS_TOKEN = "RECEIVE_ACCESS_TOKEN";
export const CLEAR_USER_INFO = "CLEAR_USER_INFO";
export const SET_MODEL_ID = "SET_MODEL_ID";

export const CONFIG_USER_LOGOUT = "CONFIG_USER_LOGOUT";
export const SET_FILTERED_CHOICES = "SET_FILTERED_CHOICES";
export const SET_SEARCHED_TEXT = "SET_SEARCHED_TEXT";
export const SET_FILTERED_LOC = "SET_FILTERED_LOC";
export const CHECKED_FILTER_PARTS = "CHECKED_FILTER_PARTS";

export const SET_CONFIG_BOM = "SET_CONFIG_BOM";
export const SET_SHOW_BOM_SECTION_FLAG = "SET_SHOW_BOM_SECTION_FLAG";
export const SET_WARNING = "SET_WARNING";
export const SET_ERROR_DATA = "SET_ERROR_DATA";
export const SET_SAVE_CONFIG_RESPONSE = "SET_SAVE_CONFIG_RESPONSE";
export const SET_CHAIDITEMS_LIST = "SET_CHAIDITEMS_LIST";
export const SET_RADIO_CHAIDITEMS_LIST = "SET_RADIO_CHAIDITEMS_LIST";
export const SET_LOADING_SELECTION = "SET_LOADING_SELECTION";
export const SET_KMAT_ID = "SET_KMAT_ID";
export const SET_SPC_ID = "SET_SPC_ID";
export const SET_ORIGIN_SELECTED_ITEMS = "SET_ORIGIN_SELECTED_ITEMS";
export const SET_ORIGINAL_ACCESS_TYPE = "SET_ORIGINAL_ACCESS_TYPE";
export const SET_AUTO_EXPAND = "SET_AUTO_EXPAND";
export const BOM_SELECTION = "BOM_SELECTION";
export const SET_SHOW_PREAPPROVED_CONFIG_BUTTON =
  "SET_SHOW_PREAPPROVED_CONFIG_BUTTON";
export const SET_IS_CONFIG_OVERDATE = "SET_IS_CONFIG_OVERDATE";

export const SET_SHOW_TOP_RECOMMEND = "SET_SHOW_TOP_RECOMMEND";
export const SET_SHOW_ADDITIONAL = "SET_SHOW_ADDITIONAL";
export const SET_CAREPACKS_STATE = "SET_CAREPACKS_STATE";
export const SET_NOTES_ATTACHMENTS = "SET_NOTES_ATTACHMENTS"; 
