import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BOM_SELECTION, UPDATE_TAB_EXPAND } from "../actions/Types";
import { ExternalProps } from "../contexts/externalPropsContext";
import {
  calculateDays,
  errorCodeFilter,
  icConflictsFilter,
  obsoleteDateFormat,
  transformDateFormat
} from "../lib/common/util";
import {
  getAllDataExpandedOrCollapsedManul,
  isConfilctsExist,
  handleDuplicateItemConflicts
} from "../services/utils";
import ItemMultipleCheckBox from "./ItemMultipleCheckbox";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    boxShadow: "0px 5px 12px rgb(0 0 0 / 25%) !important",
    borderRadius: "4px !important",
    fontSize: theme.typography.pxToRem(12)
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "50%",
    flexShrink: 0,
    color: "#000000",
    fontWeight: 400,
    padding: "8px 0px 0px 0px"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(16),
    color: "#000000",
    fontWeight: 300,
    padding: "8px 0px 0px 18px"
    // marginLeft: "1.5rem"
  },
  choiceLevelErrmsgCls: {
    backgroundColor: "red",
    color: "white",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    display: "grid !important"
  },
  choiceLevelMarginTop: {
    marginTop: "0px !important"
  },
  warningStyle: {
    backgroundColor: "#ffbf00",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5
  },
  warningErrorStyle: {
    backgroundColor: "#FD0032",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5
  },
  caption: {
    paddingTop: "7px"
  },
  currencyStyle: {
    fontWeight: "bold",
    fontSize: "12px"
  },
  captionStyle: {
    fontSize: "12px",
    paddingTop: "3px"
  }
}));

function ChaidMultiple({ chaid, model, handleItemChange ,handleQuantityChange}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const expandAll = useSelector(state => state.configFilterData.expandAll);
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const errorData = useSelector(state => state.configFilterData.errorData);
  const showWarning = useSelector(state => state.configFilterData.showWarning);
  const bomSelected = useSelector(state => state.configFilterData.bomSelected);
  const autoExpandChoice = useSelector(
    state => state.configFilterData.autoExpandChoice
  );
  const [expanded, setExpanded] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState(false);
  const { currencyCode } = React.useContext(ExternalProps);
  const handleChange = e => {
    e.preventDefault();
    setExpanded(prevState => !prevState);
    setButtonClicked(true);
  };
  useEffect(() => {
    if (autoExpandChoice?.length > 0) {
      autoExpandChoice.forEach(choiceId => {
        if (choiceId === chaid.chaId) {
          setExpanded(true);
        }
      });
    } else {
      retainChoiceState();
    }
  }, [autoExpandChoice]);

  useEffect(() => {
    if (bomSelected === chaid.chaId) {
      setExpanded(true);
      dispatch({ type: BOM_SELECTION, payload: "" });
    }
  }, [bomSelected]);
  //it will dispatch an action and update only the relevant choiceid with an inverse of current expand status
  useEffect(() => {
    if (buttonClicked) {
      const relevantExpandData = expandAll.find(
        expandedData => expandedData.tab === defaultConfigStepSlected
      );
      if (relevantExpandData) {
        relevantExpandData.choicesExpandState[chaid.chaId] = expanded;
        if (
          defaultConfigStepSlected === "carePacks" &&
          (chaid.chaId === "99999" || chaid.chaId === "99998")
        ) {
          if (relevantExpandData.choicesExpandState.hasOwnProperty("EUCPQ"))
            relevantExpandData.choicesExpandState["EUCPQ"] = expanded;
          if (relevantExpandData.choicesExpandState.hasOwnProperty("ELECTCPQ"))
            relevantExpandData.choicesExpandState["ELECTCPQ"] = expanded;
        }
      }

      const expand = getAllDataExpandedOrCollapsedManul(
        relevantExpandData.choicesExpandState
      )[0]
        ? true
        : getAllDataExpandedOrCollapsedManul(
            relevantExpandData.choicesExpandState
          )[1]
        ? false
        : relevantExpandData.expand;
      const payload = {
        tab: defaultConfigStepSlected,
        expand,
        choicesExpandState: relevantExpandData.choicesExpandState,
        flag: true,
        isClickExpandOrCollapse: false
      };
      dispatch({
        type: UPDATE_TAB_EXPAND,
        payload
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, buttonClicked]);

  const renderWarning = (val, formatEod, idx) => {
    return (
      <Box className={classes.warningStyle} padding={1} key={idx}>
        <Typography align="center" variant={"subtitle2"}>
          {`${t("common:configurator:obsolete90days")} - ${
            val.partno
          } ${formatEod}`}
        </Typography>
      </Box>
    );
  };

  const retainChoiceState = () => {
    if (expandAll.length && defaultConfigStepSlected) {
      const relevantTabData = expandAll.find(
        expandObject => expandObject.tab === defaultConfigStepSlected
      );
      if (
        relevantTabData &&
        relevantTabData.choicesExpandState &&
        Object.keys(relevantTabData.choicesExpandState).includes(chaid.chaId)
      ) {
        const icConflicts = icConflictsFilter(
          model?.conflicts[0]?.icConflicts,
          chaid
        );
        if (
          icConflicts &&
          (chaid.chaId === "99999" ||
            chaid.chaId === "99998" ||
            chaid.chaId.startsWith("CAPQ_"))
        ) {
          setExpanded(true);
        } else {
          setExpanded(relevantTabData.choicesExpandState[chaid.chaId]);
        }
      }
    }
  };

  //this will be triggered on component mount and will set the local state (expanded) after fetching results from from the redux store
  useEffect(() => {
    retainChoiceState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandAll, defaultConfigStepSlected]);

  useEffect(() => {
    if (localStorage.getItem("isDebug") === "true") {
      console.log("Inside Chaid multiple component ");
    }
  }, []);

  let itemPriceObj = 0;
  let itemPrice;
  let labelDisplay = [];
  let topLabelDisplay = [];

  if (defaultConfigStepSlected === "monitoringAccesories") {
    if (Array.isArray(chaid.firstItem) && Array.isArray(chaid.lastItem)) {
      chaid.items.forEach(item => {
        if (item.selected) {
          labelDisplay.push(`${item.partno} : ${item.partdes} `);
          itemPrice = `${item.listPrice}`;
        }
      });
    } else {
      for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
        if (
          model &&
          model.Items &&
          model.Items[i].selected &&
          model.Items[i].priority !== "" &&
          parseInt(model.Items[i].priority) >= 0 &&
          parseInt(model.Items[i].priority) <= 5
        ) {
          labelDisplay.push(
            `${model.Items[i].partno} : ${model.Items[i].partdes} `
          );
          itemPrice = `${model.Items[i].netPrice}`;
        } else if (
          model &&
          model.Items &&
          model.Items[i].selected &&
          parseInt(model.Items[i].priority) > 5
        ) {
          labelDisplay.push(
            `${model.Items[i].partno} : ${model.Items[i].partdes} `
          );
          itemPrice = `${model.Items[i].netPrice}`;
        } else if (model && model.Items && model.Items[i].selected) {
          labelDisplay.push(
            `${model.Items[i].partno} : ${model.Items[i].partdes} `
          );
          itemPrice = `${model.Items[i].netPrice}`;
        }
      }
    }
  } else {
    if (Array.isArray(chaid.firstItem) && Array.isArray(chaid.lastItem)) {
      chaid.items.forEach(item => {
        if (item.selected) {
          labelDisplay.push(`${item.partno} : ${item.partdes} `);
          itemPrice = `${item.listPrice}`;
        }
      });
    } else {
      for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
        if (
          model &&
          model.Items &&
          model.Items[i].selected &&
          model.Items[i].priority !== "" &&
          chaid.chaDes === "Top Recommended Care Packs" &&
          parseInt(model.Items[i].priority) >= 0 &&
          parseInt(model.Items[i].priority) <= 5
        ) {
          topLabelDisplay.push(
            `${model.Items[i].partno} : ${model.Items[i].partdes} `
          );
          itemPrice = `${model.Items[i].netPrice}`;
        } else if (
          model &&
          model.Items &&
          model.Items[i].selected &&
          chaid.chaDes !== "Top Recommended Care Packs" &&
          parseInt(model.Items[i].priority) > 5
        ) {
          labelDisplay.push(
            `${model.Items[i].partno} : ${model.Items[i].partdes} `
          );
          itemPrice = `${model.Items[i].netPrice}`;
        }
      }
    }
  }

  //const chaidReq = chaid.required || chaid.selcon ? true : false;
  //let ld;
  let ldDisplay;
  if (labelDisplay.length === 1) {
    ldDisplay = labelDisplay;
  } else if (labelDisplay.length > 1) {
    ldDisplay = "";
  } else {
    ldDisplay = "None";
  }

  let topDisplay;
  if (topLabelDisplay.length === 1) {
    topDisplay = topLabelDisplay;
  } else if (topLabelDisplay.length > 1) {
    topDisplay = "";
  } else {
    topDisplay = "None";
  }

  let visibleItems = chaid.items.filter(
    part => (part.visible && part.precon >= 0) || part.selected
  );
  let isVisible =
    chaid.required ||
    chaid.selcon ||
    chaid.items.some(
      part =>
        part.partno !== "" &&
        (part.visible || part.selected) &&
        part.inode >= chaid.firstItem &&
        part.inode <= chaid.lastItem
    );
  let itemLevelError = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.itemConflictsList?.map(res =>
      errorData && errorData.name === chaid.chaId
        ? itemLevelError.push(" " + res.effect.chaDes)
        : null
    );
  const isConflictsExist =
    isConfilctsExist(model, chaid) && itemLevelError.length === 0;
  if (window._DEBUG) {
    if (itemLevelError?.length && localStorage.getItem("isDebug") === "true") {
      console.log("itemLevelError=", itemLevelError, ", MODEL=", model);
    }
  }
  return (
    isVisible && (
      <Box
        className={`${classes.root} choice-container data-${chaid.chaId}`}
        marginBottom="8px"
        key={"chaid-" + chaid.chaId}
        id={"chaid-" + chaid.chaId}
        data-order={
          icConflictsFilter(model?.conflicts[0]?.icConflicts, chaid)
            ? "show"
            : "hide"
        }
      >
        <Box>
          {chaid &&
            chaid.items &&
            chaid.items.map((val, idx) => {
              let formatEod = obsoleteDateFormat(val.esDate);
              const days = calculateDays(formatEod);
              const isDateGreater = new Date(formatEod) > new Date();
              return val.selected &&
                val.visible &&
                days <= 90 &&
                isDateGreater &&
                isConflictsExist
                ? renderWarning(
                    val,
                    transformDateFormat(val.esDate, "MM/DD/YYYY"),
                    idx
                  )
                : null;
            })}
        </Box>
        <Accordion expanded={expanded} onChange={handleChange}>
          <AccordionSummary
            className={
              showWarning && showWarning.warning
                ? ""
                : classes.choiceLevelMarginTop
            }
            expandIcon={<ExpandMoreIcon />}
            aria-controls="itemPanelbh-content"
            id="itemPanelbh-header"
          >
            <Box flex={1} flexDirection="column">
              {/* `${chaid.required} `}  {`${ld} ` */}
              {/* error part starts */}
              {/* {Change to component form} */}
              <ErrorPart chaid={chaid} model={model} expanded={expanded} />
              <Box display="flex">
                <Typography
                  className={classes.heading}
                  component={"span"}
                  variant={"body2"}
                >
                  {chaid.chaDes}
                </Typography>
                <Typography
                  className={classes.secondaryHeading}
                  component={"span"}
                  variant={"body2"}
                >
                  {topDisplay && chaid.chaDes == "Top Recommended Care Packs"
                    ? topDisplay
                    : ""}
                  {ldDisplay && chaid.chaDes !== "Top Recommended Care Packs"
                    ? ldDisplay
                    : ""}
                </Typography>
              </Box>
              {labelDisplay &&
              chaid.chaDes !== "Top Recommended Care Packs" &&
              labelDisplay.length === 1 ? (
                chaid.items && chaid.items.length > 1 ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.captionStyle}
                  >
                    {t("common:configurator:priceOfPart")} &nbsp;
                    <Typography
                      color="primary"
                      className={classes.currencyStyle}
                      component="span"
                      variant="caption"
                    >
                      {currencyCode} &nbsp;
                      {Math.abs(isNaN(itemPrice) ? 0 : itemPrice).toFixed(2)}
                    </Typography>
                  </Box>
                ) : (
                  chaid.items &&
                  chaid.items.length !== 0 && (
                    <Box
                      display="flex"
                      alignItems="center"
                      className={classes.captionStyle}
                    >
                      {t("common:configurator:priceOfPart")} &nbsp;
                      <Typography
                        color="primary"
                        className={classes.currencyStyle}
                      >
                        {currencyCode} &nbsp;
                        {Math.abs(isNaN(itemPrice) ? 0 : itemPrice).toFixed(2)}
                      </Typography>
                      . {t("common:configurator:onlyComponent")}.
                    </Box>
                  )
                )
              ) : topLabelDisplay &&
                chaid.chaDes == "Top Recommended Care Packs" &&
                topLabelDisplay.length === 1 ? (
                chaid.items && chaid.items.length > 1 ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.captionStyle}
                  >
                    {t("common:configurator:priceOfPart")} &nbsp;
                    <Typography
                      color="primary"
                      className={classes.currencyStyle}
                      component="span"
                      variant="caption"
                    >
                      {currencyCode} &nbsp;
                      {Math.abs(isNaN(itemPrice) ? 0 : itemPrice).toFixed(2)}
                    </Typography>
                  </Box>
                ) : (
                  chaid.items &&
                  chaid.items.length !== 0 && (
                    <Box
                      display="flex"
                      alignItems="center"
                      className={classes.captionStyle}
                    >
                      {t("common:configurator:priceOfPart")} &nbsp;
                      <Typography
                        color="primary"
                        className={classes.currencyStyle}
                      >
                        {currencyCode} &nbsp;
                        {Math.abs(isNaN(itemPrice) ? 0 : itemPrice).toFixed(2)}
                      </Typography>
                      . {t("common:configurator:onlyComponent")}.
                    </Box>
                  )
                )
              ) : (
                expanded === false &&
                (labelDisplay.length > 1 ||
                (topLabelDisplay.length > 1 &&
                  chaid.chaDes === "Top Recommended Care Packs") ? (
                  <Typography className={classes.captionStyle}>
                    {t("common:configurator:expandToView")}
                  </Typography>
                ) : (
                  <Typography className={classes.captionStyle}>
                    {`${t("common:configurator:clickToPick")} ${chaid.chaDes}`}
                  </Typography>
                ))
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ marginTop: "-15px" }}>
            {expanded && (
              <ItemMultipleCheckBox
                chaid={chaid}
                model={model}
                handleItemChange={handleItemChange}
                selectedItemPrice={itemPriceObj}
                handleQuantityChange = {handleQuantityChange}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  );
}

export default ChaidMultiple;

export const ErrorPart = ({ chaid, model, expanded, setMargin }) => {
  const expandedMargin = { margin: "-22px -52px 15px -16px" };
  const collapsedMargin = { margin: "-13px -52px 15px -16px" };
  const parentMargin = { margin: "8px 0px -15px 0px " };
  const classes = useStyles();
  const { t } = useTranslation();
  const errorData = useSelector(state => state.configFilterData.errorData);
  const loadingSelection = useSelector(
    state => state.configFilterData.loadingSelection
  );
  const localizationChDes = {
    SYS_LOC_OPTION: "System Localization Options",
    OS_LOC_OPTION: "OS Localization Options",
    KYBD_LOC_OPTION: "Keyboard Localization Options"
  };

  const isLoadingSelection =
    model &&
    model.Items &&
    model.Items.some(item => item.inode === loadingSelection);

  let objCause;
  model &&
    model.conflicts &&
    model.conflicts[0]?.itemConflictsList?.some(res => {
      if (res.effect.chaId === chaid.chaId) {
        objCause =
          model?.conflicts[0]?.itemConflictsList.length > 0 && res.cause;
        return true;
      } else if (
        chaid.chaId === "99999" &&
        (res.effect.chaId.includes("EUCPQ") ||
          res.effect.chaId.includes("ELECTCPQ"))
      ) {
        let RecommendErr = document.querySelectorAll(".data-99999");
        if (RecommendErr[0]?.dataset?.order === "show") {
          objCause =
            model?.conflicts[0]?.itemConflictsList.length > 0 && res.cause;
          return true;
        } else return false;
      } else if (
        chaid.chaId === "99998" &&
        (res.effect.chaId.includes("EUCPQ") ||
          res.effect.chaId.includes("ELECTCPQ"))
      ) {
        let additionalErr = document.querySelectorAll(".data-99998");
        if (additionalErr[0]?.dataset?.order === "show") {
          objCause =
            model?.conflicts[0]?.itemConflictsList.length > 0 && res.cause;
          return true;
        } else return false;
      } else return false;
    });

  const getChaid = chaid => {
    if (chaid.chaId.startsWith("CAPQ_")) {
      let index = chaid.chaId.indexOf("_additional");
      if (index !== -1) {
        chaid = chaid.chaId.substring(0, index);
      } else {
        index = chaid.chaId.indexOf("_recommended");
        if (index !== -1) chaid = chaid.chaId.substring(0, index);
      }
    }
    return chaid;
  };

  let effects = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.itemConflictsList?.map(itemConflict => {
      if (itemConflict.effect.chaId === chaid.chaId) {
        !effects.includes(itemConflict.effect.chaDes) &&
          effects.push(itemConflict.effect.chaDes);
        return true;
      } else return false;
    });
    const updatedItemConflictsList = handleDuplicateItemConflicts(model)
  return (
    <>
      {(!isLoadingSelection ||
        (errorData.name !== chaid.chaId && isLoadingSelection)) &&
        model &&
        model.conflicts &&
        model.conflicts[0].icConflicts &&
        model.conflicts[0].icConflicts.length > 0 &&
        model.conflicts[0].icConflicts.map(res =>
          res.code === "ERR04" && res.chaId === getChaid(chaid).chaId ? (
            <div
              className={classes.choiceLevelErrmsgCls}
              style={
                setMargin
                  ? parentMargin
                  : expanded
                  ? expandedMargin
                  : collapsedMargin
              }
            >
              <Box className={classes.warningErrorStyle} padding={1}>
                <Typography align="center" variant={"subtitle2"}>
                  {`${t(
                    "common:configurator:plcConflict1"
                  )} ${chaid.chaDes.toUpperCase()} ${t(
                    "common:configurator:currentConflict5"
                  )} `}
                </Typography>
              </Box>
            </div>
          ) : null
        )}
      {(!isLoadingSelection ||
        (errorData.name !== chaid.chaId && isLoadingSelection)) &&
      !objCause &&
      effects.length === 0 &&
      model &&
      model.conflicts &&
      model.conflicts[0].icConflicts &&
      errorCodeFilter(model?.conflicts[0]?.icConflicts, "ERR03") &&
      icConflictsFilter(model?.conflicts[0]?.icConflicts, chaid) ? (
        <div
          className={classes.choiceLevelErrmsgCls}
          style={
            setMargin
              ? parentMargin
              : expanded
              ? expandedMargin
              : collapsedMargin
          }
        >
          <Box className={classes.warningErrorStyle} padding={1}>
            <Typography align="center" variant={"subtitle2"}>
              {`${t("common:configurator:currentConflict1")} ${
                chaid.chaDes
              } ${t("common:configurator:currentConflict2")} `}
            </Typography>
          </Box>
        </div>
      ) : null}
      {(!isLoadingSelection ||
        (errorData.name !== chaid.chaId && isLoadingSelection)) &&
        model &&
        model.conflicts &&
        model.conflicts[0].counterConflicts &&
        model.conflicts[0].counterConflicts.map(res =>
          res.code === "ERR05" && res.chaId === getChaid(chaid).chaId ? (
            <div
              className={classes.choiceLevelErrmsgCls}
              style={
                setMargin
                  ? parentMargin
                  : expanded
                  ? expandedMargin
                  : collapsedMargin
              }
            >
              <Box className={classes.warningErrorStyle} padding={1}>
                <Typography align="center" variant={"subtitle2"}>
                  {`${res.chaDes.toUpperCase()} ${t(
                    "common:configurator:pcieConflict4"
                  )} ${res?.partNos.map(item => " " + item)}.`}
                </Typography>
              </Box>
            </div>
          ) : null
        )}
      {/* Handling PLC conflict here */}
      {(!isLoadingSelection ||
        (errorData.name !== chaid.chaId && isLoadingSelection)) &&
        model &&
        model.conflicts &&
        model.conflicts[0].plcConflicts &&
        model.conflicts[0].plcConflicts
          .map(res =>
            res.code === "ERR06" &&
            (res.chaId === getChaid(chaid).chaId ||
              chaid?.items?.some(
                choice =>
                  res.partNo === choice.partno && res.itemiNode === choice.inode
              )) ? (
              <div
                className={classes.choiceLevelErrmsgCls}
                style={
                  setMargin
                    ? parentMargin
                    : expanded
                    ? expandedMargin
                    : collapsedMargin
                }
              >
                <Box className={classes.warningErrorStyle} padding={1}>
                  <Typography align="center" variant={"subtitle2"}>
                    {`${t("common:configurator:plcConflict1")} ${
                      chaid.chaId.includes("CAPQ")
                        ? t("common:configurator:plcConflictManda")
                        : ""
                    } ${chaid.chaDes.toUpperCase()} ${t(
                      "common:configurator:plcConflict2"
                    )} `}
                  </Typography>
                </Box>
              </div>
            ) : null
          )
          .find(err => err)}

      {/* Handling GA-Date conflict here */}
      {(!isLoadingSelection ||
        (errorData.name !== chaid.chaId && isLoadingSelection)) &&
        model &&
        model.conflicts &&
        model.conflicts[0].gaDateConflicts &&
        model.conflicts[0].gaDateConflicts.map(res =>
          res.code === "ERR07" && res.chaId === getChaid(chaid).chaId ? (
            <div
              className={classes.choiceLevelErrmsgCls}
              style={
                setMargin
                  ? parentMargin
                  : expanded
                  ? expandedMargin
                  : collapsedMargin
              }
            >
              <Box className={classes.warningErrorStyle} padding={1}>
                <Typography align="center" variant={"subtitle2"}>
                  {`${t(
                    "common:configurator:gaDateConflict1"
                  )} ${chaid.chaId.toUpperCase()} ${t(
                    "common:configurator:gaDateConflict2"
                  )} `}
                </Typography>
              </Box>
            </div>
          ) : null
        )}
      {/* error part ends */}
      {/* Handling itemConflicts conflict here */}
      {(!isLoadingSelection ||
        (errorData.name !== chaid.chaId && isLoadingSelection)) &&
        updatedItemConflictsList &&
        updatedItemConflictsList.conflicts &&
        updatedItemConflictsList.conflicts[0]?.itemConflictsList &&
        updatedItemConflictsList.conflicts[0]?.itemConflictsList?.map(res =>
          res.effect.chaId === chaid.chaId ? (
            <div
              className={classes.choiceLevelErrmsgCls}
              style={expanded ? expandedMargin : collapsedMargin}
              key={res.chaId}
            >
              <Box className={classes.warningErrorStyle} padding={1}>
                <Typography align="center" variant={"subtitle2"}>
                  {res.effect.chaId === res.cause.chaId
                    ? `${t("common:configurator:currentConflict1")}  ${
                        chaid.chaDes
                      } ${t("common:configurator:currentConflict6")} `
                    : `${t("common:configurator:currentConflict1")} ${
                        chaid.chaDes
                      }
                         ${t("common:configurator:currentConflict3")}  ${
                        res.cause.chaDes !== ""
                          ? res.cause.chaDes
                          : localizationChDes[res.cause.chaId] || ""
                      } - ${t("common:configurator:currentConflict4")} `}
                </Typography>
              </Box>
            </div>
          ) : null
        )}

      {/* {Handeling hiddenChoiceConflicts error here } */}
      {(!isLoadingSelection ||
        (errorData.name !== chaid.chaId && isLoadingSelection)) &&
        model &&
        model.conflicts &&
        model.conflicts[0]?.itemConflictsList?.length === 0 &&
        model.conflicts[0]?.plcConflicts?.length === 0 &&
        model.conflicts[0]?.counterConflicts?.length === 0 &&
        model.conflicts[0]?.hiddenChoiceConflicts &&
        model.conflicts[0]?.hiddenChoiceConflicts?.map(res =>
          res.cause.chaId === chaid.chaId ? (
            <div
              className={classes.choiceLevelErrmsgCls}
              style={expanded ? expandedMargin : collapsedMargin}
              key={res.chaId}
            >
              <Box className={classes.warningErrorStyle} padding={1}>
                <Typography align="center" variant={"subtitle2"}>
                  {`${t("common:configurator:currentConflict1")} ${chaid.chaDes}
                         ${t("common:configurator:hiddenChoiceConflict")} `}
                </Typography>
              </Box>
            </div>
          ) : null
        )}
      {/* error part ends */}
      {/* {Handeling choiceConflicts error here } */}
      {(!isLoadingSelection ||
        (errorData.name !== chaid.chaId && isLoadingSelection)) &&
        model &&
        model.conflicts &&
        model.conflicts[0]?.plcConflicts?.length === 0 &&
        model.conflicts[0]?.counterConflicts?.length === 0 &&
        model.conflicts[0]?.itemConflictsList?.length === 0 &&
        model.conflicts[0]?.hiddenChoiceConflicts?.length === 0 &&
        model.conflicts[0]?.choiceConflicts &&
        model.conflicts[0]?.choiceConflicts?.map(res =>
          res.effectChoice.chaId === chaid.chaId ? (
            <div
              className={classes.choiceLevelErrmsgCls}
              style={
                setMargin
                  ? parentMargin
                  : expanded
                  ? expandedMargin
                  : collapsedMargin
              }
              key={res.chaId}
            >
              <Box className={classes.warningErrorStyle} padding={1}>
                <Typography align="center" variant={"subtitle2"}>
                  {`${t("common:configurator:currentConflict1")} ${chaid.chaDes}
                       ${t("common:configurator:currentConflict3")}  ${
                    res.cause.chaDes
                  } - ${t("common:configurator:currentConflict4")} `}
                </Typography>
              </Box>
            </div>
          ) : null
        )}
      {/* error part ends */}
    </>
  );
};
