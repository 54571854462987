import React from "react";
import { useSelector } from "react-redux";
import { carePackAdditionalChaId } from "../../services/utils";
import ChaidMultiple from "../ChaidMultiple";

export const CarePackAdditional = props => {
  const additionalCarePacks = {
    chaId: carePackAdditionalChaId,
    chaDes: "Additional Care Packs",
    multiple: true,
    required: false,
    visible: true,
    precon: 0,
    selcon: false,
    firstItem: 99999999,
    lastItem: -1,
    isNode: -1,
    specified: true,
    isUsed: true,
    items: []
  };
  let hasAnyAdditionalPacks = false;
  window.hasAnyAdditionalPacks = false;
  let updatedFilteredChoices = JSON.parse(
    JSON.stringify(props.filteredChoices)
  );
  const searchText = useSelector(state => state.configFilterData.searchText);
  const showAdditional = useSelector(
    state => state.configFilterData.showAdditional
  );
  props.filteredChoices.forEach((chaid, index) => {
    if (chaid) {
      if (
        (chaid.chaId.includes("EUCPQ") || chaid.chaId.includes("ELECTCPQ")) &&
        !props.isChaidHideBasedOnRegion(chaid.chaId) &&
        chaid.visible
      ) {
        additionalCarePacks.firstItem = additionalCarePacks.firstItem
          ? chaid.firstItem < additionalCarePacks.firstItem
            ? chaid.firstItem
            : additionalCarePacks.firstItem
          : chaid.firstItem;
        additionalCarePacks.lastItem = additionalCarePacks.lastItem
          ? chaid.lastItem > additionalCarePacks.lastItem
            ? chaid.lastItem
            : additionalCarePacks.lastItem
          : chaid.lastItem;
        additionalCarePacks.items = [
          ...additionalCarePacks.items,
          ...chaid.items.filter(item => item.priority > 5 && item.visible)
        ];
        updatedFilteredChoices = [
          ...updatedFilteredChoices.slice(0, index),
          ...updatedFilteredChoices.slice(index + 1)
        ];
        hasAnyAdditionalPacks = additionalCarePacks.items.some(
          item =>
            item.partno.toLowerCase().includes(searchText.toLowerCase()) ||
            item.partdes.toLowerCase().includes(searchText.toLowerCase())
        );
        window.hasAnyAdditionalPacks = hasAnyAdditionalPacks;
      }
    }
  });
  return (
    (showAdditional || hasAnyAdditionalPacks) && (
      <ChaidMultiple
        key={additionalCarePacks.chaidId}
        chaid={additionalCarePacks}
        model={props.modelData}
        handleItemChange={props.handleItemChange}
        handleQuantityChange={props.handleQuantityChange}
      />
    )
  );
};
