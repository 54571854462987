import { useLazyQuery } from "@apollo/client";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { togglePopupActions } from "../../../actions/togglePopupActions";
import { ExternalProps } from "../../../contexts/externalPropsContext";
import {
  GET_CHOICEID,
  GET_DEFAULT_CONFIGURATION,
  GET_RSKU_IMAGE,
  RSKU_MATCH
} from "../../../GraphQL";
import displayNotistack from "../../../lib/common/SnackBarUtils";
import {
  generatArrayOfValues,
  getAllBomDetails,
  getCarepackData,
  getCloseMatchBomData,
  getFilteredAccessories,
  getFilteredBomData,
  getHardwareAvs,
  getMandatorySkus,
  getProductData,
  getRskuImages,
  getSimilarProductData,
  getSkuImages
} from "../../../lib/common/util";
import { HARDWARE, NA, OTHER_COMPONENTS } from "./Constants";
import ProductList from "./ProductList";
import ProductSkeleton from "./ProductSkeleton";
import SimilarProducts from "./SimilarProducts";
import TabPanel from "./TabPanel";
import WithAddToQuote from "./withAddToQuote";

const useStyles = makeStyles(theme => ({
  title: {
    margin: 0,
    textAlign: "center"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  content: {
    marginBottom: "140px"
  },
  allowMargin: {
    marginLeft: "13%",
    marginRight: "10%"
  },
  text: {
    color: "#000000"
  },
  buttonSpacing: {
    marginRight: 10
  },
  compareButton: {
    backgroundColor: "#075782",
    boxShadow:
      "0px 2px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)"
  },
  dailogContent: {
    marginBottom: 60
  },
  infoIcon: {
    cursor: "pointer"
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  paper: {
    padding: theme.spacing(2)
  }
}));

const TextTooltip = withStyles({
  tooltip: {
    maxWidth: "none"
  }
})(Tooltip);

const RskuDialog = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // states
  const {
    countryCode,
    country,
    clientLocale,
    currencyCode,
    incoterms,
    isStandaloneAccess = false,
    channelRegion,
    configRegion
  } = React.useContext(ExternalProps);
  const { addCTOToQuote } = props;
  const currency = currencyCode || modelData.currency;
  const { countryRegion } = useSelector(state => state.productSelector);
  const isNA = countryRegion === NA;
  const {
    products,
    similarProducts,
    showAddToQuote,
    carePacks,
    activeTab,
    nonHardwareImages,
    localization,
    isCreateRsku,
    isSkuCloning,
    rSKUBom,
    productCountryCode,
    productRegionCode,
    rskuImage,
    configID,
    skuConfiguration
  } = useSelector(state => state.rskuReducer);
  const categoryVal = localStorage.getItem("selectedCategory");
  const { modelData, configuration, steppers } = useSelector(
    state => state.configFilterData
  );
  const { showRequestSku } = useSelector(state => state.productSelector);
  const [isRequestRsku, setIsRequestRsku] = useState(false);
  const [isSimilar, setIsSimilar] = useState(false);
  const [isExact, setIsExact] = useState(false);
  const [noMatch, setNoMatch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const filteredAccessories = getFilteredAccessories(rSKUBom);
  const isOtherComponent = filteredAccessories?.length ? true : false;
  const [carePackProducts, setCarePackProducts] = useState(filteredAccessories);
  const [closeMatchData, setCloseMatchData] = useState([]);
  const [exactMatchData, setExactMatchData] = useState([]);
  const [hardwareProducts, setHardwareProducts] = useState({});
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [avs, setAvs] = useState(getHardwareAvs(rSKUBom));
  const [productSkeleton, setProductSkeleton] = useState(
    generatArrayOfValues(3)
  );
  const configId =
    configID ||
    skuConfiguration?.configId ||
    configuration?.configHeader?.configId ||
    "-";

  const [isFilteredExactMatch, setIsFilteredExactMatch] = useState(false);
  const [mandatorySkus, setMandatorySkus] = useState([]);
  const [eolHardware, setEolHardware] = useState(false);

  //function calls
  const handleSkUImages = React.useCallback(async () => {
    const response = await getSkuImages(
      filteredAccessories,
      productCountryCode,
      clientLocale
    );
    if (response) {
      dispatch({ type: "SET_RSKU_PRODUCT_IMAGES", payload: response.data });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [filteredAccessories.length]);

  const handleTabChange = React.useCallback((_, newValue) => {
    dispatch({
      type: "SET_ACTIVE_TAB",
      payload: newValue
    });
  }, []);

  const handleComapreDialogOpen = React.useCallback((params = {}) => {
    dispatch(togglePopupActions({ name: "compareDialog", open: true }));
  }, []);

  const handleRskuDialogClose = React.useCallback(() => {
    dispatch(togglePopupActions({ name: "rskuDialog", open: false }));
    dispatch({ type: "SET_CLEAR_ALL" });
  }, []);

  const handleRequestSkuDialogOpen = React.useCallback(() => {
    dispatch(togglePopupActions({ name: "requestSkuDialog", open: true }));
  }, []);

  const handleConfirmationDialogOpen = React.useCallback((params = {}) => {
    dispatch(togglePopupActions({ name: "confirmationDialog", open: true }));
  }, []);

  // hooks calls
  useEffect(() => {
    dispatch({ type: "SET_ALL_BOM_AV", payload: avs });
  }, [avs]);

  useEffect(() => {
    const {
      exactProduct,
      similarProduct,
      baseProduct,
      compareData,
      filteredBomData,
      closeMatchBomData
    } = hardwareProducts;

    dispatch({
      type: "SET_SEARCH_RSKU",
      payload: {
        exactMatch: exactProduct?.length ? exactProduct : [],
        similarMatch: similarProduct?.length ? similarProduct : [],
        compareData: compareData?.length ? compareData : [],
        baseProduct: baseProduct?.length ? baseProduct : [],
        carepack: carePackProducts,
        filteredBomData: filteredBomData?.length ? filteredBomData : [],
        closeMatchBomData: closeMatchBomData?.length ? closeMatchBomData : []
      }
    });
  }, [JSON.stringify(carePackProducts), JSON.stringify(hardwareProducts)]);

  useEffect(() => {
    filteredAccessories?.length ? handleSkUImages() : setIsLoading(false);
    const filteredBomData = getFilteredBomData(rSKUBom);
    setHardwareProducts(state => ({
      ...state,
      filteredBomData: filteredBomData
    }));
    const isWarning = steppers
      ?.map(stepper => {
        if (stepper.isWarning) return stepper.id;
      })
      .includes("hardware");
    setEolHardware(isWarning);
  }, []);

  useEffect(() => {
    if (!isLoading && !isEmpty(nonHardwareImages)) {
      const carePackWithImages = getCarepackData(
        filteredAccessories,
        nonHardwareImages
      );
      setCarePackProducts(carePackWithImages);
    }
  }, [isLoading]);
  useEffect(() => {
    if (closeMatchData.length) {
      // SImilar Products with 2 differences and 4 other attributes
      const closeMatchProducts = getSimilarProductData(
        currency,
        closeMatchData,
        rskuImage,
        2,
        4
      );
      // Bill of Materials
      const baseUnit = getAllBomDetails(rSKUBom, rskuImage);
      // Similar Products With all attributes
      const compareData = getSimilarProductData(
        currency,
        closeMatchData,
        rskuImage
      );

      const closeMatchBomData = getCloseMatchBomData(closeMatchData);
      setHardwareProducts(state => ({
        ...state,
        similarProduct: closeMatchProducts,
        baseProduct: [baseUnit],
        compareData: compareData,
        closeMatchBomData: closeMatchBomData
      }));
    }
    if (exactMatchData.length) {
      const localizedExcatMatchProduct = exactMatchData.filter(each =>
        each.skuNumber.includes(localization)
      );
      if (localizedExcatMatchProduct.length) {
        const exactMatchProduct = getProductData(
          localizedExcatMatchProduct[0],
          rSKUBom,
          currency,
          rskuImage
        );
        setHardwareProducts(state => ({
          ...state,
          exactProduct: exactMatchProduct
        }));
      } else {
        setIsFilteredExactMatch(true);
      }
    }
  }, [exactMatchData, closeMatchData]);

  useEffect(() => {
    if (!showSkeleton) {
      let requestRsku = isRequestRsku;
      if (isExact) {
        requestRsku = isNA ? true : false;
      } else if (isSimilar) {
        requestRsku = true;
      } else {
        requestRsku = true;
      }
      setIsRequestRsku(requestRsku);
    }
  }, [showSkeleton]);

  useEffect(() => {
    modelData.systemLocOptions.forEach(option => {
      if (option.selected && option.visible) {
        dispatch({ type: "SET_LOCALIZATION", payload: option.locOption });

        return;
      }
    });
  }, [modelData]);
  // middleware calls
  const [getRskuImage] = useLazyQuery(GET_RSKU_IMAGE, {
    onCompleted(response) {
      if (response?.getProductImages?.length) {
        const baseProductImages = getRskuImages(response.getProductImages);
        dispatch({
          type: "SET_RSKU_IMAGE",
          payload: baseProductImages
        });
      }
    }
  });
  const [getRskuMatch] = useLazyQuery(RSKU_MATCH, {
    onCompleted(response) {
      if (response) {
        const { exactMatchSkus, closeMatchSkus, errorMessage } =
          response.getSkuMatches;
        if (!isEmpty(exactMatchSkus)) {
          setIsExact(true);
          setExactMatchData(exactMatchSkus);
        } else if (!isEmpty(closeMatchSkus)) {
          setCloseMatchData(closeMatchSkus);
          setIsSimilar(true);
        } else {
          setNoMatch(true);
        }
        setShowSkeleton(false);
      }
    },
    onError({ graphQLErrors, networkError }) {
      setNoMatch(true);
      setShowSkeleton(false);
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (localStorage.getItem("isDebug") === "true") {
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
          }
          displayNotistack.error(
            false,
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      }
      if (networkError) {
        if (localStorage.getItem("isDebug") === "true") {
          console.log(networkError);
        }
      }
    }
  });

  const [getChoiceID] = useLazyQuery(GET_CHOICEID, {
    fetchPolicy: "no-cache",
    onCompleted({ getMandatoryChoiceIDs }) {
      if (localStorage.getItem("isDebug") === "true") {
        console.log("getMandatoryChoiceIDs", getMandatoryChoiceIDs);
      }
      const filteredSku = getMandatorySkus(getMandatoryChoiceIDs, rSKUBom);
      setMandatorySkus(filteredSku);
      getRskuImage({
        variables: {
          skuNumbers: [rSKUBom[0].partNumber]
        }
      });
      getRskuMatch({
        variables: {
          filter: {
            configId: configId.toString(),
            country: modelData?.country || productCountryCode,
            incoTerm: modelData?.incoTerm || incoterms,
            region: productRegionCode,
            numberOfRecord: 3,
            skuNumbers: avs,
            closeMatchSKUNumbers: filteredSku,
            localization,
            currency
          }
        }
      });
    },
    onError({ graphQLErrors, networkError }) {
      setNoMatch(true);
      setShowSkeleton(false);
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (localStorage.getItem("isDebug") === "true") {
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
          }
          displayNotistack.error(
            false,
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      }
      if (networkError) {
        if (localStorage.getItem("isDebug") === "true") {
          console.log(networkError);
        }
      }
    }
  });

  const [getCategory] = useLazyQuery(GET_DEFAULT_CONFIGURATION, {
    fetchPolicy: "no-cache",
    onCompleted({ catalogLookup }) {
      const category = catalogLookup?.data?.searchCatalog?.length
        ? catalogLookup?.data?.searchCatalog[0].category
        : "";

      dispatch({ type: "SET_SELECTED_CATEGORY", payload: category });
      getChoiceID({
        variables: {
          category
        }
      });
    },
    onError(error) {
      getChoiceID({
        variables: {
          category: categoryVal
        }
      });
    }
  });

  useEffect(() => {
    isSkuCloning
      ? getCategory({
          variables: {
            filter: {
              baseUnit: rSKUBom[0].partNumber,
              countryCode: productCountryCode
            }
          }
        })
      : getChoiceID({
          variables: {
            category: categoryVal
          }
        });
  }, []);

  return (
    <>
      <Dialog fullScreen open={true} onClose={handleRskuDialogClose} fullWidth>
        <DialogTitle disableTypography className={classes.title}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {t("common:rsku.dialog.mainTitle")}
          </Typography>
          <IconButton
            onClick={handleRskuDialogClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        {isLoading ? (
          <CircularProgress size={24} className={classes.loader} />
        ) : (
          <DialogContent className={classes.dailogContent}>
            <Grid container spacing={0} className={classes.content}>
              <Grid item xs={12}>
                <Box sx={{ pl: 24 }}>
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    className={classes.tabs}
                  >
                    <Tab label={HARDWARE} />
                    <Tab
                      label={OTHER_COMPONENTS}
                      disabled={!isOtherComponent}
                    />
                  </Tabs>
                </Box>
              </Grid>
              <Grid item xs={12} className={classes.allowMargin}>
                <TabPanel value={activeTab} index={0} style={{ width: "100%" }}>
                  {showSkeleton ? (
                    productSkeleton.map(each => <ProductSkeleton />)
                  ) : (
                    <>
                      {(isExact || isSimilar) && (
                        <DialogContentText className={classes.text}>
                          {t("common:rsku.dialog.subTitle")}
                        </DialogContentText>
                      )}
                      <Box>
                        {isSimilar || noMatch ? (
                          <Paper variant="outlined" className={classes.paper}>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: "bold" }}
                            >
                              {t("common:rsku.dialog.noMatch")}
                            </Typography>
                            <Typography
                              variant="caption"
                              style={{ color: "#FF0000" }}
                            >
                              {/* Add below error message in locales  once after getting proper error text */}
                              {!mandatorySkus?.length &&
                                noMatch &&
                                "OneConfig Category Lookup is failed !"}
                            </Typography>
                          </Paper>
                        ) : (
                          <>
                            {isExact && isFilteredExactMatch ? (
                              <Paper
                                variant="outlined"
                                className={classes.paper}
                              >
                                <Typography
                                  variant="h6"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {t("common:rsku.dialog.noFilteredExactMatch")}
                                </Typography>
                              </Paper>
                            ) : (
                              products.map(product => (
                                <ProductList
                                  key={product.sku}
                                  {...product}
                                  image={rskuImage}
                                />
                              ))
                            )}
                          </>
                        )}
                      </Box>
                      <Box>
                        {isSimilar && (
                          <SimilarProducts
                            data={similarProducts}
                            isSimilar={isSimilar}
                          />
                        )}
                      </Box>
                    </>
                  )}
                </TabPanel>
                <TabPanel value={activeTab} index={1} style={{ width: "100%" }}>
                  <DialogContentText className={classes.text}>
                    {t("common:rsku.dialog.otherTabTitle")}
                  </DialogContentText>
                  <Box>
                    {carePacks.map(carepack => (
                      <ProductList
                        key={carepack.sku}
                        {...carepack}
                        tabInfo={"carepack"}
                      />
                    ))}
                  </Box>
                </TabPanel>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Box sx={{ pr: 18.5 }}>
                  <DialogActions>
                    {isRequestRsku && showRequestSku && (
                      <>
                        <TextTooltip
                          arrow
                          title={
                            eolHardware
                              ? t("common:rsku.tooltip.eolHardware")
                              : t("common:rsku.tooltip.requestSKU")
                          }
                          placement="top"
                        >
                          <InfoOutlinedIcon
                            color="action"
                            className={classes.infoIcon}
                          />
                        </TextTooltip>
                        <Button
                          color="primary"
                          onClick={
                            isExact && isNA
                              ? () => handleConfirmationDialogOpen()
                              : () => handleRequestSkuDialogOpen()
                          }
                          className={classes.buttonSpacing}
                          disabled={isCreateRsku || eolHardware}
                        >
                          {t("common:rsku.button.requestRsku")}
                        </Button>
                      </>
                    )}
                    {isSimilar && (
                      <Button
                        onClick={handleComapreDialogOpen}
                        variant="contained"
                        color="primary"
                        className={`${classes.buttonSpacing} ${classes.compareButton} `}
                      >
                        {t("common:rsku.button.compare")}
                      </Button>
                    )}
                    {/* 
                    // Remove the code once hardening sprint tested succesfully
                     {(isExact || isSimilar) && (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!showAddToQuote}
                        onClick={addBTOToQuote}
                      >
                        {t("common:rsku.button.addToQuote")}
                      </Button>
                    )} */}
                    {(isExact || isSimilar) && !isStandaloneAccess && (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isStandaloneAccess || !showAddToQuote}
                        onClick={addCTOToQuote}
                      >
                        {t("common:rsku.button.addToQuote")}
                      </Button>
                    )}
                  </DialogActions>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default WithAddToQuote(RskuDialog);
