import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { isEmpty } from "lodash";
import { ConfigPdfDoc } from "../components/Configurator/ConfigPdfDoc/ConfigPdfDoc";
import { getBOMData } from "./utils";

const exportToPdf = async (newSavedConfigId, configuration, modelData,skuId) => {
  const { formattedBOMItems, grandPrice } = getBOMData(
    modelData,
    "exportToExcel",
    configuration
  );

  const fileName = !isEmpty(skuId)
  ? `${skuId}.pdf`
  : `${
      newSavedConfigId
        ? newSavedConfigId
        : configuration?.configHeader?.docType === "QU"
        ? configuration.configHeader.configId
        : "NA"
    }.pdf`;

  const blob = await pdf(
    <ConfigPdfDoc
      configuration={configuration}
      formattedBOMItems={formattedBOMItems}
      grandPrice={grandPrice}
      newSavedConfigId={newSavedConfigId}
    />
  ).toBlob();
  saveAs(blob, fileName);
};

export default exportToPdf;
