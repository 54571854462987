import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  BOM_SELECTION,
  SET_AUTO_EXPAND,
  SET_CHAIDITEMS_LIST,
  SET_ERROR_DATA,
  SET_LOADING_SELECTION,
  SET_RADIO_CHAIDITEMS_LIST,
  SET_WARNING,
  UPDATE_TAB_EXPAND,
  WEB_ASSEMBLY_UPDATE_MODEL
} from "../actions/Types";
import AccessOrganization from "../components/Configurator/ConfigExpansionDetails/AccessOrganisation";
import ConfigID from "../components/Configurator/ConfigExpansionDetails/ConfigID";
import ConfigName from "../components/Configurator/ConfigExpansionDetails/ConfigName";
import { scrollToChoice } from "../components/Configurator/ConfigStepper/ConfigStepper";
import { ExternalProps } from "../contexts/externalPropsContext";
import { calculateDays, formatDate, getStorageValue } from "../lib/common/util";
import { loadOneModel } from "../oneConfigNative/main";
import { segregatePayload, updateCarePacks } from "../oneConfigNative/util";
import {
  getAllDataExpandedOrCollapsedManul,
  getConfigData,
  processCapqName,
  shouldHideChoices
} from "../services/utils";
import CarePacksStepper from "./CarePacksStepper";
import ChaidMultiple from "./ChaidMultiple";
import ChaidSingle from "./ChaidSingle";
import Localization from "./Localization/Localization";

let itemConflicts = [];
function useConflictFocus(
  filteredChoices,
  modelData,
  expandAll,
  defaultConfigStepSlected
) {
  const dispatch = useDispatch();
  const nextConflict = useRef();
  let conflicts =
    (modelData &&
      modelData.conflicts &&
      modelData.conflicts[0].icConflicts.filter(x => {
        return (
          (x.code === "ERR04" || x.code === "ERR03") &&
          filteredChoices.filter(_f => _f.chaId === x.chaId).length > 0
        );
      })) ||
    [];

  let itemConflictsList =
    (modelData &&
      modelData.conflicts &&
      modelData.conflicts[0].itemConflictsList.filter(x => {
        return (
          filteredChoices.filter(_f => _f.chaId === x?.effect?.chaId).length > 0
        );
      })) ||
    [];

  const plcConflicts =
    (modelData &&
      modelData.conflicts &&
      modelData.conflicts[0].plcConflicts?.filter(x => {
        return (
          x.code === "ERR06" &&
          filteredChoices.filter(_f => _f.chaId === x.chaId).length > 0
        );
      })) ||
    [];

  const gaDateConflicts =
    (modelData &&
      modelData.conflicts &&
      modelData.conflicts[0].plcConflicts?.filter(x => {
        return (
          x.code === "ERR07" &&
          filteredChoices.filter(_f => _f.chaId === x.chaId).length > 0
        );
      })) ||
    [];

  if (itemConflictsList.length) {
    conflicts = [modelData?.conflicts[0]?.itemConflictsList[0]?.effect];
  } else {
    conflicts = [...conflicts, ...gaDateConflicts, ...plcConflicts];
  }

  useEffect(() => {
    if (!modelData.conflicts || modelData.conflicts.length === 0) return;
    if (
      modelData?.conflicts[0]?.itemConflictsList &&
      modelData?.conflicts[0]?.itemConflictsList[0]?.effect &&
      conflicts.indexOf(
        modelData?.conflicts[0]?.itemConflictsList[0]?.effect
      ) === -1
    ) {
      conflicts.push(modelData.conflicts[0]?.itemConflictsList[0]?.effect);
    }
    if (conflicts.length) {
      const chaids = filteredChoices.map(choice => choice.chaId);
      conflicts.sort(
        (a, b) => chaids.indexOf(a.chaId) - chaids.indexOf(b.chaId)
      );
      nextConflict.current = conflicts[0].chaId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelData.conflicts, filteredChoices, modelData]);

  itemConflicts = conflicts;
  return conflicts => {
    if (conflicts.length > 0) {
      if (expandAll.length > 0 && defaultConfigStepSlected) {
        const relevantExpandData = expandAll.find(
          expandedData => expandedData.tab === defaultConfigStepSlected
        );
        if (relevantExpandData && relevantExpandData.choicesExpandState) {
          relevantExpandData.choicesExpandState[nextConflict.current] = true;
          const expand = getAllDataExpandedOrCollapsedManul(
            relevantExpandData.choicesExpandState
          )[0]
            ? true
            : getAllDataExpandedOrCollapsedManul(
                relevantExpandData.choicesExpandState
              )[1]
            ? false
            : relevantExpandData.expand;
          const payload = {
            tab: defaultConfigStepSlected,
            expand,
            choicesExpandState: relevantExpandData.choicesExpandState,
            flag: true
          };
          dispatch({
            type: UPDATE_TAB_EXPAND,
            payload
          });
        }
      }
      let RecommendErr = document.querySelectorAll(".data-99999");
      let additionalErr = document.querySelectorAll(".data-99998");
      if (RecommendErr[0]?.dataset?.order === "show") {
        scrollToChoice("99999");
      } else if (additionalErr[0]?.dataset?.order === "show") {
        scrollToChoice("99998");
      } else {
        scrollToChoice(nextConflict.current);
      }
    }
  };
}

function Chaid(props) {
  const selectedRadioRef = useRef();
  const { tabId = "hardware" } = props;
  const { proCategory, localisationData, configurationName } = props;
  const bomData = useSelector(state => state.configFilterData.bomData);
  const showBOMSectionFlag = useSelector(
    state => state.configFilterData.showBOMSectionFlag
  );
  const carePacks = [
    {
      chaId: "99999",
      name: "recommended"
    },
    {
      chaId: "99998",
      name: "additional"
    }
  ];

  const { visibility = {} } = React.useContext(ExternalProps);
  const {
    showConfigurationID = false,
    showConfigurationName = false,
    isExpertConfig,
    showAccessType
  } = visibility;
  if (localStorage.getItem("isDebug") === "true") {
    console.log("isExpertConfig", isExpertConfig);
  }
  const checkedFilterParts = useSelector(
    state => state.configFilterData.checkedFilterParts
  );
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  const expandAll = useSelector(state => state.configFilterData.expandAll);
  const modelData = useSelector(state => state.configFilterData.modelData);
  const filteredChoices = useSelector(
    state => state.configFilterData.filteredChoices
  );
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const filteredLoc = useSelector(state => state.configFilterData.filteredLoc);
  const chaiItems = useSelector(state => state.configFilterData.chaiItems);
  const searchText = useSelector(state => state.configFilterData.searchText);
  const ocfgParams = useSelector(state => state.productSelector.ocfgParams);
  const dispatch = useDispatch();

  const [clickCount, setClickCount] = useState(0);
  const focusNext = useConflictFocus(
    filteredChoices,
    modelData,
    expandAll,
    defaultConfigStepSlected
  );
  const isGoEngine = localStorage.getItem("KB_ENGINE") === "GO";
  const userType = getStorageValue("userType");
  useEffect(() => {
    const choices = getConfigData(modelData, defaultConfigStepSlected);
    if ((searchText || checkedFilterParts) && filteredLoc.length > 0) {
      const filteredChoices = choices
        .filter(choice => filteredLoc.includes(choice.chaDes))
        .map(row => row.chaId);
      if (filteredLoc.includes("System Localization Options")) {
        filteredChoices.push("local-1");
      }
      if (filteredLoc.includes("OS Localization Options")) {
        filteredChoices.push("local-2");
      }
      if (filteredLoc.includes("Keyboard Localization Options")) {
        filteredChoices.push("local-3");
      }
      if (filteredLoc.includes("Monitor Localization Options")) {
        filteredChoices.push("local-4");
      }
      if (
        filteredLoc.includes("Customer") &&
        tabId === "configurationService" &&
        userType?.toUpperCase() !== "PARTNER"
      ) {
        filteredChoices.push("CS_C");
      }
      if (filteredChoices.length > 0) {
        dispatch({ type: SET_AUTO_EXPAND, payload: filteredChoices });
      }
    } else {
      dispatch({ type: SET_AUTO_EXPAND, payload: [] });
    }
  }, [searchText, filteredLoc]);

  async function handleQuantityChange(
    e,
    quantity,
    partno,
    chaid,
    inode,
    maxQty
  ) {
    if (quantity <= maxQty) {
      modelData.Items[inode].quantity = Number(quantity);
      let updatedModelData = modelData;
      const oneModel = new loadOneModel(updatedModelData);
      let payload = null;
      payload = {
        partQty: [
          {
            partInode: inode,
            qty: Number(quantity)
          }
        ]
      };
      const model =
        isGoEngine && !window._DISABLE_WASM
          ? window.updateQuantity &&
            window.updateQuantity(JSON.stringify(payload))
          : oneModel.updateQuantity(payload);
      if (
        localStorage.getItem("isDebug") === "true" ||
        localStorage.getItem("showTime") === "true"
      ) {
        console.log("IsSuccess", model);
      }
      if (model || window._DISABLE_WASM) {
        let modelJson = isGoEngine ? window._modelJson : model;
        dispatch({
          type: WEB_ASSEMBLY_UPDATE_MODEL,
          modelData: modelJson
        });
      }
    }
  }

  async function handleItemChange(e) {
    dispatch({ type: BOM_SELECTION, payload: "" });
    const { name, value, checked } = e.target;
    selectedRadioRef.current = e.target.closest("label");
    selectedRadioRef.current.style.opacity = 0.4;
    let currentItemId = value === "None" ? null : parseInt(value);
    const carePackObject = carePacks.find(carePack => carePack.chaId === name);

    if (value === "None") {
      dispatch({
        type: SET_LOADING_SELECTION,
        payload: "None"
      });
    } else {
      dispatch({
        type: SET_LOADING_SELECTION,
        payload: modelData.Items[currentItemId].inode
      });
    }
    setClickCount(clickCount + 1);

    // Removing _additional or _recommended text in capq based care packs
    let chaidName = name;
    if (name.startsWith("CAPQ_")) chaidName = processCapqName(chaidName);

    let selectedData = {
      name: chaidName,
      value: value
    };
    let checkboxFlag = true;
    if (value !== "None") {
      const dateFormat = formatDate(modelData.Items[currentItemId].esDate);
      const days = calculateDays(dateFormat);
      batch(() => {
        dispatch({
          type: SET_WARNING,
          payload: {
            tab: defaultConfigStepSlected,
            warning: days <= 90
          }
        });
        dispatch({
          type: SET_ERROR_DATA,
          payload: selectedData
        });
      });
      if (!e.target.checked) {
        checkboxFlag = false;
      }
    }
    let previousItemId = null;
    let payload = null;
    // let elementId = null;

    const chaidObj = modelData.Chaids.find(chaid => chaid.chaId === chaidName);
    const chaidObjItems =
      chaidObj?.items?.map(chaid => {
        chaid.tab = defaultConfigStepSlected;
        return chaid;
      }) || [];
    let chaiItemsList = [];
    if (chaidObj?.multiple) {
      if (checked && value !== "None") {
        const chaiItemsWarningsList = chaidObjItems?.filter(chaid => {
          const dateFormat1 = formatDate(chaid.esDate);
          const days1 = calculateDays(dateFormat1);
          return (
            (chaid.inode === Number(value) || chaid.selected) && days1 <= 90
          );
        });
        const combinedWarningsList = [...chaiItems, ...chaiItemsWarningsList];
        chaiItemsList = combinedWarningsList;
      } else {
        let filteredItems = chaiItems.filter(
          chaid => chaid.inode !== Number(value)
        );
        chaiItemsList = filteredItems;
      }
      const uniqueWarningsList = _.uniqBy(chaiItemsList, "inode");
      dispatch({ type: SET_CHAIDITEMS_LIST, payload: uniqueWarningsList });
    } else {
      let chaiItemsRadioList = [];
      if (checked) {
        const chaiItemsWarningsRadioList = chaidObjItems?.filter(chaid => {
          const dateFormat1 = formatDate(chaid.esDate);
          const days1 = calculateDays(dateFormat1);
          return chaid.inode === Number(value) && days1 <= 90;
        });
        chaiItemsRadioList = _.uniqBy(chaiItemsWarningsRadioList, "inode");
        const selectedItem =
          chaiItemsRadioList?.length > 0
            ? [{ [chaidObj.chaId]: chaiItemsRadioList }]
            : [];
        dispatch({ type: SET_RADIO_CHAIDITEMS_LIST, payload: selectedItem });
      }
    }

    if (chaidObj && !chaidObj.multiple) {
      // elementId = chaidObj.chaId;
      for (let i = chaidObj.firstItem; i <= chaidObj.lastItem; i++) {
        if (modelData.Items[i].selected) {
          previousItemId = modelData.Items[i].inode;
          break;
        }
      }

      if (currentItemId) {
        modelData.Items[currentItemId].selected = true;
      }
      if (previousItemId) {
        modelData.Items[previousItemId].selected = false;
        payload = {
          // changeType: ITEM_CHANGE,
          itemChanges: currentItemId
            ? [
                {
                  inputEdge: 2,
                  inputNode: previousItemId,
                  changeIn: -1
                },
                {
                  inputEdge: 2,
                  inputNode: currentItemId,
                  changeIn: 1
                }
              ]
            : [
                {
                  inputEdge: 2,
                  inputNode: previousItemId,
                  changeIn: -1
                }
              ]
        };
      } else {
        payload = {
          // changeType: ITEM_CHANGE,
          itemChanges: [
            {
              inputEdge: 2,
              inputNode: currentItemId,
              changeIn: 1
            }
          ]
        };
      }
    } else {
      payload = {
        // changeType: ITEM_CHANGE,
        itemChanges: [
          {
            inputEdge: 2,
            inputNode: currentItemId,
            changeIn: e.target.checked ? 1 : -1
          }
        ]
      };
      // elementId = currentItemId;
    }

    // functionality for checkbox deselct
    if (!checkboxFlag) {
      modelData.Items[payload.itemChanges[0].inputNode].selected = false;
    }
    console.time("radioButtonWaitingTime");
    setTimeout(
      () => {
        const { cpPayload, nonCpPayload, chaId } = segregatePayload(
          payload,
          modelData
        );
        let updatedModelData = modelData;
        if (cpPayload?.itemChanges?.length > 0) {
          updatedModelData = updateCarePacks(cpPayload, modelData, chaId);
        }
        const oneModel = new loadOneModel(updatedModelData);
        parseModelObj(
          isGoEngine && !window._DISABLE_WASM
            ? window.updateModelWithMultipleChanges &&
                window.updateModelWithMultipleChanges(JSON.stringify(payload))
            : oneModel.updateModelWithMultipleChanges(nonCpPayload),
          chaidObj || carePackObject
        );
        setTimeout(focusNext(itemConflicts));
      },
      isGoEngine ? 200 : 5
    );
    console.timeLog("radioButtonWaitingTime");
  }

  function parseModelObj(isSuccess, selectedData) {
    if (
      localStorage.getItem("isDebug") === "true" ||
      localStorage.getItem("showTime") === "true"
    ) {
      console.log("IsSuccess", isSuccess);
    }
    if (isSuccess || window._DISABLE_WASM) {
      let modelJson = isGoEngine ? window._modelJson : isSuccess;
      if (window._DEBUG) {
        printResult("window.modelJsonlocas js object has updated!");
        printResult(modelJson);
      }
      const hideChoices = ocfgParams.hideChoice;
      for (const hideChoice in hideChoices) {
        let ind = modelJson.Chaids.findIndex(
          chaid => hideChoices[hideChoice] === "Y" && chaid.chaId === hideChoice
        );
        if (ind > -1) modelJson.Chaids[ind].visible = false;
      }
      dispatch({
        type: WEB_ASSEMBLY_UPDATE_MODEL,
        modelData: modelJson
      });
      handleEvent(modelJson, selectedData);
      if (
        selectedRadioRef &&
        selectedRadioRef.current &&
        selectedRadioRef.current.style
      ) {
        selectedRadioRef.current.style.opacity = 1;
        dispatch({
          type: SET_LOADING_SELECTION,
          payload: "None"
        });
      }
      console.timeEnd("radioButtonWaitingTime");
    }
  }

  async function printResult(result) {
    if (localStorage.getItem("isDebug") === "true") {
      console.log("webassembly response", result);
    }
  }
  const handleEvent = (modelJson, selectedData) => {
    if (
      (modelJson &&
        selectedData &&
        modelJson.conflicts[0] &&
        ((modelJson.conflicts[0].icConflicts &&
          !modelJson.conflicts[0].icConflicts.find(
            item => item.chaId === selectedData.chaId && item.code === "ERR04"
          )) ||
          (modelJson.conflicts[0].itemConflicts?.cause &&
            !modelJson.conflicts[0].itemConflicts.cause[0].chaId ===
              selectedData.chaId))) ||
      (selectedData &&
        (selectedData.chaId === 99999 || selectedData.chaId === 99998))
    ) {
      if (document.querySelector("#product-selector")) {
        document.querySelector("#product-selector").dispatchEvent(
          new CustomEvent("changeItem", {
            detail: { ...modelJson, bomList: bomData, showBOMSectionFlag }
          })
        );
      } else if (document.querySelector("#configurator")) {
        document.querySelector("#configurator").dispatchEvent(
          new CustomEvent("changeItem", {
            detail: { ...modelJson, bomList: bomData, showBOMSectionFlag }
          })
        );
      }
    } else {
      if (localStorage.getItem("isDebug") === "true") {
        console.log(
          `${selectedData && selectedData.chaDes} still have conflicts`
        );
      }
    }
  };
  function showLoc(locCaption) {
    return filteredLoc.length === 0 || filteredLoc.includes(locCaption);
  }
  let customerDuplicateconflicts =
    (modelData &&
      modelData.conflicts &&
      modelData.conflicts[0].icConflicts.filter(x => {
        return x.chaId === "CS_C";
      })) ||
    [];
  const otherCustomerChoices = () => {
    const otherCustomerChoices = [];
    const filterCustomer = filteredLoc.filter(
      customer => customer === "Customer"
    );
    modelData.Chaids.forEach(chaid => {
      if (
        !customerDuplicateconflicts.length &&
        chaid.chaId === "CS_C" &&
        filterCustomer.length <= 1
      ) {
        otherCustomerChoices.push(otherCustomerChoicesComponent(chaid));
      }
    });

    return <div>{otherCustomerChoices}</div>;
  };

  const otherCustomerChoicesComponent = chaid => {
    return chaid.required ||
      chaid.selcon ||
      chaid.chaDes.toLowerCase().includes(searchText.toLowerCase()) ||
      (modelData &&
        modelData.Items &&
        modelData.Items.some(
          part =>
            part.partno === "" &&
            part.visible === true &&
            part.precon >= 0 &&
            part.inode >= chaid.firstItem &&
            part.inode <= chaid.lastItem &&
            part.partdes.toLowerCase().includes(searchText.toLowerCase())
        )) ? (
      chaid.multiple ? (
        <ChaidMultiple
          key={chaid.chaId}
          chaid={chaid}
          model={modelData}
          handleItemChange={handleItemChange}
          handleQuantityChange={handleQuantityChange}
        />
      ) : (
        <ChaidSingle
          key={chaid.chaId}
          chaid={chaid}
          model={modelData}
          handleItemChange={handleItemChange}
        />
      )
    ) : null;
  };

  const getSortedOptionObject = localOptionsArray => {
    let sortedArray =
      localOptionsArray &&
      localOptionsArray.sort((a, b) =>
        a.locOption > b.locOption ? 1 : a.locOption < b.locOption ? -1 : 0
      );

    sortedArray =
      sortedArray &&
      sortedArray.length &&
      sortedArray.map(option => {
        option["locDescrition"] = localisationData[option.locOption];
        return option;
      });
    return sortedArray;
  };

  const hardwareAddOns = modelData ? (
    <>
      {showConfigurationID && !searchText && !checkedFilterParts && (
        <ConfigID
          id={configuration?.configHeader?.configId}
          isUploadFlow={modelData?.isUploadFlow}
        />
      )}

      {showConfigurationName && !searchText && !checkedFilterParts && (
        <ConfigName name={configurationName} />
      )}
      {showAccessType && !searchText && !checkedFilterParts && (
        <AccessOrganization chaid={"access-type"} />
      )}
      {showLoc("System Localization Options") && (
        <Localization
          name="System Localization Options"
          options={getSortedOptionObject(modelData.systemLocOptions)}
          chaid={"local-1"}
        />
      )}
      {showLoc("OS Localization Options") && (
        <Localization
          name="OS Localization Options"
          options={getSortedOptionObject(modelData.osLocOptions)}
          chaid={"local-2"}
        />
      )}
      {showLoc("Keyboard Localization Options") && (
        <Localization
          name="Keyboard Localization Options"
          options={getSortedOptionObject(modelData.kybdLocOptions)}
          chaid={"local-3"}
        />
      )}
      {showLoc("Monitor Localization Options") && (
        <Localization
          name="Monitor Localization Options"
          options={getSortedOptionObject(modelData.monitorLocOptions)}
          chaid={"local-4"}
        />
      )}
    </>
  ) : null;
  return (
    <>
      <div>
        {tabId === "hardware" ? hardwareAddOns : null}
        {tabId === "carePacks" && (
          <CarePacksStepper
            proCategory={proCategory}
            handleItemChange={handleItemChange}
            handleQuantityChange={handleQuantityChange}
          />
        )}
        {tabId === "configurationService" &&
        userType?.toUpperCase() !== "PARTNER"
          ? otherCustomerChoices()
          : null}

        {/* This has been to show the filtered choices on the hard ware tab */}
        {tabId === "hardware"
          ? Object.keys(filteredLoc).length === 0
            ? filteredChoices.map(chaid =>
                chaid.visible ? (
                  chaid.multiple ? (
                    <ChaidMultiple
                      key={chaid.chaId}
                      chaid={chaid}
                      model={modelData}
                      handleItemChange={handleItemChange}
                      handleQuantityChange={handleQuantityChange}
                    />
                  ) : (
                    <ChaidSingle
                      key={chaid.chaId}
                      chaid={chaid}
                      model={modelData}
                      handleItemChange={handleItemChange}
                    />
                  )
                ) : null
              )
            : configuration?.configHeader?.rgnCd === "AP"
            ? filteredChoices
                .filter(filterChoice =>
                  filteredLoc.includes(filterChoice.chaDes)
                )
                .map(chaid =>
                  chaid.visible ? (
                    chaid.multiple ? (
                      <ChaidMultiple
                        key={chaid.chaId}
                        chaid={chaid}
                        model={modelData}
                        handleItemChange={handleItemChange}
                        handleQuantityChange={handleQuantityChange}
                      />
                    ) : (
                      <ChaidSingle
                        key={chaid.chaId}
                        chaid={chaid}
                        model={modelData}
                        handleItemChange={handleItemChange}
                      />
                    )
                  ) : null
                )
            : filteredChoices
                .filter(filterChoice =>
                  filteredLoc.includes(filterChoice.chaDes)
                )
                .map(chaid =>
                  chaid.visible ? (
                    chaid.multiple ? (
                      <ChaidMultiple
                        key={chaid.chaId}
                        chaid={chaid}
                        model={modelData}
                        handleItemChange={handleItemChange}
                        handleQuantityChange={handleQuantityChange}
                      />
                    ) : (
                      <ChaidSingle
                        key={chaid.chaId}
                        chaid={chaid}
                        model={modelData}
                        handleItemChange={handleItemChange}
                      />
                    )
                  ) : null
                )
          : null}
        {/* This is for any other tab excluding carepacks and hardware */}
        {tabId !== "carePacks" &&
          tabId !== "hardware" &&
          tabId !== "deploymentServices" &&
          filteredChoices.map(chaid =>
            chaid.visible &&
            (chaid.precon >= 0 ||
              chaid.isOverridePrecon ||
              chaid.required ||
              chaid.selcon) ? (
              chaid.multiple ? (
                <ChaidMultiple
                  key={chaid.chaId}
                  chaid={chaid}
                  model={modelData}
                  handleItemChange={handleItemChange}
                  handleQuantityChange={handleQuantityChange}
                />
              ) : (
                <ChaidSingle
                  key={chaid.chaId}
                  chaid={chaid}
                  model={modelData}
                  handleItemChange={handleItemChange}
                />
              )
            ) : null
          )}
        {tabId === "deploymentServices" &&
          (["EU", "AP", "NA"].includes(configuration?.configHeader?.rgnCd)
            ? filteredChoices
                .filter(
                  item => !shouldHideChoices(ocfgParams?.hideChoice, item.chaId)
                )
                .map(chaid =>
                  chaid.visible &&
                  (chaid.precon >= 0 ||
                    chaid.isOverridePrecon ||
                    chaid.required ||
                    chaid.selcon) ? (
                    chaid.multiple ? (
                      <ChaidMultiple
                        key={chaid.chaId}
                        chaid={chaid}
                        model={modelData}
                        handleItemChange={handleItemChange}
                        handleQuantityChange={handleQuantityChange}
                      />
                    ) : (
                      <ChaidSingle
                        key={chaid.chaId}
                        chaid={chaid}
                        model={modelData}
                        handleItemChange={handleItemChange}
                      />
                    )
                  ) : null
                )
            : filteredChoices.map(chaid =>
                chaid.visible &&
                (chaid.precon >= 0 ||
                  chaid.isOverridePrecon ||
                  chaid.required ||
                  chaid.selcon) ? (
                  chaid.multiple ? (
                    <ChaidMultiple
                      key={chaid.chaId}
                      chaid={chaid}
                      model={modelData}
                      handleItemChange={handleItemChange}
                      handleQuantityChange={handleQuantityChange}
                    />
                  ) : (
                    <ChaidSingle
                      key={chaid.chaId}
                      chaid={chaid}
                      model={modelData}
                      handleItemChange={handleItemChange}
                    />
                  )
                ) : null
              ))}
      </div>
    </>
  );
}

export default Chaid;
