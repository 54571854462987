import {
  BOM_SELECTION,
  CHECKED_FILTER_PARTS,
  CONFIG_STEP_SELECTION,
  LOAD_CONFIG_STEPPER,
  SET_ACCESS_TYPE,
  SET_AUTO_EXPAND,
  SET_CAREPACKS_STATE,
  SET_CHAIDITEMS_LIST,
  SET_CONFIG_BOM,
  SET_CONFIG_LOAD_MODEL,
  SET_CONFIG_NAME,
  SET_CONFIG_NAME_SAVE,
  SET_ERROR_DATA,
  SET_EXPAND_COLLAPSE,
  SET_EXPAND_COLLAPSE_PANEL,
  SET_FILTERED_CHOICES,
  SET_FILTERED_LOC,
  SET_IS_CONFIG_OVERDATE,
  SET_KMAT_ID,
  SET_LOADING_SELECTION,
  SET_ORIGINAL_ACCESS_TYPE,
  SET_ORIGIN_SELECTED_ITEMS,
  SET_RADIO_CHAIDITEMS_LIST,
  SET_SAVE_CONFIG_RESPONSE,
  SET_SEARCHED_TEXT,
  SET_SHOW_ADDITIONAL,
  SET_SHOW_BOM_SECTION_FLAG,
  SET_SHOW_PREAPPROVED_CONFIG_BUTTON,
  SET_SHOW_TOP_RECOMMEND,
  SET_SPC_ID,
  SET_WARNING,
  UPDATE_TAB_EXPAND,
  WEB_ASSEMBLY_UPDATE_MODEL,
  SET_NOTES_ATTACHMENTS
} from "../actions/Types";

const initialState = {
  expandAll: [],
  expandAllCount: {},
  expandCount: {},
  defaultConfigStepSlected: "hardware",
  filteredChoices: [],
  filteredLoc: [],
  showBOMSectionFlag: false,
  showWarning: {},
  modelData: {},
  errorData: {},
  chaiItems: [],
  accessType: "",
  chaiItemsRadioList: [],
  loadingSelection: {},
  kmatId: "",
  configNameRed: "",
  originSelectedItems: "[]",
  originalAccessType: "",
  haveSaved: false,
  searchText: "",
  configuration: {},
  showPreApprovedConfigButton: false,
  isConfigOverDate: false,
  showTopRecommended: false,
  showAdditional: false,
  originalData: {},
  notesAttachments:[]
};

function configFilterData(state = initialState, action) {
  switch (action.type) {
    case SET_EXPAND_COLLAPSE: {
      return {
        ...state,
        expandAll: [...state.expandAll, action.payload]
      };
    }
    case UPDATE_TAB_EXPAND:
      return {
        ...state,
        expandAll: state.expandAll.map(expandedData => {
          if (expandedData.tab === action.payload.tab) {
            return action.payload;
          } else {
            return expandedData;
          }
        })
      };

    case SET_EXPAND_COLLAPSE_PANEL: {
      let count;
      let expandByCount = -1;
      if (action.isExpanded) {
        count = state.expandCount[action.configStep] + 1;
        const countAll = state.expandAllCount[action.configStep];
        if (count === countAll) expandByCount = 1;
      } else {
        count = state.expandCount[action.configStep] - 1;
        if (count === 0) expandByCount = 0;
      }
      if (expandByCount !== -1) {
        // Only when expand by count then make action
        return {
          ...state,
          expandAll: {
            ...state.expandAll,
            [action.configStep]: Boolean(expandByCount)
          },
          expandCount: { ...state.expandCount, [action.configStep]: count }
        };
      } else {
        return {
          ...state,
          expandCount: { ...state.expandCount, [action.configStep]: count }
        };
      }
    }

    case CONFIG_STEP_SELECTION: {
      return {
        ...state,
        defaultConfigStepSlected: action.selectedStep,
        filteredChoices: []
      };
    }
    //case to store the access type when chanegd by the user
    case SET_ACCESS_TYPE: {
      return {
        ...state,
        accessType: action.payload
      };
    }

    case SET_ORIGINAL_ACCESS_TYPE: {
      return {
        ...state,
        originalAccessType: action.payload
      };
    }
    case SET_CONFIG_LOAD_MODEL: {
      const newModelData = { ...action.modelData };
      if (action.modelData) {
        // TODO: Simulation for PLC conflicts === START REMOVE
        // newModelData.conflicts[0]["plcConflicts"] = [
        //   {
        //     code: "ERR06",
        //     messageType: "PLC conflict",
        //     plcMessage: "",
        //     itemiNode: 102,
        //     chaId: "BUNIT",
        //     chaDes: "Base Unit",
        //     partNo: "3DS21AV",
        //     saDate: "20180326",
        //     esDate: "20230731"
        //   }
        // ];
        // END REMOVE

        // If there are plcConflicts,
        // put the data in model.Items respective items
        if (newModelData.conflicts[0]["plcConflicts"]) {
          newModelData.conflicts[0]["plcConflicts"].forEach(c => {
            newModelData.Items[
              c.itemiNode
            ].plcMessage = `This component is not available for this country`;
          });
        }
      }
      return {
        ...state,
        configuration: action.configuration,
        modelData: newModelData
      };
    }
    case SET_KMAT_ID: {
      return {
        ...state,
        kmatId: action.payload
      };
    }
    case SET_SPC_ID: {
      return {
        ...state,
        spcId: action.payload
      };
    }
    case LOAD_CONFIG_STEPPER: {
      return {
        ...state,
        steppers: action.steppers
      };
    }
    case SET_FILTERED_CHOICES: {
      return {
        ...state,
        filteredChoices: action.payload
      };
    }
    case SET_AUTO_EXPAND: {
      return {
        ...state,
        autoExpandChoice: action.payload
      };
    }
    case BOM_SELECTION: {
      return {
        ...state,
        bomSelected: action.payload
      };
    }
    case SET_FILTERED_LOC: {
      return {
        ...state,
        filteredLoc: action.payload
      };
    }
    case CHECKED_FILTER_PARTS: {
      return {
        ...state,
        checkedFilterParts: action.payload
      };
    }
    case WEB_ASSEMBLY_UPDATE_MODEL: {
      const newModelData = { ...action.modelData };
      if (action.modelData) {
        if (newModelData.conflicts[0]["plcConflicts"]) {
          newModelData.conflicts[0]["plcConflicts"].forEach(c => {
            newModelData.Items[
              c.itemiNode
            ].plcMessage = `This component is not available for this country`;
          });
        }
      }
      return {
        ...state,
        modelData: newModelData
      };
    }
    case SET_CONFIG_BOM: {
      return {
        ...state,
        bomData: action.payload
      };
    }
    case SET_SHOW_BOM_SECTION_FLAG: {
      return {
        ...state,
        showBOMSectionFlag: action.payload
      };
    }
    case SET_WARNING: {
      return {
        ...state,
        showWarning: action.payload
      };
    }
    case SET_ERROR_DATA: {
      return {
        ...state,
        errorData: action.payload
      };
    }
    case SET_SAVE_CONFIG_RESPONSE: {
      return {
        ...state,
        newSavedConfigId: action.newSavedConfigId,
        eventTriggered: action.eventTriggered
      };
    }
    case SET_CHAIDITEMS_LIST: {
      return {
        ...state,
        chaiItems: action.payload
      };
    }
    case SET_RADIO_CHAIDITEMS_LIST: {
      const { errorData, chaiItemsRadioList } = state;
      const finalCheckedList = [...chaiItemsRadioList, ...action.payload];
      const radioChaiItemsList = finalCheckedList.filter(val => {
        return (
          (Object.keys(val)[0] === errorData.name &&
            Object.values(val)[0][0]?.inode === Number(errorData.value)) ||
          Object.keys(val)[0] !== errorData.name
        );
      });
      return {
        ...state,
        chaiItemsRadioList:
          radioChaiItemsList?.length > 0 ? radioChaiItemsList : finalCheckedList
      };
    }
    case SET_LOADING_SELECTION: {
      return {
        ...state,
        loadingSelection: action.payload
      };
    }
    case SET_CONFIG_NAME: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          configHeader: {
            ...state.configuration?.configHeader,
            configName: action.payload
          }
        },
        configNameRed: action.payload
      };
    }
    case SET_CONFIG_NAME_SAVE: {
      return {
        ...state,
        configNameRedSaved: action.payload
      };
    }
    case SET_ORIGIN_SELECTED_ITEMS: {
      return {
        ...state,
        originSelectedItems: action.selectedItems,
        haveSaved: action.haveSaved
      };
    }
    case SET_ORIGINAL_ACCESS_TYPE: {
      return {
        ...state,
        originalAccessType: action.payload
      };
    }
    case SET_SEARCHED_TEXT: {
      return {
        ...state,
        searchText: action.payload
      };
    }
    case SET_SHOW_PREAPPROVED_CONFIG_BUTTON: {
      return {
        ...state,
        showPreApprovedConfigButton: action.payload
      };
    }

    case SET_IS_CONFIG_OVERDATE: {
      return {
        ...state,
        isConfigOverDate: action.payload
      };
    }
    case SET_SHOW_TOP_RECOMMEND: {
      return {
        ...state,
        showTopRecommended: action.payload
      };
    }
    case SET_SHOW_ADDITIONAL: {
      return {
        ...state,
        showAdditional: action.payload
      };
    }
    case SET_CAREPACKS_STATE: {
      return {
        ...state,
        showTopRecommended: action.payload,
        showAdditional: action.payload
      };
    }
    
    default:
      return {
        ...state
      };
  }
}

export default configFilterData;
