import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_TAB_EXPAND } from "../actions/Types";
import {
  getAllDataExpandedOrCollapsedManul,
  otherCarePacksChaId,
  shouldHideChoices
} from "../services/utils";
import { CarePackAdditional } from "./Localization/CarePackAdditional";
import { CarePackRecommendation } from "./Localization/CarePackRecommendation";
import { OtherCarePacks } from "./Localization/OtherCarePacks";
import { MandaCarePacks } from "./MandaCarePack";

const CarePacksStepper = props => {
  const { proCategory, handleItemChange, handleQuantityChange } = props;
  //importing state from store
  const modelData = useSelector(state => state.configFilterData.modelData);
  const filteredChoices = useSelector(
    state => state.configFilterData.filteredChoices
  );
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const showWarning = useSelector(state => state.configFilterData.showWarning);
  const expandAll = useSelector(state => state.configFilterData.expandAll);

  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  const ocfgParams = useSelector(state => state.productSelector.ocfgParams);
  const searchText = useSelector(state => state.configFilterData.searchText);
  const filteredLoc = useSelector(state => state.configFilterData.filteredLoc);
  //definign local states
  const [expanded, setExpanded] = useState(true);
  const dispatch = useDispatch();

  //Check current expand state of this tab and update the local state accordingly
  //Local state will change the behaviour of a single conatiner kind of Accordian
  useEffect(() => {
    if (expandAll.length && defaultConfigStepSlected) {
      const relevantTabData = expandAll.find(
        expandObject => expandObject.tab === defaultConfigStepSlected
      );
      if (relevantTabData) {
        if (relevantTabData?.isClickExpandOrCollapse) {
          setExpanded(relevantTabData.choicesExpandState[otherCarePacksChaId]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandAll, defaultConfigStepSlected]);

  useEffect(() => {
    if (expandAll.length && defaultConfigStepSlected) {
      const relevantTabData = expandAll.find(
        expandObject => expandObject.tab === defaultConfigStepSlected
      );
      if (relevantTabData && !relevantTabData?.isClickExpandOrCollapse) {
        setExpanded(relevantTabData.choicesExpandState[otherCarePacksChaId]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultConfigStepSlected]);

  useEffect(() => {
    if (searchText) {
      if (
        filteredLoc.includes("HP Absolute Care Pack Services") ||
        filteredLoc.includes("HW Support  CTO/BTO only (E)")
      ) {
        setExpanded(true);
      } else {
        setExpanded(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [searchText]);

  const handleChange = (otherCarePacks, e) => {
    e.preventDefault();
    const relevantExpandData = expandAll.find(
      expandedData => expandedData.tab === defaultConfigStepSlected
    );
    if (relevantExpandData && otherCarePacks) {
      otherCarePacks.map(item => {
        if (
          Object.keys(relevantExpandData.choicesExpandState).indexOf(
            item.key
          ) !== -1
        ) {
          relevantExpandData.choicesExpandState[item.key] = false;
        }
      });
      relevantExpandData.choicesExpandState[otherCarePacksChaId] = !expanded;
      if (relevantExpandData.choicesExpandState.hasOwnProperty("PSE")) {
        relevantExpandData.choicesExpandState["PSE"] = false;
      }
      if (relevantExpandData.choicesExpandState.hasOwnProperty("ABSCP")) {
        relevantExpandData.choicesExpandState["ABSCP"] = false;
      }
      const expand = getAllDataExpandedOrCollapsedManul(
        relevantExpandData.choicesExpandState
      )[0]
        ? true
        : getAllDataExpandedOrCollapsedManul(
            relevantExpandData.choicesExpandState
          )[1]
        ? false
        : relevantExpandData.expand;
      const payload = {
        tab: defaultConfigStepSlected,
        expand,
        choicesExpandState: relevantExpandData.choicesExpandState,
        flag: false,
        isClickExpandOrCollapse: false
      };
      dispatch({
        type: UPDATE_TAB_EXPAND,
        payload
      });
    }
    setExpanded(!expanded);
  };

  const isChaidHideBasedOnRegion = chaidId => {
    return shouldHideChoices(ocfgParams?.hideChoice, chaidId) 
  };

  //actual rendering of the component
  return (
    <React.Fragment>
      <CarePackRecommendation
        filteredChoices={filteredChoices}
        proCategory={proCategory}
        isChaidHideBasedOnRegion={isChaidHideBasedOnRegion}
        modelData={modelData}
        handleItemChange={handleItemChange}
      />
      <CarePackAdditional
        filteredChoices={filteredChoices}
        isChaidHideBasedOnRegion={isChaidHideBasedOnRegion}
        modelData={modelData}
        handleItemChange={handleItemChange}
      />
      <OtherCarePacks
        proCategory={proCategory}
        filteredChoices={filteredChoices}
        isChaidHideBasedOnRegion={isChaidHideBasedOnRegion}
        expanded={expanded}
        handleChange={handleChange}
        showWarning={showWarning}
        modelData={modelData}
        handleItemChange={handleItemChange}
        handleQuantityChange={handleQuantityChange}
      />
      <MandaCarePacks
        filteredChoices={filteredChoices}
        proCategory={proCategory}
        isChaidHideBasedOnRegion={isChaidHideBasedOnRegion}
        modelData={modelData}
        handleItemChange={handleItemChange}
        handleQuantityChange={handleQuantityChange}
      />
    </React.Fragment>
  );
};

export default CarePacksStepper;
