import { createStyles, Fab, makeStyles, Theme } from "@material-ui/core";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RequestAssistanceDialog from "./RequestAssistanceDialog";

const useStyles = makeStyles((theme) =>
  createStyles({
    FabButtonStyle: {
      color: "#fff",
      // zIndex: "3001",
      backgroundColor: "#0096d6",
      position: "fixed",
      maxWidth: "30px",
      bottom: "35px",
      right: theme.spacing(3),
      "& span span": {
        display: "none"
      },
      "&:hover": {
        "& span": {
          display: "inline-block"
        },
        "& span svg": {
          top: "7px",
          right: "2px",
          position: "relative"
        },
        gap: "6px",
        position: "fixed",
        fontSize: "13px",
        bottom: "35px",
        maxWidth: "fit-content",
        right: theme.spacing(3)
      }
    }
  })
);
const RequestAssistanceButton = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Fab
        color="primary"
        variant="extended"
        className={classes.FabButtonStyle}
        onClick={() => setOpen(true)}
      >
        <ContactSupportOutlinedIcon />
        <span>{"REQUEST ASSISTANCE"}</span>
      </Fab>
      {open && <RequestAssistanceDialog setOpen={setOpen} {...props} />}
    </>
  );
};
export default RequestAssistanceButton;
