import { applyLocalization } from "./applyLocalization";
import { applyLocalizationMANDA } from "./applyLocalizationManda.js";
import { conflictDetection } from "./conflictDetection";
import {
  KBD_LOCALIZATION_CATEGORY,
  MonLocChaids,
  MON_LOCALIZATION_CATEGORY,
  OS_LOCALIZATION_CATEGORY,
  SYS_LOCALIZATION_CATEGORY
} from "./Constants";
import {
  getCauseNEffect,
  getLocChoiceIdsByCategory,
  setItemConflictsLoc,
  updateVisibilityForGrayoutItems
} from "./util";
const isDebug = localStorage.getItem("isDebug") === "true";
//src/webasm/localization.go
const updateLocalizationOptionArray = (locCategory, newLocOption, model) => {
  let newOption;
  let oldOption;
  let isChanged = false;
  let options = [];

  if (locCategory === OS_LOCALIZATION_CATEGORY) {
    options = model.osLocOptions;
  } else if (locCategory === SYS_LOCALIZATION_CATEGORY) {
    options = model.systemLocOptions;
  } else if (locCategory === KBD_LOCALIZATION_CATEGORY) {
    options = model.kybdLocOptions;
  } else if (locCategory === MON_LOCALIZATION_CATEGORY) {
    options = model.monitorLocOptions;
  } else {
    return { oldOption, newOption, isChanged };
  }

  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    if (option.selected) {
      oldOption = option;
    }
  }

  newLocOption = newLocOption.trim().toUpperCase();
  for (let i = 0; i < options.length; i++) {
    if (options[i].locOption === newLocOption) {
      if (!options[i].selected) {
        newOption = options[i];
        newOption.selected = true;
        isChanged = true;
        if (oldOption != null) {
          oldOption.selected = false;
        }
        break;
      } else {
        newOption = {};
      }
    }
  }
  return { oldOption, newOption, isChanged };
};
//src/main-webasm.go
export const updateLocalization = (localizationsChange, model) => {
  if (
    model?.Conflicts?.length > 0 &&
    model?.Conflicts[0]?.ItemConflictsList?.length > 0
  ) {
    setItemConflictsLoc(model.Conflicts[0].ItemConflictsList);
  }
  localStorage.getItem("showTime") && console.time("TIME: updateLocalization");
  let change = false;
  const localizationCategories = Object.keys(localizationsChange);
  let selectedLocalizedCategory = "";
  if (localizationCategories[0] === OS_LOCALIZATION_CATEGORY) {
    selectedLocalizedCategory = OS_LOCALIZATION_CATEGORY;
  } else if (localizationCategories[0] === SYS_LOCALIZATION_CATEGORY) {
    selectedLocalizedCategory = SYS_LOCALIZATION_CATEGORY;
  } else if (localizationCategories[0] === KBD_LOCALIZATION_CATEGORY) {
    selectedLocalizedCategory = KBD_LOCALIZATION_CATEGORY;
  } else if (localizationCategories[0] === MON_LOCALIZATION_CATEGORY) {
    selectedLocalizedCategory = MON_LOCALIZATION_CATEGORY;
  } else {
    return model;
  }
  const { oldOption, newOption, isChanged } = updateLocalizationOptionArray(
    selectedLocalizedCategory,
    localizationsChange[localizationCategories[0]],
    model
  );
  if (!isChanged) {
    if (!newOption && isDebug) {
      console.log(
        `Nothing to do for ${selectedLocalizedCategory}, because ${
          localizationsChange[localizationCategories[0]]
        } is out of current region of ${model.country}}`
      );
    } else {
      isDebug &&
        console.log(
          `Nothing to do for ${selectedLocalizedCategory}, because ${
            localizationsChange[localizationCategories[0]]
          } localization option has already applied in ${model.country}`
        );
    }
    return model;
  }
  let updatedModel = model;
  for (let i = 0; i < model?.Chaids.length; i++) {
    const chaid = model?.Chaids[i];
    const choice = getLocChoiceIdsByCategory(
      selectedLocalizedCategory,
      chaid.chaId
    );
    if (choice) {
      if (MonLocChaids.includes(chaid.chaId)) {
        updatedModel = applyLocalizationMANDA(
          selectedLocalizedCategory,
          chaid,
          oldOption,
          newOption,
          updatedModel
        );
      } else {
        updatedModel = applyLocalization(
          selectedLocalizedCategory,
          chaid,
          oldOption,
          newOption,
          updatedModel
        );
      }
    }
  }
  let locChangeOption = newOption;
  change = isChanged;
  getCauseNEffect(updatedModel, locChangeOption, change);
  updatedModel = conflictDetection(updatedModel);
  updatedModel = updateVisibilityForGrayoutItems(updatedModel);
  localStorage.getItem("showTime") &&
    console.timeEnd("TIME: updateLocalization");
  return updatedModel;
};
