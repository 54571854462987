import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import SwiperCore, { Controller, Navigation, Pagination, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { HP_LOGO } from "./Configurator/Rsku/Constants";

SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

export const useStyles = makeStyles(theme => {
  return createStyles({
    slider: {
      width: "100%",
      "& .swiper-button-next, .swiper-button-prev": {
        color: theme.palette.primary.main
      }
    },
    slide: {
      textAlign: "center",
      height: 180,
      "& img": {
        maxWidth: "100%",
        height: "100%"
      }
    },
    thumbs: {
      width: 350,
      margin: "0 auto",
      padding: "20px 0",
      "& .swiper-wrapper": {
        display: "flex",
        justifyContent: "center"
      }
    },
    thumb: {
      width: 60,
      height: 50,
      textAlign: "center",
      cursor: "pointer",
      boxShadow: theme.shadows[2],
      background: theme.palette.common.white,
      borderRadius: 5,
      border: "2px solid transparent",
      "& img": {
        maxWidth: "100%",
        height: "100%"
      },
      "&.swiper-slide-thumb-active": {
        border: `2px solid ${theme.palette.primary.main}`
      }
    },
    defaultImage: {
      display: "block",
      margin: "auto",
      width: 200,
      height: 200,
      objectFit: "contain"
    }
  });
});

const ImageSwiper = ({ images }) => {
  const classes = useStyles();
  const [thumbsSwiper, setThumbsSwiper] = React.useState(0);
  return (
    <>
      {images.length > 0 ? (  
        <>
          <Swiper
            className={classes.slider}
            thumbs={{ swiper: thumbsSwiper }}
            tag="div"
            navigation
            spaceBetween={0}
            slidesPerView={1}
          >
            {images?.map((src, index) => {
              return (
                <SwiperSlide className={classes.slide} key={index}>
                  <img src={src} alt="" />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <Swiper
            className={classes.thumbs}
            slidesPerView="auto"
            spaceBetween={30}
            watchSlidesVisibility
            onSwiper={setThumbsSwiper}
          >
            {images.map((src, index) => {
              return (
                <SwiperSlide key={index} className={classes.thumb}>
                  <img src={src} alt="" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </>
      ) : (
        <img className={classes.defaultImage} src={HP_LOGO} />
      )}
    </>
  );
};

export default ImageSwiper;
