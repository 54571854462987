import {
  getLocChoiceIdsByCategory,
  isItemDatesValidOrMark,
  updateModel
} from "./util";

const valid = "V";
const DO_DESELECT = -1;
const DO_SELECT = 1;

//src/webasm/localization.go
export const applyLocalization = (
  selectedLocCat,
  choice,
  oldOption,
  newOption,
  model
) => {
  const isDebug = localStorage.getItem("isDebug") === "true";
  let updatedModel = model;
  if (getLocChoiceIdsByCategory(selectedLocCat, choice.chaId)) {
    for (let i = choice.firstItem; i <= choice.lastItem; i++) {
      let item = updatedModel.Items[i];
      if (item.partno.includes("#") && item.plcStatus === valid) {
        let itemLocOpt = item.partno.split("#")[1];
        const itemValidationOutput = isItemDatesValidOrMark(
          new Date(),
          choice,
          item
        );
        updatedModel.Items[i] = itemValidationOutput?.item;
        item = itemValidationOutput?.item;
        if (
          itemLocOpt.toUpperCase() === newOption.locOption.toUpperCase() &&
          !item.visible &&
          itemValidationOutput?.isValid
        ) {
          item.visible = true;
          isDebug &&
            console.log(
              "+++Localized item is set visible for =",
              item.partno,
              item.partdes,
              "under choice =",
              choice.chaId,
              choice.chaDes,
              "by updateVisibleFlagsForLocalizedItems"
            );
        }
        if (
          itemLocOpt.toUpperCase() === oldOption.locOption.toUpperCase() &&
          item.visible
        ) {
          if (item.selected) {
            updatedModel = updateModel(
              updatedModel.Nodes[item.inode].type,
              updatedModel.Nodes[item.inode].id,
              DO_DESELECT,
              updatedModel
            );
            isDebug &&
              console.log(
                "---Deselected item:",
                item.partno,
                item.partdes,
                "in choice ",
                choice.chaId,
                choice.chaDes,
                "by updateVisibleFlagsForLocalizedItems ---"
              );
            let newPartNo =
              item.partno.split("#")[0] + "#" + newOption.locOption;
            for (let j = choice.firstItem; j <= choice.lastItem; j++) {
              let nItem = updatedModel.Items[j];
              const itemValidationOutput = isItemDatesValidOrMark(
                new Date(),
                choice,
                nItem
              );
              if (nItem.partno === newPartNo && nItem.plcStatus === valid && itemValidationOutput?.isValid) {
                let node = updatedModel.Nodes[nItem.inode];
                updatedModel = updateModel(
                  node.type,
                  node.id,
                  DO_SELECT,
                  updatedModel
                );
                isDebug &&
                  console.log(
                    "+++Selected item:",
                    item.partno,
                    item.partdes,
                    "in choice ",
                    choice.chaId,
                    choice.chaDes,
                    "---"
                  );
              }
            }
          }
          item.visible = false;
          isDebug && console.log(
            "---Localized item is set invisible for =",
            item.partno,
            item.partdes,
            "under choice =",
            choice.chaId,
            choice.chaDes,
            "by updateVisibleFlagsForLocalizedItems"
          );
        }
      }
    }
  }
  return updatedModel;
};
