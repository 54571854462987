import { Player } from "@lottiefiles/react-lottie-player";
import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

export default function NoData(props) {
  const { t } = useTranslation();
  const animationStyle = { height: "150px" };
  const lottieFileSrc = `${process.env.CDN_LOTTIES}emptySearch.json`;
  const { disableNoData, isCustomer } = props;

  return (
    <div style={{ paddingBottom: "2rem" }}>
      <Player autoplay loop={true} src={lottieFileSrc} style={animationStyle} />
      {!disableNoData ? (
        <React.Fragment>
          <Typography
            variant="body2"
            align="center"
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            {t("common:snackbar.emptyData")}
          </Typography>
          {!isCustomer && (
            <Grid item style={{ textAlign: "center" }}>
              <Typography variant="caption" align="center" gutterBottom>
                Currently there is no record for reseller detail information.
              </Typography>
            </Grid>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography
            variant="body2"
            align="center"
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            Sorry, we cant find any matches for your query!
          </Typography>
          <Grid item style={{ textAlign: "center" }}>
            <Typography variant="caption" align="center" gutterBottom>
              Try using fewer or less-specific characters and double-check your
              spelling.
            </Typography>
          </Grid>
        </React.Fragment>
      )}
    </div>
  );
}
