import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { HPSnackbarTheme } from "../../config/theme";

const useStyles = makeStyles(theme => ({
  success: { backgroundColor: "#0171ad" },
  error: { backgroundColor: "#d7410b" },
  warning: { backgroundColor: "#ffa500" },
  info: { backgroundColor: "#0171ad" }
}));

export function StyledSnackbarProvider({ children }) {
  const classes = useStyles();
  return (
    <SnackbarProvider
      {...HPSnackbarTheme}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info
      }}
    >
      {children}
    </SnackbarProvider>
  );
}

// Must be imported at least once in the app to initialize the ref
let snackbarRef;
let showDetails;
export const SnackbarUtilsConfigurator = () => {
  snackbarRef = useSnackbar();
  const dispatch = useDispatch();
  showDetails = (msg, key) => {
    snackbarRef.closeSnackbar(key);
    dispatch({ type: "SET_STACK_TRACE", payload: msg.errorMessage });
    dispatch({ type: "SET_SHOW_ERROR_DETAILS", payload: true });
  };
  return null;
};

const variantFontColor = {
  success: "white",
  warning: "white",
  error: "white",
  info: "white"
};

const displayNotistack = {
  success(msg, options = {}) {
    this.toast(false, msg, { ...options, variant: "success" });
  },
  warning(msg, options = {}) {
    this.toast(false, msg, { ...options, variant: "warning" });
  },
  info(msg, options = {}) {
    this.toast(false, msg, { ...options, variant: "info" });
  },
  error(showErrorDetails, msg, options = {}) {
    this.toast(showErrorDetails, msg, { ...options, variant: "error" });
  },
  toast(
    showErrorDetails,
    msg,
    { variant, autoHideDuration = 6000, vertical = "top", horizontal = "left" }
  ) {
    let msgDuration = 30 * msg ? msg.length : 0;
    let duration =
      msgDuration > autoHideDuration ? msgDuration : autoHideDuration;
    const message = (
      <Typography
        style={{
          color: variantFontColor[variant],
          wordBreak: "break-all",
          padding: "0 10px 0 0"
        }}
      >
        {showErrorDetails ? msg.snackbarMessage : msg}
      </Typography>
    );
    snackbarRef.enqueueSnackbar(message, {
      variant: variant,
      autoHideDuration: duration,
      anchorOrigin: { vertical, horizontal },
      action: key =>
        !showErrorDetails ? (
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            style={{ position: "absolute", top: 0, bottom: 0, right: 0 }}
            onClick={() => {
              snackbarRef.closeSnackbar(key);
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <Button
            onClick={() => {
              showDetails(msg, key);
            }}
          >
            <Typography style={{ color: "white" }}>View Details</Typography>
          </Button>
        )
    });
  }
};

export default displayNotistack;
