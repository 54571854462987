import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  BOM_SELECTION,
  SET_CONFIG_BOM,
  SET_SHOW_BOM_SECTION_FLAG
} from "../../../actions/Types";
import { ExternalProps } from "../../../contexts/externalPropsContext";
import { displayCurrencyLite } from "../../../lib/common/util";
import {
  getBOMData,
  getConfigData,
  getShowBOMSectionFlag
} from "../../../services/utils";
const useStyles = makeStyles(theme => ({
  root: {
    padding: "10px 0px",
    margin: "10% 0",
    position: "sticky",
    top: 8
  },
  partno: {
    color: "#1279A4",
    width: "25%",
    paddingRight: "0px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  description: {
    width: "40%",
    color: "#1279A4",
    wordBreak: "break-all"
  },
  totalData: {
    textAlign: "right",
    fontWeight: "600",
    justifyContent: "flex-end",
    marginRight: "5px"
  },
  total: {
    padding: "16px"
  },
  noOverflow: {
    overflowX: "hidden"
  },

  summary: {
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "1px 0px !important"
    },
    height: "64px"
  },
  summaryHeader: {
    marginTop: "16px",
    height: "100%"
  },
  details: {
    "& .MuiAccordionDetails-root": {
      padding: "0px !important"
    }
  },
  accordianDetail: {
    padding: "0px 16px"
  },
  quantity: {
    width: "5%"
  },
  listprice: {
    width: "30%"
  }
}));
const HARDWARE = "hardware";
const StyledTableCell = withStyles(theme => ({
  head: {
    fontSize: 12,
    wordBreak: "keep-all",
    border: 0
  },
  body: {
    fontSize: 12,
    border: 0,
    verticalAlign: "initial"
  }
}))(TableCell);

export default function ConfigBOM() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);
  const [configBOM, setconfigBOM] = React.useState({});
  const [showBOMSectionFlag, setShowBOMSectionFlag] = React.useState(false);
  const modelData = useSelector(state => state.configFilterData.modelData);
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const isConfigOverDate = useSelector(
    state => state.configFilterData.isConfigOverDate
  );
  const currentConfigID = useSelector(
    state => state.productSelector.currentConfigID
  );
  const showGADate = useSelector(state => state.productSelector.showGADate);
  const { visibility } = React.useContext(ExternalProps);
  const { t } = useTranslation();
  const headCells = [
    {
      id: "partNumber",
      numeric: false,
      disablePadding: false,
      label: t("common:table.columns.partNumber"),
      width: "30%"
    },
    {
      id: "description",
      numeric: false,
      disablePadding: true,
      label: t("common:table.columns.desc"),
      width: "37%"
    },
    {
      id: "quantity",
      numeric: true,
      disablePadding: false,
      label: t("common:table.columns.qty"),
      width: "20%"
    },
    {
      id: "listPrice",
      numeric: true,
      disablePadding: false,
      label: t("common:table.columns.listPrc"),
      width: "30%"
    }
  ];
  const dispatch = useDispatch();
  const { currencyCode = modelData.currency || "USD" } =
    React.useContext(ExternalProps);
  const choices = getConfigData(modelData, defaultConfigStepSlected);

  useEffect(() => {
    const { formattedBOMItems, grandPrice } = getBOMData(
      modelData,
      "",
      configuration
    );
    setconfigBOM({ grandPrice, formattedBOMItems });
    const showBOMSectionFlag = getShowBOMSectionFlag(modelData, visibility);
    setShowBOMSectionFlag(showBOMSectionFlag);
    batch(() => {
      dispatch({ type: SET_CONFIG_BOM, payload: formattedBOMItems });
      dispatch({
        type: SET_SHOW_BOM_SECTION_FLAG,
        payload: showBOMSectionFlag
      });
    });
  }, [modelData, dispatch]);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded);
  };
  const scrollToChoice = row => {
    if (
      row.sectionId === HARDWARE &&
      defaultConfigStepSlected === HARDWARE &&
      row.choiceId
    ) {
      dispatch({ type: BOM_SELECTION, payload: row.choiceId });
    }
  };

  const customStyle = row => {
    return {
      color:
        defaultConfigStepSlected === HARDWARE
          ? row.sectionId === HARDWARE && row.choiceId
            ? "#1279A4"
            : "black"
          : "black",
      cursor:
        row.sectionId === HARDWARE &&
        defaultConfigStepSlected === HARDWARE &&
        row.choiceId
          ? "pointer"
          : "",
      wordBreak: "break-word"
    };
  };
  return (
    showBOMSectionFlag && (
      <div className={classes.root}>
        <Accordion
          expanded={expanded}
          onChange={handleChange()}
          className={classes.details}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.summary}
          >
            <Grid container className={classes.summaryHeader}>
              <Grid item xs={6}>
                <Typography>
                  <Box fontStyle="normal" fontSize={20}>
                    {t("common:bom.bom")}
                  </Box>
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={6}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography>
                  <Box fontStyle="normal" textAlign="center" fontWeight={700}>
                    {`${t("common:bom.grandTotal")}: `}
                    {displayCurrencyLite({
                      value: (configBOM && configBOM.grandPrice) || "0.00",
                      currency: currencyCode,
                      locale: "en-US"
                    })}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.accordianDetail}>
            <Grid container>
              <Table size="small" style={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    {headCells.map(headCell => (
                      <StyledTableCell
                        align="left"
                        key={headCell.id}
                        style={{
                          width: headCell.width
                        }}
                      >
                        {headCell.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {configBOM &&
                    configBOM.formattedBOMItems &&
                    configBOM.formattedBOMItems.map((row, index) => (
                      <TableRow
                        key={row.partNumber + index}
                        style={{ border: 0 }}
                      >
                        <Tooltip
                          title={row.partNumber}
                          aria-label="row.partNumber"
                        >
                          <StyledTableCell
                            style={customStyle(row)}
                            className={classes.partno}
                            onClick={() => scrollToChoice(row)}
                          >
                            {row.partNumber}
                          </StyledTableCell>
                        </Tooltip>
                        <StyledTableCell
                          style={customStyle(row)}
                          className={classes.description}
                          onClick={() => scrollToChoice(row)}
                        >
                          {row.description}
                        </StyledTableCell>
                        <StyledTableCell className={classes.quantity}>
                          {row.qty}
                        </StyledTableCell>
                        <StyledTableCell className={classes.listprice}>
                          {row.netPrice?.toLocaleString("en-US") || "0.00"}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Grid container className={classes.total}>
                <Grid item xs={6}>
                  <Typography>
                    <Box fontStyle="normal" fontWeight={700}>
                      {t("common:bom.grandTotal")}:
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <Box
                      fontStyle="normal"
                      textAlign="right"
                      fontWeight={700}
                      pr={2}
                    >
                      {displayCurrencyLite({
                        value: (configBOM && configBOM.grandPrice) || "0.00",
                        currency: currencyCode,
                        locale: "en-US"
                      })}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  );
}
