import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NoData from "./NoData";

const useStyles = makeStyles(theme => ({
  headStyle: {
    fontSize: 15,
    fontWeight: "bold"
  },
  field: {
    "& .MuiTableCell-sizeSmall": {
      padding: "8px 15px 8px 8px"
    }
  },
  childRow: {
    "& .MuiTableCell-sizeSmall": {
      padding: "8px 15px 8px 8px"
    },
    backgroundColor: theme.palette.action.hover
  },
  cursor: {
    cursor: "pointer",
    "& .MuiTableCell-sizeSmall": {
      padding: theme.spacing(1, 2, 1, 1)
    }
  },
  hrStyle: {
    backgroundColor: "rgb(224, 224, 224)",
    height: "1px",
    border: "none"
  }
}));
const CustomTable = props => {
  const { t } = useTranslation();
  const {
    columns,
    data,
    rowsPerPageOptions,
    minWidth,
    verticalCellAlignment,
    height,
    loading,
    isCustomer,
    showGrandTotal,
    grandTotal
  } = props;

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    props.defaultRowsPerPage
      ? props.defaultRowsPerPage
      : props.rowsPerPageOptions
      ? rowsPerPageOptions[0]
      : data?.length
  );
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(columns[0].id);
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    if (data && data.length <= 10) {
      setPage(0);
    }
  }, [data]);
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map(el => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };
  const tableData = props.sort
    ? stableSort(data, getComparator(order, orderBy))
    : data;

  useEffect(() => {
    setRowsPerPage(
      props.defaultRowsPerPage
        ? props.defaultRowsPerPage
        : props.rowsPerPageOptions
        ? rowsPerPageOptions[0]
        : data?.length
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (localStorage.getItem("isDebug") === "true") {
    console.log("testTableData", tableData);
  }
  return (
    <Fragment>
      <TableContainer>
        <Table size={"small"} style={{ minWidth: minWidth }}>
          <TableHead className={classes.field}>
            <TableRow>
              {columns.map(column =>
                column.sort ? (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    classes={{ head: classes.headStyle }}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    width={column.colWidth}
                    classes={{ head: classes.headStyle }}
                  >
                    {column.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((record, index) => (
                <TableRow
                  key={`row-${index}`}
                  hover={props.hover}
                  className={
                    props.hover
                      ? classes.cursor
                      : record && record.hasParent
                      ? classes.childRow
                      : classes.field
                  }
                  onClick={
                    props.hover && props.handleChange
                      ? () => props.handleChange(record, index)
                      : null
                  }
                  style={{ height }}
                >
                  {columns.map(column => {
                    let value =
                      column.id === "stateProvince"
                        ? record[column.id]
                          ? record[column.id]
                          : record["country"]
                        : record[column.id];
                    if (column.dataType === "float") {
                      value = parseFloat(value).toFixed(2);
                    }
                    if (column.dateTime) {
                      value = moment(value).format("lll");
                    }
                    return (
                      <TableCell
                        id={column.id}
                        key={column.id}
                        align={column.align}
                        style={{
                          fontFamily: column.fontFamily,
                          verticalAlign: verticalCellAlignment
                            ? "top"
                            : "inherit"
                        }}
                      >
                        {column.render
                          ? column.render({ index, record, value })
                          : column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!loading && tableData.length === 0 ? (
        <div style={{ marginTop: "1rem" }}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "10vh" }}
          >
            <Grid item>
              <NoData isCustomer={isCustomer} />
            </Grid>
          </Grid>
        </div>
      ) : (
        <>
          {!props.hidePagination && (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              labelRowsPerPage={`${t("common:table.rowsPerPage")}:`}
              component="div"
              count={(data && data.length) || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}
      {showGrandTotal && <hr className={classes.hrStyle}></hr>}
      {showGrandTotal && (
        <Grid container>
          <Grid item md={8} style={{ textAlign: "right" }}>
            <Typography variant="subtitle1">
              {t("common:searchBundle.grandTotal")}
            </Typography>
          </Grid>
          <Grid item md={4} style={{ textAlign: "right" }}>
            <Typography variant="subtitle1">
              <b>{grandTotal}</b>
            </Typography>
          </Grid>
        </Grid>
      )}
      {showGrandTotal && <hr className={classes.hrStyle}></hr>}
    </Fragment>
  );
};

export default CustomTable;
