import moment from "moment";
import {
  calculateDays,
  dateCompare,
  obsoleteDateFormat
} from "../lib/common/util";

const getHighestSequence = function (uiChoices) {
  let highestSequence = Math.max(
    ...uiChoices
      .filter(t => t.sectionId === "hardware")
      .map(o => o.choiceSequence),
    0
  );
  return highestSequence;
};

export const getChoices = function (
  modelData,
  tabId = "hardware",
  proCategory
) {
  // Empty
  let tabChaidsList = window.uiSpecData;
  if (!(modelData && modelData.Chaids)) return [];
  // Chaids
  let sections = tabChaidsList.UISpec[0].sections.find(
    section => section.id === tabId
  );
  let accordions =
    sections !== undefined
      ? [...sections.choices].sort(function (a, b) {
          return a.sequence - b.sequence;
        })
      : null;
  //sanity check - if accordions are not present, do return empty choice;
  if (accordions === null) {
    return [];
  }
  let highestSequence = accordions[accordions.length - 1].sequence;

  // adding the leftover choices in the hardware tab
  if (["hardware", "carePacks"].includes(tabId) && modelData) {
    let data = modelData.Chaids.filter(chaid => {
      // if (Object.keys(ocfgParams?.hideChoice).find(e => e === chaid.chaId))
      //   chaid.isUsed = true;

      return (
        // !chaid.isUsed &&
        (tabId === "hardware"
          ? !chaid.chaId.startsWith("CAPQ_")
          : chaid.chaId.startsWith("CAPQ_") &&
            !accordions.some(item => item.id === chaid.chaId)) &&
        !chaid.isSpecOrigin &&
        (chaid.precon >= 0 ||
          chaid.isOverridePrecon ||
          chaid.required ||
          chaid.selcon) &&
        chaid.visible
      );
    });
    data.forEach(entity => {
      let obj = {
        id: entity.chaId,
        title: entity.chaDes,
        uiControlType: entity.multiple ? "checkbox" : "radioGroupGrid",
        sequence: ++highestSequence,
        exclude: false,
        itemSequenceBy: "partno"
      };
      accordions.push(obj);
    });
  }
  const icConflictsArr = modelData.conflicts[0].icConflicts?.map(f => f.chaId);
  const choiceConflicts = modelData.conflicts[0]?.choiceConflicts?.map(
    choiceConflict => choiceConflict.effectChoice.chaId
  );
  const finalConflictsArr = [...icConflictsArr, ...choiceConflicts];
  const requiredChoices = modelData.conflicts?.length ? finalConflictsArr : [];
  let ret = modelData.Chaids.filter(choice => {
    const isRequired = requiredChoices.includes(choice.chaId);
    const isChoiceConflict = choiceConflicts?.includes(choice.chaId);
    choice.isOverridePrecon = isChoiceConflict;
    return (
      (choice.precon >= 0 || isRequired) &&
      choice.visible &&
      accordions &&
      accordions.some(acc => acc.id === choice.chaId) &&
      modelData &&
      (isRequired ||
        choice.required ||
        choice.selcon ||
        modelData.Items.some(
          part =>
            part.partno !== "" &&
            part.visible === true &&
            part.inode >= choice.firstItem &&
            part.inode <= choice.lastItem
        ))
    );
  });

  //handle a case where all items have plcStatus = "I" and visible ==false
  const choicesRequired = modelData.Chaids.filter(
    chaid =>
      (chaid.precon >= 0 ||
        chaid.isOverridePrecon ||
        chaid.required ||
        chaid.selcon) &&
      chaid.visible &&
      chaid.required &&
      accordions &&
      accordions.some(acc => acc.id === chaid.chaId) &&
      modelData &&
      modelData.Items.some(
        part =>
          part.plcStatus === "I" &&
          !part.visible &&
          part.inode >= chaid.firstItem &&
          part.inode <= chaid.lastItem
      )
  );

  //merging two arrays while removing duplicates, creating a set
  ret = [...new Set([...ret, ...choicesRequired])];

  if (tabId === "hardware" && modelData && proCategory) {
    // adding localizations for the hardware tab, to Filter Parts
    //we also check if proCategory is not print
    if (proCategory !== "print") {
      const systemLocOptions = {
        chaId: "systemLocOptions",
        chaDes: "System Localization Options",
        type: "loc",
        multiple: false,
        required: false,
        visible: true,
        precon: -1,
        selcon: false,
        firstItem: 0,
        lastItem: modelData.systemLocOptions?.length - 1 || 0,
        iNode: 0,
        specified: false,
        isUsed: true,
        items:
          (modelData.systemLocOptions &&
            modelData.systemLocOptions.map((loc, idx) => ({
              partno: loc.locOption,
              partdes: "",
              visible: loc.visible,
              precon: 0,
              selcon: false,
              selected: loc.selected,
              locDescrition: `${loc.locOption}-${loc.locDescrition}`,
              inode: idx
            }))) ||
          []
      };
      const osLocOptions = {
        chaId: "osLocOptions",
        chaDes: "OS Localization Options",
        type: "loc",
        multiple: false,
        required: false,
        visible: true,
        precon: -1,
        selcon: false,
        firstItem: 0,
        lastItem: modelData.osLocOptions?.length - 1 || 0,
        iNode: 0,
        specified: false,
        isUsed: true,
        items:
          (modelData.osLocOptions &&
            modelData.osLocOptions.map((loc, idx) => ({
              partno: loc.locOption,
              partdes: "",
              visible: loc.visible,
              precon: 0,
              selcon: false,
              locDescrition: `${loc.locOption}-${loc.locDescrition}`,
              selected: loc.selected,
              inode: idx
            }))) ||
          []
      };
      const kybdLocOptions = {
        chaId: "kybdLocOptions",
        chaDes: "Keyboard Localization Options",
        type: "loc",
        multiple: false,
        required: false,
        visible: true,
        precon: -1,
        selcon: false,
        firstItem: 0,
        lastItem: modelData.kybdLocOptions?.length - 1 || 0,
        iNode: 0,
        specified: false,
        isUsed: true,
        items:
          (modelData.kybdLocOptions &&
            modelData.kybdLocOptions.map((loc, idx) => ({
              partno: loc.locOption,
              partdes: "",
              visible: loc.visible,
              precon: 0,
              selcon: false,
              locDescrition: `${loc.locOption}-${loc.locDescrition}`,
              selected: loc.selected,
              inode: idx
            }))) ||
          []
      };
      const monitorLocOptions = {
        chaId: "monitorLocOptions",
        chaDes: "Monitor Localization Options",
        type: "loc",
        multiple: false,
        required: false,
        visible: true,
        precon: -1,
        selcon: false,
        firstItem: 0,
        lastItem: modelData.monitorLocOptions?.length - 1 || 0,
        iNode: 0,
        specified: false,
        isUsed: true,
        items:
          (modelData.monitorLocOptions &&
            modelData.monitorLocOptions.map((loc, idx) => ({
              partno: loc.locOption,
              partdes: "",
              visible: loc.visible,
              precon: 0,
              selcon: false,
              selected: loc.selected,
              locDescrition: `${loc.locOption}-${loc.locDescrition}`,
              inode: idx
            }))) ||
          []
      };
      ret.splice(
        0,
        0,
        systemLocOptions,
        osLocOptions,
        kybdLocOptions,
        monitorLocOptions
      );
    }
  }

  return ret;
};

export const compare = (a, b) => {
  // Inside choice by priority (must be 2nd)
  const p = a.priority - b.priority;
  if (p !== 0) return p;
  // By part number
  const valueA = a.chaId.toUpperCase();
  const valueB = b.chaId.toUpperCase();
  let comparison = 0;
  if (valueA > valueB) {
    comparison = 1;
  } else if (valueA < valueB) {
    comparison = -1;
  }
  return comparison;
};

export const getItem = (modelData, chaid) => {
  return (
    modelData &&
    modelData.Items &&
    modelData.Items.find(
      part =>
        part.visible &&
        // part.precon &&
        part.inode >= chaid.firstItem &&
        part.inode <= chaid.lastItem &&
        part.selected
    )
  );
};

export const getConfigData = (modelData, tabId = "hardware") => {
  let tabChaidsList = window.uiSpecData;
  const choices = getChoices(modelData, tabId);
  const dataFromApi = choices
    .filter(
      chaid =>
        chaid.visible &&
        (chaid.precon >= 0 ||
          chaid.isOverridePrecon ||
          chaid.required ||
          chaid.selcon)
    )
    .map(chaid => {
      chaid.items =
        modelData &&
        modelData.Items.filter(
          part =>
            part.visible &&
            // part.precon &&
            part.inode >= chaid.firstItem &&
            part.inode <= chaid.lastItem
        );
      return chaid;
    });
  //After filtering the qualified data from the API, we are simplying making it
  //in the order of UI specs.json file.

  const sectionFromUi = tabChaidsList.UISpec[0].sections.find(
    section => section.id === tabId
  );
  let resultsArray = [];
  let foundArray = [];

  if (sectionFromUi) {
    sectionFromUi.choices.forEach(choice => {
      const choicefoundInUISpec = dataFromApi.find(
        chaid => chaid.chaId === choice.id
      );
      if (choicefoundInUISpec) {
        foundArray.push(choicefoundInUISpec);
        //remove this element from the api data array based on its index
        const index = dataFromApi.indexOf(choicefoundInUISpec);
        dataFromApi.splice(index, 1);
      }
    });
  }
  //displaying the choices present in the UI specs first and then displaying all the left over items
  resultsArray = [...foundArray, ...dataFromApi];
  return resultsArray;
};

export const filterChaids = (modelData, accordions) => {
  let filteredChaids = modelData.Chaids.filter(
    chaid =>
      // chaid.precon &&
      chaid.visible &&
      accordions &&
      accordions.some(acc => acc.id === chaid.chaId)
  ).map(chaid =>
    chaid.required ||
    chaid.selcon ||
    (modelData &&
      modelData.Items.some(
        part =>
          part.partno !== "" &&
          part.visible === true &&
          // part.precon &&
          part.inode >= chaid.firstItem &&
          part.inode <= chaid.lastItem
      ))
      ? (chaid.isUsed = true)
      : (chaid.isUsed = false)
  );
  return filteredChaids;
};

export const isRunOnLocalhost = () => {
  return (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  );
};

export const getBOMData = (modelData, type, configuration) => {
  let filteredBOMItems,
    formattedBOMItems = [];
  let grandPrice = 0;
  let tabId = "hardware";
  let choiceBOMItems = [];
  let saveBomChoices = [];
  let tabChaidsList = window.uiSpecData;

  choiceBOMItems = modelData.Chaids;
  updateCarePackSelection(modelData);
  if (modelData && modelData.Items) {
    filteredBOMItems = modelData.Items.filter(
      item =>
        item.selected === true && item.partno !== "" && item.visible === true
    );
    saveBomChoices = [];
    // filteredBOMItems = filteredBOMItems.sort((a, b) => a.inode - b.inode);
    filteredBOMItems.forEach((selectedItem, index) => {
      const choiceObj = choiceBOMItems.find(
        choice =>
          selectedItem.inode >= choice.firstItem &&
          selectedItem.inode <= choice.lastItem
      );

      saveBomChoices = [
        ...saveBomChoices,
        {
          choiceId: choiceObj?.chaId || "",
          choiceName: choiceObj?.chaDes || "",
          selectedItem
        }
      ];
    });

    // Fill uiChoices
    let uiChoices = [];
    tabChaidsList.UISpec[0].sections
      .map(section => section)
      .forEach(section => {
        section.choices.forEach(choice => {
          // Manipulating the sequence of monitors & accessories and configuration services (only For EMEA & AMS), refer issue #3445
          if (
            !["AP", "APJ"].includes(
              configuration?.configHeader?.rgnCd || modelData.region
            )
          ) {
            let sectionLength = tabChaidsList.UISpec[0].sections.length;
            if (section.id === "monitoringAccesories") {
              section.sequence =
                tabChaidsList.UISpec[0].sections[sectionLength - 1].sequence +
                1;
            } else if (section.id === "configurationService") {
              let cpIndex = tabChaidsList.UISpec[0].sections.findIndex(
                section => section.id === "carePacks"
              );
              section.sequence =
                tabChaidsList.UISpec[0].sections[cpIndex].sequence - 1;
            }
          }
          uiChoices.push({
            sectionId: section.id,
            sectionSequence: section.sequence,
            choiceId: choice.id,

            choiceSequence: choice.sequence
          });
        });
      });

    // Create sorted array of selected items
    let selectedItems = [];
    const hardwareChoice = uiChoices[0]; // assuming "hardware"
    let highestSequence = getHighestSequence(uiChoices);

    saveBomChoices.forEach(item => {
      const uiChoice = uiChoices.find(c => c.choiceId === item.choiceId);
      if (uiChoice) {
        selectedItems.push({
          ...uiChoice,
          selectedItem: item.selectedItem
        });
      } else {
        selectedItems.push({
          sectionId: "hardware",
          sectionSequence: hardwareChoice.sectionSequence,
          choiceId: item.choiceId,
          choiceSequence: ++highestSequence,
          selectedItem: item.selectedItem
        });
      }
    });
    selectedItems
      .sort(
        // Sort by section->choice->item

        (a, b) =>
          a.sectionSequence - b.sectionSequence ||
          a.choiceSequence - b.choiceSequence
      )
      .forEach(item => {
        // Iterate and fill formattedBOMItems array

        const selectedItem = item.selectedItem;
        let formattedListPrice =
          selectedItem.listPrice === ""
            ? 0
            : parseFloat(selectedItem.listPrice);
        let formattedNetPrice =
          selectedItem.netPrice === "" ? 0 : parseFloat(selectedItem.netPrice);
        //grandPrice += formattedListPrice;
        if (selectedItem.partno.includes("#")) {
          if (type && type === "exportToExcel") {
            formattedBOMItems.push({
              partNumber: selectedItem.partno,
              description: selectedItem.partdes,
              qty: selectedItem.quantity !== 0 ? selectedItem.quantity : 1,
              listPrice:
                selectedItem.quantity === 0
                  ? formattedListPrice
                  : selectedItem.quantity * formattedListPrice,
              netPrice:
                selectedItem.quantity === 0
                  ? formattedNetPrice
                  : selectedItem.quantity * formattedNetPrice,

              choiceId: item.choiceId,
              inode: selectedItem.inode,
              sectionId: item.sectionId
            });
          } else {
            formattedBOMItems.push({
              partNumber: selectedItem.partno.split("#")[0],
              description: selectedItem.partdes,
              qty: selectedItem.quantity !== 0 ? selectedItem.quantity : 1,
              listPrice:
                selectedItem.quantity === 0
                  ? formattedListPrice
                  : selectedItem.quantity * formattedListPrice,
              netPrice:
                selectedItem.quantity === 0
                  ? formattedNetPrice
                  : selectedItem.quantity * formattedNetPrice,

              choiceId: item.choiceId,
              inode: selectedItem.inode,
              sectionId: item.sectionId
            });
            formattedBOMItems.push({
              partNumber: selectedItem.partno,
              description: selectedItem.partdes,
              choiceId: item.choiceId,
              qty: selectedItem.quantity !== 0 ? selectedItem.quantity : 1,
              listPrice: 0.0,
              netPrice: 0.0,
              inode: selectedItem.inode,
              sectionId: item.sectionId
            });
          }
        } else {
          const updatedItems = addQuantityForSamePartNumber(
            formattedBOMItems,
            selectedItem
          );
          if (updatedItems) {
            formattedBOMItems = [...updatedItems];
          } else {
            formattedBOMItems.push({
              partNumber: selectedItem.partno,
              description: selectedItem.partdes,
              qty: selectedItem.quantity !== 0 ? selectedItem.quantity : 1,
              listPrice:
                selectedItem.quantity === 0
                  ? formattedListPrice
                  : selectedItem.quantity * formattedListPrice,
              netPrice:
                selectedItem.quantity === 0
                  ? formattedNetPrice
                  : selectedItem.quantity * formattedNetPrice,
              inode: selectedItem.inode,
              choiceId: item.choiceId,
              sectionId: item.sectionId
            });
          }
        }
      });
  }

  grandPrice = formattedBOMItems.reduce((sum, f) => sum + f.listPrice, 0);
  return {
    formattedBOMItems,
    grandPrice
  };
};

const addQuantityForSamePartNumber = (items = [], selectedItem = {}) => {
  const index = items.findIndex(obj => obj.partNumber === selectedItem.partno);
  if (index > -1) {
    items[index] = {
      ...items[index],
      qty: items[index].qty + selectedItem.quantity,
      listPrice:
        selectedItem.listPrice * (items[index].qty + selectedItem.quantity),
      netPrice:
        parseFloat(selectedItem.netPrice) *
        (items[index].qty + selectedItem.quantity)
    };
  }
  return index > -1 ? items : false;
};

const filterPLCConflict = (modelData, visibility) => {
  const {
    showMonitorsAccessoriesTab = true,
    showManageabilityServicesTab = true,
    showConfigurationServicesTab = true
  } = visibility;
  let filteredItems = [];
  if (!showMonitorsAccessoriesTab) {
    filteredItems = [
      ...filteredItems,
      ...getConfigData(modelData, "monitoringAccesories").map(
        item => item.items
      )
    ];
  }
  if (!showManageabilityServicesTab) {
    filteredItems = [
      ...filteredItems,
      ...getConfigData(modelData, "manageabilityService").map(
        item => item.items
      )
    ];
  }
  if (!showConfigurationServicesTab) {
    filteredItems = [
      ...filteredItems,
      ...getConfigData(modelData, "configurationService").map(
        item => item.items
      )
    ];
  }
  return filteredItems.flat();
};

export const getShowBOMSectionFlag = (modelData, visibility) => {
  if (typeof modelData === "undefined" || Object.keys(modelData).length === 0) {
    return false;
  } else {
    const filteredItems = filterPLCConflict(modelData, visibility).map(
      item => item.partno
    );
    const { formattedBOMItems } = getConfigBom(modelData);
    let showBOMSectionFlag =
      ((modelData.isValid &&
        modelData.conflicts &&
        modelData.conflicts[0] &&
        Object.values(modelData.conflicts[0]).every(
          item => Object.keys(item).length === 0
        )) ||
        (!modelData.isValid &&
          (!modelData.conflicts ||
            (modelData.conflicts &&
              modelData.conflicts[0] &&
              modelData.conflicts[0].counterConflicts.length === 0 &&
              modelData.conflicts[0].icConflicts.length === 0 &&
              modelData.conflicts[0].gaDateConflicts.length === 0 &&
              modelData.conflicts[0].itemConflictsList.length === 0 &&
              (modelData.conflicts[0].plcConflicts.length === 0 ||
                modelData.conflicts[0].plcConflicts.filter(item =>
                  filteredItems.includes(item.partNo)
                ).length === modelData.conflicts[0].plcConflicts.length) &&
              modelData.conflicts[0].plcConflicts.filter(
                item =>
                  modelData.Chaids.filter(
                    items => items.chaId === item.chaId
                  )[0].required
              ).length === 0)))) &&
      formattedBOMItems &&
      formattedBOMItems.length > 0;
    return showBOMSectionFlag;
  }
};

//Function to handle conflict errors
export const validateStepper = (modelData, stepperData) => {
  if (
    Object.keys(modelData).length > 0 &&
    Object.keys(stepperData).length > 0
  ) {
    const choices = getConfigData(modelData, stepperData.id);
    let isComplete = true;
    let icConflictFound = null;
    let counterConflictsFound = null;
    let itemLevelConflictFound = null;
    let plcConflictFound = null;
    let gaDateConflictFound = null;
    let choiceConfilctFound = null;
    //before finding ensure that the array has the length
    icConflictFound =
      modelData.conflicts[0].icConflicts.length &&
      modelData.conflicts[0].icConflicts
        .filter(x => x.code === "ERR04" || x.code === "ERR03")
        .find(conflictChoice => {
          return choices.find(
            stepperChoice => stepperChoice.chaId === conflictChoice.chaId
          );
        });
    itemLevelConflictFound =
      modelData?.conflicts[0]?.itemConflicts?.effect &&
      choices.find(
        stepperChoice =>
          stepperChoice.chaId ===
          modelData.conflicts[0].itemConflicts.effect[0].chaId
      );
    choiceConfilctFound =
      modelData?.conflicts[0]?.choiceConflicts[0]?.effectChoice &&
      choices.find(
        stepperChoice =>
          stepperChoice.chaId ===
          modelData.conflicts[0].choiceConflicts[0].effectChoice.chaId
      );
    counterConflictsFound =
      modelData.conflicts[0].counterConflicts.length &&
      modelData.conflicts[0].counterConflicts.find(conflictChoice => {
        return choices.find(
          stepperChoice => stepperChoice.chaId === conflictChoice.chaId
        );
      });
    //adding validation for plc conflict
    plcConflictFound =
      modelData.conflicts[0].plcConflicts.length &&
      modelData.conflicts[0].plcConflicts.find(conflictChoice => {
        return choices.find(
          stepperChoice => stepperChoice.chaId === conflictChoice.chaId
        );
      });
    //adding validation for gaDate conflict
    gaDateConflictFound =
      modelData.conflicts[0].gaDateConflicts.length &&
      modelData.conflicts[0].gaDateConflicts.find(conflictChoice => {
        return choices.find(
          gaDatefuture => gaDatefuture.chaId === conflictChoice.chaId
        );
      });
    if (
      icConflictFound ||
      counterConflictsFound ||
      itemLevelConflictFound ||
      plcConflictFound ||
      gaDateConflictFound ||
      choiceConfilctFound
    ) {
      isComplete = false;
    }
    return isComplete;
  } else {
    return true;
  }
};

export const getUiDataFileName = category => {
  let uiSpecFile;
  switch (category) {
    case "Business Laptop PCs":
      uiSpecFile = "notebook";
      break;
    case "Business Desktop PCs":
      uiSpecFile = "desktop";
      break;
    case "Mobile Workstations":
      uiSpecFile = "mobilews";
      break;
    case "Workstations":
      uiSpecFile = "workstation";
      break;
    case "Point of Sale Solutions":
      uiSpecFile = "pointofsale";
      break;
    case "Color LaserJet Printer Transactional":
      uiSpecFile = "printer";
      break;
    case "Color LaserJet Multifunctional Transactional":
      uiSpecFile = "printer";
      break;
    default:
      uiSpecFile = "desktop";
  }
  return uiSpecFile;
};

export const getUISpecData = (function () {
  window.uiSpecData = null;
  window.uiSpecFileName = "";
  return {
    getData: async function (uiSpecFileName) {
      if (window.uiSpecFileName !== uiSpecFileName) {
        const response = await fetch(
          `${process.env.REACT_APP_S3_BUCKET_URL}/${uiSpecFileName}.json`
        );
        const data = await response.json();
        window.uiSpecData = data;
        window.uiSpecFileName = uiSpecFileName;
      }
      return window.uiSpecData;
    }
  };
})();

// to frame config object structure for save api

export const getConfigObjectDataForSave = records => {
  return records && records.length
    ? records
        .filter(choice => choice.items && choice.items.length > 0)
        .map(ele => {
          const data = { choiceId: ele.chaId };
          data.items =
            ele.items && ele.items.length > 0
              ? ele.items
                  .filter(item => item.selected || item.localSelected)
                  .map(detail => ({
                    qty: 1,
                    valueId: detail.valueId ? detail.valueId.toString() : "",
                    partNo: detail?.partno ? detail.partno : ""
                  }))
              : [];
          return data;
        })
        .filter(choices => choices.items && choices.items.length > 0)
    : [];
};
export const setModelDataForDefaultConfig = records => {
  const chaids =
    records?.Chaids && records?.Chaids?.length > 0
      ? records.Chaids.map(chaid => {
          const items = records.Items.filter(part => {
            let partToBeReturned;
            if (
              part.partno === "" &&
              part.valueId !== "" &&
              part.inode >= chaid.firstItem &&
              part.inode <= chaid.lastItem
            ) {
              partToBeReturned = part;
            } else if (
              part.partno !== "" &&
              part.inode >= chaid.firstItem &&
              part.inode <= chaid.lastItem
            ) {
              partToBeReturned = part;
            }
            return partToBeReturned;
          });
          chaid["items"] =
            chaid.items && chaid.items.length > 0
              ? chaid.items
              : items && items.length > 0
              ? items
              : [];
          return chaid;
        })
      : [];
  return chaids;
};
export const getLocalizedMandaCPItem = (modelData, chaid) => {
  const monitorLocOptions =
    modelData.monitorLocOptions &&
    modelData.monitorLocOptions
      .filter(item => item.selected === true)
      .map(item => item.locOption);
  let ipartno = chaid.chaId.substring(5);
  let item = modelData?.Items.find(v => v.partno === ipartno);
  if (item === undefined) {
    ipartno = ipartno.concat("#" + monitorLocOptions);
    item = modelData?.Items.find(v => v.partno === ipartno);
    return item;
  }
  return item;
};
export const getConfigBom = (modelData, filterParams) => {
  let filteredBOMItems = [];
  let choiceBOMItems = [];
  let saveBomChoices = [];
  let formattedBOMItems = [];
  let maxGaDate = "";
  let tabChaidsList = window.uiSpecData;
  choiceBOMItems = modelData.Chaids;
  let haveFutureItem = false;
  updateCarePackSelection(modelData);
  if (modelData && modelData.Items) {
    filteredBOMItems = modelData.Items.filter(
      item =>
        item.selected === true && item.partno !== "" && item.visible === true
    );
    saveBomChoices = [];
    // filteredBOMItems = filteredBOMItems.sort((a, b) => a.inode - b.inode);
    filteredBOMItems.forEach((selectedItem, index) => {
      const choiceObj = choiceBOMItems.find(
        choice =>
          selectedItem.inode >= choice.firstItem &&
          selectedItem.inode <= choice.lastItem
      );
      saveBomChoices = [
        ...saveBomChoices,
        {
          choiceId: choiceObj?.chaId || "",
          choiceName: choiceObj?.chaDes || "",
          selectedItem
        }
      ];
    });
    // Fill uiChoices
    let uiChoices = [];
    tabChaidsList.UISpec[0].sections
      .map(section => section)
      .forEach(section => {
        section.choices.forEach(choice => {
          uiChoices.push({
            sectionId: section.id,
            sectionSequence: section.sequence,
            choiceId: choice.id,
            choiceSequence: choice.sequence
          });
        });
      });

    let maxValueSeqOfUISpec =
      Math.max.apply(
        Math,
        uiChoices.map(function (o) {
          return o.choiceSequence;
        })
      ) + 1;
    // Create sorted array of selected items
    let selectedItems = [];
    let highestSequence = getHighestSequence(uiChoices);
    saveBomChoices.forEach(item => {
      const uiChoice = uiChoices.find(c => c.choiceId === item.choiceId);
      if (uiChoice) {
        selectedItems.push({
          ...uiChoice,
          selectedItem: item.selectedItem,
          itemChoiceId: item.choiceId,
          itemChoiceName: item.choiceName
        });
      } else {
        selectedItems.push({
          selectedItem: item.selectedItem,
          itemChoiceId: item.choiceId,
          itemChoiceName: item.choiceName,
          choiceSequence: ++highestSequence
        });
      }
    });
    filterParams &&
      selectedItems.forEach(item => {
        if (dateCompare(item.selectedItem?.gaDate)) {
          maxGaDate =
            maxGaDate && maxGaDate > item.selectedItem?.gaDate
              ? maxGaDate
              : item.selectedItem?.gaDate;
          haveFutureItem = !isInvalidDate(item.selectedItem?.gaDate);
          return true;
        } else {
          return false;
        }
      });
    // Sort by section->choice->item
    selectedItems = selectedItems.sort(
      (a, b) =>
        a.sectionSequence - b.sectionSequence ||
        a.choiceSequence - b.choiceSequence
    );
    let lineItemIncremental = 1;
    // Iterate and fill formattedBOMItems array
    selectedItems.forEach((item, index) => {
      const selectedItem = item.selectedItem;
      if (selectedItem.partno.includes("#")) {
        formattedPushFunc("LPN");
        formattedPushFunc("NLPN");
      } else {
        formattedPushFunc("NLPN");
      }

      function formattedPushFunc(pnval) {
        formattedBOMItems.push({
          lineItemId: lineItemIncremental++,
          materialNumber:
            pnval === "LPN"
              ? selectedItem.partno.split("#")[0]
              : selectedItem.partno || selectedItem.valueId,
          startingPointFlag: selectedItem.localSelected ? "N" : "Y",
          parentLineItemId: 1,
          qty: selectedItem.quantity !== 0 ? selectedItem.quantity : 1,
          choiceId: item.itemChoiceId || "N/A",
          choiceName: item.itemChoiceName || "N/A",
          materialDescription: selectedItem.partdes,
          delFl: "N"
        });
      }
    });
  }
  return { formattedBOMItems, haveFutureItem, maxGaDate };
};

export const isInvalidDate = gaDate => {
  return gaDate.includes("9999") || gaDate.includes("8888");
};

export const getLocalizationOption = records => {
  return records && records.length
    ? records
        .filter(item => item.selected && item.visible)
        .map(locOpt => ({ id: locOpt.locOption }))
    : [];
};

export const validateWarning = (
  stepperData,
  filteredData,
  modelData,
  defaultConfigStepSlected
) => {
  let warning = false;
  let isItemWarning = false;
  let tabChaidsList = window.uiSpecData;
  let tabData = tabChaidsList.UISpec[0].sections.find(
    section => section.id === stepperData.id
  );

  const dataFromApi = tabData?.choices.map(choice => {
    const chaid =
      modelData &&
      modelData?.Chaids?.find(
        chaid =>
          chaid.chaId === choice.id &&
          chaid.visible &&
          (chaid.precon >= 0 ||
            chaid.isOverridePrecon ||
            chaid.required ||
            chaid.selcon)
      );

    const items =
      chaid &&
      modelData?.Items.filter(item => {
        let formatEod = obsoleteDateFormat(item.esDate);
        const isDateGreater = new Date(formatEod) > new Date();
        const days = calculateDays(formatEod);
        return (
          item.inode >= chaid.firstItem &&
          item.inode <= chaid.lastItem &&
          item.selected &&
          item.visible &&
          isDateGreater &&
          days <= 90
        );
      });
    return items && items.length ? true : false;
  });
  isItemWarning = isItemWarning || dataFromApi?.find(f => f === true);

  // warning =
  //   filteredData?.length > 0
  //     ? filteredData.some(item => {
  //         return item.tab
  //           ? item.tab === stepperData.id
  //           : Object.values(item)[0][0]?.tab === stepperData.id;
  //       })
  //     : isItemWarning;
  return isItemWarning;
};

export const carePackRecommendedChaId = "99999";

export const carePackAdditionalChaId = "99998";

export const otherCarePacksChaId = "SSS99";

export const setIsSpecOrigin = data => {
  data.Chaids.forEach(chaid => {
    chaid.isSpecOrigin = isSpecOrigin(chaid);
  });
};

const isSpecOrigin = chaid => {
  let index = window.uiSpecData.UISpec[0].sections.findIndex(section => {
    return section.choices.findIndex(choice => choice.id === chaid.chaId) > -1;
  });
  return index > -1;
};

export const isCarePackSelected = (carePacks, iNode) => {
  return carePacks?.findIndex(carePack => carePack.itemInode === iNode) > -1;
};

const updateCarePackSelection = modelData => {
  modelData?.carePacks?.forEach(carePack => {
    modelData.Items[carePack.itemInode].selected = modelData.Items[
      carePack.itemInode
    ]
      ? true
      : false;
  });
};

export const processCapqName = capqName => {
  let additionalIndex = capqName.indexOf("_additional");
  if (additionalIndex !== -1) capqName = capqName.substring(0, additionalIndex);
  let recommendedIndex = capqName.indexOf("_recommended");
  if (recommendedIndex !== -1)
    capqName = capqName.substring(0, recommendedIndex);
  return capqName;
};

export const handleAddToQuoteClick = (
  modelData,
  configNameRed,
  userId,
  userEmail,
  companyId,
  regionCode
) => {
  let bmiData = JSON.parse(localStorage.getItem("bmiData"));
  const mDCPOrgID = bmiData["mDCPOrgID"] || "";
  let configDescRes = "";
  const updatedChoicesDetails = setModelDataForDefaultConfig(modelData);
  let { formattedBOMItems: configBOM } = getConfigBom(modelData);
  const getConfigObjectDataRes = getConfigObjectDataForSave(
    updatedChoicesDetails
  );
  const systemLocalizationDefault = getLocalizationOption(
    modelData.systemLocOptions
  );
  const osLocalizationDefault = getLocalizationOption(modelData.osLocOptions);
  const keyBoardLocalizationDefault = getLocalizationOption(
    modelData.kybdLocOptions
  );
  let systemLocOptions, osLocOptions, kybdLocOptions;
  systemLocOptions =
    modelData.systemLocOptions &&
    modelData.systemLocOptions.filter(item => item.selected === true);
  osLocOptions =
    modelData.osLocOptions &&
    modelData.osLocOptions.filter(item => item.selected === true);
  kybdLocOptions =
    modelData.kybdLocOptions &&
    modelData.kybdLocOptions.filter(item => item.selected === true);

  let countryLcLzRes = [
    {
      countryCode: modelData.country,
      systemLocalization: systemLocOptions && systemLocOptions[0]?.locOption,
      osLocalization: osLocOptions && osLocOptions[0]?.locOption,
      keyBoardLocalization: kybdLocOptions && kybdLocOptions[0]?.locOption,
      delFl: "N"
    }
  ];

  // Extract Base Unit to pass inside configHeader if changed
  let selectedBaseUnit = undefined;
  if (configBOM && configBOM.length) {
    selectedBaseUnit = configBOM.find(config => config.choiceId === "BUNIT");
  }

  let inputData = {
    configName: configNameRed,
    configId: null,
    configDesc: configDescRes,
    configHeader: {
      addtnlRegs: "",
      configType: "custom",
      leadCountryCode: modelData.country || "US",
      configHighLights: "",
      configImage: "",
      kmatId: modelData.kmatId || "",
      baseUnitAv: selectedBaseUnit?.materialNumber || "",
      programName: "",
      accessType: "organization",
      refConfigId: "",
      userId: userId,
      userEmail: userEmail,
      customerCompanyName: companyId,
      mdcpOrgId: mDCPOrgID || "",
      originatingAsset: "OCIQ",
      bandingFlag: "N",
      bandingType: "OC-FIX",
      docType: "QU",
      regionCode: modelData.region || "NA",
      lead_locale: "",
      autoLocalized: "",
      kbVersion: "",
      marketProgramName: "",
      marketProgramType: "",
      shipmentDate: "",
      exportStatusId: "new",
      bandingEol: null,
      configEol: null,
      businessModel: "indirect",
      copiedFrom: "",
      localizationType: "",
      priceGeo: modelData.priceGeo,
      currencyCd: modelData.currency,
      incoterm: modelData.incoTerm,
      priceListType: modelData.priceListType,
      globalFl: "N",
      activeFl: "Y",
      delFl: "N",
      favoriteFl: "N",
      productLine: "NA",
      storeFront: "IQ",
      startDate: moment().format("YYYY-MM-DD") || null,
      endDate: moment().add(1, "year").format("YYYY-MM-DD") || null,
      geoMrkt: "",
      spcType: "C"
    },
    countryLcLz: countryLcLzRes,
    configBom: configBOM && configBOM.length ? configBOM : [],
    configObject: {
      configDefault: {
        configuration: {
          localization: [
            {
              choiceId: "systemLocalization",
              values: [...systemLocalizationDefault]
            },
            {
              choiceId: "osLocalization",
              values: [...osLocalizationDefault]
            },
            {
              choiceId: "keyBoardLocalization",
              values: [...keyBoardLocalizationDefault]
            }
          ],
          choices: [...getConfigObjectDataRes]
        }
      },
      configBanding: { banding: {} }
    },
    hierarchy: {
      categoryName: "N/A",
      seriesName: "N/A",
      productType: "compute"
    }
  };
  return inputData;
};

export const handleData = (
  newConfigId,
  modelData,
  configNameRed,
  transactionID
) => {
  let { formattedBOMItems, grandPrice } = getBOMData(modelData);
  const data = {
    transactionID: transactionID,
    eventTriggered: "ADD_TO_QUOTE",
    configID: newConfigId,
    configName: configNameRed,
    refConfigID: newConfigId,
    disableReconfigure: false,
    retainConfigID: true,
    productCategory: "compute",
    components: formattedBOMItems.map(bom => {
      return {
        partNumber: bom.partNumber,
        description: bom.description,
        quantity: bom.qty,
        listPrice: bom.listPrice.toFixed(2).toString(),
        requestedBDNetPrice: "150.00",
        plcOverride: false
      };
    })
  };
  handleEvent(data);
};

const handleEvent = modelJson => {
  if (document.querySelector("#product-selector")) {
    document
      .querySelector("#product-selector")
      .dispatchEvent(new CustomEvent("saveConfig", { detail: modelJson }));
  } else if (document.querySelector("#configurator")) {
    document
      .querySelector("#configurator")
      .dispatchEvent(new CustomEvent("saveConfig", { detail: modelJson }));
  } else if (document.querySelector("#upload-config")) {
    document
      .querySelector("#upload-config")
      .dispatchEvent(new CustomEvent("saveConfig", { detail: modelJson }));
  }
};

//This function will get all elements collapsed or expanded
export const getAllDataExpandedOrCollapsedManul = data => {
  //checking if all choices are expanded
  const allChoiceExpanded = Object.values(data).every(choice => {
    return choice === true;
  });
  const allChoiceCollapsed = Object.values(data).every(choice => {
    return choice === false;
  });
  return [allChoiceExpanded, allChoiceCollapsed];
};
export const handleDuplicateItemConflicts = model => {
  for (let i = 0; i < model.conflicts[0]?.itemConflictsList.length; i++) {
    for (let j = i + 1; j < model.conflicts[0]?.itemConflictsList.length; j++) {
      if (
        model.conflicts[0]?.itemConflictsList[i].effect.chaDes ===
        model.conflicts[0]?.itemConflictsList[j]?.effect?.chaDes
      ) {
        model.conflicts[0]?.itemConflictsList.splice(i + 1, 1);
      }
    }
  }
  return model;
};
// This function will handle more than one banner shown during cause-effect conflict
export const isConfilctsExist = (model, chaid) => {
  let counterConflictsError = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.counterConflicts?.forEach(res => {
      return chaid.chaId === res?.chaId
        ? counterConflictsError.push(" " + res.chaDes)
        : null;
    });
  let gaDateConflictsError = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.gaDateConflicts?.forEach(res => {
      return chaid.chaId === res?.chaId
        ? gaDateConflictsError.push(" " + res.chaDes)
        : null;
    });
  let icConflictsError = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.icConflicts?.forEach(res => {
      return chaid.chaId === res?.chaId
        ? icConflictsError.push(" " + res.chaDes)
        : null;
    });
  let plcConflictsError = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.plcConflicts?.forEach(res => {
      return chaid.chaId === res?.chaId
        ? plcConflictsError.push(" " + res.chaDes)
        : null;
    });
  return (
    plcConflictsError.length === 0 &&
    icConflictsError.length === 0 &&
    gaDateConflictsError.length === 0 &&
    counterConflictsError.length === 0
  );
};

export const shouldHideChoices = (hideChoices, chaid) => {
  const newHideChoice = {};
  for (const key in hideChoices) {
    if (hideChoices[key] === "Y") {
      newHideChoice[key] = hideChoices[key];
    }
  }
  if (Object.keys(newHideChoice).includes(chaid)) {
    return true;
  } else {
    return false;
  }
};
