import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import React from "react";
import { batch, useDispatch } from "react-redux";
import CpqButton from "../../../lib/common/CpqButton";

const useStyles = makeStyles(theme => ({
  uploadConfigBtn: {
    marginTop: 10,
    marginRight: 20,
    backgroundColor: "#fff",
    color: "#29a8dd",
    border: "2px solid #29a8dd",
    fontWeight: "bold"
  },
  backToQuote: {
    marginTop: 10,
    border: "2px solid #29a8dd",
    fontWeight: "bold",
    backgroundColor: "#29a8dd"
  }
}));

const UploadSuccess = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const uploadOtherConfiguration = () => {
    batch(() => {
      dispatch({
        type: "SET_UPLOAD_CONFIG_ITEMS",
        payload: []
      });
      dispatch({
        type: "SET_ACTIVE_STEP",
        payload: 0
      });
    });
  };

  const backToQuote = () => {
    dispatch({
      type: "SET_ACTIVE_STEP",
      payload: 0
    });
    props.handleClose();
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      direction="column"
      alignItems="center"
      style={{ height: "90vh" }}
    >
      <CheckCircleOutlineIcon style={{ color: "#29a8dd", fontSize: "40vh" }} />
      <Typography variant="h6" align="center" style={{ marginBottom: 10 }}>
        Your Configuration has been successfuly <br /> added to quote.
      </Typography>
      <Box>
        <CpqButton
          variant="contained"
          color="primary"
          onClick={uploadOtherConfiguration}
          className={classes.uploadConfigBtn}
        >
          {"Upload other Configuration"}
        </CpqButton>

        <CpqButton
          variant="contained"
          color="primary"
          onClick={backToQuote}
          className={classes.backToQuote}
        >
          {"Back to Quote"}
        </CpqButton>
      </Box>
    </Grid>
  );
};

export default UploadSuccess;
