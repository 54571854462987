import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles(theme => ({
  media: {
    padding: 10,
    backgroundSize: "100%",
    width: "80%",
    height: 90,
    marginLeft: "10px"
  },
  progress: {
    position: "absolute",
    margin: "auto",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  },
  seriesBorderselect: {
    border: `2px solid ${theme.palette.primary.light}`
  },
  cardEllipsis: {
    fontWeight: "bold"
  }
}));

export default function CategorySeriesCard(props) {
  const classes = useStyles();

  const { series, active, handleClick, showCategorySeriesCard } = props;

  const { name, imageUrl } = series;
  let fallBackImageUrl =
    "https://hpi.bigmachines.com/bmfsweb/hpi/image/images/pdc_missing_image.png";
  return (
    <Card
      raised={active}
      className={active ? classes.seriesBorderselect : ""}
      onClick={() => handleClick(series)}
    >
      <CardActionArea>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={3}>
            <CardMedia
              className={classes.media}
              image={imageUrl ? imageUrl : fallBackImageUrl}
              title={name}
              component="div"
            />
            <CircularProgress
              style={{
                display: active && showCategorySeriesCard ? "block" : "none"
              }}
              className={classes.progress}
            />
          </Grid>
          <Grid item xs={9}>
            <CardContent>
              <Typography
                className={classes.cardEllipsis}
                variant="subtitle2"
                color={active ? "primary" : "initial"}
                align="left"
              >
                {name}
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}
