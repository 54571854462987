/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BOM_SELECTION, UPDATE_TAB_EXPAND } from "../actions/Types";
import { ExternalProps } from "../contexts/externalPropsContext";
import {
  calculateDays,
  errorCodeFilter,
  getStorageValue,
  icConflictsFilter,
  obsoleteDateFormat,
  shouldShowOnUI,
  transformDateFormat,
  updateIframeHeight
} from "../lib/common/util";
import {
  getAllDataExpandedOrCollapsedManul,
  getItem,
  handleDuplicateItemConflicts,
  isConfilctsExist
} from "../services/utils";
import ItemSingleRadio from "./ItemSingleRadio";
import ItemSingleSelect from "./ItemSingleSelect";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    boxShadow: "0px 5px 12px rgb(0 0 0 / 25%) !important",
    borderRadius: "4px !important",
    fontSize: theme.typography.pxToRem(12),

    "& .Mui-expanded": {
      marginTop: 0
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    flexBasis: "50%",
    flexShrink: 0,
    color: "#000000",
    fontWeight: 400,
    padding: "8px 0px 0px 0px"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(16),
    color: "#000000",
    fontWeight: 300,
    padding: "8px 0px 0px 18px"
  },
  currencyStyle: {
    fontWeight: "bold",
    fontSize: "12px"
  },
  captionStyle: {
    fontSize: "12px",
    fontWeight: "normal",
    paddingTop: "3px"
  },
  expanded: {
    "&$expanded": {
      margin: "1px 0"
    }
  },
  choiceLevelErrmsgCls: {
    backgroundColor: "red",
    color: "white",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    display: "grid !important"
  },
  choiceLevelMarginTop: {
    marginTop: "0px !important"
  },
  accordionDetails: {
    width: "100%",
    boxSizing: "border-box",
    marginTop: "-15px"
  },
  warningStyle: {
    backgroundColor: "#ffbf00",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5
  },
  warningErrorStyle: {
    backgroundColor: "#FD0032",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5
  }
}));

function ChaidSingle({ chaid, model, handleItemChange }) {
  //setting margins based on expand collapse state
  const expandedMargin = {
    margin: "-0px -52px 15px -16px"
  };
  const collapsedMargin = { margin: "-12px -52px 15px -16px" };
  const classes = useStyles();
  const dispatch = useDispatch();
  const expandAll = useSelector(state => state.configFilterData.expandAll);
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const autoExpandChoice = useSelector(
    state => state.configFilterData.autoExpandChoice
  );
  const bomSelected = useSelector(state => state.configFilterData.bomSelected);
  const errorData = useSelector(state => state.configFilterData.errorData);
  const showWarning = useSelector(state => state.configFilterData.showWarning);
  const loadingSelection = useSelector(
    state => state.configFilterData.loadingSelection
  );
  const showGADate = useSelector(state => state.productSelector.showGADate);
  const isConfigOverDate = useSelector(
    state => state.configFilterData.isConfigOverDate
  );

  const localizationChDes = {
    SYS_LOC_OPTION: "System Localization Options",
    OS_LOC_OPTION: "OS Localization Options",
    KYBD_LOC_OPTION: "Keyboard Localization Options"
  };

  const currentConfigID = useSelector(
    state => state.productSelector.currentConfigID
  );
  const [expanded, setExpanded] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState(false);
  const { currencyCode } = React.useContext(ExternalProps);
  const handleChange = e => {
    e.preventDefault();
    setExpanded(expanded => !expanded);
    setButtonClicked(true);
  };
  const { t } = useTranslation();
  useEffect(() => {
    updateIframeHeight(false);
  }, [expanded]);
  useEffect(() => {
    if (bomSelected === chaid.chaId) {
      setExpanded(true);
      dispatch({ type: BOM_SELECTION, payload: "" });
    }
  }, [bomSelected]);
  useEffect(() => {
    if (autoExpandChoice?.length > 0) {
      autoExpandChoice.forEach(choiceId => {
        if (choiceId === chaid.chaId) {
          setExpanded(true);
        }
      });
    } else {
      retainChoiceState();
    }
  }, [autoExpandChoice]);

  //it will dispatch an action and update only the relevant choiceid with an inverse of current expand status
  useEffect(() => {
    if (buttonClicked) {
      const relevantExpandData = expandAll.find(
        expandedData => expandedData.tab === defaultConfigStepSlected
      );
      if (relevantExpandData && relevantExpandData.choicesExpandState) {
        relevantExpandData.choicesExpandState[chaid.chaId] = expanded;
      }

      // parent-child accordians should handle differently
      // // If child accordian is clicked the parent should always be open
      // setting expand: true here is wrong!
      const expand = getAllDataExpandedOrCollapsedManul(
        relevantExpandData.choicesExpandState
      )[0]
        ? true
        : getAllDataExpandedOrCollapsedManul(
            relevantExpandData.choicesExpandState
          )[1]
        ? false
        : relevantExpandData.expand;
      const payload = {
        tab: defaultConfigStepSlected,
        expand,
        choicesExpandState: relevantExpandData.choicesExpandState,
        flag: true
      };
      dispatch({
        type: UPDATE_TAB_EXPAND,
        payload
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, buttonClicked]);

  const renderWarning = (val, formatEod, idx) => {
    return (
      <Box className={classes.warningStyle} padding={1} key={idx}>
        <Typography align="center" variant={"subtitle2"}>
          {`${t("common:configurator:obsolete90days")} - ${
            val.partno
          } ${formatEod}`}
        </Typography>
      </Box>
    );
  };

  const retainChoiceState = () => {
    if (expandAll.length && defaultConfigStepSlected) {
      const relevantTabData = expandAll.find(
        expandObject => expandObject.tab === defaultConfigStepSlected
      );
      if (
        relevantTabData &&
        relevantTabData.choicesExpandState &&
        Object.keys(relevantTabData.choicesExpandState).includes(chaid.chaId)
      ) {
        const icConflicts = icConflictsFilter(
          model?.conflicts[0]?.icConflicts,
          chaid
        );
        if (icConflicts && chaid.chaId.startsWith("CAPQ_")) {
          setExpanded(true);
        } else {
          setExpanded(relevantTabData.choicesExpandState[chaid.chaId]);
        }
      }
    }
  };
  useEffect(() => {
    retainChoiceState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandAll, defaultConfigStepSlected]);

  useEffect(() => {
    if (localStorage.getItem("isDebug") === "true") {
      console.log("Inside Chaid single component ");
    }
  }, []);

  const isLoadingSelection =
    model &&
    model.Items &&
    model.Items.some(item => item.inode === loadingSelection);

  const chaidReq = chaid.required || chaid.selcon ? true : false;
  const item = getItem(model, chaid);

  let labelDisplay,
    itemSelected,
    itemPrice,
    itemPriceObj,
    pricesList = [];
  let selectedNetPrice = item?.netPrice || 0;

  const choices =
    model &&
    model.Items &&
    model.Items.filter(
      part =>
        part.visible &&
        part.inode >= chaid.firstItem &&
        part.inode <= chaid.lastItem
    );

  let netPriceArray = [];
  choices &&
    choices.forEach(element => {
      netPriceArray.push(element.netPrice);
    });
  let isMinNetPrice = Math.min(...netPriceArray) === Number(selectedNetPrice);

  for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
    if (item !== undefined) {
      if (i === item.inode) {
        labelDisplay = `${item.partno} - ${item.partdes} `;
        itemPrice = `${item.netPrice}`;
      }
      if (item.selected) {
        itemSelected = true;
      }
    }
  }

  // const ld = labelDisplay ? true : false;
  const ldDisplay = labelDisplay
    ? labelDisplay
    : t("common:opportunity.dialog.legalEntity.none");
  const layout = localStorage.getItem("ItemLayout");

  const itemLayout = layout === "dropdown" ? true : false;
  //const choiceLevelErrmsg = (chaidReq && itemSelected === undefined) ? true : false;
  let objCause;
  model &&
    model.conflicts &&
    model.conflicts[0]?.itemConflictsList?.some(res => {
      if (res.chaId === chaid.chaId) {
        objCause = res.cause[0];
        return true;
      } else return false;
    });
  let effects = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.itemConflictsList?.map(itemConflict => {
      if (itemConflict.effect.chaId === chaid.chaId) {
        !effects.includes(itemConflict.effect.chaDes) &&
          effects.push(itemConflict.effect.chaDes);
        return true;
      } else return false;
    });
  let itemLevelError = [];
  model &&
    model.conflicts &&
    model.conflicts[0]?.itemConflictsList?.forEach(res =>
      errorData && errorData.name === chaid.chaId
        ? itemLevelError.push(" " + res.cause.chaDes)
        : null
    );
  const isConflictsExist =
    isConfilctsExist(model, chaid) && itemLevelError.length === 0;
  if (window._DEBUG) {
    if (itemLevelError?.length && localStorage.getItem("isDebug") === "true") {
      console.log("itemLevelError=", itemLevelError, ", MODEL=", model);
    }
  }
  const singleChoice = chaid?.items?.filter(
    part =>
      part.visible &&
      part.inode >= chaid.firstItem &&
      part.inode <= chaid.lastItem &&
      shouldShowOnUI(
        { currentConfigID, showGADate, isConfigOverDate },
        part.gaDate,
        part.selected
      )
  );
  const updatedItemConflictsList = handleDuplicateItemConflicts(model);
  const singleChoiceText = singleChoice?.length;
  let userType = getStorageValue("userType");
  return userType?.toUpperCase() !== "PARTNER" || chaid.chaId !== "CS_C" ? (
    <Box
      className={`${classes.root} choice-container`}
      marginBottom="8px"
      key={"chaid-" + chaid.chaId}
      id={"chaid-" + chaid.chaId}
    >
      <Box>
        {chaid &&
          chaid.items &&
          chaid.items.map((val, idx) => {
            let formatEod = obsoleteDateFormat(val.esDate);
            const days = calculateDays(formatEod);
            const isDateGreater = new Date(formatEod) > new Date();
            return val.selected &&
              val.visible &&
              days <= 90 &&
              isDateGreater &&
              isConflictsExist
              ? renderWarning(
                  val,
                  transformDateFormat(val.esDate, "MM/DD/YYYY"),
                  idx
                )
              : null;
          })}
      </Box>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          className={
            showWarning && showWarning.warning
              ? ""
              : classes.choiceLevelMarginTop
          }
          expandIcon={<ExpandMoreIcon />}
          aria-controls="itemPanelbh-content"
          id="itemPanelbh-header"
        >
          <Box flexDirection="column" flexGrow={1}>
            {/* {`${chaid.required} `}  {`${itemSelected} `} */}
            {/* error part starts */}
            {(!isLoadingSelection ||
              (errorData.name !== chaid.chaId && isLoadingSelection)) &&
              model &&
              model.conflicts &&
              model.conflicts[0].icConflicts &&
              model.conflicts[0].icConflicts.map(res =>
                res.code === "ERR04" && res.chaId === chaid.chaId ? (
                  <div
                    className={classes.choiceLevelErrmsgCls}
                    style={expanded ? expandedMargin : collapsedMargin}
                    key={res.chaId}
                  >
                    <Box className={classes.warningErrorStyle} padding={1}>
                      <Typography align="center" variant={"subtitle2"}>
                        {`${t(
                          "common:configurator:plcConflict1"
                        )} ${chaid.chaDes.toUpperCase()} ${t(
                          "common:configurator:currentConflict5"
                        )} `}
                      </Typography>
                    </Box>
                  </div>
                ) : null
              )}
            {/* RED banner on effected Choice */}
            {(!isLoadingSelection ||
              (errorData.name !== chaid.chaId && isLoadingSelection)) &&
            objCause ? (
              <div
                className={classes.choiceLevelErrmsgCls}
                style={expanded ? expandedMargin : collapsedMargin}
              >
                <Box className={classes.warningErrorStyle} padding={1}>
                  <Typography align="center" variant={"subtitle2"}>
                    {`${t("common:configurator:currentConflict1")} ${
                      chaid.chaDes
                    } ${t("common:configurator:currentConflict3")} ${
                      objCause.chaDes
                    } - ${t("common:configurator:currentConflict4")} `}
                  </Typography>
                </Box>
              </div>
            ) : null}
            {(!isLoadingSelection ||
              (errorData.name !== chaid.chaId && isLoadingSelection)) &&
            !objCause &&
            effects.length === 0 &&
            model &&
            model.conflicts &&
            model.conflicts[0].icConflicts &&
            errorCodeFilter(model?.conflicts[0]?.icConflicts, "ERR03") &&
            icConflictsFilter(model?.conflicts[0]?.icConflicts, chaid) ? (
              <div
                className={classes.choiceLevelErrmsgCls}
                style={expanded ? expandedMargin : collapsedMargin}
              >
                <Box className={classes.warningErrorStyle} padding={1}>
                  <Typography align="center" variant={"subtitle2"}>
                    {`${t("common:configurator:currentConflict1")} ${
                      chaid.chaDes
                    } ${t("common:configurator:currentConflict2")} `}
                  </Typography>
                </Box>
              </div>
            ) : null}
            {/* Handling PLC conflict here */}
            {(!isLoadingSelection ||
              (errorData.name !== chaid.chaId && isLoadingSelection)) &&
              model &&
              model.conflicts &&
              model.conflicts[0].plcConflicts &&
              model.conflicts[0].plcConflicts
                .map(res =>
                  res.code === "ERR06" &&
                  (res.chaId === chaid.chaId ||
                    chaid?.items?.some(
                      choice =>
                        res.partNo === choice.partno &&
                        res.itemiNode === choice.inode
                    )) ? (
                    <div
                      className={classes.choiceLevelErrmsgCls}
                      style={expanded ? expandedMargin : collapsedMargin}
                      key={res.chaId}
                    >
                      <Box className={classes.warningErrorStyle} padding={1}>
                        <Typography align="center" variant={"subtitle2"}>
                          {`${t("common:configurator:plcConflict1")} ${
                            chaid.chaId.includes("CAPQ")
                              ? t("common:configurator:plcConflictManda")
                              : ""
                          } ${chaid.chaDes.toUpperCase()} ${t(
                            "common:configurator:plcConflict2"
                          )} `}
                        </Typography>
                      </Box>
                    </div>
                  ) : null
                )
                .find(err => err)}
            {/* error part ends */}

            {/* Handling GA-Date conflict here */}
            {(!isLoadingSelection ||
              (errorData.name !== chaid.chaId && isLoadingSelection)) &&
              model &&
              model.conflicts &&
              model.conflicts[0].gaDateConflicts &&
              model.conflicts[0].gaDateConflicts.map(res =>
                res.code === "ERR07" && res.chaId === chaid.chaId ? (
                  <div
                    className={classes.choiceLevelErrmsgCls}
                    style={expanded ? expandedMargin : collapsedMargin}
                    key={res.chaId}
                  >
                    <Box className={classes.warningErrorStyle} padding={1}>
                      <Typography align="center" variant={"subtitle2"}>
                        {`${t(
                          "common:configurator:gaDateConflict1"
                        )} ${chaid.chaId.toUpperCase()} ${t(
                          "common:configurator:gaDateConflict2"
                        )} `}
                      </Typography>
                    </Box>
                  </div>
                ) : null
              )}
            {/* error part ends */}

            {/* Handling itemConflicts conflict here */}
            {(!isLoadingSelection ||
              (errorData.name !== chaid.chaId && isLoadingSelection)) &&
              updatedItemConflictsList &&
              updatedItemConflictsList.conflicts &&
              updatedItemConflictsList.conflicts[0]?.itemConflictsList &&
              updatedItemConflictsList.conflicts[0]?.itemConflictsList?.map(
                res =>
                  res.effect.chaId === chaid.chaId ? (
                    <div
                      className={classes.choiceLevelErrmsgCls}
                      style={expanded ? expandedMargin : collapsedMargin}
                      key={res.chaId}
                    >
                      <Box className={classes.warningErrorStyle} padding={1}>
                        <Typography align="center" variant={"subtitle2"}>
                          {`${t("common:configurator:currentConflict1")} ${
                            chaid.chaDes
                          }
                         ${t("common:configurator:currentConflict3")}  ${
                            res.cause.chaDes !== ""
                              ? res.cause.chaDes
                              : localizationChDes[res.cause.chaId] || ""
                          } - ${t("common:configurator:currentConflict4")} `}
                        </Typography>
                      </Box>
                    </div>
                  ) : null
              )}
            {/* error part ends */}
            {/* {Handeling counterConflicts} */}
            {(!isLoadingSelection ||
              (errorData.name !== chaid.chaId && isLoadingSelection)) &&
              model &&
              model.conflicts &&
              model.conflicts[0].counterConflicts &&
              model.conflicts[0].counterConflicts.map(res =>
                res.code === "ERR05" && res.chaId === chaid.chaId ? (
                  <div
                    className={classes.choiceLevelErrmsgCls}
                    style={expanded ? expandedMargin : collapsedMargin}
                    key={res.chaId}
                  >
                    <Box className={classes.warningErrorStyle} padding={1}>
                      <Typography align="center" variant={"subtitle2"}>
                        {res?.counterDesc.includes("PCIE")
                          ? `${res.chaDes.toUpperCase()} ${t(
                              "common:configurator:pcieConflict"
                            )}${t("common:configurator:pcieConflict3")} ${
                              res?.partNos
                            }.`
                          : `${res.chaDes.toUpperCase()} ${t(
                              "common:configurator:pcieConflict2"
                            )}${t("common:configurator:pcieConflict3")} ${
                              res?.partNos
                            }.`}
                      </Typography>
                    </Box>
                  </div>
                ) : null
              )}

            {/* {Handeling hiddenChoiceConflicts error here } */}
            {(!isLoadingSelection ||
              (errorData.name !== chaid.chaId && isLoadingSelection)) &&
              model &&
              model.conflicts &&
              model.conflicts[0]?.itemConflictsList?.length === 0 &&
              model.conflicts[0]?.plcConflicts?.length === 0 &&
              model.conflicts[0]?.counterConflicts?.length === 0 &&
              model.conflicts[0]?.hiddenChoiceConflicts &&
              model.conflicts[0]?.hiddenChoiceConflicts?.map(res =>
                res.cause.chaId === chaid.chaId ? (
                  <div
                    className={classes.choiceLevelErrmsgCls}
                    style={expanded ? expandedMargin : collapsedMargin}
                    key={res.chaId}
                  >
                    <Box className={classes.warningErrorStyle} padding={1}>
                      <Typography align="center" variant={"subtitle2"}>
                        {`${t("common:configurator:currentConflict1")} ${
                          chaid.chaDes
                        }
                         ${t("common:configurator:hiddenChoiceConflict")} `}
                      </Typography>
                    </Box>
                  </div>
                ) : null
              )}
            {/* error part ends */}
            {/* {Handeling choiceConflicts error here } */}
            {(!isLoadingSelection ||
              (errorData.name !== chaid.chaId && isLoadingSelection)) &&
              model &&
              model.conflicts &&
              model.conflicts[0]?.plcConflicts?.length === 0 &&
              model.conflicts[0]?.counterConflicts?.length === 0 &&
              model.conflicts[0]?.itemConflictsList?.length === 0 &&
              model.conflicts[0]?.hiddenChoiceConflicts?.length === 0 &&
              model.conflicts[0]?.choiceConflicts &&
              model.conflicts[0]?.choiceConflicts?.map(res =>
                res.effectChoice.chaId === chaid.chaId ? (
                  <div
                    className={classes.choiceLevelErrmsgCls}
                    style={expanded ? expandedMargin : collapsedMargin}
                    key={res.chaId}
                  >
                    <Box className={classes.warningErrorStyle} padding={1}>
                      <Typography align="center" variant={"subtitle2"}>
                        {`${t("common:configurator:currentConflict1")} ${
                          chaid.chaDes
                        }
                       ${t("common:configurator:currentConflict3")}  ${
                          res.cause.chaDes
                        } - ${t("common:configurator:currentConflict4")} `}
                      </Typography>
                    </Box>
                  </div>
                ) : null
              )}
            {/* error part ends */}
            <Box display="flex">
              <Typography
                className={classes.heading}
                component={"span"}
                variant={"body2"}
              >
                {chaid.chaDes}
              </Typography>
              <Typography
                className={classes.secondaryHeading}
                component={"span"}
                variant={"body2"}
              >
                {ldDisplay}
              </Typography>
            </Box>
            {itemSelected ? (
              singleChoiceText > 1 && chaid.items ? (
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.captionStyle}
                >
                  {t("common:configurator:priceOfPart")} &nbsp;
                  <Typography
                    color="primary"
                    className={classes.currencyStyle}
                    component="span"
                    variant="caption"
                  >
                    {currencyCode}&nbsp;
                    {Math.abs(isNaN(itemPrice) ? 0 : itemPrice).toFixed(2)}.
                  </Typography>
                  {isMinNetPrice
                    ? t("common:configurator.text2")
                    : t("common:configurator.text1")}
                </Box>
              ) : (
                chaid.items &&
                chaid.items.length !== 0 && (
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.captionStyle}
                  >
                    {t("common:configurator:priceOfPart")} &nbsp;
                    <Typography
                      color="primary"
                      component="span"
                      variant="caption"
                      className={classes.currencyStyle}
                    >
                      {currencyCode}&nbsp;
                      {Math.abs(isNaN(itemPrice) ? 0 : itemPrice).toFixed(2)}
                    </Typography>
                    . {t("common:configurator:onlyComponent")}.
                  </Box>
                )
              )
            ) : (
              expanded === false && (
                <Typography className={classes.captionStyle}>
                  {`${t("common:configurator:clickToPick")} ${chaid.chaDes}`}
                </Typography>
              )
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {itemLayout ? (
            <>
              <ItemSingleSelect
                chaid={chaid}
                handleItemChange={handleItemChange}
                item={item}
                model={model}
                chaidReq={chaidReq}
                pricesList={pricesList}
                selectedItemPrice={itemPriceObj}
              />
            </>
          ) : (
            expanded && (
              <ItemSingleRadio
                chaid={chaid}
                handleItemChange={handleItemChange}
                model={model}
                chaidReq={chaidReq}
                pricesList={pricesList}
                selectedItemPrice={itemPriceObj}
              />
            )
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  ) : (
    <div></div>
  );
}

export default ChaidSingle;
