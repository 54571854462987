import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles(() => {
  return createStyles({
    bullet: {
      fontSize: "0.70rem",
      display:
        window.navigator.userAgent.indexOf("Trident") !== -1
          ? "-ms-inline-flexbox"
          : "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      position: "relative",
      bottom: 1
    },
    listItem: {
      paddingRight: 18,
      fontSize: 18
    }
  });
});

const Bullet = React.memo(({ name, value, highlight, lg }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={lg ? lg : 6}
      component={"li"}
      zeroMinWidth
      className={classes.listItem}
    >
      <Tooltip placement="top-start" title={value}>
        <Typography
          variant="inherit"
          component={"div"}
          gutterBottom
          className={classes.bullet}
          color={highlight ? "primary" : ""}
        >
          <div>
            <b>{value}</b>
          </div>
        </Typography>
      </Tooltip>
    </Grid>
  );
});

const ProductBullets = React.memo(({ bullets, lg }) => (
  <Grid container component={"ul"} style={{ paddingLeft: 16, marginTop: 2 }}>
    {bullets?.map(({ name, value, highlight }) => (
      <Bullet
        key={name}
        name={name}
        value={value}
        highlight={highlight}
        lg={lg}
      />
    ))}
  </Grid>
));

export default React.memo(ProductBullets);
