import { useLazyQuery } from "@apollo/client";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { isEmpty } from "lodash";
import { withSnackbar } from "notistack";
import React from "react";
import Dropzone from "react-dropzone";
import { useTranslation, withTranslation } from "react-i18next";
import { batch, useDispatch } from "react-redux";
import XLSX from "xlsx";
import ExternalProps from "../../../contexts/externalPropsContext";
import { UPLOAD_CONFIG_API } from "../../../GraphQL";
import displayNotistack from "../../../lib/common/SnackBarUtils";
import { showAddToQuoteBtn } from "../../../lib/common/util";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 auto",
    padding: "0rem 1rem 1rem 1rem",
    width: "100%",
    borderWidth: 2,
    borderRadius: "1rem",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#c9c9d3",
    outline: "none",
    transition: "border 0.24s ease-in-out"
  },
  dropYour: {
    fontWeight: "normal",
    color: "black",
    textAlign: "center",
    fontSize: "0.9rem"
  },
  orStyle: {
    textAlign: "center",
    fontSize: "1.2rem",
    fontWeight: "bold"
  },
  browse: {
    textAlign: "center",
    color: "#0096D6",
    fontWeight: "normal",
    fontSize: "1rem",
    cursor: "pointer"
  },
  supportedFiles: {
    textAlign: "center",
    fontSize: "0.9rem",
    paddingBottom: "0.8rem",
    color: "#424242"
  }
});

function SheetJSApp(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { countryCode = "US", configRegion = "NA" } =
    React.useContext(ExternalProps);
  const handleFile = file => {
    let hasCompleteUpload = false;
    const extentions = ["xlsx", "xls", "csv"];
    const fileExtention = file.name.split(".").pop();
    const fileSize = file.size / 1024;
    if (!extentions.includes(fileExtention)) {
      displayNotistack.error(false, t("common:uploadConfig.uploadFailed"));
      return false;
    }
    if (fileSize > 500) {
      displayNotistack.error(false, t("common:uploadConfig.sizeExceed"));
      return;
    }
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const wsLength = wb.SheetNames.length;

      let data = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: "",
        blankrows: false
      });

      let newData = [];
      const pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9#]{4,}$/;
      const optionPattern = /^[A-Z]{1}[A-Z0-9]{2}$/;
      const partNoNames = [
        "partno",
        "model no.",
        "modelno",
        "part number",
        "partnumber",
        "product number",
        "productnumber"
      ];
      const optionNames = ["option"];

      let headerRowIndex;
      let partNoColumnIndex;
      let optionColumnIndex;
      let firstRow;
      let finalIndex;
      const itemColumnIndex = {
        sku: "",
        option: ""
      };
      for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
        const items = data[rowIndex];
        items.forEach((columnItem, columnIndex) => {
          if (partNoNames.includes(columnItem?.toString()?.toLowerCase())) {
            headerRowIndex = rowIndex;
            partNoColumnIndex = columnIndex;
          } else if (
            optionNames.includes(columnItem?.toString()?.toLowerCase())
          ) {
            optionColumnIndex = columnIndex;
          }
        });
        if (!isNaN(headerRowIndex)) {
          break;
        }
      }
      function getColumnIndex(item, index) {
        item = isNaN(item) ? item.trim() : item;
        if (item) {
          if (
            itemColumnIndex.sku === "" &&
            isNaN(item) &&
            pattern.test(item) &&
            !optionPattern.test(item)
          ) {
            itemColumnIndex.sku = index;
          } else if (
            itemColumnIndex.option === "" &&
            isNaN(item) &&
            !pattern.test(item) &&
            optionPattern.test(item)
          ) {
            itemColumnIndex.option = index;
          }
        }
      }
      if (!isNaN(headerRowIndex) && !isNaN(partNoColumnIndex)) {
        data.slice(headerRowIndex + 1).forEach(item => {
          const option =
            !isNaN(optionColumnIndex) && item[optionColumnIndex].trim();
          const sku = option
            ? item[partNoColumnIndex].trim() + "#" + item[optionColumnIndex]
            : item[partNoColumnIndex].trim();
          sku && newData.push(sku);
        });
      } else {
        //for translated title, find the firstDataRow
        for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
          const rowItem = data[rowIndex];
          for (let colIndex = 0; colIndex < rowItem.length; colIndex++) {
            if (
              isNaN(rowItem[colIndex]) &&
              pattern.test(rowItem[colIndex].trim())
            ) {
              firstRow = rowIndex;
              break;
            }
          }
          if (!isNaN(firstRow)) {
            break;
          }
        }
        //get correspond column by getColumnIndex function
        if (!isNaN(firstRow)) {
          for (let index = firstRow; index < data.length; index++) {
            const currentItem = data[index];
            const haveGotColumnIndex = Object.values(itemColumnIndex).every(
              item => item !== ""
            );
            if (!haveGotColumnIndex) {
              if (index === data.length - 1) {
                finalIndex = index;
              }
              currentItem.forEach((item, colIndex) =>
                getColumnIndex(item, colIndex)
              );
            } else {
              finalIndex = index;
              break;
            }
          }
        }
        //handle Data
        if (
          !isNaN(firstRow) &&
          (typeof itemColumnIndex.sku === "number" ||
            finalIndex === data.length - 1)
        ) {
          data.slice(firstRow).forEach(item => {
            const option =
              itemColumnIndex.option && item[itemColumnIndex.option];
            const sku = option
              ? item[itemColumnIndex.sku].trim() + "#" + option
              : item[itemColumnIndex.sku].trim();
            sku && newData.push(sku);
          });
        }
      }

      data = newData.filter(item => pattern.test(item));

      let snackbarMsg = "";
      if (wsLength > 1) {
        snackbarMsg = t("common:snackbar.excelSheet");
      } else if (isEmpty(data)) {
        snackbarMsg = t("common:snackbar.emptyFile");
      }
      if (snackbarMsg !== "") {
        displayNotistack.error(false, snackbarMsg);
        hasCompleteUpload = true;
        return;
      }
      props.setLoading(true);
      uploadConfigApi({
        variables: {
          filter: {
            bomList: data,
            shipmentDate: "2021-03-21",
            country: countryCode,
            region: "",
            mktProgramName: "",
            mktProgramType: ""
          }
        }
      });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);

    const timer = setInterval(() => {
      props.setProgressValue(prevProgress => {
        if (!hasCompleteUpload && prevProgress === 80) {
          prevProgress = 70;
        }
        if (prevProgress >= 100) {
          if (timer) clearInterval(timer);
        }
        return prevProgress + 10;
      });
    }, 50);
  };

  const [uploadConfigApi] = useLazyQuery(UPLOAD_CONFIG_API, {
    fetchPolicy: "no-cache",
    onCompleted(response) {
      props.setLoading(false);
      props.setHasUpload(false);
      props.setModelId(
        response.uploadConfigApi?.bunitKmatResponse?.response?.kmatId
      );
      props.setRegion(response.uploadConfigApi?.configHeader?.region);
      const isValid =
        response.uploadConfigApi?.bomValidateResponse?.bomReponse?.status ===
          "BUILDABLE" && showAddToQuoteBtn(response.uploadConfigApi?.products);
      batch(() => {
        dispatch({
          type: "SET_ADD_TO_QUOTE_BUTTON_VISIBILITY",
          payload: isValid
        });
        dispatch({
          type: "SET_ACTIVE_STEP",
          payload: 1
        });
        dispatch({
          type: "SET_BILL_OF_MATERIALS_ITEMS",
          payload: response.uploadConfigApi?.products
        });
        dispatch({
          type: "SET_CONFIGURATION_NAME",
          payload: response.uploadConfigApi?.configName
        });
        dispatch({
          type: "SET_ERRORS",
          payload:
            response.uploadConfigApi?.bomValidateResponse?.bomReponse?.error
        });

        dispatch({
          type: "SET_WARNINGS",
          payload:
            response.uploadConfigApi?.bomValidateResponse?.bomReponse?.warning
        });
        dispatch({
          type: "SET_STATUS",
          payload:
            response.uploadConfigApi?.bomValidateResponse?.bomReponse?.status
        });
      });
    },
    onError({ response }) {
      console.log("Error");
    }
  });

  return props.loading ? (
    <React.Fragment />
  ) : (
    <>
      <Typography gutterBottom>
        {t("common:searchProduct.uploadBOMFile")}{" "}
        <b>{t("common:searchProduct.baseUnit")}</b>{" "}
        {t("common:searchProduct.componentFirst")} <br />
        {t("common:upload.uploadText5")}
        <b>{t("common:upload.uploadText6")} </b>
        {t("common:upload.uploadText7")}
      </Typography>
      <DragDropFile handleFile={handleFile}>
        <div className="row">
          <div className="col-xs-12">
            <TranslatedDataInput
              handleFile={handleFile}
              classes={props.classes}
            />
          </div>
        </div>
      </DragDropFile>
    </>
  );
}

class DragDropFile extends React.Component {
  suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  onDrop = evt => {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  };
  render() {
    return (
      <div
        onDrop={this.onDrop}
        onDragEnter={this.suppress}
        onDragOver={this.suppress}
      >
        {this.props.children}
      </div>
    );
  }
}

class DataInput extends React.Component {
  handleDrop = uploadingFiles => {
    if (uploadingFiles && uploadingFiles[0])
      this.props.handleFile(uploadingFiles[0]);
  };
  showOnHover = event => {
    if (event.target.closest) {
      event.target.closest(".container>div").style.borderColor = "#0096d6";
    }
  };

  hideOnHover = event => {
    if (event.target.closest) {
      event.target.closest(".container>div").style.borderColor = "#eeeeee";
    }
  };

  render() {
    const { t } = this.props;
    const { classes } = this.props;
    const supportedFileTypes = [" .xls,", " .xlsx,", " .csv"];
    return (
      <form className="form-inline">
        <div>
          <Dropzone onDrop={this.handleDrop} multiple>
            {({ getRootProps, getInputProps }) => (
              <section className="container">
                <div
                  onMouseOver={this.showOnHover}
                  onMouseOut={this.hideOnHover}
                  {...getRootProps({ className: `${classes.container}` })}
                >
                  <input
                    {...getInputProps()}
                    accept=".csv,.xls,.xlsx"
                    style={{
                      visibility: "hidden",
                      width: 0
                    }}
                  />
                  <Typography variant="caption" className={classes.dropYour}>
                    {t("common:searchProduct.dropFiles")}
                    <Typography className={classes.orStyle}>
                      {t("common:searchProduct.or")}
                    </Typography>
                    <Typography className={classes.browse}>
                      {t("common:uploadConfig.browseFolder")}
                    </Typography>
                    <Typography className={classes.supportedFiles}>
                      {t("common:uploadConfig.fileTypes")}
                    </Typography>
                  </Typography>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      </form>
    );
  }
}
const TranslatedDataInput = withTranslation()(DataInput);

export default withStyles(styles)(withSnackbar(SheetJSApp));
