import moment from "moment";
import {
  CAREPACK_ELECT_CPQ,
  CAREPACK_EU_CPQ,
  CLICEnabled,
  CODE_CONFLICT_ITEM,
  CODE_COUNTER_CONFLICT,
  CODE_GA_DATE_CONFLICT,
  CODE_PLC_CONFLICT,
  CODE_REQUIRED_CHOICE_EMTY,
  CustomerChaid,
  DO_SELECT,
  EdgeType_I_AVL,
  HIDDEN_CHOICES,
  INVALID,
  INVALIDPLCSTATUS,
  KBD_LOCALIZATION_CATEGORY,
  KYBD_LOC_OPTION,
  MONITOR_CAREPACKS_PREFIX,
  MON_LOCALIZATION_CATEGORY,
  MON_LOC_OPTION,
  OS_LOCALIZATION_CATEGORY,
  OS_LOC_OPTION,
  OUTPUT_FINISHING_CHOICE_ID,
  PAPER_TRAY_CHOICE_ID,
  PartialCLICEnabled,
  SPECIAL_ITEM_MESSAGE,
  SYS_LOCALIZATION_CATEGORY,
  SYS_LOC_OPTION,
  TEXT_CONFLICT,
  TEXT_COUNTER_CONFLICT,
  TEXT_EMPTY,
  TEXT_GA_DATE_CONFLICT,
  TEXT_PLC_CONFLICT,
  TEXT_PRICE_CONFLICT,
  TEXT_WARNING,
  VALID
} from "./Constants.js";
import { loadOneModel } from "./main.js";
import { processInput } from "./processInput.js";
import {
  getChoiceConflicts,
  getHiddenChoiceConflicts,
  getIChaid,
  getItemConflictsNn,
  getSameConflictItems,
  initializeNeuralNetVars,
  setChoiceConflicts,
  setHiddenChoiceConflicts,
  setItemConflictsNn
} from "./processOutput.js";
const isDebug = window.localStorage.getItem("isDebug") === "true";
let itemConflictLoc = [];
let sysLocDependentChaids = [
  "PWRCRD",
  "WRRTY",
  "RSTRCD",
  "MON",
  "DIBHW",
  "DIB2",
  "DIBMISC",
  "DIB",
  "RCB"
];

export const binarySearch = (modelData, iNode, key) => {
  let left = 0;
  let right = modelData?.length - 1;
  while (left <= right) {
    let middle = Math.floor((left + right) / 2);
    let middleData = key ? modelData[middle][key] : modelData[middle];
    if (middleData === iNode) {
      return middle;
    } else if (middleData < iNode) {
      left = middle + 1;
    } else {
      right = middle - 1;
    }
  }
  return -1;
};
//src/webasm/validate.go
export const getSelectedItemInPreconFalseChoices = model => {
  const itemIdxs = [];
  for (let i = 0; i < model?.Chaids?.length; i++) {
    const item = model.Chaids[i];
    if (item.precon < 0) {
      for (let i = item.firstItem; i <= item.lastItem; i++) {
        if (model.Items[i].selected) {
          itemIdxs.push(i);
        }
      }
    }
  }
  return itemIdxs;
};
//src/webasm/validate.go
export const getConflictsForAllUnselectedItemInRequiredChoices = model => {
  let isComplete = true;
  let icCnflts = [];
  for (let j = 0; j < model?.Chaids?.length; j++) {
    const chaObj = model.Chaids[j];
    let unSelectedFlag = 0;
    if ((chaObj.required || chaObj.selcon) && chaObj.visible) {
      if (chaObj.firstItem === chaObj.lastItem) {
        if (!model.Items[chaObj.firstItem].selected) {
          unSelectedFlag = 1;
        }
      } else {
        for (var i = chaObj.firstItem; i <= chaObj.lastItem; i++) {
          if (model.Items[i].selected && model.Items[i].visible) {
            unSelectedFlag = 0;
            break;
          } else {
            unSelectedFlag = 1;
          }
        }
      }
    }

    if (unSelectedFlag !== 0) {
      const newCnflts = {
        code: CODE_REQUIRED_CHOICE_EMTY,
        messageType: TEXT_WARNING,
        chaId: chaObj.chaId,
        chaDes: chaObj.chaDes,
        partNo: TEXT_EMPTY
      };

      icCnflts = [...icCnflts, newCnflts];

      if (isComplete) {
        isComplete = false;
      }
    }
  }

  model.isComplete = isComplete;
  return icCnflts;
};
//src/webasm/validate.go
export const getConflictsForSelectedItemInPreconFalseItems = (
  model,
  itmCnfltList
) => {
  let conflictsItemMap = [];
  let isConflict = false;
  let icCnflts = [];
  let sameConflictItemsMap = {};
  let flagMap = {};
  model?.sameConflictItems?.forEach(each => {
    sameConflictItemsMap[each] = true;
    itmCnfltList?.forEach(conflict => {
      if (conflict?.effect.iitem == each) {
        flagMap[each] = true;
      }
    });
  });

  for (let i = 0; i < model?.Items?.length; i++) {
    const item = model.Items[i];
    const isDebug = window.localStorage.getItem("isDebug") === "true";
    item?.selected &&
      item?.precon <= -2 &&
      isDebug &&
      console.log("PRECON WITH -2", item);
    if (item.visible && item.precon < 0 && item.selected) {
      if (flagMap[item.inode]) {
        conflictsItemMap.push({
          itemINode: item.inode,
          itemPartNo: item.partno
        });
        if (!isConflict) {
          isConflict = true;
        }
      } else if (!sameConflictItemsMap[item.inode]) {
        conflictsItemMap.push({
          itemINode: item.inode,
          itemPartNo: item.partno
        });
        if (!isConflict) {
          isConflict = true;
        }
      }
    }
  }

  for (let i = 0; i < conflictsItemMap.length; i++) {
    const item = conflictsItemMap[i];
    for (let j = 0; j < model?.Chaids?.length; j++) {
      const chaObj = model.Chaids[j];
      if (!HIDDEN_CHOICES[chaObj?.chaId]) {
        if (
          item.itemINode >= chaObj.firstItem &&
          item.itemINode <= chaObj.lastItem
        ) {
          const newCnflts = {
            code: CODE_CONFLICT_ITEM,
            messageType: TEXT_CONFLICT,
            chaId: chaObj.chaId,
            chaDes: chaObj.chaDes,
            partNo: item.itemPartNo
          };
          icCnflts = [...icCnflts, newCnflts];
        }
      }
    }
  }

  model.isConflict = isConflict;
  return icCnflts;
};
//src/webasm/validate.go
export const isPlcStatusInvalid = (model, i) => {
  return model.Items[i].plcStatus === INVALIDPLCSTATUS;
};

export const isListPriceInvalid = (modelData, i) => {
  return modelData.Items[i].priceStatus != VALID;
};

//src/webasm/validate.go
export const getPlcConflicts = model => {
  let plcInValidSelectedItems = [];
  let priceInvalidSelectedItems = [];
  let chSetVisible = false;
  for (let index = 0; index < model?.Chaids?.length; index++) {
    const chaid = model.Chaids[index];
    if (!(chaid.iNode == 0 || chaid?.chaId == CustomerChaid)) {
      for (var i = chaid.firstItem; i <= chaid.lastItem; i++) {
        if (isPlcStatusInvalid(model, i)) {
          if (isInvalidItemsVisible(model, i)) {
            model.Items[i].visible = false;
          }
          if (model?.Items[i].selected) {
            plcInValidSelectedItems = [...plcInValidSelectedItems, i];
            model.Items[i].visible = true;
            if (!chSetVisible) {
              chSetVisible = true;
            }
          }
        } else if (isListPriceInvalid(model, i)) {
          if (model.Items[i].selected) {
            priceInvalidSelectedItems = [...priceInvalidSelectedItems, i];
            model.Items[i].visible = true;
            if (!chSetVisible) {
              chSetVisible = true;
            }
          }
        }
      }
    }
    if (
      chSetVisible ||
      model.Chaids[index].required ||
      model.Chaids[index].selcon
    ) {
      model.Chaids[index].visible = true;
    }
    chSetVisible = false;
  }
  let plcConflicts = [];
  for (let j = 0; j < plcInValidSelectedItems.length; j++) {
    const each = plcInValidSelectedItems[j];
    for (var i = 0; i < model.Chaids.length; i++) {
      const chaid = model.Chaids[i];
      if (!HIDDEN_CHOICES[chaid.chaId]) {
        if (each >= chaid.firstItem && each <= chaid.lastItem) {
          const newPlcConflict = {
            code: CODE_PLC_CONFLICT,
            messageType: TEXT_PLC_CONFLICT,
            plcMessage: model.Items[each].plcMessage,
            itemiNode: model.Items[each].inode,
            chaId: chaid.chaId,
            chaDes: chaid.chaDes,
            partNo: model.Items[each].partno,
            saDate: model.Items[each].saDate,
            esDate: model.Items[each].esDate
          };
          plcConflicts = [...plcConflicts, newPlcConflict];
          model.isPlcConflict = true;
          break;
        }
      }
    }
  }
  for (let j = 0; j < priceInvalidSelectedItems.length; j++) {
    const each = priceInvalidSelectedItems[j];
    for (var i = 0; i < model.Chaids.length; i++) {
      const chaid = model.Chaids[i];
      if (!HIDDEN_CHOICES[chaid.chaId]) {
        if (each >= chaid.firstItem && each <= chaid.lastItem) {
          const newPlcConflict = {
            code: CODE_PLC_CONFLICT,
            messageType: TEXT_PRICE_CONFLICT,
            plcMessage: model.Items[each].plcMessage,
            itemiNode: model.Items[each].inode,
            chaId: chaid.chaId,
            chaDes: chaid.chaDes,
            partNo: model.Items[each].partno,
            saDate: model.Items[each].saDate,
            esDate: model.Items[each].esDate
          };
          plcConflicts = [...plcConflicts, newPlcConflict];
          model.isPlcConflict = true;
          break;
        }
      }
    }
  }
  return plcConflicts;
};

const isPastDate = date => {
  const inputDate = moment(date).format("YYYY-MM-DD");
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  return todayDate > inputDate;
};

const isFutureDate = date => {
  const inputDate = moment(date).format("YYYY-MM-DD");
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  return todayDate < inputDate;
};
const generateGADateConflicts = (model, todayDate) => {
  let gaConflictsItemMap = [];
  let gaDateConflicts = [];
  for (let index = 0; index < model?.Items?.length; index++) {
    const item = model.Items[index];
    if (item.gaDate !== "NA") {
      const gaDate = moment(item.gaDate).format("YYYY-MM-DD");
      if (item.selected && item.visible && gaDate > todayDate) {
        // check if the item is selected & visible &gadate is future
        gaConflictsItemMap.push({
          itemINode: item.inode,
          itemPartNo: item.partno
        });
      }
    }
  }

  for (let i = 0; i < gaConflictsItemMap.length; i++) {
    const item = gaConflictsItemMap[i];
    for (let j = 0; j < model?.Chaids?.length; j++) {
      const chaObj = model.Chaids[j];
      if (
        item.itemINode >= chaObj.firstItem &&
        item.itemINode <= chaObj.lastItem &&
        chaObj.visible &&
        chaObj.precon >= 0
      ) {
        const newCnflts = {
          code: CODE_GA_DATE_CONFLICT,
          messageType: TEXT_GA_DATE_CONFLICT,
          chaId: chaObj.chaId,
          chaDes: chaObj.chaDes,
          partNo: item.itemPartNo
        };
        gaDateConflicts = [...gaDateConflicts, newCnflts];
      }
    }
  }
  return gaDateConflicts;
};

export const getgaDateConflicts = model => {
  const startDate = model?.startDate;
  const date = new Date();
  const todayDate = moment(date).format("YYYY-MM-DD");
  let gaDateConflicts = [];
  const showGaDateError = model.showGaDateConfigError;

  // Case 1 & 2: ShowGADate is off && SPC start date is future or past date
  if (!model.showGADate && showGaDateError) {
    if (isFutureDate(startDate) || isPastDate(startDate)) {
      gaDateConflicts = generateGADateConflicts(model, todayDate);
    }
  }
  model.isGaDateConflict = gaDateConflicts.length > 0;
  return gaDateConflicts;
  // Trial ends here
};

//src/webasm/validate.go
export const printModelUpdate = model => {
  let isPaperTraySelected = false;
  for (let index = 0; index < model?.Chaids?.length; index++) {
    const chaObj = model.Chaids[index];
    if (
      chaObj.chaId === PAPER_TRAY_CHOICE_ID &&
      chaObj.precon >= 0 &&
      chaObj.visible
    ) {
      for (var i = chaObj.firstItem; i <= chaObj.lastItem; i++) {
        if (model.items && model.items[i].selected) {
          isPaperTraySelected = true;
          break;
        }
      }
    } else if (chaObj.chaId === OUTPUT_FINISHING_CHOICE_ID) {
      if (isPaperTraySelected) {
        model.Chaids[index].visible = true;
      } else if (!isPaperTraySelected) {
        model.Chaids[index].visible = false;
        for (var j = chaObj.firstItem; j <= chaObj.lastItem; j++) {
          if (model.items && model.items[j].selected) {
            model.Chaids[index].visible = true;
          }
        }
      }
    }
  }
};
//src/webasm/validate.go getEffect
export const getEffects = (model, conflicts) => {
  let eff = [];
  let sameConflictItemsMap = {};
  const Edge_Type_Sel = 68;
  let itmCnfltList = [];
  if (conflicts?.length > 0 && conflicts[0].itemConflictsList.length > 0) {
    itmCnfltList = conflicts[0].itemConflictsList;
  }
  const flagMap = {};
  model?.sameConflictItems?.forEach(each => {
    sameConflictItemsMap[each] = true;
    itmCnfltList?.forEach(conflict => {
      if (conflict?.effect?.iitem == each) {
        flagMap[each] = true;
      }
    });
  });
  for (let i = 0; i < model?.Items?.length; i++) {
    const item = model.Items[i];
    if (item.visible && item.precon < 0 && item.selected) {
      if (flagMap[item.inode]) {
        const { Ichaid, ChaId, ChaDes } = getIChaid(item.inode, model);
        if (!HIDDEN_CHOICES[ChaId]) {
          const newEffect = {
            iitem: item.inode,
            partNo: item.partno,
            ichaid: Ichaid,
            chaId: ChaId,
            chaDes: ChaDes,
            etype: Edge_Type_Sel
          };
          eff = [...eff, newEffect];
        } else if (!sameConflictItemsMap[item.inode]) {
          const { Ichaid, ChaId, ChaDes } = getIChaid(item.inode, model);
          if (!HIDDEN_CHOICES[ChaId]) {
            const newEffect = {
              iitem: item.inode,
              partNo: item.partno,
              ichaid: Ichaid,
              chaId: ChaId,
              chaDes: ChaDes,
              etype: Edge_Type_Sel
            };
            eff = [...eff, newEffect];
          }
        }
      }
      for (let index = 0; index < model?.conflicts?.length; index++) {
        model.conflicts[index].itemConflicts = {
          cause: [],
          effect: eff
        };
      }
    }
  }

  if (eff?.length === 0) {
    for (let index = 0; index < model?.conflicts?.length; index++) {
      model.conflicts[index].itemConflicts = {};
    }
  }
  isDebug && console.log("getEffects uitl=>", eff);
  return model;
};
//src/webasm/print.go
export const printConflicts = modelData => {
  if (
    localStorage.getItem("isDebug") === "true" ||
    localStorage.getItem("showTime") === "true"
  ) {
    console.log(`\n=======CONFLICTS & FLAGS=======`);
    console.log("ERR04 - Required Choice level Warning");
    console.log("ERR03 - Item level Conflict");
    console.log("ERR05 - Counter level Conflict");
    console.log("ERR06 - PLC level Conflict");
    console.log("Conflicts:", JSON.stringify(modelData.conflicts));
    console.log("IsValid:", modelData.isValid);
    console.log(`================================\n`);
  }
};
const sortPayloadByInode = itemChanges => {
  itemChanges.sort(function (a, b) {
    return a?.inputNode - b?.inputNode;
  });
  return itemChanges;
};
const removeDuplicatesFromNonCP = nonCp => {
  const cm = [];
  let res = [];
  nonCp.forEach(x => {
    if (x?.inputNode && x?.changeIn && x?.inputEdge) {
      const chngIn = String(x.changeIn);
      const inptEge = String(x.inputEdge);
      const inptNde = String(x.inputNode);
      cm[chngIn + ":" + inptEge + ":" + inptNde] = x;
    }
  });
  Object?.keys(cm)?.map(each => {
    let payLd = cm[each];
    if (payLd) {
      res = [...res, payLd];
    }
  });
  return res;
};
//src/service/carepacks-selection.go
export const segregatePayload = (payload, modelData) => {
  let carePackArr = [];
  let nonCarePackArr = [];
  let chaId = {};

  for (let i = 0; i < payload.itemChanges.length; i++) {
    const item = payload.itemChanges[i];
    chaId = getIChaid(item.inputNode, modelData);
    if (
      chaId.ChaId &&
      (chaId.ChaId === CAREPACK_ELECT_CPQ ||
        chaId.ChaId === CAREPACK_EU_CPQ ||
        chaId.ChaId.startsWith(MONITOR_CAREPACKS_PREFIX, 0))
    ) {
      carePackArr.push(item);
    } else {
      nonCarePackArr.push(item);
    }
  }
  let cpPayload = {
    itemChanges: carePackArr
  };
  let nonCpPayload = {
    itemChanges: sortPayloadByInode(removeDuplicatesFromNonCP(nonCarePackArr))
  };
  let segModel = {
    cpPayload,
    nonCpPayload,
    chaId
  };
  return segModel;
};
//src/webasm/validate.go
export const getAllCounterConflicts = model => {
  let ctrConflictsMap = [];
  let ctrConflictsItemsMap = [];
  let cntrCnflts = [];
  let cntrCnfltsArray = [];

  for (let index = 0; index < model?.Counters?.length; index++) {
    const item = model.Counters[index];
    if (item.count < item.min || item.count > item.max) {
      ctrConflictsMap.push(index);
    }
  }
  for (let index = 0; index < ctrConflictsMap.length; index++) {
    const each = ctrConflictsMap[index];
    for (let itemIndex = 0; itemIndex < model?.Items?.length; itemIndex++) {
      const modelItem = model.Items[itemIndex];
      if (modelItem.selected) {
        let edgesData = model?.Nodes[modelItem.inode]?.edges;
        for (let i = 0; i < edgesData.length; i++) {
          let edgesDataItem = edgesData[i];
          if (edgesDataItem.type === 0x5) {
            for (let k = 0; k < edgesDataItem?.node?.length; k++) {
              const edgesItemNode = edgesDataItem.node[k];
              let nodeData = model?.Nodes[edgesItemNode]?.edges;
              for (let j = 0; j < nodeData.length; j++) {
                let nodeDataItem = nodeData[j];
                if (nodeDataItem.type === 0x1) {
                  let ctrInode = nodeDataItem.node[0];
                  let ctrData = model?.Nodes[ctrInode]?.edges;
                  for (let l = 0; l < ctrData.length; l++) {
                    let ctrDataItem = ctrData[l];
                    if (ctrDataItem.type === 0x46) {
                      if (ctrDataItem.node[0] === each) {
                        ctrConflictsItemsMap[each] = ctrConflictsItemsMap[each]
                          ? [...ctrConflictsItemsMap[each], modelItem.inode]
                          : [modelItem.inode];
                        break;
                      }
                    }
                  }
                } else {
                  if (nodeDataItem.type === 0x46) {
                    let nodesData = nodeDataItem?.node;
                    for (let k = 0; k < nodesData.length; k++) {
                      let ctrDataItem = nodesData[k];
                      if (ctrDataItem === each) {
                        ctrConflictsItemsMap[each] = ctrConflictsItemsMap[each]
                          ? [...ctrConflictsItemsMap[each], modelItem.inode]
                          : [modelItem.inode];
                        break;
                      }
                    }
                    break;
                  }
                }
              }
            }
            break;
          }
        }
      }
    }
  }

  for (const [key, value] of Object.entries(ctrConflictsItemsMap)) {
    // eslint-disable-next-line no-loop-func
    for (let itemIndex = 0; itemIndex < model?.Items?.length; itemIndex++) {
      const item = model.Items[itemIndex];
      for (let j = 0; j < value.length; j++) {
        const val = value[j];
        if (item.inode === val) {
          for (let k = 0; k < model?.Chaids?.length; k++) {
            const v = model.Chaids[k];
            if (item.inode >= v.firstItem && item.inode <= v.lastItem) {
              let counter = model?.Counters;
              for (let i = 0; i < counter.length; i++) {
                let vC = counter[i];
                if (i === parseInt(key)) {
                  if (
                    !doesElementExistInCounterConflicts(
                      item.partno,
                      cntrCnfltsArray
                    )
                  ) {
                    const newConflitElements = {
                      partNo: item.partno,
                      count: vC.count,
                      counterDesc: vC.chaDes,
                      max: vC.max,
                      min: vC.min,
                      chaId: v.chaId,
                      chaDes: v.chaDes
                    };
                    cntrCnfltsArray = [...cntrCnfltsArray, newConflitElements];
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }
    model.isCounterConflict = true;
  }
  if (cntrCnfltsArray && cntrCnfltsArray?.length > 0) {
    let checkMap = {};
    let cntrMap = {};
    cntrCnfltsArray?.forEach(cntrConflict => {
      const isExistingChaID = cntrMap[cntrConflict?.chaId];
      if (!isExistingChaID) {
        cntrMap[cntrConflict?.chaId] = {
          code: CODE_COUNTER_CONFLICT,
          messageType: TEXT_COUNTER_CONFLICT,
          chaId: cntrConflict?.chaId,
          chaDes: cntrConflict?.chaDes,
          count: cntrConflict?.count,
          counterDesc: cntrConflict?.counterDesc,
          min: cntrConflict?.min,
          max: cntrConflict?.max
        };
        if (checkMap[cntrConflict?.chaId]) {
          checkMap[cntrConflict?.chaId] = [
            ...checkMap[cntrConflict?.chaId],
            cntrConflict?.partNo
          ];
        } else {
          checkMap[cntrConflict?.chaId] = [cntrConflict?.partNo];
        }
      } else {
        checkMap[cntrConflict?.chaId] = [
          ...checkMap[cntrConflict?.chaId],
          cntrConflict?.partNo
        ];
      }
    });
    Object.keys(cntrMap).forEach(each => {
      const cntrMapitem = cntrMap[each];
      if (checkMap[each]) {
        const newCnflts2 = {
          partNos: checkMap[each],
          code: cntrMapitem?.code,
          messageType: cntrMapitem?.messageType,
          chaDes: cntrMapitem?.chaDes,
          chaId: cntrMapitem?.chaId,
          count: cntrMapitem?.count,
          counterDesc: cntrMapitem?.counterDesc,
          max: cntrMapitem?.max,
          min: cntrMapitem?.min
        };
        cntrCnflts = [...cntrCnflts, newCnflts2];
      }
    });
  }
  return cntrCnflts;
};
//src/service/carepacks-selection.go
export const updateCarePacks = (payload, modelData, chaId) => {
  isDebug && console.log("beforeupdateCarePacks", modelData.carePacks);
  isDebug && console.log("beforepayload", payload);
  let cp = modelData.carePacks;

  for (let i = 0; i < payload?.itemChanges?.length; i++) {
    const item = payload.itemChanges[i];
    if (
      item.changeIn === 1 &&
      !modelData.Items[item.inputNode].selected &&
      modelData.Items[item.inputNode].visible
    ) {
      modelData.Items[item.inputNode].selected = true;
      const { Ichaid, ChaId, ChaDes } = getIChaid(item.inputNode, modelData);
      const doesElementExistSlice = modelData.carePacks?.find(
        carepack => carepack.itemInode === item.inputNode
      );
      if (!doesElementExistSlice) {
        const data = {
          chaId: ChaId,
          itemInode: item.inputNode,
          partNo: modelData.Items[item.inputNode].partno,
          valueId: modelData.Items[item.inputNode].valueId,
          selected: true
        };
        modelData.carePacks = modelData?.carePacks
          ? [...modelData.carePacks, data]
          : [data];
      }
    } else if (item.changeIn === -1) {
      modelData.Items[item.inputNode].selected = false;
      const doesElementExistSlice = modelData.carePacks?.find(
        carepack => carepack.itemInode === item.inputNode
      );
      if (doesElementExistSlice) {
        cp =
          modelData.carePacks.length > 0 &&
          modelData.carePacks.filter(each => each.itemInode !== item.inputNode);
        cp =
          cp &&
          cp.reduce((data, each) => {
            const duplicateItems = data.find(
              item => item.itemInode === each.itemInode
            );
            if (!duplicateItems) {
              return data.concat([each]);
            } else {
              return data;
            }
          }, []);

        modelData.carePacks = cp;
      }
    }
  }
  isDebug && console.log("aftercarepackupdate", modelData.carePacks);
  return modelData;
};
//src/webasm/localization.go
export const getLocChoiceIdsByCategory = (category, ChaId) => {
  let response = false;
  switch (category) {
    case SYS_LOCALIZATION_CATEGORY:
      response = ["CTRYLOC", "CKIT", "CLOC"].includes(ChaId);
      break;
    case KBD_LOCALIZATION_CATEGORY:
      response = category === ChaId;
      break;
    case OS_LOCALIZATION_CATEGORY:
      response = ["OS", "OSLOC", "APPSW", "APPSW1", "APPSW2"].includes(ChaId);
      break;
    case MON_LOCALIZATION_CATEGORY:
      response = ["ASCM_64283", "ASCM_3242660"].includes(ChaId);
      break;
    default:
      response = false;
  }
  return response;
};
const doesElementExistInSelectConflictItems = (modelMapArr, mapp) => {
  let flag = false;
  for (let i = 0; i < modelMapArr?.length; i++) {
    if (mapp[i]) {
      flag = true;
      break;
    }
  }
  return flag;
};
export const setSameConflictItems = modelData => {
  let sameConflictItemsTemp = modelData?.sameConflictItems;
  modelData.sameConflictItems = [];
  sameConflictItemsTemp?.forEach(each => {
    if (
      modelData.Items[each].selected &&
      modelData.Items[each].precon < 0 &&
      !modelData.sameConflictItems?.includes(each)
    ) {
      modelData.sameConflictItems = [...modelData.sameConflictItems, each];
    }
  });

  let sameConflictItems = getSameConflictItems();
  if (
    sameConflictItems &&
    !doesElementExistInSelectConflictItems(
      modelData?.sameConflictItems,
      sameConflictItems
    )
  ) {
    Object.keys(sameConflictItems)?.forEach(each => {
      let conflictNode = Number(each);
      if (!modelData?.sameConflictItems?.includes(conflictNode)) {
        modelData.sameConflictItems = [
          ...modelData.sameConflictItems,
          conflictNode
        ];
      }
    });
  }
};

//src/main-webasm.go
export const updateModel = (iEdge, iNode, changeIn, modelData) => {
  initializeNeuralNetVars();
  if (updatedModel?.conflicts?.length > 0) {
    if (updatedModel.conflicts[0].itemConflictsList?.length > 0) {
      setItemConflictsNn(
        updatedModel?.conflicts[0] &&
          updatedModel?.conflicts[0]?.itemConflictsList
      );
    }
    if (updatedModel.conflicts[0].hiddenChoiceConflicts?.length > 0) {
      setHiddenChoiceConflicts(
        updatedModel?.conflicts[0] &&
          updatedModel?.conflicts[0]?.hiddenChoiceConflicts
      );
    }
    if (updatedModel.conflicts[0].choiceConflicts?.length > 0) {
      setChoiceConflicts(
        updatedModel?.conflicts[0] &&
          updatedModel?.conflicts[0]?.choiceConflicts
      );
    }
  }
  const payload = {
    inputEdge: iEdge,
    inputNode: iNode,
    changeIn
  };
  const updatedModel = processInput(payload, modelData);
  if (updatedModel?.conflicts?.length > 0) {
    updatedModel.conflicts[0].itemConflictsList = getItemConflictsNn();
    updatedModel.conflicts[0].hiddenChoiceConflicts =
      getHiddenChoiceConflicts();
    updatedModel.conflicts[0].choiceConflicts = getChoiceConflicts();
  }
  setSameConflictItems(updatedModel);
  return updatedModel;
};
export const getFormattedTime = time => {
  return (
    time.getFullYear().toString() +
    "-" +
    (time.getMonth() + 1).toString() +
    "-" +
    time.getDate().toString()
  );
};
//src/webasm/localization.go
export const isSpecificItem = (choice, item) => {
  if (
    item.partno === TEXT_EMPTY &&
    item.plcStatus === VALID &&
    item.emDate != ""
  ) {
    return true;
  }
  return false;
};

export const isSpecialDateFilter = (startDate, endDate, targetDate) => {
  return endDate.getFullYear() == 9999 || endDate.getFullYear() == 8888;
};

export const isSpecialValidationPass = (target, startDate, endDate) => {
  return !isSpecialDateFilter(startDate, endDate, target);
};

//src/lib/slice.go
export const doesSubElementExistString = (elm, arr) => {
  let idx = -1;
  let flag = false;
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    if (element.includes(":")) {
      const data = element.split(":");
      if (data[0] == elm) {
        idx = index;
        flag = true;
        break;
      }
    } else {
      if (element == elm) {
        idx = index;
        flag = true;
        break;
      }
    }
  }
  return { idx, flag };
};

//src/webasm/utils.go
export const isSpecialDateCase = (startDate, endDate, referenceTime) => {
  if (startDate.getFullYear() === 9999 || startDate.getFullYear() === 8888) {
    if (endDate.getFullYear() === 9999 || endDate.getFullYear() === 8888) {
      return true;
    }
  }
  return false;
};
//src/webasm/localization.go
export const isBetweenDate = (referenceTime, priceStartDate, priceEndDate) => {
  if (!priceStartDate || !priceEndDate) {
    return false;
  }
  // Product Backlog Item 62157: [Enabler] Allow components with future start date on Configurator
  const startDate = new Date(priceStartDate);
  const endDate = new Date(priceEndDate);
  if (startDate < endDate && referenceTime < endDate) {
    return true;
  }
  // if (startDate < endDate) {
  //   return true;
  // }
  // if (referenceTime < endDate) {
  //   return true;
  // }
  // if (isSpecialDateCase(startDate, endDate, referenceTime)) {
  //   return true;
  // }
  return false;
};
//src/webasm/localization.go
export const isMaterialNotExpired = (referenceTime, item) => {
  let isItemNotExpired = false;
  if (item.plcStatus !== VALID) {
    return { item, isItemNotExpired };
  }
  const startDate =
    item?.saDate !== ""
      ? item?.saDate?.slice(0, 4) +
        "-" +
        item?.saDate?.slice(4, 6) +
        "-" +
        item?.saDate?.slice(6)
      : "";
  const endDate =
    item?.esDate !== ""
      ? item?.esDate?.slice(0, 4) +
        "-" +
        item?.esDate?.slice(4, 6) +
        "-" +
        item?.esDate?.slice(6)
      : "";
  const startDt = new Date(item?.saDate);
  const endDt = new Date(item?.esDate);
  if (!isSpecialValidationPass(startDt, endDt, referenceTime)) {
    return false;
  }
  if (isBetweenDate(referenceTime, startDate, endDate)) {
    isItemNotExpired = true;
    return { item, isItemNotExpired };
  }
  item.plcStatus = INVALID;
  item.plcMessage =
    "Material date invalid for " + getFormattedTime(referenceTime);
  return { item, isItemNotExpired };
};
//src/webasm/validate.go
export const isInvalidItemsVisible = (modelData, index) => {
  let result = false;
  if (
    modelData.Items[index].plcStatus == INVALIDPLCSTATUS &&
    !modelData.Items[index].selected &&
    modelData.Items[index].visible
  ) {
    result = true;
  }
  return result;
};
//src/webasm/localization.go
export const isPriceValid = (referenceTime, item) => {
  let isValidPrice = false;
  if (item.priceStatus !== VALID) {
    return { item, isValidPrice };
  }
  const sDate = new Date(item.priceStartDate);
  const eDate = new Date(item.priceEndDate);
  if (isBetweenDate(referenceTime, sDate, eDate)) {
    isValidPrice = true;
    return { item, isValidPrice };
  }
  item.priceStatus = INVALID;
  item.priceMessage =
    "Price date invalid for " + getFormattedTime(referenceTime);
  return { item, isValidPrice };
};
//src/webasm/localization.go
export const isItemDatesValidOrMark = (referenceTime, choice, item) => {
  let isValid = false;
  if (isSpecificItem(choice, item)) {
    item.priceMessage = SPECIAL_ITEM_MESSAGE;
    item.plcMessage = SPECIAL_ITEM_MESSAGE;
    isValid = true;
    return { item, isValid };
  }
  const priceValidityData = isPriceValid(referenceTime, item);
  item = priceValidityData.item;
  const { isValidPrice } = priceValidityData;
  const itemExpiryData = isMaterialNotExpired(referenceTime, item);
  item = itemExpiryData.item;
  const { isItemNotExpired } = itemExpiryData;
  if (isValidPrice && isItemNotExpired) {
    isValid = true;
    return { item, isValid };
  }
  return { item, isValid };
};
//src/webasm/validate.go

export const RemoveElmConflictsForNotSelected = (iNode, itemConflict) => {
  let tempArr = [];
  itemConflict.forEach(conflict => {
    if (conflict.effect.iitem !== iNode) {
      tempArr = [...tempArr, conflict];
    }
  });
  return tempArr;
};
const doesElementExistItemConflictsLoc = (inode, itemConflictsLoc) => {
  let flag = false;
  itemConflictsLoc.forEach(each => {
    if (each.effect.iitem === inode) {
      flag = true;
    }
  });
  return flag;
};
export const getCauseNEffect = (model, localization, change) => {
  // let eff = [];
  // let cause = [];
  let chg = -1;
  if (change) {
    chg = 1;
  }

  let tempItemConflicts = itemConflictLoc;
  itemConflictLoc?.forEach(conflicts => {
    if (localization.locOptionCategory === KYBD_LOC_OPTION) {
      if (conflicts.cause.chaId?.includes(KYBD_LOC_OPTION)) {
        if (conflicts.cause.partno !== localization.locOption) {
          tempItemConflicts = RemoveElmConflictsForNotSelected(
            conflicts?.effect.iitem,
            tempItemConflicts
          );
        }
      }
    }
    if (localization.locOptionCategory === OS_LOC_OPTION) {
      if (conflicts.cause.chaId?.includes(OS_LOC_OPTION)) {
        if (conflicts.cause.partno !== localization.locOption) {
          tempItemConflicts = RemoveElmConflictsForNotSelected(
            conflicts?.effect.iitem,
            tempItemConflicts
          );
        }
      }
    }
    if (localization.locOptionCategory === SYS_LOC_OPTION) {
      if (conflicts.cause.chaId?.includes(SYS_LOC_OPTION)) {
        if (conflicts.cause.partno !== localization.locOption) {
          tempItemConflicts = RemoveElmConflictsForNotSelected(
            conflicts?.effect.iitem,
            tempItemConflicts
          );
        }
      }
    }
    if (localization.locOptionCategory === MON_LOC_OPTION) {
      if (conflicts.cause.chaId?.includes(MON_LOC_OPTION)) {
        if (conflicts.cause.partno !== localization.locOption) {
          tempItemConflicts = RemoveElmConflictsForNotSelected(
            conflicts?.effect.iitem,
            tempItemConflicts
          );
        }
      }
    }
  });
  itemConflictLoc = [];
  itemConflictLoc = tempItemConflicts;
  for (let i = 0; i < model?.Items?.length; i++) {
    const item = model.Items[i];
    if (
      item.visible &&
      item.selected &&
      item.precon < 0 &&
      !doesElementExistItemConflictsLoc(item?.inode, itemConflictLoc)
    ) {
      const { Ichaid, ChaId, ChaDes } = getIChaid(item.inode, model);
      let newEffect = {
        cause: {
          chaDes: "",
          partno: localization?.locOption,
          chaId: localization?.locOptionCategory,
          ichange: chg,
          etype: 0,
          ichaid: 0,
          iitem: 0
        },
        effect: {
          iitem: item.inode,
          partno: item.partno,
          ichaid: Ichaid,
          chaId: ChaId,
          chaDes: ChaDes
        }
      };
      itemConflictLoc = [...itemConflictLoc, newEffect];
    }
  }
};
export const getItemConflictsLoc = () => {
  return itemConflictLoc;
};

export const setItemConflictsLoc = itemConflicts => {
  itemConflictLoc = itemConflicts;
};

export const doesElementExistInDeletedEntries = (elm, toBeDeletedEntries) => {
  return toBeDeletedEntries.some(each => each?.effect?.partno === elm);
};
export const doesElementExistInDeletedEntriesChoice = (
  elm,
  choiceConflicts
) => {
  return choiceConflicts.some(each => each?.effectChoice?.chaId === elm);
};

export const doesElementExistInCounterConflicts = (elm, cntrCnfltsArray) => {
  return cntrCnfltsArray?.some(each => each?.partNo === elm);
};

export const removeCPconflicts = (itemconflicts, model) => {
  let toBeDeletedEntries = [];
  let tempRetConflicts = [];
  let flagCse = false;
  let flagEff = false;
  itemconflicts?.length > 0 &&
    itemconflicts?.forEach(conflicts => {
      model?.Items?.forEach(items => {
        if (
          conflicts?.effect?.partno?.length > 0 &&
          conflicts?.effect?.partno === items?.partno &&
          (!items?.selected || items.precon >= 0)
        ) {
          toBeDeletedEntries = [...toBeDeletedEntries, conflicts];
        }
        if (conflicts?.cause?.partno == items?.partno) {
          flagCse = true;
        }
        if (conflicts?.effect?.partno == items?.partno) {
          flagEff = true;
        }
      });
      if (!flagCse || !flagEff) {
        toBeDeletedEntries = [...toBeDeletedEntries, conflicts];
      }
      flagCse = false;
      flagEff = false;
    });
  if (toBeDeletedEntries.length > 0) {
    itemconflicts?.forEach(conflicts => {
      let flag = false;
      if (
        doesElementExistInDeletedEntries(
          conflicts.effect.partno,
          toBeDeletedEntries
        )
      ) {
        flag = true;
      }
      if (!flag) {
        tempRetConflicts = [...tempRetConflicts, conflicts];
      }
    });
    itemconflicts = [];
    itemconflicts = tempRetConflicts;
  }
  return itemconflicts;
};
export const DoesElementExistInEffect = (elm, itemConflicts) => {
  for (var i = 0; i < itemConflicts.length; i++) {
    if (itemConflicts[i]?.effect?.partno === elm) return true;
  }
  return false;
};
export const DoesElementExistInIcconflicts = (elm, icConflicts) => {
  for (var i = 0; i < icConflicts.length; i++) {
    if (icConflicts[i]?.partNo === elm) return true;
  }
  return false;
};

export const getRetainedCauseEffect = (
  icConflicts,
  iConfilcts,
  retainedConflicts
) => {
  let icConflictFlag = false;
  if (icConflicts.length > 0) {
    for (let index = 0; index < icConflicts.length; index++) {
      if (icConflicts[index].messageType === TEXT_CONFLICT)
        icConflictFlag = true;
    }
  }
  if (icConflicts.length > 0 && icConflictFlag) {
    for (let index = 0; index < icConflicts.length; index++) {
      if (
        icConflicts[index]?.messageType === TEXT_CONFLICT &&
        iConfilcts.length > 0
      ) {
        for (let conflict = 0; conflict < iConfilcts?.length; conflict++) {
          let iC = iConfilcts[conflict];
          if (iC?.cause?.length > 0) {
            const effects = iC?.effect;
            for (let effect = 0; effect < effects?.length; effect++) {
              if (
                !DoesElementExistInEffect(
                  effects[effect].partno,
                  retainedConflicts
                )
              ) {
                retainedConflicts = [
                  ...retainedConflicts,
                  { cause: iC?.cause[0], effect: effects[effect] }
                ];
              }
            }
          }
        }
      }
    }

    //Conflicts removal
    if (retainedConflicts.length > 0) {
      let tempConflicts = [];
      let toBeDeletedEntries = [];
      for (let index = 0; index < retainedConflicts?.length; index++) {
        for (let range = 0; range < icConflicts.length; range++) {
          if (
            !DoesElementExistInIcconflicts(
              retainedConflicts[index]?.effect?.partno,
              icConflicts
            ) &&
            icConflicts[range].messageType === TEXT_CONFLICT
          ) {
            toBeDeletedEntries = [
              ...toBeDeletedEntries,
              retainedConflicts[index]
            ];
          }
        }
      }
      if (toBeDeletedEntries.length > 0) {
        for (let index = 0; index < retainedConflicts?.length; index++) {
          let retFlag = false;
          for (let del = 0; del < toBeDeletedEntries.length; del++) {
            if (
              toBeDeletedEntries[del]?.effect?.partno ===
              retainedConflicts[index]?.effect?.partno
            ) {
              retFlag = true;
            }
          }
          if (!retFlag)
            tempConflicts = [...tempConflicts, retainedConflicts[index]];
        }
      }
      if (tempConflicts.length > 0) retainedConflicts = tempConflicts;
    }
  } else {
    retainedConflicts = [];
  }
  return retainedConflicts;
};
// module.exports = {
//   binarySearch,
//   getSelectedItemInPreconFalseChoices,
//   getConflictsForAllUnselectedItemInRequiredChoices,
//   getConflictsForSelectedItemInPreconFalseItems,
//   getPlcConflicts,
//   printModelUpdate,
//   printConflicts,
//   getEffects,
//   segregatePayload,
//   getAllCounterConflicts,
//   updateCarePacks
// };
export const selectSingleAvailableItemUnderRequiredChoice = model => {
  let payLoad = {
    itemChanges: [{}]
  };
  model?.Chaids.forEach(choice => {
    if (
      (choice.required || choice.selcon) &&
      choice.visible &&
      choice.precon >= 0
    ) {
      let counter = 0;
      let node;
      for (let i = choice.firstItem; i <= choice.lastItem; i++) {
        const item = model.Items[i];
        if (
          item.visible &&
          item.plcStatus === VALID &&
          item.priceStatus === VALID
        ) {
          counter++;
          if (counter > 1 || item.selected) {
            i = choice.lastItem + 1;
            counter++;
          } else {
            node = model.Nodes[item.inode];
          }
        }
      }

      if (counter === 1 && node) {
        const iPayLoad = {
          inputEdge: node.type,
          inputNode: node.id,
          changeIn: DO_SELECT
        };
        if (payLoad?.itemChanges[0]?.inputNode) {
          payLoad.itemChanges = [...payLoad.itemChanges, iPayLoad];
        } else {
          payLoad.itemChanges = [iPayLoad];
        }
      }
    }
  });
  if (payLoad?.itemChanges[0]?.inputNode) {
    const { cpPayload, nonCpPayload, chaId } = segregatePayload(payLoad, model);
    const oneModel = new loadOneModel(model);
    oneModel.updateModelWithMultipleChanges(nonCpPayload);
  }
};
const doesElementExist = (elm, clicPartiallyAffectedItems) => {
  return clicPartiallyAffectedItems?.some(each => each == elm);
};

export const updateVisibilityForGrayoutItems = modelData => {
  sysLocDependentChaids?.map(sysChaid => {
    modelData.Chaids.map(chaid => {
      if (chaid.chaId == sysChaid) {
        for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
          let item = modelData.Items[i];
          if (item?.partno?.includes("#")) {
            if (item.precon < 0 && item.visible && !item.selected) {
              modelData.Items[i].plcMessage =
                item?.plcMessage +
                " " +
                "UpdateVisibilityForGrayoutItems applied";
              modelData.Items[i].visible = false;
            } else if (
              !item.visible &&
              item.precon >= 0 &&
              item.priceStatus == VALID &&
              item.plcStatus == VALID &&
              item.isBandedItem
            ) {
              modelData.Items[i].plcMessage =
                item?.plcMessage +
                " " +
                "UpdateVisibilityForGrayoutItems applied";
              modelData.Items[i].visible = true;
            }
          }
        }
      }
      if (HIDDEN_CHOICES && HIDDEN_CHOICES[chaid.chaId]) {
        chaid.visible = false;
      }
    });
  });
  modelData.Chaids.forEach(chaid => {
    for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
      let item = modelData.Items[i];
      const { idx, flag } = doesSubElementExistString(
        String(item?.inode),
        modelData?.clicPartiallyAffectedItems
      );
      if (flag) {
        const clicData = modelData.clicPartiallyAffectedItems[idx]?.split(":");
        let clicEdge = -1;
        if (clicData.length > 1) {
          if (clicData[1]?.includes(",")) {
            const clicD = clicData[1].split(",");
            clicEdge = Number(clicD[clicD.length - 1]);
          } else {
            clicEdge = Number(clicData[1]);
          }
        }
        if (
          modelData.Items[i].precon < 0 &&
          clicEdge == EdgeType_I_AVL &&
          !modelData.Items[i].selected
        ) {
          if (modelData.Items[i].visible) {
            modelData.Items[i].visible = false;
            modelData.Items[i].isBandedItem = modelData.Items[i].visible;
            modelData.Items[i].plcMessage = PartialCLICEnabled;
          }
        } else {
          if (
            !modelData.Items[i].visible &&
            item.precon >= 0 &&
            item.priceStatus == VALID &&
            item.plcStatus == VALID
          ) {
            modelData.Items[i].visible = true;
            modelData.Items[i].isBandedItem = modelData.Items[i].visible;
            modelData.Items[i].plcMessage =
              modelData.Items[i].plcMessage +
              " -> UpdateVisibilityForGrayoutItems updated on visibility with CLIC";
          }
        }
      }
    }
    if (HIDDEN_CHOICES && HIDDEN_CHOICES[chaid.chaId]) {
      chaid.visible = false;
    }
  });
  return modelData;
};

export const filterChoiceConflicts = (chConflicts, model) => {
  let toBeDeletedEntries = [];
  let tempRetConflicts = [];
  if (chConflicts?.length > 0) {
    chConflicts.forEach(conflict => {
      let selFlag = false;
      let firstItem = -1;
      let lastItem = -1;
      for (let i = 0; i < model.Chaids?.length; i++) {
        let chaid = model.Chaids[i];
        if (conflict?.effectChoice.chaId == chaid?.chaId) {
          firstItem = chaid.firstItem;
          lastItem = chaid.lastItem;
          break;
        }
      }
      if (firstItem != -1 && lastItem != -1) {
        for (let i = firstItem; i <= lastItem; i++) {
          if (model.Items[i].selected) {
            selFlag = true;
            break;
          }
        }
        if (!selFlag) {
          toBeDeletedEntries = [...toBeDeletedEntries, conflict];
        }
      }
    });
  }
  if (toBeDeletedEntries?.length > 0) {
    chConflicts?.forEach(conflict => {
      let flag = false;
      if (
        doesElementExistInDeletedEntriesChoice(
          conflict?.effectChoice?.chaId,
          toBeDeletedEntries
        )
      ) {
        flag = true;
      }
      if (!flag) {
        tempRetConflicts = [...tempRetConflicts, conflict];
      }
    });
    chConflicts = [];
    chConflicts = tempRetConflicts;
  }
  return chConflicts;
};
