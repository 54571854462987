import { Tooltip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ExternalProps } from "../../../contexts/externalPropsContext";
import ClipboardField from "../../../lib/common/ClipboardField";
import { displayStyledCurrencyLite } from "../../../lib/common/util";
import { AMS, NA } from "./Constants";
import ProductBullets from "./ProductBullets";
import ProductDescription from "./ProductDescription";
import ProductQuantity from "./ProductQuantity";

const useStyles = makeStyles(theme => {
  return createStyles({
    avatar: {
      width: 135,
      height: 100,
      margin: `0 auto ${theme.spacing(2)}px auto`,
      borderRadius: 0,
      "&[class*=MuiAvatar-colorDefault]": {
        // background: `url(${hpLogo}) no-repeat center`,
        // "& svg": {
        //   display: "none"
        // }
      },
      "& [class*=MuiAvatar-img]": {
        width: "auto",
        height: "100%"
      }
    },
    paper: {
      padding: theme.spacing(2)
    },
    hightlightedGrid: {
      padding: theme.spacing(2),
      border: "4px solid #0096D6"
    },
    price: {
      textAlign: "right"
    },
    firstColumn: {
      textAlign: "center",
      cursor: "pointer"
    },
    thirdColumn: {
      textAlign: "right",
      [theme.breakpoints.down("xs")]: {
        textAlign: "left"
      }
    },
    baseProductStyle: {
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline"
      }
    }
  });
});

const ProductList = props => {
  const classes = useStyles();
  const {
    listPrice = "0.00",
    sku,
    image,
    currency,
    currencySign,
    name,
    attributes,
    category,
    quantity,
    tabInfo,
    isSimilar,
    skuType
  } = props;
  const { countryRegion } = useSelector(state => state.productSelector);
  const { modelData } = useSelector(state => state.configFilterData);
  const displayCurrencySymbol = countryRegion === AMS || countryRegion === NA;
  const selectedSku = useSelector(state => state.rskuReducer.sku);
  const { currencyCode, isStandaloneAccess = false } =
    React.useContext(ExternalProps);
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <Grid item xs={12} key={sku} zeroMinWidth style={{ marginBottom: 15 }}>
        <Paper
          variant="outlined"
          className={
            sku === selectedSku ? classes.hightlightedGrid : classes.paper
          }
        >
          <Grid container spacing={2}>
            <Grid
              zeroMinWidth
              item
              xs={12}
              sm={"auto"}
              md={"auto"}
              lg={"auto"}
              xl={"auto"}
              className={classes.firstColumn}
              onClick={handleClick}
            >
              {/* IMAGE */}
              <Avatar src={image[0]} className={classes.avatar} />
              {/* SKU */}
              <Typography
                variant={"body2"}
                component={"span"}
                color="textSecondary"
                noWrap
              >
                {sku}
                <ClipboardField text={sku} />
              </Typography>
            </Grid>
            <Grid zeroMinWidth item xs sm md lg xl>
              {/* NAME */}
              <Tooltip placement="top-start" title={"Click for more details"}>
                <Typography
                  onClick={handleClick}
                  className={classes.baseProductStyle}
                >
                  {name}
                </Typography>
              </Tooltip>
              {attributes && tabInfo !== "carepack" ? (
                <ProductBullets bullets={attributes} />
              ) : (
                <></>
              )}
            </Grid>
            <Grid
              zeroMinWidth
              item
              xs={12}
              sm={"auto"}
              md={"auto"}
              lg={"auto"}
              xl={"auto"}
              className={classes.thirdColumn}
            >
              <Box
                display={"flex"}
                justifyContent="space-between"
                flexDirection={{
                  xs: "row",
                  sm: "column",
                  md: "column",
                  lg: "column",
                  xl: "column"
                }}
              >
                {/* PRICE */}
                <Typography
                  variant={"body1"}
                  noWrap
                  component={"div"}
                  color="primary"
                  style={{ marginBottom: "1rem", fontWeight: "bold" }}
                >
                  {/* currency symbol for AMS/NA regions */}
                  {displayCurrencySymbol && (
                    <Box marginRight={"0.2em"} component={"span"}>
                      {currencySign}
                    </Box>
                  )}
                  {/* amount */}
                  <Box component={"span"} fontSize={"0.875rem"}>
                    {displayStyledCurrencyLite({
                      amount: listPrice,
                      currencyArg: currencyCode || modelData.currency,
                      locale: "en-US"
                    })}
                  </Box>
                  {/* currency code */}
                  {!displayCurrencySymbol && (
                    <Box marginLeft={"0.2em"} component={"span"}>
                      {currency}
                    </Box>
                  )}
                </Typography>
                <Box>
                  {/* QUANTITY */}
                  {!isStandaloneAccess && (
                    <ProductQuantity
                      sku={sku}
                      name={name}
                      category={category}
                      listPrice={listPrice}
                      image={image[0]}
                      currency={currency}
                      currencySign={currencySign}
                      quantity={quantity}
                      isSimilarProduct={isSimilar ? true : false}
                      skuType={skuType}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {open && (
        <ProductDescription
          handleClose={handleClose}
          sku={sku}
          name={name}
          image={image}
        />
      )}
    </>
  );
};

export default ProductList;
