import { useLazyQuery } from "@apollo/client";
import { Grid, IconButton, InputAdornment, InputBase } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { grey } from "@material-ui/core/colors";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SET_SHOW_PREAPPROVED_CONFIG_BUTTON } from "../../../actions/Types";
import { ExternalProps } from "../../../contexts/externalPropsContext";
import { GET_DEFAULT_CONFIGURATION, PRODUCT_SELECTION } from "../../../GraphQL";
import { sortAlphabeticallyByName } from "../../../lib/common/util";
import {
  SET_BUSINESS_MODEL,
  SET_COUNTRY,
  SET_COUNTRY_REGION,
  SET_DISABLE,
  SET_PROGRAM_TYPE,
  SET_SHOWSERIES
} from "./Constants";
let nofoundData = null;

const useStyles = makeStyles(theme => ({
  search: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "10px"
  },
  searchIcon: {
    position: "absolute"
  },
  formControl: {
    width: "170px",
    marginRight: theme.spacing(3),
    minWidth: "10vw",
    maxWidth: 300,
    backgroundColor: theme.palette.primary.grey
  },
  headerGrid: {
    paddingTop: "10px"
  },
  inputBase: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: grey[100]
  },
  marginBar: {
    marginRight: "30px",
    paddingTop: "20px"
  },
  titlePadding: {
    padding: "17px 30px 5px 8px",
    display: "flex",
    alignItems: "center"
  },
  lineHeightBar: {
    lineHeight: "1em"
  },
  input: {
    padding: "9px !important",
    border: "none !important"
  },
  paddingBottom: {
    paddingBottom: "10px"
  },
  selectMargin: {
    marginLeft: "-4%",
    fontSize: "18px"
  },
  searchResult: {
    fontFamily: "hpsimplified",
    fontSize: "normal",
    fontWeight: "normal",
    marginRight: "256px"
  }
}));

const DefaultConfigSearchFilters = props => {
  const {
    setShowDefaultConfig,
    setNoShowModel,
    baseUnitValue,
    setBaseUnitValue,
    proCategory
  } = props;
  const {
    locationID,
    routeToMarket,
    userType,
    isStandaloneAccess,
    transactionID,
    pricingGeo = "US",
    channelRegion = "",
    configRegion = "",
    countryCode = "US"
  } = React.useContext(ExternalProps);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [quoteRecord] = useState({});
  const country = useSelector(state => state.productSelector.country);
  const countrySelectedDropdown = useSelector(
    state => state.productSelector.countrySelectedDropdown
  );
  const userEmail1 = useSelector(state => state.productSelector.userEmail1);
  const categories = useSelector(state => state.productSelector.categories);
  const seriesInfo = useSelector(state => state.productSelector.seriesInfo);
  const [businessModel, setbusinessModel] = useState(routeToMarket);
  const [currentCountry, setCurrentCountry] = useState("");

  const businessModelOptions = ["Direct", "Indirect"];
  const [programType, setProgramType] = useState(
    quoteRecord.programType_channel_line
  );

  const { isDisplayProdCountry } = React.useContext(ExternalProps);
  const countryRegion = useSelector(
    state => state.productSelector.countryRegion
  );
  const displayBusinessModel =
    isStandaloneAccess && userType.toUpperCase() === "INTERNAL";

  useEffect(() => {
    const progType = localStorage.getItem("setProgramType");
    if (progType) {
      setProgramType(progType);
      localStorage.removeItem("setProgramType");
    }
  }, []);

  useEffect(() => {
    setCurrentCountry(
      isStandaloneAccess
        ? countrySelectedDropdown
          ? countrySelectedDropdown
          : country
        : countryCode
    );
  }, [countrySelectedDropdown, country]);

  useEffect(() => {
    const filteredRegion = countryRegion?.length
      ? countryRegion
      : channelRegion || configRegion;
    dispatch({
      type: "SET_PRODUCT_REGION_AND_COUNTRY",
      payload: {
        productCountryCode: currentCountry,
        productRegionCode: filteredRegion
      }
    });
  }, [currentCountry, countryRegion]);

  const changeProgramType = event => {
    setProgramType(event.target.value);
    dispatch({
      type: SET_PROGRAM_TYPE,
      payload: event.target.value
    });
  };
  const changeBusinessModel = event => {
    setbusinessModel(event.target.value);
    dispatch({
      type: SET_BUSINESS_MODEL,
      payload: event.target.value
    });
  };

  const changeCountry = event => {
    dispatch({
      type: SET_COUNTRY,
      payload: event.target.value
    });
    const newRegion = Object.keys(countryData).filter(region => {
      return (
        countryData[region].countries.filter(cntr => {
          return cntr.code === event.target.value;
        }).length > 0
      );
    })[0];
    dispatch({
      type: SET_COUNTRY_REGION,
      payload: newRegion
    });
    window.sessionStorage.setItem(
      "prdCountry" + userEmail1,
      event.target.value
    );
    window.sessionStorage.removeItem("countryFetchFromUserOCA" + userEmail1);
  };

  // const programTypeOptions = PROGRAM_TYPE_LIST;
  const programTypeOptions = ["Recommended", "Extended", "Global"];
  const [countryData, setCountryData] = useState();
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    const country = [];
    if (countryData && countryData.length !== 0) {
      for (let keys in countryData) {
        countryData[keys].countries.map(cnty => country.push(cnty));
      }
      setCountryList(country);
    }
  }, [countryData]);
  const [productSelectionDataCall] = useLazyQuery(PRODUCT_SELECTION, {
    fetchPolicy: "no-cache",
    onCompleted({ productSelection }) {
      const countryDt =
        productSelection.ProductSelectionData.regionLookupDataMap;
      setCountryData(countryDt);
      dispatch({
        type: "SET_SHOW_GADATE",
        payload: productSelection.ShowGADate
      });
      dispatch({
        type: SET_SHOW_PREAPPROVED_CONFIG_BUTTON,
        payload: productSelection.ShowPreApproved
          ? productSelection.ShowPreApproved
          : false
      });
    },
    onError({ graphQLErrors, networkError }) {
      setLoading(false);
    }
  });
  useEffect(() => {
    setCountryRegionOnMount(countryData);
  }, [countryData]);
  useEffect(() => {
    getShowGADateProductSelection();
  }, [currentCountry, countryRegion, businessModel]);
  const setCountryRegionOnMount = countryRegions => {
    let countryRegionSelected = "";
    countryRegions &&
      currentCountry &&
      Object.keys(countryRegions).forEach(region => {
        const country = countryRegions[region].countries.find(
          country => country.code === currentCountry
        );
        if (country) {
          countryRegionSelected = region;
        }
      });
    if (countryRegionSelected !== configRegion) {
      dispatch({
        type: SET_COUNTRY_REGION,
        payload: countryRegionSelected
      });
    }
  };
  const getShowGADateProductSelection = () => {
    productSelectionDataCall({
      variables: {
        filter: {
          region: countryRegion || channelRegion || configRegion || "",
          country: currentCountry || "",
          routeToMarket: displayBusinessModel
            ? businessModel
            : routeToMarket || "Direct"
        },
        transactionId: transactionID || ""
      }
    });
  };
  useEffect(() => {
    if (!baseUnitValue) setNoShowModel(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseUnitValue]);

  useEffect(() => {
    dispatch({
      type: "SET_DISPLAY_BUSINESSMODEL",
      payload: displayBusinessModel
    });
  }, [displayBusinessModel]);
  // const countryOptions = countryList
  //   ? countryList.sort(sortAlphabeticallyByName)
  //   : [];

  // const isManageConfig =
  //   getAttributeValue("attribute-isManageConfig") === "true";
  // const isFixedConfiguration =
  //   getAttributeValue("attribute-isFixedConfiguration") === "false"

  const handleSearch = () => {
    if (baseUnitValue !== "") {
      dispatch({
        type: "RESET_SEARCH_CONFIG"
      });
      dispatch({ type: SET_DISABLE, payload: true });
      setLoading(true);
      dispatch({
        type: "SET_RESET_SELECTED"
      });
      getBaseUnitConfigurations({
        variables: {
          filter: {
            baseUnit: baseUnitValue,
            countryCode: currentCountry,
            proCategory: proCategory,
            programType: programType === "None Selected" ? "" : programType,
            locationID,
            routeToMarket: displayBusinessModel
              ? businessModel
              : routeToMarket || "Direct"
          }
        }
      });
    }
  };

  const handleSetSeries = category => {
    if (category && category !== "")
      return seriesInfo[category].sort(sortAlphabeticallyByName);
  };
  const [getBaseUnitConfigurations] = useLazyQuery(GET_DEFAULT_CONFIGURATION, {
    fetchPolicy: "no-cache",
    onCompleted({ catalogLookup }) {
      if (!catalogLookup.data.searchCatalog) {
        setNoShowModel(true);
        setLoading(false);
      } else {
        setNoShowModel(false);
      }

      setLoading(false);
      setShowDefaultConfig(true);
      if (
        catalogLookup &&
        catalogLookup.data &&
        catalogLookup.data.searchCatalog
      ) {
        catalogLookup.data.searchCatalog = catalogLookup.data.searchCatalog.map(
          config => {
            if (config.imageURL) {
              //needed for backward compatibility for old urls received from api
              if (config.imageURL.includes("$BASE_PATH$")) {
                config.imageURL = config.imageURL.replace(
                  "$BASE_PATH$",
                  "https://hpi.bigmachines.com/bmfsweb/hpi/image"
                );
              }
            } else {
              config.imageURL = `${process.env.REACT_APP_DEFAULT_CONFIG_ICON}`;
            }
            return config;
          }
        );
      }
      if (
        catalogLookup &&
        catalogLookup.data &&
        catalogLookup.data.searchCatalog &&
        catalogLookup.data.searchCatalog[0] &&
        (catalogLookup.data.searchCatalog[0].category ||
          catalogLookup.data.searchCatalog[0].series)
      ) {
        const series = handleSetSeries(
          catalogLookup.data.searchCatalog[0].category
        );
        dispatch({
          type: "SEARCH_DEFAULT_SPC_BY_BASEUNIT",
          payload: {
            searchListConfigurations: catalogLookup.data.searchCatalog,
            isProgramType: true,
            selectedCategory: catalogLookup.data.searchCatalog[0].category,
            selectedSeries: catalogLookup.data.searchCatalog[0].series,
            series: series,
            showSeries: catalogLookup.data.searchCatalog[0].series
              ? true
              : false
          }
        });
      } else {
        dispatch({
          type: SET_SHOWSERIES,
          payload: { showSeries: false, catalogLookup }
        });
      }
    },
    onError({ graphQLErrors, networkError }) {
      setLoading(false);
    }
  });
  const [isDisable, setIsDisable] = useState(true);
  const handleBaseUnit = e => {
    setBaseUnitValue(e);
    nofoundData = null;
    if (e !== "") {
      setIsDisable(false);
    } else {
      setNoShowModel(false);
      setIsDisable(true);
      dispatch({
        type: "SET_RESET_SELECTED"
      });
    }
    dispatch({
      type: "SET_BASEUNIT",
      payload: {
        baseunit: e
      }
    });
  };

  useEffect(() => {
    setProgramType("None Selected");
    dispatch({
      type: SET_PROGRAM_TYPE,
      payload: "None Selected"
    });
  }, [isDisplayProdCountry]);

  useEffect(() => {
    const country = [];
    if (countryData && countryData.length !== 0) {
      for (let keys in countryData) {
        countryData[keys].countries.map(cnty => country.push(cnty));
      }
      setCountryList(country);
    }
  }, [countryData]);
  countryList.sort(sortAlphabeticallyByName);
  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.headerGrid}>
        <Grid container item xs={12} className={classes.paddingBottom}>
          <Grid xs={3} item className={classes.titlePadding}>
            <Typography
              variant="h6"
              align="left"
              margin={1}
              className={classes.selectMargin}
            >
              {t("common:productSelector.textMessage.selectOrSearch")}
            </Typography>
          </Grid>
          <Grid item xs container alignItems="center" wrap="nowrap">
            <Grid item xs={4} className={classes.marginBar}>
              <InputBase
                placeholder={t(
                  "common:productSelector.textMessage.searchBaseUnit"
                )}
                className={classes.inputBase}
                fullWidth={true}
                size="small"
                disabled={!(categories && categories.length)}
                value={baseUnitValue}
                onChange={e => handleBaseUnit(e.target.value)}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSearch();
                  }
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton onClick={handleSearch} disabled={isDisable}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Typography variant="h6" className={classes.searchResult}>
                {" "}
                {nofoundData}{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" wrap="nowrap">
                {proCategory !== "print" && (
                  <FormControl className={classes.formControl}>
                    <InputLabel id="programType-simple-select-label">
                      {/* {t("common:productSelector.textMessage.programType")} */}
                    </InputLabel>
                    <Select
                      labelId="programType-simple-select-label"
                      id="programType-simple-select"
                      value={programType || ""}
                      onChange={changeProgramType}
                      disabled={!(categories && categories.length)}
                    >
                      <MenuItem value="None Selected">
                        <Typography
                          color="textSecondary"
                          className={classes.lineHeightBar}
                        >
                          {t("common:productSelector.textMessage.programType")}
                        </Typography>
                      </MenuItem>
                      {programTypeOptions.map(programType => (
                        <MenuItem value={programType} key={programType}>
                          {programType}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {isDisplayProdCountry && (
                  <FormControl className={classes.formControl}>
                    <InputLabel id="country-simple-select-label">
                      {t("common:productSelector.textMessage.productCountry")}
                    </InputLabel>
                    <Select
                      labelId="country-simple-select-label"
                      id="country-simple-select"
                      value={
                        (countrySelectedDropdown
                          ? countrySelectedDropdown
                          : country) || ""
                      }
                      onChange={changeCountry}
                    >
                      {countryData &&
                        countryList &&
                        countryList.map(country => (
                          <MenuItem value={country.code} key={country.code}>
                            {country.name.toUpperCase()}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
                {displayBusinessModel && (
                  <FormControl className={classes.formControl}>
                    <InputLabel id="businessModel-simple-select-label">
                      {" "}
                      {t("common:productSelector.textMessage.businessModel")}
                    </InputLabel>
                    <Select
                      labelId="businessModel-simple-select-label"
                      id="businessModel-simple-select"
                      value={businessModel || ""}
                      onChange={changeBusinessModel}
                    >
                      {businessModelOptions.map(businessModel => (
                        <MenuItem value={businessModel} key={businessModel}>
                          {businessModel}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {loading && (
        <div align="center">
          <CircularProgress />
        </div>
      )}
    </React.Fragment>
  );
};

export default DefaultConfigSearchFilters;
