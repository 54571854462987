import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SET_EXPAND_COLLAPSE, UPDATE_TAB_EXPAND } from "../../../actions/Types";
import Chaid from "../../../configurator/Chaid";
import { ExternalProps } from "../../../contexts/externalPropsContext";
import {
  carePackAdditionalChaId,
  carePackRecommendedChaId,
  getAllDataExpandedOrCollapsedManul,
  shouldHideChoices
} from "../../../services/utils";
import DisplayKmatId from "./DisplayKmatId";
import DisplaySpcId from "./DisplaySpcId";
const useStyles = makeStyles(theme => ({
  btn: {
    fontFamily: "inherit",
    fontSize: "16px",
    color: "#29A8DD",
    fontWeight: "normal",
    textTransform: "capitalize"
  }
}));

const ConfigHome = props => {
  const { proCategory, localisationData } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const expandAll = useSelector(state => state.configFilterData.expandAll);
  const { visibility = {}, userType } = React.useContext(ExternalProps);
  const {
    showStartingPointInformation = true,
    isExpertConfig,
    showAccessType
  } = visibility;
  const defaultConfigStepSlected = useSelector(
    state => state.configFilterData.defaultConfigStepSlected
  );
  const filteredChoices = useSelector(
    state => state.configFilterData.filteredChoices
  );
  const searchText = useSelector(state => state.configFilterData.searchText);
  const checkedFilterParts = useSelector(
    state => state.configFilterData.checkedFilterParts
  );
  const configuration = useSelector(
    state => state.configFilterData.configuration
  );
  const filteredLoc = useSelector(state => state.configFilterData.filteredLoc);
  function showLoc(locCaption) {
    return filteredLoc.length === 0 || filteredLoc.includes(locCaption);
  }
  const modelData = useSelector(state => state.configFilterData.modelData);
  const ocfgParams = useSelector(state => state.productSelector.ocfgParams);
  const showAccessOrganization =
    showAccessType && !searchText && !checkedFilterParts;
  const isPartnerPortalFlag = userType?.toUpperCase() === "PARTNER";
  //It will dispatch an action and update the current tab expand status along with the indivisual choice expand status
  const autoExpandChoice = useSelector(
    state => state.configFilterData.autoExpandChoice
  );
  useEffect(() => {
    if (filteredChoices && filteredChoices.length) {
      if (defaultConfigStepSlected && expandAll) {
        const found = expandAll.find(
          expandedData => expandedData.tab === defaultConfigStepSlected
        );
        if (!found) {
          let choiceObject = {};
          for (let i = 0; i < filteredChoices.length; i++) {
            if (
              !shouldHideChoices(
                ocfgParams?.hideChoice,
                filteredChoices[i].chaId
              )
            ) {
              choiceObject[filteredChoices[i].chaId] = false;
            }

            if (
              defaultConfigStepSlected === "carePacks" &&
              filteredChoices[i].chaId.startsWith("CAPQ_")
            ) {
              choiceObject[filteredChoices[i].chaId + "_additional"] = false;
              choiceObject[filteredChoices[i].chaId + "_recommended"] = false;
            }
          }

          //to incorporate the locale display options in hardware tab
          if (defaultConfigStepSlected === "hardware") {
            choiceObject["local-1"] = false;
            choiceObject["local-2"] = false;
            choiceObject["local-3"] = false;
            choiceObject["local-4"] = false;
            // choiceObject["kmat-display"] = false;
            // choiceObject["spcid-display"] = false;
            choiceObject["access-type"] = false;
          }

          //to incorporate the locale display options in carePack tab
          if (defaultConfigStepSlected === "carePacks") {
            choiceObject["99999"] = false;
            choiceObject["99998"] = false;
            choiceObject["EUCPQ"] = false;
            choiceObject["ELECTCPQ"] = false;
            if (proCategory === "compute") choiceObject["SSS99"] = false;
          }
          //to incorporate the locale display options in deploymentServices tab
          if (defaultConfigStepSlected === "deploymentServices") {
            // eslint-disable-next-line array-callback-return
            Object.keys(choiceObject).every(deploymentChaid => {
              if (shouldHideChoices(ocfgParams?.hideChoice, deploymentChaid)) {
                delete choiceObject[deploymentChaid];
              }
            });
          }

          if (
            defaultConfigStepSlected === "configurationService" &&
            userType?.toUpperCase() !== "PARTNER"
          ) {
            modelData.Chaids.forEach(chaid => {
              if (chaid.chaId === "CS_C") {
                choiceObject["CS_C"] = false;
              }
            });
          }

          const payload = {
            tab: defaultConfigStepSlected,
            expand: false,
            choicesExpandState: choiceObject,
            flag: false
          };
          if (defaultConfigStepSlected === "carePacks") {
            payload["isClickExpandOrCollapse"] = true;
            if (!window.hasAnyAdditionalPacks) {
              delete payload?.choicesExpandState["99998"];
              delete payload?.choicesExpandState["EUCPQ"];
              delete payload?.choicesExpandState["ELECTCPQ"];
            }
            if (!window.hasAnyRecommendedPacks) {
              delete payload?.choicesExpandState["99999"];
              delete payload?.choicesExpandState["EUCPQ"];
              delete payload?.choicesExpandState["ELECTCPQ"];
            }
          }
          if (defaultConfigStepSlected === "hardware") {
            if (!showAccessOrganization)
              delete payload?.choicesExpandState["access-type"];
            if (!showLoc("System Localization Options"))
              delete payload?.choicesExpandState["local-1"];
            if (!showLoc("OS Localization Options"))
              delete payload?.choicesExpandState["local-2"];
            if (!showLoc("Keyboard Localization Options"))
              delete payload?.choicesExpandState["local-3"];
            if (!showLoc("Monitor Localization Options"))
              delete payload?.choicesExpandState["local-4"];
          }
          dispatch({
            type: SET_EXPAND_COLLAPSE,
            payload
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredChoices]);

  useEffect(() => {
    if (defaultConfigStepSlected && expandAll) {
      const found = expandAll.find(
        expandedData => expandedData.tab === defaultConfigStepSlected
      );
      let filterSearchChoiceObject = {};
      if (found) {
        if (checkedFilterParts && defaultConfigStepSlected === "hardware") {
          if (filteredLoc) {
            if (filteredLoc.includes("System Localization Options")) {
              filterSearchChoiceObject["local-1"] = true;
            }
            if (filteredLoc.includes("OS Localization Options")) {
              filterSearchChoiceObject["local-2"] = true;
            }
            if (filteredLoc.includes("Keyboard Localization Options")) {
              filterSearchChoiceObject["local-3"] = true;
            }
            if (filteredLoc.includes("Monitor Localization Options")) {
              filterSearchChoiceObject["local-4"] = true;
            }
          }
          if (filteredChoices.length) {
            for (let index = 0; index < filteredChoices.length; index++) {
              filterSearchChoiceObject[filteredChoices[index].chaId] = false;
            }
            const expand = getAllDataExpandedOrCollapsedManul(
              filterSearchChoiceObject
            )[0]
              ? true
              : false;
            const payload = {
              tab: defaultConfigStepSlected,
              expand,
              choicesExpandState: filterSearchChoiceObject,
              flag: false
            };
            dispatch({
              type: UPDATE_TAB_EXPAND,
              payload
            });
          }
        }
        if (searchText) {
          if (filteredChoices.length) {
            for (let index = 0; index < filteredChoices.length; index++) {
              // eslint-disable-next-line array-callback-return
              Object.keys(found.choicesExpandState).map(el => {
                if (el === filteredChoices[index].chaId) {
                  filterSearchChoiceObject[el] = false;
                }
                if (defaultConfigStepSlected === "carePacks") {
                  if (
                    el.startsWith("CAPQ_") &&
                    (el.endsWith("_recommended") || el.endsWith("_additional"))
                  ) {
                    filterSearchChoiceObject[el] = false;
                  }
                  if (
                    (filteredChoices[index].chaId === "ELECTCPQ" ||
                      filteredChoices[index].chaId === "EUCPQ") &&
                    (el === "99998" || el === "99999")
                  ) {
                    filterSearchChoiceObject[el] = false;
                  }
                  if (
                    filteredChoices[index].chaId === "PSE" ||
                    filteredChoices[index].chaId === "ABSCP"
                  )
                    if (proCategory === "compute")
                      filterSearchChoiceObject["SSS99"] = false;
                }
              });
            }
            if (defaultConfigStepSlected === "carePacks") {
              for (let i = 0; i < filteredChoices.length; i++) {
                if (
                  filteredChoices[i].chaId.startsWith("CAPQ_") &&
                  !found.choicesExpandState.hasOwnProperty(
                    filteredChoices[i].chaId
                  )
                ) {
                  found.choicesExpandState[filteredChoices[i].chaId] = false;
                  found.choicesExpandState[
                    filteredChoices[i].chaId + "_additional"
                  ] = false;
                }
              }
            }

            if (autoExpandChoice?.length > 0) {
              for (const filterChoiceItem in filterSearchChoiceObject) {
                autoExpandChoice.forEach(choiceId => {
                  if (filterChoiceItem.chaId === choiceId.chaId) {
                    filterSearchChoiceObject[filterChoiceItem] = true;
                  }
                });
              }
            }
            if (checkedFilterParts && defaultConfigStepSlected === "hardware") {
              if (filteredLoc.includes("System Localization Options")) {
                filterSearchChoiceObject["local-1"] = true;
              }
              if (filteredLoc.includes("OS Localization Options")) {
                filterSearchChoiceObject["local-2"] = true;
              }
              if (filteredLoc.includes("Keyboard Localization Options")) {
                filterSearchChoiceObject["local-3"] = true;
              }
              if (filteredLoc.includes("Monitor Localization Options")) {
                filterSearchChoiceObject["local-4"] = true;
              }
            }
            if (filteredLoc.includes("Customer")) {
              filterSearchChoiceObject["CS_C"] = true;
            }
            const expand = getAllDataExpandedOrCollapsedManul(
              filterSearchChoiceObject
            )[0]
              ? true
              : false;
            const payload = {
              tab: defaultConfigStepSlected,
              expand,
              choicesExpandState: filterSearchChoiceObject,
              flag: false
            };
            dispatch({
              type: UPDATE_TAB_EXPAND,
              payload
            });
          }
        }
        if (checkedFilterParts && filteredLoc?.length > 0) {
          for (let index = 0; index < filteredChoices.length; index++) {
            if (
              !shouldHideChoices(
                ocfgParams?.hideChoice,
                filteredChoices[index].chaId
              )
            ) {
              filterSearchChoiceObject[filteredChoices[index].chaId] = true;
            }
            if (
              defaultConfigStepSlected === "carePacks" &&
              filteredChoices[index].chaId.startsWith("CAPQ_")
            ) {
              filterSearchChoiceObject[
                filteredChoices[index].chaId + "_additional"
              ] = false;
              filterSearchChoiceObject[
                filteredChoices[index].chaId + "_recommended"
              ] = false;
            }
            // eslint-disable-next-line array-callback-return
            Object.keys(found.choicesExpandState).map(el => {
              if (defaultConfigStepSlected === "carePacks") {
                if (
                  el.startsWith("CAPQ_") &&
                  (el.endsWith("_recommended") || el.endsWith("_additional"))
                ) {
                  filterSearchChoiceObject[el] = false;
                }
                if (
                  (filteredChoices[index].chaId === "ELECTCPQ" ||
                    filteredChoices[index].chaId === "EUCPQ") &&
                  (el === "99998" || el === "99999")
                ) {
                  filterSearchChoiceObject[el] = false;
                }
                if (
                  filteredChoices[index].chaId === "PSE" ||
                  filteredChoices[index].chaId === "ABSCP"
                )
                  if (proCategory === "compute")
                    filterSearchChoiceObject["SSS99"] = false;
              }
            });
          }
          if (
            defaultConfigStepSlected === "configurationService" &&
            userType?.toUpperCase() !== "PARTNER"
          ) {
            modelData.Chaids.forEach(chaid => {
              if (chaid.chaId === "CS_C") {
                filterSearchChoiceObject["CS_C"] = false;
              }
            });
          }

          //to incorporate the locale display options in carePack tab
          if (defaultConfigStepSlected === "carePacks") {
            filterSearchChoiceObject["99999"] = false;
            filterSearchChoiceObject["99998"] = false;
            filterSearchChoiceObject["EUCPQ"] = false;
            filterSearchChoiceObject["ELECTCPQ"] = false;
            if (proCategory === "compute")
              filterSearchChoiceObject["SSS99"] = false;
          }
          const expand = getAllDataExpandedOrCollapsedManul(
            filterSearchChoiceObject
          )[0]
            ? true
            : false;
          const payload = {
            tab: defaultConfigStepSlected,
            expand,
            choicesExpandState: filterSearchChoiceObject,
            flag: false
          };
          dispatch({
            type: UPDATE_TAB_EXPAND,
            payload
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, checkedFilterParts, filteredLoc]);

  //This function will get all elements collapsed or expanded
  const getAllDataExpandedOrCollapsed = () => {
    const relevantExpandData = expandAll.find(
      expandedData => expandedData.tab === defaultConfigStepSlected
    );
    if (relevantExpandData) {
      const allChoiceCollapsed = Object.keys(
        relevantExpandData.choicesExpandState
      ).every(choice => {
        if (choice === "ELECTCPQ" || choice === "EUCPQ") {
          return true;
        }
        if (
          choice === carePackAdditionalChaId &&
          !window.hasAnyAdditionalPacks
        ) {
          return true;
        }
        if (
          choice === carePackRecommendedChaId &&
          !window.hasAnyRecommendedPacks
        ) {
          return true;
        }
        if (choice === "ABSCP" && !window.ABSCP) {
          return true;
        }
        if (choice === "PSE" && !window.PSE) {
          return true;
        }

        if (defaultConfigStepSlected === "deploymentServices") {
          if (shouldHideChoices(ocfgParams?.hideChoice, choice)) {
            return true;
          }
        }
        return relevantExpandData.choicesExpandState[choice] === false;
      });
      //checking if all choices are expanded
      const allChoiceExpanded = Object.keys(
        relevantExpandData.choicesExpandState
      ).every(choice => {
        if (choice === "ELECTCPQ" || choice === "EUCPQ") {
          return true;
        }
        if (
          choice === carePackAdditionalChaId &&
          !window.hasAnyAdditionalPacks
        ) {
          return true;
        }
        if (
          choice === carePackRecommendedChaId &&
          !window.hasAnyRecommendedPacks
        ) {
          return true;
        }
        if (choice === "ABSCP" && !window.ABSCP) {
          return true;
        }
        if (choice === "PSE" && !window.PSE) {
          return true;
        }
        if (defaultConfigStepSlected === "deploymentServices") {
          if (shouldHideChoices(ocfgParams?.hideChoice, choice)) {
            return true;
          }
        }
        return relevantExpandData.choicesExpandState[choice] === true;
      });
      return [allChoiceExpanded, allChoiceCollapsed];
    }
  };

  //this function will handle the onClick event of Button which will expand all or collapse all the choice accordians
  const onExpandBtnHandler = e => {
    e.preventDefault();
    const findExpandData = expandAll.find(
      expandedData => expandedData.tab === defaultConfigStepSlected
    );
    if (findExpandData) {
      let choiceObject = {};
      const allExpanded = getAllDataExpandedOrCollapsed()[0];
      if (allExpanded) findExpandData.expand = allExpanded;

      const allCollasped = getAllDataExpandedOrCollapsed()[1];
      if (allCollasped) findExpandData.expand = false;
      if (findExpandData.expand) {
        //that means we want to collapse all.
        for (let i = 0; i < filteredChoices.length; i++) {
          if (
            !shouldHideChoices(ocfgParams?.hideChoice, filteredChoices[i].chaId)
          ) {
            choiceObject[filteredChoices[i].chaId] = false;
          }
          if (
            defaultConfigStepSlected === "carePacks" &&
            filteredChoices[i].chaId.startsWith("CAPQ_")
          ) {
            // eslint-disable-next-line array-callback-return
            Object.keys(findExpandData.choicesExpandState).map(item => {
              if (
                item.endsWith("_additional") ||
                item.endsWith("_recommended")
              ) {
                choiceObject[item] = false;
              }
              if (
                filteredChoices[i].chaId.startsWith("CAPQ_") &&
                !item.endsWith("_recommended") &&
                !item.endsWith("_additional")
              ) {
                choiceObject[filteredChoices[i].chaId + "_additional"] = false;
              }
            });
          }
        }
        //to address the localisation cases -> harware tab
        if (defaultConfigStepSlected === "hardware") {
          choiceObject["local-1"] = false;
          choiceObject["local-2"] = false;
          choiceObject["local-3"] = false;
          choiceObject["local-4"] = false;
          // choiceObject["kmat-display"] = false;
          // choiceObject["spcid-display"] = false;
          choiceObject["access-type"] = false;
        }
        //to address the localisation cases -> carePack tab

        if (defaultConfigStepSlected === "carePacks") {
          choiceObject["99999"] = false;
          choiceObject["99998"] = false;
          choiceObject["EUCPQ"] = false;
          choiceObject["ELECTCPQ"] = false;
          if (proCategory === "compute") choiceObject["SSS99"] = false;
        }
        if (
          defaultConfigStepSlected === "configurationService" &&
          userType?.toUpperCase() !== "PARTNER"
        ) {
          modelData.Chaids.forEach(chaid => {
            if (chaid.chaId === "CS_C") {
              choiceObject["CS_C"] = false;
            }
          });
        }
      } else {
        //means we want to expand all
        for (let i = 0; i < filteredChoices.length; i++) {
          if (
            !shouldHideChoices(ocfgParams?.hideChoice, filteredChoices[i].chaId)
          ) {
            choiceObject[filteredChoices[i].chaId] = true;
          }
          if (
            defaultConfigStepSlected === "carePacks" &&
            filteredChoices[i].chaId.startsWith("CAPQ_")
          ) {
            // eslint-disable-next-line array-callback-return
            Object.keys(findExpandData.choicesExpandState).map(item => {
              if (
                item.endsWith("_additional") ||
                item.endsWith("_recommended")
              ) {
                choiceObject[item] = true;
              }
              if (
                filteredChoices[i].chaId.startsWith("CAPQ_") &&
                !item.endsWith("_recommended") &&
                !item.endsWith("_additional")
              ) {
                choiceObject[filteredChoices[i].chaId + "_additional"] = true;
              }
            });
          }
        }
        //to address the localisation cases -> harware tab
        if (defaultConfigStepSlected === "hardware") {
          choiceObject["local-1"] = true;
          choiceObject["local-2"] = true;
          choiceObject["local-3"] = true;
          choiceObject["local-4"] = true;
          // choiceObject["kmat-display"] = false;
          // choiceObject["spcid-display"] = false;
          choiceObject["access-type"] = true;
        }
        //to address the localisation cases -> carePack tab

        if (defaultConfigStepSlected === "carePacks") {
          choiceObject["99999"] = true;
          choiceObject["99998"] = true;
          choiceObject["EUCPQ"] = true;
          choiceObject["ELECTCPQ"] = true;
          if (proCategory === "compute") choiceObject["SSS99"] = true;
        }
        if (
          defaultConfigStepSlected === "configurationService" &&
          userType?.toUpperCase() !== "PARTNER"
        ) {
          modelData.Chaids.forEach(chaid => {
            if (chaid.chaId === "CS_C") {
              choiceObject["CS_C"] = true;
            }
          });
        }
        //to incorporate the locale display options in deploymentServices tab
        if (defaultConfigStepSlected === "deploymentServices") {
          // eslint-disable-next-line array-callback-return
          Object.keys(choiceObject).every(deploymentChaid => {
            if (shouldHideChoices(ocfgParams?.hideChoice, deploymentChaid)) {
              delete choiceObject[deploymentChaid];
            }
          });
        }
      }
      const payload = {
        tab: defaultConfigStepSlected,
        expand: !findExpandData.expand,
        choicesExpandState: choiceObject,
        flag: true
      };
      if (defaultConfigStepSlected === "carePacks") {
        payload["isClickExpandOrCollapse"] = true;
        if (!window.hasAnyAdditionalPacks && !window.hasAnyRecommendedPacks) {
          delete payload?.choicesExpandState["99998"];
          delete payload?.choicesExpandState["99999"];
          delete payload?.choicesExpandState["EUCPQ"];
          delete payload?.choicesExpandState["ELECTCPQ"];
        }
      }
      if (defaultConfigStepSlected === "hardware") {
        if (!showAccessOrganization)
          delete payload?.choicesExpandState["access-type"];
        if (!showLoc("System Localization Options"))
          delete payload?.choicesExpandState["local-1"];
        if (!showLoc("OS Localization Options"))
          delete payload?.choicesExpandState["local-2"];
        if (!showLoc("Keyboard Localization Options"))
          delete payload?.choicesExpandState["local-3"];
        if (!showLoc("Monitor Localization Options"))
          delete payload?.choicesExpandState["local-4"];
      }
      dispatch({
        type: UPDATE_TAB_EXPAND,
        payload
      });
    }
  };

  // TODO: 37280
  const fetchButtonContent = useCallback(() => {
    if (expandAll.length && defaultConfigStepSlected) {
      const relevantExpandData = expandAll.find(
        expandedData => expandedData.tab === defaultConfigStepSlected
      );
      if (relevantExpandData) {
        const allChoiceCollapsed = getAllDataExpandedOrCollapsed()[1];
        const allChoiceExpanded = getAllDataExpandedOrCollapsed()[0];
        if (relevantExpandData.expand && allChoiceCollapsed) {
          return t("common:configurator:expandAll");
        } else if (!relevantExpandData.expand && allChoiceExpanded) {
          return t("common:configurator:collapseAll");
        } else if (relevantExpandData.expand) {
          return t("common:configurator:collapseAll");
        } else if (!relevantExpandData.expand) {
          return t("common:configurator:expandAll");
        }
      }
    } else {
      return "Loading";
    }
  }, [expandAll, dispatch, defaultConfigStepSlected]);

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        {(filteredChoices.length ||
          (filteredLoc.length && filteredLoc[0] !== "dummy")) && (
          <Button
            color="primary"
            className={classes.btn}
            onClick={onExpandBtnHandler}
          >
            {fetchButtonContent()}
          </Button>
        )}
      </Box>
      <Chaid
        tabId={defaultConfigStepSlected}
        proCategory={proCategory}
        localisationData={localisationData}
        configurationName={configuration?.configHeader?.configName}
      />
      {defaultConfigStepSlected === "hardware" &&
        !searchText &&
        !checkedFilterParts && <DisplayKmatId chaid="kmat-display" />}
      {!isPartnerPortalFlag &&
        defaultConfigStepSlected === "hardware" &&
        !searchText &&
        !checkedFilterParts &&
        showStartingPointInformation && (
          <DisplaySpcId chaid="spcid-display" isExpertConfig={isExpertConfig} />
        )}
    </>
  );
};

export default ConfigHome;
