import Typography from "@material-ui/core/Typography";
import React from "react";
import { carePackRecommendedChaId } from "../../services/utils";
import ChaidMultiple from "../ChaidMultiple";
import { useSelector } from "react-redux";

export const CarePackRecommendation = props => {
  const recommendedCarePacks = {
    chaId: carePackRecommendedChaId,
    chaDes: "Top Recommended Care Packs",
    multiple: true,
    required: false,
    visible: true,
    precon: 0,
    selcon: false,
    firstItem: 999999,
    lastItem: -1,
    isNode: -1,
    specified: true,
    isUsed: true,
    items: []
  };
  let hasAnyRecommendedPacks = false;
  window.hasAnyRecommendedPacks = false;
  let updatedFilteredChoices = JSON.parse(
    JSON.stringify(props.filteredChoices)
  );
  const searchText = useSelector(state => state.configFilterData.searchText);
  const showTopRecommended = useSelector(
    state => state.configFilterData.showTopRecommended
  );
  props.filteredChoices.forEach((chaid, index) => {
    if (
      (chaid.chaId.includes("EUCPQ") || chaid.chaId.includes("ELECTCPQ")) &&
      !props.isChaidHideBasedOnRegion(chaid.chaId) &&
      chaid.visible
    ) {
      recommendedCarePacks.firstItem = recommendedCarePacks.firstItem
        ? chaid.firstItem < recommendedCarePacks.firstItem
          ? chaid.firstItem
          : recommendedCarePacks.firstItem
        : chaid.firstItem;
      recommendedCarePacks.lastItem = recommendedCarePacks.lastItem
        ? chaid.lastItem > recommendedCarePacks.lastItem
          ? chaid.lastItem
          : recommendedCarePacks.lastItem
        : chaid.lastItem;
      recommendedCarePacks.items = [
        ...recommendedCarePacks.items,
        ...chaid.items.filter(item => item.priority <= 5 && item.visible)
      ];
      updatedFilteredChoices = [
        ...updatedFilteredChoices.slice(0, index),
        ...updatedFilteredChoices.slice(index + 1)
      ];

      let isVisible =
        recommendedCarePacks.required ||
        recommendedCarePacks.selcon ||
        recommendedCarePacks.items.some(
          part =>
            part.partno !== "" &&
            ((part.visible && part.precon >= 0) || part.selected) &&
            part.inode >= recommendedCarePacks.firstItem &&
            part.inode <= recommendedCarePacks.lastItem
        );

      hasAnyRecommendedPacks =
        recommendedCarePacks.items.some(
          item =>
            item.partno.toLowerCase().includes(searchText.toLowerCase()) ||
            item.partdes.toLowerCase().includes(searchText.toLowerCase())
        ) && isVisible;
      window.hasAnyRecommendedPacks = hasAnyRecommendedPacks;
    }
  });
  return showTopRecommended || hasAnyRecommendedPacks ? (
    <ChaidMultiple
      key={recommendedCarePacks.chaId}
      chaid={recommendedCarePacks}
      model={props.modelData}
      handleItemChange={props.handleItemChange}
      handleQuantityChange={props?.handleQuantityChange}
    />
  ) : (
    props.proCategory !== "print" && (
      <Typography
        variant="subtitle1"
        style={{ color: "#29A8DD", marginBottom: "1.35em" }}
      >
        NO TOP RECOMMENDED CARE PACKS FOUND
      </Typography>
    )
  );
};
