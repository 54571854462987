import { Box, makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { togglePopupActions } from "../../../actions/togglePopupActions";
import ExternalProps from "../../../contexts/externalPropsContext";
import ClipboardField from "../../../lib/common/ClipboardField";
import { BOM } from "./Constants";
import ProductBullets from "./ProductBullets";

const useStyles = makeStyles(theme => ({
  title: {
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white"
  },
  card: {
    width: "auto",
    minHeight: "100%",
    marginLeft: "5px"
  },
  cardContent: {
    "&:hover": {
      border: "2px solid #0096D6",
      borderTop: "none",
      cursor: "pointer"
    }
  }
}));

export default function CompareCard(props) {
  const classes = useStyles();
  const { isStandaloneAccess = false } = React.useContext(ExternalProps);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { type, product } = props;
  const title =
    type === BOM
      ? t("common:rsku.compare.billOfMaterials")
      : t("common:rsku.compare.similarProducts");

  const { similarProducts } = useSelector(state => state.rskuReducer);

  const handleComapreDialogClose = React.useCallback(() => {
    dispatch(togglePopupActions({ name: "compareDialog", open: false }));
  }, []);

  const quantityAddition = (sku, quantity) => {
    let isSimilarProduct = false;

    similarProducts.map(products => {
      if (sku === products.sku) {
        isSimilarProduct = true;
      }
    });
    batch(() => {
      dispatch({
        type: "SET_SELECTED_SKU",
        payload: sku
      });

      dispatch({
        type: "SET_UPDATE_QUANTITY",
        payload: {
          sku,
          quantity: quantity + 1,
          isSimilarProduct,
          skuType: product.skuType
        }
      });
    });
  };

  const handleCardContent = (sku, quantity) => {
    if (type !== BOM) {
      quantityAddition(product.sku, product.quantity);
      handleComapreDialogClose();
      dispatch({
        type: "SET_ACTIVE_TAB",
        payload: 0
      });
    }
  };
  return (
    <Card className={classes.card}>
      <Box
        className={classes.title}
        style={{ backgroundColor: type === BOM ? "#0096D6" : "#075782" }}
      >
        {title}
      </Box>
      <CardContent
        style={{ marginBottom: "0px", paddingBottom: "0px" }}
        className={type !== BOM && !isStandaloneAccess && classes.cardContent}
        onClick={!isStandaloneAccess && handleCardContent}
      >
        <Box
          width="100%"
          height="auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CardMedia
            style={{
              width: "250px",
              height: "180px"
            }}
            component="img"
            image={product.image}
          />
        </Box>

        <Typography variant={"subtitle1"}>{product.name}</Typography>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Typography variant={"body2"}>
            {product.sku}
            <ClipboardField text={product.sku} />
          </Typography>
        </Box>
        <ProductBullets bullets={product.attributes} lg={12}></ProductBullets>
      </CardContent>
    </Card>
  );
}
