import { Grid, Paper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const DisplayError = props => {
  const { message, warningMsg, uploadStatus } = props;
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <Typography>
        {message.length
          ? t("common:uploadConfig.errorsNote")
          : t("common:uploadConfig.warningsNote")}
      </Typography>
      <Paper
        style={{
          backgroundColor: message.length
            ? "#F7D7D7"
            : warningMsg.length
            ? "#ffbf00"
            : "",
          padding: "10px"
        }}
      >
        {uploadStatus === "NON BUILDABLE" || message.length ? (
          <Typography
            style={{
              color: "red",
              wordWrap: "break-word",
              whiteSpace: "pre-wrap"
            }}
          >
            {message}
          </Typography>
        ) : uploadStatus === "BUILDABLE" && warningMsg.length ? (
          <Typography
            style={{
              wordWrap: "break-word",
              whiteSpace: "pre-wrap"
            }}
            variant={"subtitle2"}
          >
            {warningMsg}
          </Typography>
        ) : (
          <></>
        )}
      </Paper>
      {props.modelId && !uploadStatus === "BUILDABLE" && !warningMsg.length && (
        <Typography style={{ padding: "5px 5px 5px 0px" }}>
          You can resolve the issues by modifying configurations.
          <br /> Click "Re-configure" Button to proceed or "Return To Quote"
          Button to re-configure in the system where it was initially created.
        </Typography>
      )}
    </Grid>
  );
};
