import { MANDACarepackChaidPreffix } from "./Constants";
import {
    getLocChoiceIdsByCategory,
    isItemDatesValidOrMark,
    updateModel
} from "./util";

const valid = "V";
const DO_DESELECT = -1;
const DO_SELECT = 1;
export const updateCarepacksMANDA = (modelData, mandaCarepack) => {
  const isDebug = localStorage.getItem("isDebug") === "true";
  isDebug && console.log("Start updateCarepacksMANDA fn");
  let cp = [];
  modelData?.Chaids.forEach((chaid,index) => {
    if (chaid?.chaId == mandaCarepack) {
      for (let i = chaid.firstItem; i <= chaid.lastItem; i++) {
        if (modelData.Items[i].visible && modelData.Items[i].selected) {
          modelData.Items[i].selected = false; //carepacks are not driven by neural-net
          const doesElementExistSlice = modelData.carePacks?.find(
            carepack => carepack.itemInode === i
          );
          if (doesElementExistSlice) {
            cp =
              modelData.carePacks.length > 0 &&
              modelData.carePacks.filter(each => each.itemInode !== i);
            cp =
              cp &&
              cp.reduce((data, each) => {
                const duplicateItems = data.find(
                  item => item.itemInode === each.itemInode
                );
                if (!duplicateItems) {
                  return data.concat([each]);
                } else {
                  return data;
                }
              }, []);
            modelData.carePacks = cp;
          }
        }
      }
    }
  });
  isDebug && console.log("End updateCarepacksMANDA fn");
  return modelData
};
//src/webasm/localization.go
export const applyLocalizationMANDA = (
  selectedLocCat,
  choice,
  oldOption,
  newOption,
  model
) => {
  const isDebug = localStorage.getItem("isDebug") === "true";
  let updatedModel = model;
  if (getLocChoiceIdsByCategory(selectedLocCat, choice.chaId)) {
    for (let i = choice.firstItem; i <= choice.lastItem; i++) {
    let item = updatedModel.Items[i];
      if (item.partno.includes("#") && item.plcStatus === valid) {
        let itemLocOpt = item.partno.split("#")[1];
        const itemValidationOutput = isItemDatesValidOrMark(
          new Date(),
          choice,
          item
        );
        updatedModel.Items[i] = itemValidationOutput?.item;
        item = itemValidationOutput?.item;
        if (
          itemLocOpt.toUpperCase() === newOption.locOption.toUpperCase() &&
          !item.visible &&
          itemValidationOutput?.isValid
        ) {
          item.visible = true;
          isDebug &&
            console.log(
              "+++Localized item is set visible for =",
              item.partno,
              item.partdes,
              "under choice =",
              choice.chaId,
              choice.chaDes,
              "by updateVisibleFlagsForLocalizedItems"
            );
        }
        if (
          itemLocOpt.toUpperCase() === oldOption.locOption.toUpperCase() &&
          item.visible
        ) {
          if (item.selected) {
            updatedModel = updateModel(
              updatedModel.Nodes[item.inode].type,
              updatedModel.Nodes[item.inode].id,
              DO_DESELECT,
              updatedModel
            );
            isDebug &&
              console.log(
                "---Deselected item:",
                item.partno,
                item.partdes,
                "in choice ",
                choice.chaId,
                choice.chaDes,
                "by updateVisibleFlagsForLocalizedItems ---"
              );
            let itemBasePart = item.partno.split("#")[0];
            updatedModel = updateCarepacksMANDA(
              updatedModel,
              MANDACarepackChaidPreffix + itemBasePart
            );
            let newPartNo =
                itemBasePart + "#" + newOption.locOption;
            for (let j = choice.firstItem; j <= choice.lastItem; j++) {
              let nItem = updatedModel.Items[j];
              const itemValidationOutput = isItemDatesValidOrMark(
                new Date(),
                choice,
                nItem
              );
              if (nItem.partno === newPartNo && nItem.plcStatus === valid && itemValidationOutput?.isValid) { // TODO check price information here~!
                let node = updatedModel.Nodes[nItem.inode];
                updatedModel = updateModel(
                  node.type,
                  node.id,
                  DO_SELECT,
                  updatedModel
                );
                isDebug &&
                  console.log(
                    "+++Selected item:",
                    nItem.partno,
                    nItem.partdes,
                    "in choice ",
                    choice.chaId,
                    choice.chaDes,
                    "---"
                  );
              }
            }
          }
          item.visible = false;
          isDebug && console.log(
            "---Localized item is set invisible for =",
            item.partno,
            item.partdes,
            "under choice =",
            choice.chaId,
            choice.chaDes,
            "by updateVisibleFlagsForLocalizedItems"
          );
        }
      }
    }
  }
  return updatedModel;
};
