import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useState } from "react";
import { ExternalProps } from "../../../contexts/externalPropsContext";
import { generatArrayOfValues } from "../../../lib/common/util";
const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: 10
  },
  avatar: {
    width: 130,
    height: 100
  },
  listItem: {
    paddingRight: 18
  },

  header: {
    marginLeft: 16,
    marginRight: 16
  },
  bullets: {
    paddingLeft: 16,
    marginTop: 2,
    listStyleType: "none"
  },
  quanity: {
    width: 25,
    height: 25
  }
}));

const ProductSkeleton = () => {
  const { isStandaloneAccess = false } = React.useContext(ExternalProps);
  const classes = useStyles();
  const [productAttributes, setProductAttributes] = useState(
    generatArrayOfValues(6)
  );
  return (
    <Grid xs={12}>
      <Paper className={classes.paper}>
        <Grid container xs={12} spacing={2}>
          <Grid zeroMinWidth item xs="auto">
            <Skeleton variant="rectangle" className={classes.avatar} />
            <Typography key="caption" variant="caption">
              <Skeleton />
            </Typography>
          </Grid>
          <Grid zeroMinWidth item xs sm md lg xl>
            <Typography component="div" variant="body1">
              <Skeleton className={classes.header} />
            </Typography>
            <Grid container component={"ul"} className={classes.bullets}>
              {productAttributes.map(each => (
                <Grid
                  item
                  xs={6}
                  component={"li"}
                  zeroMinWidth
                  className={classes.listItem}
                >
                  <Typography variant="caption" component={"div"} gutterBottom>
                    <Skeleton />
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid zeroMinWidth item xs={"auto"}>
            <Typography
              variant={"body1"}
              noWrap
              component={"div"}
              color="primary"
              style={{ marginBottom: "2rem" }}
            >
              <Skeleton width="100px" />
            </Typography>
            {!isStandaloneAccess && (
              <Box display={"flex"} justifyContent="space-between">
                <Skeleton variant="circle" className={classes.quanity} />
                <Typography variant={"caption"} style={{ paddingTop: 10 }}>
                  <Skeleton width={25} height={10} />
                </Typography>
                <Skeleton variant="circle" className={classes.quanity} />
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ProductSkeleton;
