import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import React from "react";
import { useDispatch } from "react-redux";
import { ExternalProps } from "../../../contexts/externalPropsContext";

const useStyles = makeStyles(theme => {
  return createStyles({
    quantityTextField: {
      margin: `0px ${theme.spacing(0.5)}px`,
      "& [class*=MuiInput-input]": {
        textAlign: "center"
      }
    },
    quantityButton: {
      padding: 0
    },
    deleteIcon: {
      minWidth: 0,
      marginLeft: theme.spacing(1.5),
      padding: theme.spacing(0.5),
      "&:hover": {
        color: theme.palette.secondary.main
      }
    },
    deleteIconTooltipTitle: {
      textTransform: "capitalize"
    }
  });
});

const ProductQuantity = ({
  sku,
  category,
  listPrice,
  name,
  image,
  origin,
  parentSku,
  currency,
  currencySign,
  autoAttached,
  quantity,
  isSimilarProduct,
  skuType
}) => {
  const classes = useStyles();
  const [inputWidth, setInputWidth] = React.useState(27);
  const dispatch = useDispatch();
  const { isStandaloneAccess = false } = React.useContext(ExternalProps);
  const handleOutputQuantities = quantityValue => {
    const payload = {
      sku,
      quantity: quantityValue,
      category,
      name,
      listPrice,
      image,
      parentSku,
      currency,
      currencySign,
      isSimilarProduct,
      skuType
    };

    quantity = quantityValue;
    dispatch({
      type: "SET_UPDATE_QUANTITY",
      payload
    });
  };
  const handleIncrement = () => {
    handleOutputQuantities(parseInt(quantity) + 1);
  };

  const handleDecrement = () => {
    handleOutputQuantities(parseInt(quantity) - 1);
  };

  const handleQuantity = event => {
    const regexp = /^[0-9\b]+$/;
    const quantityValue = event.target.value || 0;
    if (
      regexp.test(quantityValue) &&
      quantityValue >= 0 &&
      quantityValue <= 999999
    ) {
      handleOutputQuantities(parseInt(quantityValue));
    }
  };

  const getFieldWidth = React.useCallback(value => {
    const input = value ? value.toString() : 0;
    const width = input ? (parseInt(input.toString().length) + 2) * 9 : 27;
    setInputWidth(width);
  }, []);

  React.useEffect(() => {
    getFieldWidth(quantity);
  }, [quantity]);

  return (
    <>
      <Box whiteSpace={"nowrap"}>
        <IconButton
          onClick={handleDecrement}
          size="small"
          disabled={isStandaloneAccess || quantity <= 0}
          color="primary"
          component="span"
          className={classes.quantityButton}
        >
          <RemoveCircleIcon />
        </IconButton>
        <TextField
          style={{ width: `${inputWidth}px` }}
          onChange={handleQuantity}
          type="text"
          value={quantity}
          disabled={isStandaloneAccess}
          InputProps={{
            inputProps: {
              maxLength: 6
            }
          }}
          className={classes.quantityTextField}
        />
        <IconButton
          onClick={handleIncrement}
          component="span"
          size="small"
          color="primary"
          disabled={isStandaloneAccess || quantity >= 999999}
          className={classes.quantityButton}
        >
          <AddCircleIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default ProductQuantity;
