import { Box, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
  required: {
    color: "#ff0000"
  }
}));

function ItemSingleSelect({ chaid, handleItemChange, item, model, chaidReq }) {
  const classes = useStyles();

  const invisibleItems = model.Items.filter(
    part =>
      part.visible === false &&
      part.precon >= 0 &&
      part.inode >= chaid.firstItem &&
      part.inode <= chaid.lastItem
  ).map(item => item);

  return (
    <>
      <div id={"chaid-dropdown-" + chaid.chaId}>
        <select
          name={chaid.chaId}
          id={chaid.chaId}
          value={item ? item.inode : ""}
          onChange={handleItemChange}
          className={`cp-dropdown ${
            chaidReq && !item ? classes.required : null
          }`}
        >
          {item ? null : chaidReq ? (
            <option key="empty" value="empty">
              {"<"}Please make a selection{">"}
            </option>
          ) : (
            <option key="empty" value="empty">
              None
            </option>
          )}

          {model.Items.filter(
            part =>
              part.visible &&
              part.precon >= 0 &&
              part.inode >= chaid.firstItem &&
              part.inode <= chaid.lastItem
          ).map(item => (
            <option key={item.inode} value={item.inode}>
              {item.partno} - {item.partdes}
            </option>
          ))}
        </select>
      </div>

      <Box width="800px" marginBottom="5px">
        {invisibleItems.length > 0 ? (
          <i>Invisible Items Count:{invisibleItems.length}</i>
        ) : null}
      </Box>
    </>
  );
}

export default ItemSingleSelect;
